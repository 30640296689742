import React from 'react'
import PropTypes from 'prop-types'; // ES6
import { v4 as uuid } from 'uuid';
import utils from '../../Services/Utils';


class BaseInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFocused: false,
            isValid: props.required ? !!props.value : true,
        }
        this.uid = uuid()
        this.initHyperForm();
        this.changeCalled = false;
    }

    initHyperForm = () => {
        if (this.props.frm)
            this.props.frm.validate(this.uid, this)
    }

    componentWillUnmount() {
        if (this.props.frm)
            this.props.frm.removeValidation(this.uid, this)
    }

    // componentDidCatch(ex) {
    //     console.log(ex)
    // }

    onChange = (event) => {
        this.changeCalled = true;
        // let event = values[0];
        if (event && event.target && event.target.value !== undefined) {
            let value = event.target.value;
            let isValid = true;
            if (this.props.required) {
                // value = value.trim();
                isValid = this.validateValue(value)
            }

            this.setState({ isValid });
        }
        else {
            // console.log(this.props.required, event)
            if (this.props.required && !event) {
                this.setState({ isValid: false })
            }
            else {
                this.setState({ isValid: true })
            }
        }

        if (this.props.onChange)
            this.props.onChange(event);
    }

    onBlur = (event) => {
        this.changeCalled = true;
        // let event = values[0];
        if (event && event.target && event.target.value !== undefined) {
            let value = event.target.value;
            let isValid = true;
            if (this.props.required) {
                // value = value.trim();
                isValid = this.validateValue(value)
            }

            this.setState({ isValid });
        }
        else {
            // console.log(this.props.required, event)
            if (this.props.required && !event) {
                this.setState({ isValid: false })
            }
            else {
                this.setState({ isValid: true })
            }
        }

        if (this.props.onBlur)
            this.props.onBlur(event);
    }

    validateValue(value) {
        // console.log(value)
        // console.log(value,this.props.label)
        let isValid = true;
        if (value && value.trim)
            value = value.trim()
        if (this.props.type === "email") {
            isValid = utils.isValidEmail(value);
        }
        else if (this.props.type === "number") {
            isValid = utils.IsNumber(value);
            if (this.props.min !== undefined) {
                isValid = parseInt(value) >= parseInt(this.props.min)
            }
            if (this.props.max !== undefined) {
                isValid = parseInt(value) <= parseInt(this.props.max)
            }
        }
        else if (this.props.type === "mobile") {
            isValid = utils.IsNumber(value) && value.toString().length === 10;
        }
        else if (this.props.type === "password") {
            isValid = value.length >= 8 && value.length <= 128;
        }
        else {
            if (typeof value != "string")
                isValid = !!value;
            else
                isValid = !!value;
        }
        return isValid;
    }

    onFocus = (...args) => {
        this.setState({ isFocused: true }, () => {

        });
        if (this.props.onFocus) {
            this.props.onFocus(...args);
        }
    }

    isValid() {
        if (!this.props.required)
            return true;
        return this.state.isFocused ? this.validateValue(this.getValue()) : true;
    }

    isActuallyValid() {
        if (!this.props.required)
            return true;
        return this.validateValue(this.getValue())
    }

    getValue = () => {
        return this.props.value;
    }

    getHelperText() {
        if (this.props.helperText)
            return this.props.helperText;
        let msg = "";
        if (!this.isValid() && this.state.isFocused) {
            if (this.props.type === "email") {
                msg = "Enter valid email address";
            }
            else if (this.props.type === "number") {
                msg = "Enter valid number";
                if (this.props.min !== undefined) {
                    msg += ", Number should not be less then " + this.props.min;
                }
                if (this.props.max !== undefined) {
                    msg += ", Number should not be grater then " + this.props.max;
                }
            }
            else if (this.props.type === "mobile") {
                msg = "Mobile number must be of 10 digits";
            }
            else if (this.props.type === "password") {
                msg = "Password must be of minimum 8 characters";
            }
            else {
                msg = "This field is required";
            }
        }
        // console.log(this.isValid(), this.state, msg)
        return msg;
    }

}

BaseInput.propTypes = {
    required: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.any,
    frm: PropTypes.any
}

export default BaseInput;
