const enums = {
    ACCOUNT_CATEGORY: {
        PURCHASE: "PURCHASE",
        PURCHASE_RETURN: "PURCHASE_RETURN",
        SALES: "SALES",
        SALES_RETURN: "SALES_RETURN",
        CUSTOMER: "CUSTOMER",
        SUPPLIER: "SUPPLIER",
        PAYMENT: "PAYMENT",
        RECEIPT: "RECEIPT",
        EXPENSE: "EXPENSE",
        INCOME: "INCOME",
        CASH_BANK: "CASH_BANK",
        FINANCE_COMPANY: "FINANCE_COMPANY",
        CAPITAL: "CAPITAL",
        DRAWING: "DRAWING",
        PERSONAL: "PERSONAL",
        REAL: "REAL",
        PNL: "PNL",
    },
    JOURNAL_CATEGORY: {
        PURCHASE: "PURCHASE",
        PURCHASE_RETURN: "PURCHASE_RETURN",
        PURCHASE_CREDIT_NOTE: "PURCHASE_CREDIT_NOTE",
        SALES: "SALES",
        SALES_RETURN: "SALES_RETURN",
        SALES_DEBIT_NOTE: "SALES_DEBIT_NOTE",
        CUSTOMER: "CUSTOMER",
        SUPPLIER: "SUPPLIER",
        PAYMENT: "PAYMENT",
        RECEIPT: "RECEIPT",
        EXPENSE: "EXPENSE",
        INCOME: "INCOME",
        CASH_BANK: "CASH_BANK",
        CAPITAL: "CAPITAL",
        CONTRA: "CONTRA",
        DRAWING: "DRAWING",
        PERSONAL: "PERSONAL",
        REAL: "REAL",
        PNL: "PNL",
    },
    AUTO_COMPLETE_ADD_TYPE: {
        ADD_CUSTOMER: "ADD_CUSTOMER",
        ADD_SUPPLIER: "ADD_SUPPLIER",
        ADD_EXPENSE: "ADD_EXPENSE",
        ADD_INCOME: "ADD_INCOME",
        ADD_PAYMENT_OPTION: "ADD_PAYMENT_OPTION",
    },
    ACCOUNT_TYPE: {
        PERSONAL: "PERSONAL",
        PNL: "PNL",
        REAL: "REAL",
    },
    CATEGORY_TO_TYPE_MAP: {

    },
    SALES_PURCHASE_STATUS: {
        // ALL: 'ALL',
        PAID: 'Paid',
        PARTIALLY_PAID: 'Partially Paid',
        UNPAID: 'Unpaid'
    },
    PAYMENT_STATUS: {
        PAID: 'Paid',
        PARTIALLY_PAID: 'Partially Paid',
        UNPAID: 'Unpaid'
    },
    DB_ACTION: {
        INSERT: "INSERT",
        UPDATE: "UPDATE",
        DELETE: "DELETE",
        DELETE_MANY: 'DELETE_MANY',
    },
    EVENT: {
        ACCOUNT_ADD_UPDATE_MODEL: "ACCOUNT_ADD_UPDATE_MODEL",
        UPDATE_ITEM_AUTO_COMPLETE_LIST: "UPDATE_ITEM_AUTO_COMPLETE_LIST",
        LOGOUT: "LOGOUT",
        BARCODE_SCANNED: "BARCODE_SCANNED",
        ENQUEUE_SNACKBAR: "ENQUEUE_SNACKBAR",
    },
    FILE_DOCUMENT_TYPE: {
        USER: "USER",
        OTHER: "OTHER",
        SALES_PURCHASE: "SALES_PURCHASE",
    },
    DISCOUNT_OPTION: {
        FIXED: "Fixed",
        PERCENTAGE: "Percentage",
    },
    ITEM_TYPE: {
        PRODUCT: "Product",
        SERVICE: "Service",
    },
    ALERT_TYPE: {
        SUCCESS: 'SUCCESS',
        ERROR: 'ERROR',
        INFO: 'INFO',
        WARNING: 'WARNING',
    },
    USER_DATA: "userData",
    SOCKET_API_ACTION_CODE: {
        GET_JOURNAL_LIST: "GET_JOURNAL_LIST",
        ADD_JOURNAL: "ADD_JOURNAL",
        UPDATE_JOURNAL: "UPDATE_JOURNAL",
        DELETE_JOURNAL: "DELETE_JOURNAL",
    },
    // CATEGORY_ACTION_TYPE: {
    //     ADD_CATEGORY: "ADD_CATEGORY",
    //     UPDATE_CATEGORY: "UPDATE_CATEGORY",
    //     ADD_SUB_CATEGORY: "ADD_SUB_CATEGORY",
    //     UPDATE_SUB_CATEGORY: "UPDATE_SUB_CATEGORY",
    // },
    ITEM_UNIT_TYPE: {
        BAGS: 'BAGS',
        BOTTLE: 'BOTTLE',
        BOX: 'BOX',
        CARAT: 'CARAT',
        CENT: 'CENT',
        CM: 'CM',
        DOZEN: 'DOZEN',
        FEET: 'FEET',
        GRAM: 'GRAM',
        HRS: 'HRS',
        KG: 'KG',
        LTR: 'LTR',
        MG: 'MG',
        MLT: 'MLT',
        MM: 'MM',
        MTR: 'MTR',
        PCS: 'PCS',
        TABLET: 'TABLET',
        TONNE: 'TONNE',
    },
    SUBSCRIPTION_PACKAGE: {
        DEMO: 'DEMO',
        ONE_YEAR: 'ONE_YEAR',
        THREE_YEAR: 'THREE_YEAR',
        FIVE_YEAR: 'FIVE_YEAR',
        LIFE_TIME: 'LIFE_TIME',
        CUSTOM: 'CUSTOM',
    },
    SALES_TAX_TYPE_NAME: {
        "LOCAL": 'Local',
        "OUT_STATE": 'OutState',
        "EXPORT_DEEMED": 'Export Deemed',
        "EXPORT_WITH_IGST": 'Export With IGST',
        "EXPORT_UNDER_BOND_LUT": 'Export Under Bond/LUT',
        "MERCHANT_EXPORT_LOCAL": 'Merchant Export Local',
        "MERCHANT_EXPORT_OUT_STATE": 'Merchant Export OutState',
    },
    SALES_TAX_TYPE: {
        "LOCAL": 'LOCAL',
        "OUT_STATE": 'OUT_STATE',
        "EXPORT_DEEMED": 'EXPORT_DEEMED',
        "EXPORT_WITH_IGST": 'EXPORT_WITH_IGST',
        "EXPORT_UNDER_BOND_LUT": 'EXPORT_UNDER_BOND_LUT',
        "MERCHANT_EXPORT_LOCAL": 'MERCHANT_EXPORT_LOCAL',
        "MERCHANT_EXPORT_OUT_STATE": 'MERCHANT_EXPORT_OUT_STATE',
    },
    SALES_RETURN_TAX_TYPE_NAME: {
        NO_TAX: 'No Tax',
        "LOCAL": 'Local',
        "OUT_STATE": 'OutState',
        "EXPORT_DEEMED": 'Export Deemed',
        "EXPORT_WITH_IGST": 'Export With IGST',
        "EXPORT_UNDER_BOND_LUT": 'Export Under Bond/LUT',
        "MERCHANT_EXPORT_LOCAL": 'Merchant Export Local',
        "MERCHANT_EXPORT_OUT_STATE": 'Merchant Export OutState',
    },
    SALES_RETURN_TAX_TYPE: {
        NO_TAX: 'NO_TAX',
        "LOCAL": 'LOCAL',
        "OUT_STATE": 'OUT_STATE',
        "EXPORT_DEEMED": 'EXPORT_DEEMED',
        "EXPORT_WITH_IGST": 'EXPORT_WITH_IGST',
        "EXPORT_UNDER_BOND_LUT": 'EXPORT_UNDER_BOND_LUT',
        "MERCHANT_EXPORT_LOCAL": 'MERCHANT_EXPORT_LOCAL',
        "MERCHANT_EXPORT_OUT_STATE": 'MERCHANT_EXPORT_OUT_STATE',
    },
    PURCHASE_TAX_TYPE: {
        NO_TAX: 'NO_TAX',
        LOCAL_REGISTER: 'LOCAL_REGISTER',
        LOCAL_UNREGISTER: 'LOCAL_UNREGISTER',
        OUT_STATE: 'OUT_STATE',
        IMPORT_DEEMED: 'IMPORT_DEEMED',
        IMPORT_WITH_IGST: 'IMPORT_WITH_IGST',
        IMPORT_UNDER_BOND_LUT: 'IMPORT_UNDER_BOND_LUT',
        MERCHANT_IMPORT_LOCAL: 'MERCHANT_IMPORT_LOCAL',
        MERCHANT_IMPORT_OUT_STATE: 'MERCHANT_IMPORT_OUT_STATE',
    },
    PURCHASE_TAX_TYPE_NAME: {
        NO_TAX: 'No Tax',
        LOCAL_REGISTER: 'Local Register',
        LOCAL_UNREGISTER: 'Local Unregister',
        OUT_STATE: 'Out State',
        IMPORT_DEEMED: 'Import Deemed',
        IMPORT_WITH_IGST: 'Import With IGST',
        IMPORT_UNDER_BOND_LUT: 'Import Under Bond/LUT',
        MERCHANT_IMPORT_LOCAL: 'Merchant Import Local',
        MERCHANT_IMPORT_OUT_STATE: 'Merchant Import OutState',
    },
    INVOICE_PREFIX: {
        SALES_INVOICE: 'INV',
        PURCHASE_INVOICE: 'INV',
        DEBIT_NOTE: "DN",
        CREDIT_NOTE: 'CN'
    },
    EXEMPTED_GST_ITEM_KEY: {
        NIL_RATED: 'gst_nill_rated',
        NON_GST: 'gst_non_gst',
        EXEMPTED: 'gst_exempt'
    },
    SUBSCRIPTION_UI_TYPE: {
        ACCOUNTING: "ACCOUNTING",
        BILLING: "BILLING",
        SCANNING: "SCANNING",
    },
    OTHER_GST_KEY: {
        NILL_RATED: 'gst_nill_rated',
        NON_GST: 'gst_non_gst',
        EXEMPTED: 'gst_exempt',
    },
    USER_ROLE: {
        COMPANY_ADMIN: "COMPANY_ADMIN",
        OPERATOR: "OPERATOR",
        SALES_PERSON: "SALES_PERSON",
        AUDITOR: "AUDITOR",
    },
}

enums.CATEGORY_TO_TYPE_MAP[enums.ACCOUNT_CATEGORY.PURCHASE] = enums.ACCOUNT_TYPE.PNL;
enums.CATEGORY_TO_TYPE_MAP[enums.ACCOUNT_CATEGORY.PURCHASE_RETURN] = enums.ACCOUNT_TYPE.PNL;
enums.CATEGORY_TO_TYPE_MAP[enums.ACCOUNT_CATEGORY.SALES] = enums.ACCOUNT_TYPE.PNL;
enums.CATEGORY_TO_TYPE_MAP[enums.ACCOUNT_CATEGORY.SALES_RETURN] = enums.ACCOUNT_TYPE.PNL;
enums.CATEGORY_TO_TYPE_MAP[enums.ACCOUNT_CATEGORY.CUSTOMER] = enums.ACCOUNT_TYPE.PERSONAL;
enums.CATEGORY_TO_TYPE_MAP[enums.ACCOUNT_CATEGORY.SUPPLIER] = enums.ACCOUNT_TYPE.PERSONAL;
enums.CATEGORY_TO_TYPE_MAP[enums.ACCOUNT_CATEGORY.PAYMENT] = enums.ACCOUNT_TYPE.PNL;
enums.CATEGORY_TO_TYPE_MAP[enums.ACCOUNT_CATEGORY.RECEIPT] = enums.ACCOUNT_TYPE.PNL;
enums.CATEGORY_TO_TYPE_MAP[enums.ACCOUNT_CATEGORY.EXPENSE] = enums.ACCOUNT_TYPE.PNL;
enums.CATEGORY_TO_TYPE_MAP[enums.ACCOUNT_CATEGORY.INCOME] = enums.ACCOUNT_TYPE.PNL;
enums.CATEGORY_TO_TYPE_MAP[enums.ACCOUNT_CATEGORY.CASH_BANK] = enums.ACCOUNT_TYPE.REAL;
enums.CATEGORY_TO_TYPE_MAP[enums.ACCOUNT_CATEGORY.CAPITAL] = enums.ACCOUNT_TYPE.PERSONAL;
enums.CATEGORY_TO_TYPE_MAP[enums.ACCOUNT_CATEGORY.DRAWING] = enums.ACCOUNT_TYPE.PERSONAL;
enums.CATEGORY_TO_TYPE_MAP[enums.ACCOUNT_CATEGORY.PERSONAL] = enums.ACCOUNT_TYPE.PERSONAL;
enums.CATEGORY_TO_TYPE_MAP[enums.ACCOUNT_CATEGORY.REAL] = enums.ACCOUNT_TYPE.REAL;
enums.CATEGORY_TO_TYPE_MAP[enums.ACCOUNT_CATEGORY.PNL] = enums.ACCOUNT_TYPE.PNL;

export default enums;