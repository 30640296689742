import { createMuiTheme, } from "@material-ui/core/styles";

// Create a theme instance.
export const HyperTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#1f8867"
            //  main: "#155f48"
        },
        secondary: {
            // main: "#F9EEBD",
            // main: "#FEFCD0",
            // main: "#fefcd9",
            main: "#1976d2",
            // main: "#D0DB61",
        }
    },
    typography: {
        // fontFamily: "Comic Sans MS",
        body2: {
            // fontFamily: "Times New Roman",
            // fontSize: "1.1rem"
        }
    },
    shape: {
        // borderRadius: 30
    },
    spacing: 8,
    overrides: {
        MuiPaper: {
            // root: {
            //     backgroundColor:'#3a8b72',
            //     color:'white'
            // },

            rounded: {
                borderRadius: 8
            }
        },
        MuiContainer: {
            root: {
                // paddingLeft: 0,
                // paddingRight: 0
            }
        },

        MuiListItem: {
            gutters: {
                // paddingLeft: 8,
                // paddingRight: 8
            },
        },
        MuiToolbar: {
            // regular: {
            //     minHeight: "40px !important"
            // }
        },
        MuiBottomNavigation: {
            root: {
                height: "auto"
            }
        },
        MuiSvgIcon: {
            root: {
                // height:"0.8em",
                // width:"0.8em"
            }
        },
        MuiFilledInput: {
            root: {
                // backgroundColor: "green"
            }
        },
        MuiInputLabel: {
            root: {
                // backgroundColor: "yellow"
            }
        },
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0
            }
        },
        MuiTextField: {
            root: {}
        },
        MuiButton: {
            root: {
                textTransform: "capitalize",
                fontWeight: 'bold'
            }
        },
    },
    props: {
        MuiButton: {
            // disableRipple: true,
            variant: "contained",
            color: "primary"
        },
        MuiCheckbox: {
            // disableRipple: true
        },
        MuiTextField: {
            variant: "outlined",
            // InputLabelProps: {
            //     shrink: true
            // }
        },
        MuiPaper: {
            // elevation: 12
        },
        MuiCard: {
            // elevation: 12
        },
        MuiLink:{
            underline:'none'
        }

    }
});
