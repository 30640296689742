import React from 'react'
import { } from '../../Types/AllTypes';
import { Container, Typography } from '@material-ui/core';
import api from '../../Services/ApiServices';
import Invoice from './Invoice';
import utils from '../../Services/Utils';


interface PublicInvoiceProps {

}
interface PublicInvoiceState {
    invoiceData: any
}



class PublicInvoice extends React.Component<PublicInvoiceProps, PublicInvoiceState> {
    constructor(props: PublicInvoiceProps) {
        super(props);
        let state: PublicInvoiceState = {
            invoiceData: null,
        };
        this.state = state;
    }

    componentDidMount() {

        this.getInvoiceData()
    }

    getInvoiceData = async () => {
        try {
            //console.log("GetInvoiceData")
            let invoiceData = await api.getInvoiceData((this.props as any).match.params.id)
            // console.log(invoiceData, "invoiceData")
            this.setState({ invoiceData: invoiceData })
        }
        catch (ex) {
            console.log("ex", ex);
            utils.showError(ex.message);
        }
    }


    render() {
        if (!this.state.invoiceData)
            return <Typography align="center">Loading...</Typography>
        return (
            <Container>
                <Invoice
                    objSales={this.state.invoiceData}
                    objCompany={this.state.invoiceData.companyDetail}
                    objCustomer={this.state.invoiceData.customerDetail}
                />
            </Container>
        );
    }
}

export default PublicInvoice;
