import React from 'react'
import {  ICompanySettings } from '../../Types/AllTypes';
import HyperForm from '../../Components/HyperInput/HyperForm';
import utils from '../../Services/Utils';
import HyperAutoCompleteField from '../../Components/HyperInput/HyperAutoCompleteField';
import { winState } from '../../Services/ScanRUtilService';
import { Checkbox } from '@material-ui/core';
// import { IconCheckBox, IconCheckBoxOutline } from '../../Components/Icons/Icons';
// import auth from '../../Services/AuthService';

interface CompanySelectAutoCompleteProps {
    onChange?: (e: any) => void
    required?: boolean
    frm?: any
    label?: string
    autoFocus?: boolean
    placeholder?: string
    defaultSelectFirstItem?: boolean
    disabled?: boolean
    selectedCompany?: ICompanySettings[]
}

interface CompanySelectAutoCompleteState {
    lstCompany: ICompanySettings[]
    lstSelectedCompanies?: ICompanySettings[]
}


class CompanySelectAutoComplete extends React.Component<CompanySelectAutoCompleteProps, CompanySelectAutoCompleteState> {
    constructor(props: CompanySelectAutoCompleteProps) {
        super(props);
        let state: CompanySelectAutoCompleteState = {
            lstCompany: [],
            lstSelectedCompanies: undefined
        };


        this.state = state;
        this.frm = new HyperForm();
    }
    frm: HyperForm

    componentDidMount() {
        this.loadAccounts();
    }

    async loadAccounts() {
        try {
            let lstCompany = winState.state.user.lstCompany;

            lstCompany = lstCompany.map((objCompany: any) => {
                objCompany.label = `${objCompany.companyName}`;
                return { ...objCompany };
            })
            let lstSelectedCompanies = undefined;

            if (this.props.selectedCompany && this.props.selectedCompany.length > 0) {
                lstSelectedCompanies = lstCompany.filter((item: ICompanySettings) => {
                    let isSelected = false;
                    if (this.props.selectedCompany)
                        this.props.selectedCompany.forEach((selCompany: ICompanySettings) => {
                            if (selCompany.id === item.id) {
                                isSelected = true
                            }
                        })
                    return isSelected;
                });
            }
            this.setState({ lstCompany, lstSelectedCompanies })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }

    }

    // getFilteredAccountList(lstCompany?: ICompanySettings[]) {
    //     if (!lstCompany)
    //         lstCompany = this.state.lstCompany;
    //     if (!lstCompany) {
    //         return lstCompany;
    //     }
    //     if (this.props.category) {
    //         return lstCompany.filter((item) => item.category === this.props.category);
    //     }
    //     else if (this.props.categories) {
    //         return lstCompany.filter((item) => this.props.categories?.includes(item.category));
    //     }

    //     return lstCompany;
    // }

    render() {


        return (
            <React.Fragment>
                <HyperAutoCompleteField
                    options={this.state.lstCompany}
                    value={this.state.lstSelectedCompanies || []}
                    onChange={(e: any) => {
                        this.setState({ lstSelectedCompanies: e })
                        if (this.props.onChange) {
                            this.props.onChange(e);
                        }
                    }}
                    label={this.props.label}
                    required={this.props.required}
                    frm={this.props.frm}
                    disabled={this.props.disabled}
                    autoFocus={this.props.autoFocus}
                    placeholder={this.props.placeholder}
                    disableCloseOnSelect
                    multiple
                    renderOption={(option: any, { selected }: any) => (
                        <React.Fragment>
                            <Checkbox
                                // icon={<IconCheckBoxOutline />}
                                // checkedIcon={<IconCheckBox />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                color="primary"
                            />
                            {option.label}
                        </React.Fragment>
                    )}
                />
            </React.Fragment>
        );
    }
}

export default CompanySelectAutoComplete;