import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import enums from '../../enums';
import utils from '../../Services/Utils';
import api from '../../Services/ApiServices';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import { ICompanySettings, IWindowState } from '../../Types/AllTypes';
import HyperForm from '../../Components/HyperInput/HyperForm';
import StateAutoComplete from '../Customers/StateAutoComplete';
import HyperCheckboxField from '../../Components/HyperInput/HyperCheckboxField';
import withWindowState from '../../Components/withWindowState';
import ImageUpload from '../../Components/ImageUpload';
import HyperSelectField from '../../Components/HyperInput/HyperSelectField';
import HyperSwitchField from '../../Components/HyperInput/HyperSwitchField';
import scanRUtils from '../../Services/ScanRUtilService';
import ItemUnitAutoComplete from '../Item/ItemUnitAutoComplete';
import TaxAutoComplete from '../Tax/TaxAutoComplete';



const style: any = (theme: Theme) => ({
    paper: {
        marginTop: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 15,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        fontWeight: 'bold'
    },
})

interface ISettingsProps extends WithStyles, IWindowState {

}

interface ISettingsState {
    settings: ICompanySettings
}

class Settings extends Component<ISettingsProps, ISettingsState> {
    constructor(props: ISettingsProps) {
        super(props);
        this.state = {
            settings: this.props.companySettings as ICompanySettings
        }
    }

    frm = new HyperForm();

    setSettings(obj: Partial<ICompanySettings>) {
        this.setState({ settings: { ...this.state.settings, ...obj } });
    }

    getSettings(key: string) {
        return (this.state.settings as any)[key];
    }

    onSave = async (e: any) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.frm.setIsFormSubmitted(true)
            if (this.frm.isValid()) {

                let result = await api.updateCompanySettings(this.state.settings);
                utils.showSuccess(result.message)
                this.props.setCompanySettings(this.state.settings)

            }
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
    }

    render() {

        return (
            <Container style={{ marginTop: 20 }}>
                <form onSubmit={this.onSave}>
                    {/* {winState.state.user} */}
                    <Grid container spacing={2}>

                        <Grid item xs={6} style={{ textAlign: 'center' }}>
                            <ImageUpload
                                documentType={enums.FILE_DOCUMENT_TYPE.USER}
                                onImageSelect={(imageURL: string, extension: string) => {
                                    this.setSettings({ companyLogoURL: imageURL })
                                }}
                                height={170}
                                width={170}
                                imagePath={this.state.settings.companyLogoURL}
                            />
                            <br />
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'center' }}>
                            <ImageUpload
                                documentType={enums.FILE_DOCUMENT_TYPE.USER}
                                onImageSelect={(imageURL: string, extension: string) => {
                                    this.setSettings({ companySignatureURL: imageURL })
                                }}
                                height={170}
                                width={170}
                                imagePath={this.state.settings.companySignatureURL}
                            />
                            <br />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <HyperTextField
                                required
                                label={"Company Name"}
                                value={this.state.settings.companyName}
                                onChange={(event: any) => {
                                    this.setSettings({ companyName: event.target.value })
                                }}
                                frm={this.frm}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <HyperTextField
                                label={"GST No"}
                                value={this.state.settings.gstNo}
                                onChange={(event: any) => {
                                    this.setSettings({ gstNo: event.target.value })
                                }}
                                frm={this.frm}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <HyperTextField
                                required
                                type="email"
                                label={"Email"}
                                value={this.state.settings.companyEmail}
                                onChange={(event: any) => {
                                    this.setSettings({ companyEmail: event.target.value })
                                }}
                                frm={this.frm}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <HyperTextField
                                type="mobile"
                                label={"Phone Number"}
                                value={this.state.settings.companyPhone}
                                onChange={(event: any) => {
                                    this.setSettings({ companyPhone: event.target.value })
                                }}
                                frm={this.frm}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <HyperTextField
                                type="mobile"
                                label={"Phone Number1"}
                                value={this.state.settings.companyPhone1}
                                onChange={(event: any) => { this.setSettings({ companyPhone1: event.target.value }) }}
                                frm={this.frm}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <StateAutoComplete
                                label="Your State"
                                value={this.state.settings.state}
                                onChange={(newValue: string) => { this.setSettings({ state: newValue }) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <HyperTextField
                                label={"Invoice Prefix"}
                                value={this.state.settings.invoicePrefix}
                                onChange={(event: any) => {
                                    this.setSettings({ invoicePrefix: event.target.value })
                                }}
                                frm={this.frm}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <HyperTextField
                                required
                                type="number"
                                label={"Current Invoice Number"}
                                value={this.state.settings.currentInvoiceNumber}
                                onChange={(event: any) => {
                                    this.setSettings({ currentInvoiceNumber: event.target.value })
                                }}
                                frm={this.frm}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <HyperSelectField
                                options={scanRUtils.getInvoiceTemplateList()}
                                optionIdField="link"
                                labelWidth={120}
                                optionNameField="name"
                                selectedValue={this.state.settings.defaultInvoiceTemplate}
                                label="Select Template"
                                fullWidth
                                onChange={(event: any) => { this.setSettings({ defaultInvoiceTemplate: event.target.value }) }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ItemUnitAutoComplete
                                label="Default Item Unit"
                                value={this.state.settings.defaultItemUnit}
                                onChange={(newValue: any) => { this.setSettings({ defaultItemUnit: newValue?.key }) }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TaxAutoComplete
                                required
                                onChange={(lstTaxes: any) => {
                                    this.setSettings({ defaultTaxes: [lstTaxes] })
                                }}
                                selectedItem={this.state.settings.defaultTaxes ? this.state.settings.defaultTaxes[0] : undefined}
                                label={"Default Tax"}
                                frm={this.frm}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <HyperTextField
                                required
                                type="text"
                                rows={3}
                                rowsMax={7}
                                label={"Address"}
                                multiline
                                value={this.state.settings.address}
                                onChange={(event: any) => {
                                    this.setSettings({ address: event.target.value })
                                }}
                                frm={this.frm}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <HyperTextField
                                required
                                type="text"
                                rows={3}
                                rowsMax={7}
                                label={"Terms And Condition"}
                                multiline
                                value={this.state.settings.companyTerms}
                                onChange={(event: any) => {
                                    this.setSettings({ companyTerms: event.target.value })
                                }}
                                frm={this.frm}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <HyperCheckboxField
                                fullWidth
                                options={["Show Advance Menu"]}
                                value={this.state.settings.showAdvanceMenu ? "Show Advance Menu" : ""}
                                onChange={(value: any) => {
                                    this.setSettings({ showAdvanceMenu: !this.state.settings.showAdvanceMenu })
                                }}
                                frm={this.frm}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    <HyperSwitchField
                                        label={"Show Item Serial Number? (If you check this option you will see your serial no. with item.)"}
                                        checked={this.state.settings.showItemSerialNo}
                                        onChange={(val: boolean) => this.setSettings({ showItemSerialNo: val })}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: 'center' }} >
                            <Button type="submit" color="primary" variant="contained">Save</Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>

        )
    }
}


export default withWindowState(withStyles(style)(Settings), ["companySettings"]);
