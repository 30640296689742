import React from 'react'
import { Menu, MenuItem, withStyles, WithStyles, Typography, Avatar, Theme, Button, Divider, Badge } from '@material-ui/core'

import utils from '../Services/Utils'
import enums from '../enums'
import withWindowState from '../Components/withWindowState'
import { winState } from '../Services/ScanRUtilService'
import { IconSetting, IconExit, IconQRCode, IconDownload, IconKey } from '../Components/Icons/Icons'
import { IWindowState, ICompanySettings } from '../Types/AllTypes'
import HyperModal from '../Components/HyperInput/HyperModal'
import DeviceIDQRCodeModel from '../Components/Common/DeviceIDQRCodeModel'

import GenerateBarcodeModel from '../Components/Common/GenerateBarcodeModel'
import ChangePassword from '../Pages/Login/ChangePassword'
import history from './history'

import { HyperTheme } from '../Theme/Theme'
import auth from '../Services/AuthService'
import { reInitApp } from '..'

const styles = (theme: Theme) => ({
    link: {
        textDecoration: 'none',
        color: theme.palette.common.white,
    },
    button: {
        borderRadius: 10,
        // padding: ,
    },
    menuPaper: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white
    },
    loggedUserName: {
        color: theme.palette.common.black,
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap',
        overflow: 'hidden',
        marginRight: theme.spacing(),
        'text-transform': 'none',
        maxWidth: 150,
    },
    avatar: {
        'text-transform': 'uppercase',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        width: theme.spacing(4),
        height: theme.spacing(4),
        fontSize: 14,
    },
})
interface UserMenuProps extends WithStyles, IWindowState {

}

class UserMenu extends React.Component<UserMenuProps> {
    state = {
        anchorEl: null,
        lstCompany: auth.getUserCompanies()
    }

    qrModel = new HyperModal(this)
    barcodeModel = new HyperModal(this)
    changePasswordModel = new HyperModal(this)

    handleMenu = (event: any) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleClose = () => {
        this.setState({ anchorEl: null })
    }

    onClickQrCode = () => {
        this.handleClose();
        this.qrModel.toggle(true)
    }

    onGenerateBarcodeClick = () => {
        this.handleClose();
        this.barcodeModel.toggle(true)
    }


    logout = () => {
        utils.fireEvent(enums.EVENT.LOGOUT)
    }

    getDisplayName() {
        if (winState.state.companySettings?.companyName) {
            return winState.state.companySettings?.companyName;
        }

        if (winState.state.user) {
            return (winState.state.user.name || winState.state.user.username);
        }

        return "User";
    }

    onChangePasswordClick = () => {
        this.handleClose();
        this.changePasswordModel.toggle()
    }

    onSettingClick = () => {
        history.push("/settings")
        this.handleClose();
    }
    onCompanyChange = (selectCompany: ICompanySettings) => {


        this.state.lstCompany.forEach((objCompany: ICompanySettings) => {
            if (objCompany.companyName === selectCompany.companyName) {
                objCompany.isSelected = true
            }
            else {
                objCompany.isSelected = false
            }
        })
        auth.setCompanyId(selectCompany.id)
        reInitApp()
    }

    render() {
        const { classes } = this.props
        const { anchorEl } = this.state
        let user = winState.state.user;
        if (!user) {
            return null;
        }
        return (
            <React.Fragment>
                <Button variant="contained" color="default" onClick={this.handleMenu} className={classes.button}>
                    <Typography className={classes.loggedUserName}>
                        {this.getDisplayName()}
                    </Typography>
                    <Avatar className={classes.avatar}>
                        {this.getDisplayName().toString().substr(0, 2)}
                    </Avatar>
                </Button>

                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    classes={{ paper: classes.menuPaper }}
                    open={!!anchorEl}
                    onClose={this.handleClose}
                >

                    {this.state.lstCompany.length > 1 && this.state.lstCompany.map((objCompany: ICompanySettings, index: number) => {
                        return (
                            <MenuItem key={index} onClick={() => { this.onCompanyChange(objCompany) }} >
                                {objCompany.isSelected ?
                                    <Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} color="secondary" overlap="circle" badgeContent=" ">
                                        <Avatar style={{ backgroundColor: HyperTheme.palette.grey[200] }} src={objCompany.companyLogoURL} />
                                    </Badge>
                                    :
                                    <Avatar style={{ backgroundColor: HyperTheme.palette.grey[200] }} src={objCompany.companyLogoURL} />
                                }
                                <Typography style={{ paddingLeft: 7 }}>
                                    {objCompany.companyName}
                                </Typography>
                            </MenuItem>
                        )
                    })
                    }
                    <Divider style={{ backgroundColor: "white", color: "white" }} />

                    <MenuItem onClick={this.onSettingClick}>
                        <IconSetting style={{ marginRight: 10 }} />
                        <Typography >Setting</Typography>
                    </MenuItem>
                    <MenuItem onClick={this.onChangePasswordClick}>
                        <IconKey style={{ marginRight: 10 }} />
                        <Typography >Change Password</Typography>
                    </MenuItem>
                    <MenuItem onClick={this.onClickQrCode}>
                        <IconQRCode style={{ marginRight: 10 }} />
                        <Typography >Open Device QR</Typography>
                    </MenuItem>
                    <MenuItem onClick={this.onGenerateBarcodeClick}>
                        <IconQRCode style={{ marginRight: 10 }} />
                        <Typography >Generate Barcodes</Typography>
                    </MenuItem>
                    <MenuItem onClick={utils.downloadScanner}>
                        <IconDownload style={{ marginRight: 10 }} />
                        <Typography >Download Scanner</Typography>
                    </MenuItem>
                    <MenuItem onClick={this.logout}>
                        <IconExit style={{ marginRight: 10 }} />
                        <Typography >Log out</Typography>
                    </MenuItem>
                </Menu>
                <DeviceIDQRCodeModel
                    isOpen={this.qrModel.isOpen()}
                    toggle={this.qrModel.toggle}
                />
                <GenerateBarcodeModel
                    isOpen={this.barcodeModel.isOpen()}
                    toggle={this.barcodeModel.toggle}
                />
                {this.changePasswordModel.isOpen() &&
                    <ChangePassword
                        isOpen={this.changePasswordModel.isOpen()}
                        toggle={this.changePasswordModel.toggle}
                    />
                }
            </React.Fragment >
        )
    }
}



export default withWindowState(withStyles(styles)(UserMenu), ["user", "companySettings"])
