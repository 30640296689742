import api from "./ApiServices";
import { ISalesItem, IItem, WindowStateType, IDiscount, ISales } from "../Types/AllTypes";
import { WindowState } from "./WindowState";
import JsBarcode from "jsbarcode";
import { CategorySearchOption } from "../Types/SearchOptions";
import enums from "../enums";
// import GSTStateCode from "../Data/GSTStateCode.json";
import GSTStateName from "../Data/GSTStateName.json";
import moment from 'moment';
import utils from "./Utils";
import ReactDOM from 'react-dom';

const win = window as any;
const GST_StateName: any = GSTStateName;
// const GST_StateCode: any = GSTStateCode;
let GST_StateWiseCode: any = {}
Object.keys(GSTStateName).forEach((key: any) => { GST_StateWiseCode[(GSTStateName as any)[key]] = key });

class ScanRUtilService {
    constructor() {
        if (!win.objCache)
            win.objCache = {};
    }

    getWindowCache(key: string) {
        return win.objCache[key]
    }

    setWindowCache(key: string, value: any) {
        win.objCache[key] = value;
    }

    updateBottomNavigationCount(TabISBWs: number = 0) {
        // utils.fireEvent(enums.EVENT.UPDATE_BOTTOM_NAVIGATION_COUNT, { TabISBWs })
    }

    countTax(price: number, lstTaxes: any, qty: number = 1) {
        let tax = 0;
        if (!lstTaxes || !lstTaxes.length)
            return tax;
        // console.log(lstTaxes, "lstTaxes")
        for (let objTax of lstTaxes) {
            if (objTax.percentage) {
                tax += (price * objTax.percentage / 100);
            }
        }

        return this.toFixedFloat((tax * qty));
    }
    countDiscountAmount(itemPrice: number, objDiscount: IDiscount) {
        let discountAmount = 0;
        let totalAmount = 0;
        if (itemPrice) {
            totalAmount = itemPrice;// * qty;
            if (objDiscount.discountPercentage) {
                discountAmount = (totalAmount * objDiscount.discountPercentage) / 100
            }
        }
        return this.toFixedFloat(discountAmount);
    }
    countReverseDiscount(priceAfterDiscount: number, objDiscount: IDiscount) {
        let percentageAfterDiscount = 100
        if (objDiscount.discountPercentage)
            percentageAfterDiscount -= objDiscount.discountPercentage;
        let amountBeforeDiscount = (priceAfterDiscount * percentageAfterDiscount) / 100;
        // console.log(percentageAfterDiscount, objDiscount, "objDiscount")
        return this.toFixedFloat(amountBeforeDiscount);

    }
    countTotalTaxPercentage(lstTaxes: any) {
        let totalTaxPercentage = 0;
        if (!lstTaxes || !lstTaxes.length)
            return totalTaxPercentage + "%";

        for (let objTax of lstTaxes) {
            if (objTax.percentage) {
                totalTaxPercentage += objTax.percentage;
            }
        }
        return `${totalTaxPercentage}%`;
    }
    isExemptedItem(lstTaxes: any) {
        let isExempted = false;

        if (!lstTaxes || !lstTaxes.length)
            return false;

        for (let objTax of lstTaxes) {
            if (objTax.key === enums.EXEMPTED_GST_ITEM_KEY.EXEMPTED || objTax.key === enums.EXEMPTED_GST_ITEM_KEY.NIL_RATED || objTax.key === enums.EXEMPTED_GST_ITEM_KEY.NON_GST)
                isExempted = true
        }
        if (isExempted)
            return true;

        return false;
    }

    countReverseTax(amountWithTax: number, lstTaxes: any, qty: number = 1) {
        let tax = 0;
        if (!lstTaxes || !lstTaxes.length)
            return tax;

        for (let objTax of lstTaxes) {
            if (objTax.percentage) {
                tax += amountWithTax - ((amountWithTax * 100) / (objTax.percentage + 100));
            }
        }
        return this.toFixedFloat(tax);
    }

    toFixed(amount: any, decimal: number = 2) {
        amount = parseFloat(amount).toFixed(decimal)
        return amount;
    }

    toFixedFloat(amount: any, decimal: number = 2) {
        return parseFloat(this.toFixed(amount, decimal));
    }

    countDiscount(amount: number, per: number = 1) {
        let discount = (amount * per) / 100
        return parseFloat(this.toFixed(discount, 2));
    }
    isNilRated(taxes: any) {
        if (taxes && taxes.length > 0) {
            let isNilRated = false;
            taxes.forEach((item: any) => {
                if (item.key === enums.OTHER_GST_KEY.NILL_RATED)
                    isNilRated = true
            });
            return isNilRated;
        }
    }
    isExempted(taxes: any) {
        if (taxes && taxes.length > 0) {
            let isNilRated = false;
            taxes.forEach((item: any) => {
                if (item.key === enums.OTHER_GST_KEY.EXEMPTED)
                    isNilRated = true
            });
            return isNilRated;
        }
    }
    isZeroRated(taxes: any) {
        if (!taxes || taxes.length === 0) {
            return true
        }
        return false
    }
    isNonGST(taxes: any) {
        if (taxes && taxes.length > 0) {
            let isNilRated = false;
            taxes.forEach((item: any) => {
                if (item.key === enums.OTHER_GST_KEY.NON_GST)
                    isNilRated = true
            });
            return isNilRated;
        }
    }

    getTotalCounts(lstSalesItem: ISalesItem[], objDiscount: IDiscount = ({} as IDiscount)) {
        let amount = 0;
        let tax = 0;
        // let totalWithTax = 0;
        let subTotal = 0;
        let discountAmount = 0;

        if (lstSalesItem) {
            for (let objSalesItem of lstSalesItem) {
                subTotal += objSalesItem.price * objSalesItem.qty;
                // totalWithTax += objSalesItem.amount;
                tax += objSalesItem.tax;
            }
        }

        if (objDiscount.discountType === enums.DISCOUNT_OPTION.FIXED)
            discountAmount = objDiscount.discountAmount;
        else if (objDiscount.discountPercentage)
            discountAmount = this.countDiscount(subTotal, objDiscount.discountPercentage);

        return {
            subTotal,
            discountAmount,
            amount,
            tax,
            totalAfterDiscount: this.toFixedFloat(subTotal - discountAmount),
            amountWithTax: this.toFixedFloat(subTotal - discountAmount + tax)
        }
    }


    getTaxes(objSalesItem: ISalesItem, taxInvoiceType: string) {
        if (taxInvoiceType === enums.SALES_TAX_TYPE.MERCHANT_EXPORT_LOCAL || taxInvoiceType === enums.SALES_TAX_TYPE.MERCHANT_EXPORT_OUT_STATE) { //??
            return [{ name: 'Merchant Export Tax', percentage: 0.1 }]
        }
        return objSalesItem.taxes
    }

    prettyPrice(amount: number) {
        if (!amount)
            amount = 0;
        if (!amount.toFixed) {
            amount = parseInt(amount as any);
        }
        return `₹${amount.toFixed(2)}`;
    }

    invoiceNumberManager = (lstINV: any) => {
        let objReturn = {
            fromNo: 0,
            toNo: 0,
            totalNumber: 0,
            totalCancelled: 0
        };

        if (!lstINV || lstINV.length === 0)
            return objReturn;
        objReturn.fromNo = Math.min(...lstINV)
        objReturn.toNo = Math.max(...lstINV)

        for (let i = objReturn.fromNo; i <= objReturn.toNo; i++) {
            objReturn.totalNumber++;
            if (!lstINV.includes(i.toString())) {
                objReturn.totalCancelled++;
            }
        }
        return objReturn;
    }

    async getSalesAccount() {
        // let lstAccounts = await api.getAccountsList_withWindowCache();
    }

    async getItem(id: number) {
        let lstItems: IItem[] = await api.getItemList_withWindowCache();
        return lstItems.find(item => item.id === id)
    }

    generateBarcodeList(rows: number, cols: number, height: number, width: number, barcodePrefix: string, currentBarcodeIndex: number = 0) {
        let html = "";
        html += `<body>`;
        html += `<style>
                    body {
                        // height: 297mm;
                        // width: 210mm;
                        // padding:0px;
                        // margin:0px;
                        // /* outline: 1px solid blue; */
                        // outline: 1px solid blue;
                    }

                    .card-div {
                        // outline: 1px solid red;
                    }

                    .card-img {
                        margin-top:5%;
                        margin-left:5%;
                        height: 89%;
                        width: 89%;
                    }

                    .root-div{
                        position: absolute;
                        left:0px;
                        top:0px;
                        height:100%;
                        width:100%;
                    }
                    @media print { 
                        @page { 
                            margin-top: 0; 
                            margin-bottom: 0;
                            margin:0;
                        }                         
                    } 
                </style>`;


        for (let i = 1; i <= rows; i++) {

            for (let j = 1; j <= cols; j++) {

                let canvas = document.createElement("canvas");
                let bar = `${barcodePrefix}${currentBarcodeIndex.toString().padStart(7, "0")}`;
                JsBarcode(canvas, bar, {
                    format: "ean13",
                });

                currentBarcodeIndex++;
                let imgURI = canvas.toDataURL("image/png");

                let leftPadding = (210 - (width * cols)) / (cols + 1);
                let startLeft = ((leftPadding * j) + (width * j)) - width;
                let startTop = ((height * i) + 10) - height;

                html += `<div class="card-div" style="top:${startTop}mm;left:${startLeft}mm;height:${height}mm;width:${width}mm;position:absolute;">
                    <img class="card-img" style="border:0px solid gold;" src="${imgURI}" />
                    </div>`;

            }

        }


        html += "</body>"
        return html;
    }

    async loadCategory() {
        try {
            let so: CategorySearchOption = new CategorySearchOption();
            let lstCategories = await api.getCategoryList(so);
            lstCategories.forEach((item: any) => {
                item.label = item.name;
            })
            await winState.setCategory({ lstCategories });
        }
        catch (ex) {
            console.log("Error while loading Category,", ex)
        }
    }
    checkGST(g: string) {
        if (!g)
            return false;
        let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(g)
        if (regTest) {
            let a = 65, b = 55, c = 36, p: any;
            return Array['from'](g).reduce((i, j, k, g) => {
                p = (p = (j.charCodeAt(0) < a ? parseInt(j) : j.charCodeAt(0) - b) * (k % 2 + 1)) > c ? 1 + (p - c) : p;
                return k < 14 ? i + p : j === ((c = (c - (i % c))) < 10 ? c : String.fromCharCode(c + b));
            }, 0);
        }
        return regTest
    }

    getStateNameFromGST(g: string) {
        if (!this.checkGST(g))
            return ""
        return GST_StateName[g.slice(0, 2)]
    }

    getStateCodeFromGST(g: string) {
        if (!this.checkGST(g))
            return ""
        return g.slice(0, 2)
    }
    getStateCodeFromState(state: string) {
        if (!state)
            return ""
        return GST_StateWiseCode[state]
    }

    getPlaceOfSupply(stateOrGST: string, isSameState: boolean = true) {
        if (!stateOrGST && !isSameState)
            return 'N/A';

        let companyGST = winState.state.companySettings.gstNo;
        if (!stateOrGST && isSameState && companyGST) {
            // console.log(this.getStateNameFromGST(companyGST), winState.state.companySettings.gstNo)
            return `${companyGST.slice(0, 2)}-${this.getStateNameFromGST(companyGST)}`;
        }

        if (this.checkGST(stateOrGST)) {
            return `${stateOrGST.slice(0, 2)}-${this.getStateNameFromGST(stateOrGST)}`
        }
        // console.log(stateOrGST, "stateOrGST")
        return `${this.getStateCodeFromState(stateOrGST)}-${stateOrGST}`
    }
    verifyGSTWithState(GSTIN: string, state: string) {
        if (!this.checkGST(GSTIN))
            return false
        if (this.getStateNameFromGST(GSTIN) === state)
            return true
        return false;
    }
    getPANIntoGST(g: string) {
        if (!this.checkGST(g))
            return ""
        return g.slice(2, 12);
    }

    isIntraState(stateOrGST: string) {
        if (!stateOrGST)
            return true
        if (this.checkGST(stateOrGST)) {
            if (winState.state.companySettings.gstNo) {
                return this.getStateCodeFromGST(winState.state.companySettings.gstNo) === this.getStateCodeFromGST(stateOrGST)
            }
        }
        if (stateOrGST === winState.state.companySettings.state)
            return true
        return false
    }
    isIntraStatePurchase(invoiceTaxTypeGSTOrState: string) {
        if (!invoiceTaxTypeGSTOrState)
            return true;
        if (enums.PURCHASE_TAX_TYPE.LOCAL_REGISTER === invoiceTaxTypeGSTOrState || enums.PURCHASE_TAX_TYPE.LOCAL_UNREGISTER === invoiceTaxTypeGSTOrState) {
            return true
        }
        if (enums.PURCHASE_TAX_TYPE.MERCHANT_IMPORT_OUT_STATE === invoiceTaxTypeGSTOrState || enums.PURCHASE_TAX_TYPE.MERCHANT_IMPORT_LOCAL === invoiceTaxTypeGSTOrState || enums.PURCHASE_TAX_TYPE.OUT_STATE === invoiceTaxTypeGSTOrState || enums.PURCHASE_TAX_TYPE.IMPORT_DEEMED === invoiceTaxTypeGSTOrState || enums.PURCHASE_TAX_TYPE.IMPORT_UNDER_BOND_LUT === invoiceTaxTypeGSTOrState || enums.PURCHASE_TAX_TYPE.IMPORT_WITH_IGST === invoiceTaxTypeGSTOrState) {
            return false
        }
        return this.isIntraState(invoiceTaxTypeGSTOrState);
    }
    isIntraStateSales(invoiceTaxTypeGSTOrState: string) {
        if (!invoiceTaxTypeGSTOrState)
            return true;
        if (enums.SALES_TAX_TYPE.LOCAL === invoiceTaxTypeGSTOrState) {
            return true
        }
        if (enums.SALES_TAX_TYPE.EXPORT_DEEMED === invoiceTaxTypeGSTOrState || enums.SALES_TAX_TYPE.EXPORT_UNDER_BOND_LUT === invoiceTaxTypeGSTOrState || enums.SALES_TAX_TYPE.EXPORT_WITH_IGST === invoiceTaxTypeGSTOrState || enums.SALES_TAX_TYPE.OUT_STATE === invoiceTaxTypeGSTOrState || enums.SALES_TAX_TYPE.MERCHANT_EXPORT_LOCAL === invoiceTaxTypeGSTOrState || enums.SALES_TAX_TYPE.MERCHANT_EXPORT_OUT_STATE === invoiceTaxTypeGSTOrState) {
            return false
        }
        return this.isIntraState(invoiceTaxTypeGSTOrState);
    }

    download(filename: string, text: string) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    prepareGST_R1_JSON(lstSales: any, objHSN: any, dateRange: any) {
        let filingPeriod = moment(dateRange.endDate).format("MM") + moment().format("YYYY")
        let GSTIN = winState.state.companySettings.gstNo
        let b2b: any = {}
        let b2cl: any = {}
        let b2cs: any = {}
        let hsn: any = []
        let exp: any = { "WPAY": [], "WOPAY": [] }
        let nil: any = {
            INTRB2B: { sply_ty: "INTRB2B", expt_amt: 0, nil_amt: 0, ngsup_amt: 0 },
            INTRAB2B: { sply_ty: "INTRAB2B", expt_amt: 0, nil_amt: 0, ngsup_amt: 0 },
            INTRB2C: { sply_ty: "INTRB2C", expt_amt: 0, nil_amt: 0, ngsup_amt: 0 },
            INTRAB2C: { sply_ty: "INTRAB2C", expt_amt: 0, nil_amt: 0, ngsup_amt: 0 },
        }
        Object.keys(objHSN.lstHSNCode).forEach((item: any, index: number) => {
            let objHSNItem = objHSN.lstHSNCode[item];
            hsn.push({
                num: index + 1,
                desc: objHSNItem.description,
                uqc: objHSNItem.UQC,
                qty: objHSNItem.totalQty,
                val: objHSNItem.totalValue,
                txval: objHSNItem.taxableValue,
                iamt: objHSNItem.integratedTaxAmount,
                samt: objHSNItem.stateTaxAmount,
                camt: objHSNItem.centralTaxAmount,
                hsn_sc: objHSNItem.HSNCode
            })
        })
        lstSales.forEach((objSales: any) => {
            let isIntraState = this.isIntraState(objSales.debitAccount.info.GST);
            if (objSales.info.invoiceTaxType === enums.SALES_TAX_TYPE.EXPORT_DEEMED || objSales.info.invoiceTaxType === enums.SALES_TAX_TYPE.EXPORT_UNDER_BOND_LUT || objSales.info.invoiceTaxType === enums.SALES_TAX_TYPE.EXPORT_WITH_IGST) {
                objSales.finalSalesItem = {
                    "inum": enums.INVOICE_PREFIX.SALES_INVOICE + "-" + objSales.info.INVNumber,
                    "idt": utils.convertToSystemDateFormate(objSales.createdAt),
                    "val": this.toFixedFloat(objSales.amount),
                    "itms": []
                }

                objSales.info.items.forEach((salesItem: any, index: number) => {
                    objSales.finalSalesItem.itms.push({
                        "num": index + 1,
                        itm_det: {
                            "txval": salesItem.totalTaxableAmount,
                            "rt": parseFloat(this.countTotalTaxPercentage(salesItem.taxes)),
                            "iamt": isIntraState ? 0 : salesItem.tax,
                            "csamt": 0.0
                        }

                    });
                });

                if (enums.SALES_TAX_TYPE.EXPORT_UNDER_BOND_LUT) {
                    exp["WOPAY"].push(objSales.finalSalesItem);
                }
                else
                    exp["WPAY"].push(objSales.finalSalesItem);
            }
            else if (objSales.debitAccount.info.GST) {
                objSales.finalSalesItem = {
                    "inum": enums.INVOICE_PREFIX.SALES_INVOICE + "-" + objSales.info.INVNumber,
                    "idt": utils.convertToSystemDateFormate(objSales.date),
                    "val": this.toFixedFloat(objSales.amount),
                    "pos": objSales.debitAccount.info.GST.slice(0, 2),
                    "rchrg": "N",
                    "itms": [],
                    "inv_typ": "R"
                }

                objSales.info.items.forEach((salesItem: any, index: number) => {
                    objSales.finalSalesItem.itms.push({
                        "num": index + 1,
                        itm_det: {
                            "txval": salesItem.totalTaxableAmount,
                            "rt": parseFloat(this.countTotalTaxPercentage(salesItem.taxes)),
                            "iamt": isIntraState ? 0 : salesItem.tax,
                            "camt": isIntraState ? this.toFixedFloat(salesItem.tax / 2) : 0,
                            "samt": isIntraState ? this.toFixedFloat(salesItem.tax / 2) : 0,
                            "csamt": 0.0
                        }
                    })
                })

                if (!b2b[objSales.debitAccount.info.GST])
                    b2b = { [objSales.debitAccount.info.GST]: [objSales.finalSalesItem], ...b2b };
                else
                    b2b[objSales.debitAccount.info.GST].push(objSales.finalSalesItem);
            }
            else {
                if (objSales.amount > 249999 && !scanRUtils.isIntraState(objSales.debitAccount.info.state)) {
                    objSales.finalSalesItem = {
                        "inum": enums.INVOICE_PREFIX.SALES_INVOICE + "-" + objSales.info.INVNumber,
                        "idt": utils.convertToSystemDateFormate(objSales.createdAt),
                        "val": this.toFixedFloat(objSales.amount),
                        "itms": []
                    }

                    objSales.info.items.forEach((salesItem: any, index: number) => {
                        objSales.finalSalesItem.itms.push({
                            "num": index + 1,
                            itm_det: {
                                "txval": salesItem.totalTaxableAmount,
                                "rt": parseFloat(this.countTotalTaxPercentage(salesItem.taxes)),
                                "iamt": isIntraState ? 0 : salesItem.tax,
                            }

                        })
                    })
                    let pos: any = this.getStateCodeFromGST(objSales.debitAccount.info.GST)
                    if (!pos)
                        pos = this.getStateCodeFromGST(winState.state.companySettings.gstNo)

                    if (!b2cl[pos])
                        b2cl = { [pos]: [objSales.finalSalesItem], ...b2cl };
                    else
                        b2cl[pos].push(objSales.finalSalesItem);

                }
                else {
                    let pos: any = this.getStateCodeFromGST(objSales.debitAccount.info.GST)
                    if (!pos)
                        pos = this.getStateCodeFromGST(winState.state.companySettings.gstNo)
                    let finalSalesItem: any = {}

                    objSales.info.items.forEach((salesItem: any) => {
                        let objKey = pos + "-" + parseFloat(this.countTotalTaxPercentage(salesItem.taxes));
                        let b2clItem = b2cs[objKey];
                        finalSalesItem = {
                            rt: parseFloat(this.countTotalTaxPercentage(salesItem.taxes)),
                            typ: "OE",
                            pos: pos,
                            txval: salesItem.totalTaxableAmount,
                            iamt: isIntraState ? 0 : salesItem.tax,
                            camt: isIntraState ? this.toFixedFloat(salesItem.tax / 2) : 0,
                            samt: isIntraState ? this.toFixedFloat(salesItem.tax / 2) : 0,
                            sply_ty: isIntraState ? "INTRA" : "INTER"
                        }

                        if (!b2clItem) {
                            b2cs = { [objKey]: finalSalesItem, ...b2cs };
                        }
                        else {
                            b2clItem.txval += finalSalesItem.txval;
                            b2clItem.iamt += finalSalesItem.iamt;
                            b2clItem.camt += finalSalesItem.camt;
                            b2clItem.samt += finalSalesItem.samt;
                            b2cs[objKey] = b2clItem;
                        }
                    })
                }
            }
        })

        let gstReport = {
            gstin: GSTIN,
            fp: filingPeriod,
            b2b: Object.keys(b2b).map((key: any) => { return { ctin: key, inv: b2b[key] } }),
            b2cl: Object.keys(b2cl).map((key: any) => { return { pos: key, inv: b2cl[key] } }),
            b2cs: Object.keys(b2cs).map((key: any) => { return { ...b2cs[key] } }),
            exp: Object.keys(exp).map((key: any) => { return { exp_typ: key, inv: exp[key] } }),
            hsn: { data: hsn },
            nil: { inv: Object.keys(nil).map((key: any) => { return { ...nil[key] } }) },
            cdnur: [],
            doc_issue: { doc_det: [] },
        }

        return gstReport;
    }
    makeAddressFromObj(addressObj: any) {
        if (!addressObj || Object.keys(addressObj).length === 0)
            return "N/A"
        let addressLine1 = addressObj.addressLine1 ? addressObj.addressLine1 + "," : "";
        let addressLine2 = addressObj.addressLine2 ? addressObj.addressLine2 + "," : "";
        let cityPincode = (addressObj.city && addressObj.pincode) ? `${addressObj.city}-${addressObj.pincode}` : "";
        return `${addressLine1} ${addressLine2} ${cityPincode}`;
    }
    async getDefaultAccountByCategory(catName: string) {
        try {
            let lstAccount = await api.getAccountsList_withWindowCache();
            let defaultSalesAccount = lstAccount.find((account: any) => {
                // if (account.category === "SALES_RETURN" && account.info?.isDefault)
                return (account.category === catName && account.info?.isDefault)
            })
            return defaultSalesAccount
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
    }
    async getItemFromSerialNoAndValidate(serialNo: string, lstSerialNo: string[], catName: string) {
        if (!serialNo)
            throw Error("Please Provide Serial Number!");
        if (lstSerialNo.includes(serialNo))
            throw Error("Serial Number is already exist!")
        if (winState.state.global.lstSerialNo.includes(serialNo))
            throw Error("Same Serial Number exist in another Item!")
        let objItem = await api.getItemFromSerialNo(serialNo, catName)
        if (objItem) {
            let tmpSerialNoList = [...lstSerialNo]
            tmpSerialNoList.push(serialNo);
            winState.setGlobal({ lstSerialNo: tmpSerialNoList })
            return objItem
        }
        return false
    }

    renderToString(reactElement: any): Promise<{ html: string, text: string }> {
        return new Promise((resolve, reject) => {
            let tempDiv = document.createElement("div");

            ReactDOM.render(reactElement, tempDiv, () => {
                resolve({ html: tempDiv.innerHTML, text: tempDiv.innerText });
                ReactDOM.unmountComponentAtNode(tempDiv);
                tempDiv.remove();
            });
        });
    }

    checkCurrentPackageWithUI() {

    }
    isBilling() {

    }

    getInvoiceTemplateList() {
        let invoicesTemplates = [
            // { link: "template5", name: "Default Template" },
            { link: "fullPageTemplate", name: "Full Page Template" },
            { link: "miniTemplate", name: "Mini Template" },
            { link: "ImageHeaderTemplate", name: "Image Header Template" },
            { link: "Sticker4X6Template", name: "Sticker 4x6 Template" },
            { link: "GSTTemplate1", name: "GST Template 1" },
            { link: "GSTMiniTemplate1", name: "GST Mini Template" },
            // { link: "template1", name: "Color Template" },
            // { link: "template2", name: "Normal Template" },
        ]
        return invoicesTemplates;
    }

    getJournalSortOption() {
        let data = [
            { key: "transactionDate", name: "Transaction Date" },
            { key: "date", name: "Date" },
        ]
        return data;
    }

    isActiveSubscription(expiryDate: any) {
        let currentDateTime = new Date();
        let expiryDateTime = new Date(expiryDate);
        if (currentDateTime.getTime() > expiryDateTime.getTime())
            return false
        return true
    }
    getAttachmentList(journalCategory: string, attachmentKey: string = "") {
        let lstAttachment = winState.state.companySettings.attachments
        if (!lstAttachment || lstAttachment.length === 0) {
            return null;
        }

        return lstAttachment.filter((objAttachment: any) => {
            return (objAttachment.showOn.includes(journalCategory) || attachmentKey === objAttachment.key)
        })
    }

    getPercentageOfFixeDiscount(subTotal: number, discountAmount: number) {
        if (subTotal && discountAmount) {
            return this.toFixedFloat((discountAmount * 100) / subTotal)
        }
        return 0;
    }

    sendPaymentRemainderSMS = async (objSales: ISales) => {
        try {
            let isConfirm = await utils.showConfirm(`It Will Send SMS To ${objSales.debitAccount.name} mobile. Do you want to Send SMS?`)
            if (!isConfirm)
                return;
            utils.showLoader();
            console.log(scanRUtils.toFixedFloat(objSales.info.totalDueAmount))
            await api.sendPaymentRemainderSMS(objSales.info.INVNumber, utils.convertToSystemDateFormate(objSales.info.dueDate), scanRUtils.toFixedFloat(objSales.info.totalDueAmount), objSales.debitAccount.name, objSales.debitAccount.info?.mobile)
            utils.showSuccess("SMS Sent!")
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }

}



const scanRUtils = new ScanRUtilService();
(window as any)["scanRUtils"] = scanRUtils;
export const winState: WindowStateType = (window as any)["winState"] || (new WindowState() as unknown) as WindowStateType;
(window as any)["winState"] = winState;
export default scanRUtils;
