import React from 'react';

import Mousetrap from 'mousetrap';
import HyperModal from './HyperInput/HyperModal';
import AddUpdateSales from '../Pages/Sales/AddUpdateSales';
import AddUpdatePurchase from '../Pages/Purchases/AddUpdatePurchase';
interface ShortCutsContainerProps {
}
interface ShortCutsContainerState {
}

class ShortCutsContainer extends React.Component<ShortCutsContainerProps, ShortCutsContainerState> {
    scrollElement: any = document;
    constructor(props: ShortCutsContainerProps) {
        super(props)
        this.state = {

        }
        this.addSalesModal = new HyperModal(this);
        this.addPurchaseModal = new HyperModal(this);
    }
    addSalesModal: HyperModal;
    addPurchaseModal: HyperModal;
    componentDidMount() {
        Mousetrap.bind(['alt + f8'], () => {
            this.addSalesModal.toggle(true);
            return false;
        });
        Mousetrap.bind(['alt + f9'], () => {
            this.addPurchaseModal.toggle(true);
            return false;
        });
        // this.scrollElement.addEventListener("scroll", this.onScroll.bind(this));



        // document.addEventListener("keydown", function (e) {
        //     console.log(e)
        //     if ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) && e.keyCode == 71) {
        //         e.preventDefault();
        //         e.stopPropagation();
        //         // Process the event here (such as click on submit button)
        //         // document.forms[0].submit()
        //         document.querySelector("form")?.submit();
        //         // document.dispatchEvent(new KeyboardEvent('keypress',{'key':'Enter'}));
        //         return false;

        //     }
        // });
    }
    componentWillUnmount() {
        // this.scrollElement.removeEventListener("scroll", this.onScroll);
    }



    render() {
        return (
            <React.Fragment>
                {this.addSalesModal.isOpen() &&
                    <AddUpdateSales
                        isOpen={this.addSalesModal.isOpen()}
                        toggle={() => this.addSalesModal.toggle(!this.addSalesModal.isOpen())}

                    />
                }

                {this.addPurchaseModal.isOpen() &&
                    <AddUpdatePurchase
                        isOpen={this.addPurchaseModal.isOpen()}
                        toggle={() => this.addPurchaseModal.toggle(!this.addPurchaseModal.isOpen())}

                    />
                }
            </React.Fragment>
        );
    }
}

export default ShortCutsContainer;
