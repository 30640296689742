import React from 'react'
import { Button, Container, Grid } from '@material-ui/core'
import utils from '../../Services/Utils';
import NotificationSkeleton from '../../Components/Skeletons/NotificationSkeleton';
import DataTable from '../../Components/DataTable';
import { ItemSearchOption } from '../../Types/SearchOptions';
import api from '../../Services/ApiServices';
import AddUpdateItem from './AddUpdateItem';
import HyperModal from '../../Components/HyperInput/HyperModal';
import { IItem } from '../../Types/AllTypes';
import { IconAdd } from '../../Components/Icons/Icons';
import scanRUtils from '../../Services/ScanRUtilService';

interface ItemsProps {

}

interface ItemsState {
    shouldShowSkeleton: boolean,
    lstItems: IItem[],
    currentData?: IItem
}

class Items extends React.Component<ItemsProps, ItemsState> {
    constructor(props: ItemsProps) {
        super(props);
        let state: ItemsState = {
            shouldShowSkeleton: true,
            lstItems: [],
            currentData: undefined
        };

        this.state = state;
        this.modal = new HyperModal(this);
    }
    modal: HyperModal;

    componentDidMount() {
        this.loadItems();
    }

    async loadItems() {
        try {
            let so = new ItemSearchOption();
            let lstItems = await api.getItemList(so);
            this.setState({ lstItems, shouldShowSkeleton: false, currentData: undefined })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }

    }

    onDeleteClick = async (objItem: IItem) => {
        let confirmed = await utils.showConfirm("Do you really want to delete this?", "");
        if (confirmed) {
            try {
                let data = await api.deleteItem(objItem.id);
                if (data.isUsed)
                    return utils.showWarning("You can't delete this item because it's already used in Sales Invoice.")
                this.loadItems();
            }
            catch (ex) {
                utils.showError(ex.msg || ex.message)
            }
        }
    }

    onAddClick = () => {
        this.modal.toggle(true, { currentData: undefined })
    }

    onEditClick = (objItem: IItem) => {
        this.modal.toggle(true, { currentData: objItem });
    }

    render() {
        return (
            <Container>
                <div style={{ textAlign: 'end', marginBottom: 20 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={this.onAddClick}
                        startIcon={<IconAdd />}
                    >
                        New
                    </Button>
                </div>
                <Grid item xs={12} >
                    {this.state.shouldShowSkeleton ? (
                        <NotificationSkeleton />
                    ) : (
                            <DataTable
                                title="Items"
                                columns={[
                                    {
                                        title: "ID", render: (rowData: IItem) => {
                                            return `#${rowData.id}`;
                                        },
                                        sortField: "id"
                                    },
                                    { title: "Name", field: "name" },


                                    {
                                        title: "Purchase Price",
                                        render: (rowData: IItem) => {
                                            return scanRUtils.prettyPrice(rowData.defaultPurchasePrice)
                                        },
                                        sortField: "defaultPurchasePrice"
                                    },
                                    {
                                        title: "Sales Price", render: (rowData: IItem) => {
                                            return scanRUtils.prettyPrice(rowData.defaultSalesPrice)
                                        },
                                        sortField: "defaultSalesPrice"
                                    },
                                    { title: "Unit", field: "unit" },
                                    { title: "Category", field: "category.name" },
                                    { title: "Sub Category", field: "subCategory.name" },
                                ]}
                                data={this.state.lstItems}
                                options={{
                                    sorting: true,
                                }}
                                onEdit={(rowData: IItem) => { this.onEditClick(rowData) }}
                                onDelete={(rowData: IItem) => { this.onDeleteClick(rowData) }}
                            // onView={this.viewUser}
                            />
                        )}
                </Grid>
                {(this.modal.isOpen()) &&
                    <AddUpdateItem
                        isOpen={this.modal.isOpen()}
                        objItem={this.state.currentData}
                        onComplete={this.loadItems.bind(this)}
                        toggle={this.modal.toggle}
                    />
                }
            </Container>
        );
    }
}

export default Items;