import React from 'react'
import { Button, Container, Grid } from '@material-ui/core'
import AddUpdateAccount from './AddUpdateAccount';
import utils from '../../Services/Utils';
import NotificationSkeleton from '../../Components/Skeletons/NotificationSkeleton';
import DataTable from '../../Components/DataTable';
import { IAccount } from '../../Types/AllTypes';
import api from '../../Services/ApiServices';
import { AccountSearchOption } from '../../Types/SearchOptions';
import AccountText from './AccountText';
import { IconAdd } from '../../Components/Icons/Icons';
import HyperModal from '../../Components/HyperInput/HyperModal';

interface AccountsProps {}
interface AccountsState {
    shouldShowSkeleton: boolean,
    lstAccounts: IAccount[],
    currentData?: IAccount
}

class Accounts extends React.Component<AccountsProps, AccountsState> {
    constructor(props: AccountsProps) {
        super(props);
        let state: AccountsState = {
            shouldShowSkeleton: true,
            lstAccounts: [],
            currentData: undefined
        };

        this.state = state;
        this.modal = new HyperModal(this);
    }
    modal: HyperModal;

    componentDidMount() {
        this.loadAccounts();
    }

    async loadAccounts() {
        try {
            let SO = new AccountSearchOption();
            let lstAccounts = await api.getAccountsList(SO);
            this.setState({ lstAccounts, shouldShowSkeleton: false })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }

    }

    onAddClick = () => {
        this.modal.toggle(true)
    }

    onEditClick = (objAccount: IAccount) => {
        this.modal.toggle(true, { currentData: objAccount });
    }

    onDeleteClick = async (objAccount: IAccount) => {
        let confirmed = await utils.showConfirm("Do you really want to delete this?", "");
        if (confirmed) {

            try {
                utils.showLoader();
                let data = await api.deleteAccount(objAccount.id);
                if (data.isUsed)
                    return utils.showWarning("You can't delete this Account because it's already used.")
                this.loadAccounts();
            }
            catch (ex) {
                utils.showError(ex.msg || ex.message)
            }
            finally {
                utils.hideLoader();
            }
        }
    }

    render() {
        // console.log(this.state)
        return (
            <Container>
                <div style={{ textAlign: 'end', marginBottom: 20 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={this.onAddClick}
                        startIcon={<IconAdd />}
                    >
                        New
                    </Button>
                </div>
                <Grid item xs={12}>
                    {this.state.shouldShowSkeleton ? (
                        <NotificationSkeleton />
                    ) : (
                            <DataTable
                                title="Accounts"
                                columns={[
                                    {
                                        title: "ID", render: (rowData: IAccount) => {
                                            return `#${rowData.id}`;
                                        },
                                        sortField: "id"
                                    },
                                    {
                                        title: "Name", render: (rowData: IAccount) => {
                                            return <AccountText objAccount={rowData} />
                                        },
                                        sortField: "name"
                                    },
                                    { title: "Type", field: "type" },
                                    { title: "Category", field: "category" },
                                ]}
                                data={this.state.lstAccounts}
                                options={{
                                    sorting: true,
                                }}
                                onEdit={(rowData: IAccount) => { this.onEditClick(rowData) }}
                                onDelete={(rowData: IAccount) => { this.onDeleteClick(rowData) }}
                            // onView={this.viewUser}
                            />
                        )}
                </Grid>
                {(this.modal.isOpen()) &&
                    <AddUpdateAccount
                        isOpen={this.modal.isOpen()}
                        toggle={this.modal.toggle}
                        objAccount={this.state.currentData}
                        onComplete={this.loadAccounts.bind(this)}
                    />
                }
            </Container>
        );
    }
}

export default Accounts;