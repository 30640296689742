import React, { Component, FormEvent } from "react";
import { Dialog, IconButton, Slide, DialogTitle, DialogProps, DialogContent, Button } from "@material-ui/core";
import { TransitionProps } from '@material-ui/core/transitions';
import { withStyles } from "@material-ui/styles";
import CloseIcon from '@material-ui/icons/Close';
import {
    Theme,
    WithStyles,
    createStyles,
} from "@material-ui/core";
import utils from "../Services/Utils";

const styles = (theme: Theme) =>
    createStyles({
        dialogTitle: {
            color: theme.palette.primary.main,
            fontWeight: 'bold'
        },
        dialogTitlePrimary: {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
        },
        closeButtonPrimary: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.primary.contrastText,
        },
        btnSave: {
            position: 'absolute',
            right: theme.spacing(10),
            top: theme.spacing(2),
            color: theme.palette.primary.dark,
        },
    });

interface ICommonModalProps extends WithStyles {
    dialogProps: DialogProps,
    title?: any,
    isFullScreen?: boolean
    dialogAction?: any,
    maxWidth?: DialogProps["maxWidth"]
    primaryTitle?: boolean
    toggle: () => void,
    disableEscapeKey?: boolean
    onSave?: (e: FormEvent) => void
    disableOnSave?: boolean
}

interface ICommonModalState {

}

class CommonModal extends Component<ICommonModalProps, ICommonModalState> {
    componentDidMount(){
        // Mousetrap.bind(['+x'], () => {
        //     this.props.toggle();
        //     return false;
        // });
    }
    render() {
        return (
            <Dialog
                fullScreen={this.props.isFullScreen || !utils.isDesktop()}
                fullWidth
                scroll="paper"
                maxWidth={this.props.maxWidth || 'md'}
                TransitionComponent={Transition}
                {...this.props.dialogProps}
                onClose={this.props.disableEscapeKey ? undefined : this.props.toggle}
            >
                <DialogTitle className={!this.props.primaryTitle ? this.props.classes.dialogTitlePrimary : this.props.classes.dialogTitle} >
                    {this.props.title}
                    {!!(this.props.onSave) &&
                        <Button disabled={this.props.disableOnSave ? true : false} variant="contained" color="default" onClick={this.props.onSave} className={this.props.classes.btnSave} >
                            Save
                        </Button>
                    }
                    <IconButton style={{ backgroundColor: 'white' }} onClick={this.props.toggle} className={!this.props.primaryTitle ? this.props.classes.closeButtonPrimary : this.props.classes.closeButton} >
                        <CloseIcon color={"primary"} />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers >
                    {this.props.children}
                </DialogContent>
                {this.props.dialogAction}
            </Dialog>
        );
    }
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default withStyles(styles)(CommonModal);
