import React from 'react'
import { Container, Grid, Button } from '@material-ui/core'
import api from '../../../Services/ApiServices';
import ReportsDataTable from '../../../Components/ReportsDataTable';
// import scanRUtils from '../../../Services/ScanRUtilService';
// import ItemAutoComplete from '../../Item/ItemAutoComplete';
import { IWindowState } from '../../../Types/AllTypes';
import utils from '../../../Services/Utils';
// import enums from '../../../enums';
import HyperDateRangePicker from '../../../Components/HyperDateRangePicker';
import moment from "moment";
import { RouteComponentProps } from 'react-router-dom';
import withWindowState from '../../../Components/withWindowState';
import { StockSummaryByCategorySearchOption } from '../../../Types/SearchOptions';
// import SeeMore from '../../../Components/SeeMore';

interface CategoryWiseStockProps extends RouteComponentProps, IWindowState {
}

interface CategoryWiseStockState {
    lstCategoryWiseStock: any
    dateRange: any
    isLoading: boolean
}


class CategoryWiseStock extends React.Component<CategoryWiseStockProps, CategoryWiseStockState> {
    constructor(props: CategoryWiseStockProps) {
        super(props);
        let state: CategoryWiseStockState = {
            lstCategoryWiseStock: [],
            isLoading: false,
            dateRange: { startDate: moment().startOf("month").toDate(), endDate: moment().endOf("month").toDate(), label: "This Month" }
        };
        this.state = state;
    }

    async componentDidMount() {
        this.getCategoryWiseStock()
    }
    getCategoryWiseStock = async () => {
        try {
            await this.setState({ isLoading: true });
            let so = new StockSummaryByCategorySearchOption();
            let data = await api.getStockSummaryByCategory(so);

            console.log(data, "data");
            for (let objCategory of data) {
                if (objCategory.parentId) {
                    objCategory.subCategoryName = objCategory.name;
                    objCategory.categoryName = data.find((item: any) => item.id === objCategory.parentId)?.name || "";
                }
                else {
                    objCategory.categoryName = objCategory.name;
                    objCategory.subCategoryName = "Default";
                }
                let totalCategoryStock = 0;
                for (let objItem of objCategory.lstItems) {
                    totalCategoryStock += (objItem?.purchase?.totalQty || 0) - (objItem?.sales?.totalQty || 0)
                }
                objCategory.totalCategoryStock = totalCategoryStock;
            }

            // for (let objCategory of data) {
            //     if (!objCategory.parentId) {
            //         let filterData = data.filter((item: any) => item.parentId === objCategory.id)
            //         objCategory.totalStock = filterData.reduce((total: number, row: any) => total + (row.totalCategoryStock), 0) + objCategory.totalCategoryStock;
            //         objCategory.categoryName = `${objCategory.categoryName} (${objCategory.totalStock} Total Qty)`
            //         console.log(objCategory.totalStock);
            //     }
            // }

            this.setState({ lstCategoryWiseStock: data })

        }
        catch (ex) {
            // console.log(ex)
            utils.showError(ex.message)
        }
        finally {
            await this.setState({ isLoading: false });
        }
    }

    render() {
        // console.log(this.state, "State")
        // let lstExtendColumns = [];

        // if (this.props.companySettings.showItemSerialNo) {
        //     lstExtendColumns.push({ title: "S/N", render: this.getSN, sortField: "info.items.serialNo" })
        // }
        return (
            <Container>
                <Grid container spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item md={6} style={{ textAlign: 'right', margin: 'auto' }}>
                        <Button onClick={this.getCategoryWiseStock} variant="contained" color="primary">Refresh</Button>{" "}
                        <HyperDateRangePicker
                            initialDateRange={this.state.dateRange}
                            onChange={async (range: any) => {
                                await this.setState({ dateRange: range });
                                this.getCategoryWiseStock()
                            }}
                        />
                    </Grid>
                    {/* <Grid item md={6}>
                        <ItemAutoComplete
                            placeholder="Select Item for Stock Book"
                            itemId={this.state.objItem?.id}
                            onChange={async (data: IItem) => { await this.setState({ objItem: data }); this.getCategoryWiseStock() }}
                        />
                    </Grid> */}
                </Grid>

                <ReportsDataTable
                    key={Date.now()}
                    title={`Stock Wise Stock`}
                    columns={[
                        { title: "Category Name", field: "categoryName", defaultGroupOrder: 0 },
                        { title: "Sub Category Name", field: "subCategoryName", grouping: false },
                        { title: "Total Stock", field: "totalCategoryStock", grouping: false },
                    ]}
                    data={this.state.lstCategoryWiseStock}
                    options={{ grouping: true, pageSize: 20 }}
                    isLoading={this.state.isLoading}
                    // parentChildData={(row: any, rows: any) => rows.find((a: any) => a.id === row.parentId)}
                    onRowClick={() => { }}
                />

            </Container>
        );

    }
}

export default withWindowState(CategoryWiseStock, ["companySettings"]);