class Config {
  API_URL: string = "http://localhost:2001";
  // API_URL: string = "http://192.168.0.104:2001";
  C_SDF: String = "DD/MM/YYYY"; // Manik++ 10032020
  C_SDF_INCL_HM: String = "DD/MM/YYYY HH:MMa"; // KRD++ 19042016
  C_SDF_INCL_HM_SS: String = "dd/mm/yyyy hh:MM:ss TT"; // ADJ++ 04052019
  SCANNER_APP_URL = "https://drive.google.com/file/d/19GyUz_LLRKBRTp1aeLHhnEEM6o8aKe64/view?usp=sharing";
  EXTENSION_ZIP_URL = "https://drive.google.com/file/d/1If5qE29Cn5LtRPVoWQGvaZc5jUucxhLl/view?usp=sharing";
  APP_VERSION = process.env.REACT_APP_CACHE_BUST || "dev";

  constructor() {
    if (process.env.NODE_ENV === "production") {
      this.API_URL = process.env.REACT_APP_API_URL || "";
      // this.STRIPE_PK = 'pk_live_PWemGA1GFNlpnvryP4jQUrWU00ACqCoaKJ';
    }
  }
}

const config = new Config();
(window as any)["config"] = config;
export default config;
