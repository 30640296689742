import React from 'react'
import { ISales, IWindowState, ICustomer } from '../../Types/AllTypes';
import CommonModal from '../../Components/CommonModal';
import { Container } from '@material-ui/core';
import withWindowState from '../../Components/withWindowState';
import Invoice from './Invoice';

interface InvoiceModalProps extends IWindowState {
    isOpen: boolean
    objSales?: ISales
    toggle: () => void
}
interface InvoiceModalState { }

class InvoiceModal extends React.Component<InvoiceModalProps, InvoiceModalState> {
    constructor(props: InvoiceModalProps) {
        super(props);
        let state: InvoiceModalState = {
        };
        this.state = state;
    }

    render() {

        return (
            <Container>
                <CommonModal
                    dialogProps={{
                        open: this.props.isOpen
                    }}
                    toggle={this.props.toggle}
                    title={"Invoice"}
                >
                    <Invoice
                        objSales={this.props.objSales || ({} as ISales)}
                        objCompany={this.props.companySettings}
                        objCustomer={(this.props.objSales?.debitAccount as ICustomer) || ({} as ICustomer)}
                        showTemplateSelection={true}
                    />
                </CommonModal>
            </Container>
        );
    }
}

export default withWindowState(InvoiceModal, ["companySettings", "user"]);
