import React, { FormEvent } from 'react'
import moment from 'moment';
import CommonModal from '../../Components/CommonModal';
import { Grid, Button } from '@material-ui/core';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import { IAccount, Journal } from '../../Types/AllTypes';
import HyperForm from '../../Components/HyperInput/HyperForm';
import api from '../../Services/ApiServices';
import utils from '../../Services/Utils';
import AccountAutoComplete from '../Journal/AccountAutoComplete';
import { JournalInsertObject } from '../../Types/InsertObjects';
import HyperAmountField from '../../Components/HyperInput/HyperAmountField';
import enums from '../../enums';
import Alert from '@material-ui/lab/Alert';
import scanRUtils from '../../Services/ScanRUtilService';
import { JournalSearchOptions } from '../../Types/SearchOptions';
import ManageUnpaidSales from './ManageUnpaidSales';
interface AddUpdateSalesReceiptProps {
    objJournal?: Journal
    isOpen: boolean
    isDisabledDebitField?: boolean
    isDisabledCreditField?: boolean
    isDisabledAmountField?: boolean
    hideDebitField?: boolean
    hideCreditField?: boolean
    hideAmountField?: boolean
    showDueAmount?: boolean

    onComplete?: () => void
    toggle: () => void
}

interface AddUpdateSalesReceiptState {
    objJournal: Journal
    lstUnpaidSales: any
}


const initialJournal: Journal = {
    _id: "",
    debitAccountId: 0,
    creditAccountId: 0,
    amount: 0,
    comment: "",
    date: "",
    createdAt: "",
    username: "",
    id: 0,
    debitAccount: {} as IAccount,
    creditAccount: {} as IAccount,
} as Journal;


class AddUpdateSalesReceipt extends React.Component<AddUpdateSalesReceiptProps, AddUpdateSalesReceiptState> {
    constructor(props: AddUpdateSalesReceiptProps) {
        super(props);
        // console.log(props)
        let state: AddUpdateSalesReceiptState = {
            objJournal: initialJournal,
            lstUnpaidSales: []
        };

        if (this.props.objJournal) {
            state.objJournal = this.props.objJournal
            state.objJournal.amount = scanRUtils.toFixedFloat(this.props.objJournal.amount)
            this.isEditing = (this.props.hideDebitField || this.props.hideCreditField) ? false : true;
        }
        if (!state.objJournal.date) {
            state.objJournal.date = moment().format("YYYY-MM-DD")
        }
        else {
            state.objJournal.date = moment(state.objJournal.date).format("YYYY-MM-DD")
        }

        this.state = state;
        this.frm = new HyperForm();
    }
    frm: HyperForm
    isEditing: boolean = false;

    componentDidMount() {
        // document.addEventListener(enums.EVENT.Journal_ADD_UPDATE_MODEL, this.onModelToggle);
    }

    componentWillUnmount() {
        // document.removeEventListener(enums.EVENT.Journal_ADD_UPDATE_MODEL, this.onModelToggle);
    }

    onModelToggle = (event?: any) => {
        this.props.toggle()
    }

    setJournal(obj: Partial<Journal>) {
        this.setState({ objJournal: { ...this.state.objJournal, ...obj } });
    }

    onSubmit = async (e: FormEvent) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.frm.setIsFormSubmitted(true)
            // console.log(this.state.lstUnpaidSales, "lstUnpaidSales")
            if (this.frm.isValid()) {
                let lstReceipt: any = [];
                this.state.lstUnpaidSales.forEach((objSales: any) => {
                    if (objSales.isSelected) {
                        let io = new JournalInsertObject();
                        io.id = this.state.objJournal.id;
                        io.debitAccountId = this.state.objJournal.debitAccountId;
                        io.creditAccountId = this.state.objJournal.creditAccountId;
                        io.amount = objSales.info.totalDueAmount;
                        io.comment = this.state.objJournal.comment;
                        io.date = this.state.objJournal.date;
                        io.category = enums.JOURNAL_CATEGORY.RECEIPT;
                        if (this.isEditing) {
                            io.info = this.state.objJournal.info;
                            lstReceipt.push({ actionCode: enums.DB_ACTION.UPDATE, data: io });
                        }
                        else {
                            io.info = { salesId: objSales.id };
                            lstReceipt.push({ actionCode: enums.DB_ACTION.INSERT, data: io });
                        }
                    }
                });
                if (lstReceipt.length === 0) {
                    return utils.showAlert("Please Selected Sales");
                }

                utils.showLoader();
                await api.journalMultiAction(lstReceipt);
                this.onModelToggle();
                if (this.props.onComplete) {
                    this.props.onComplete();
                }
            }
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }

    getCustomerUnpaidInvoice = async (creditAccountId: number) => {
        try {
            let so = new JournalSearchOptions();
            so.category = enums.JOURNAL_CATEGORY.SALES;
            so.debitAccountId = creditAccountId;
            so.info = {
                "info.paymentStatus": { $in: [enums.PAYMENT_STATUS.UNPAID, enums.PAYMENT_STATUS.PARTIALLY_PAID] }
            };
            let lstJournal = await api.getJournals(so);
            await this.setState({ lstUnpaidSales: lstJournal })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
    }
    onUnpaidSalesChange = () => {
        let amount: number = 0;
        this.state.lstUnpaidSales.forEach((item: any) => {
            if (item.isSelected && !isNaN(scanRUtils.toFixedFloat(item.info.totalDueAmount))) {
                amount += scanRUtils.toFixedFloat(item.info.totalDueAmount);
            }
        })
        this.setJournal({ amount: amount })
    }
    render() {
        // console.log("[RECEIPT STATE]", this.state.objJournal,this.props.objJournal)
        return (
            <div>
                <CommonModal
                    dialogProps={{
                        open: this.props.isOpen
                    }}
                    title={"Receipt"}
                    toggle={this.props.toggle}
                >

                    {this.props.showDueAmount ?
                        <Grid item xs={12}>
                            <Alert severity="info">Total Due Amount - {scanRUtils.prettyPrice(this.props.objJournal?.amount || 0)}</Alert>
                        </Grid>
                        :
                        ""
                    }

                    <form onSubmit={this.onSubmit}>
                        <Grid container spacing={2}>
                            {!this.props.hideCreditField &&
                                <Grid item xs={12} md={6}>
                                    <AccountAutoComplete
                                        accountId={this.state.objJournal.creditAccountId}
                                        onChange={(objAccount: IAccount) => {
                                            if (objAccount) {
                                                this.setJournal({ creditAccountId: objAccount.id })
                                                this.getCustomerUnpaidInvoice(objAccount.id)
                                            }
                                        }}
                                        label="Received From"
                                        frm={this.frm}
                                        category={enums.JOURNAL_CATEGORY.CUSTOMER}
                                        disabled={this.props.isDisabledCreditField ? true : false}
                                        required
                                        autoFocus
                                        addBtnLabel="Add New Customer"
                                        autoCompleteAddModalType={enums.AUTO_COMPLETE_ADD_TYPE.ADD_CUSTOMER}
                                    />
                                </Grid>
                            }
                            {!this.props.hideDebitField &&
                                <Grid item xs={12} md={6}>
                                    <AccountAutoComplete
                                        accountId={this.state.objJournal.debitAccountId}
                                        onChange={(objAccount: IAccount) => {
                                            if (objAccount)
                                                this.setJournal({ debitAccountId: objAccount.id })
                                        }}
                                        label="Received"
                                        category={enums.JOURNAL_CATEGORY.CASH_BANK}
                                        disabled={this.props.isDisabledDebitField ? true : false}
                                        frm={this.frm}
                                        required
                                        addBtnLabel="Add New Payment Option"
                                        autoCompleteAddModalType={enums.AUTO_COMPLETE_ADD_TYPE.ADD_PAYMENT_OPTION}
                                    />
                                </Grid>
                            }
                            {!!(this.state.lstUnpaidSales.length > 0) &&
                                <Grid xs={12} item>
                                    <ManageUnpaidSales lstUnpaidSales={this.state.lstUnpaidSales} onChange={this.onUnpaidSalesChange} />
                                </Grid>
                            }
                            {!this.props.hideAmountField &&
                                <Grid item xs={12} md={6}>
                                    <HyperAmountField
                                        required
                                        value={this.state.objJournal.amount}
                                        onChange={(e: any) => { this.setJournal({ amount: parseFloat(e.target.value) }) }}
                                        label={"Amount"}
                                        type="number"
                                        frm={this.frm}
                                        disabled={true}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} md={6}>

                                <HyperTextField
                                    required
                                    value={this.state.objJournal.date}
                                    onChange={(e: any) => {
                                        this.setJournal({ date: (e.target.value) })
                                    }}
                                    label={"Date"}
                                    type="date"
                                    frm={this.frm}
                                />
                            </Grid>
                            <Grid item xs={12}>

                                <HyperTextField
                                    required
                                    value={this.state.objJournal.comment}
                                    onChange={(e: any) => {
                                        this.setJournal({ comment: (e.target.value) })
                                    }}
                                    label={"Comment"}
                                    type="text"
                                    multiline
                                    frm={this.frm}
                                />
                            </Grid>

                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Button type="submit" color="primary" variant="contained">
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CommonModal>
            </div>
        );
    }
}

export default AddUpdateSalesReceipt;