import React from 'react'
import { Container, withTheme, Theme, Grid, Button } from '@material-ui/core'
import HyperModal from '../../../Components/HyperInput/HyperModal';
import api from '../../../Services/ApiServices';
import { AccountBookSearchOption } from '../../../Types/SearchOptions';
import { Journal, IAccount } from '../../../Types/AllTypes';
import moment from "moment";
import AccountAutoComplete from '../../Journal/AccountAutoComplete';
import HyperDateRangePicker from '../../../Components/HyperDateRangePicker';
import utils from '../../../Services/Utils';
import AccountStatementTable from '../../Accounts/AccountStatementTable';
import scanRUtils, { winState } from '../../../Services/ScanRUtilService';
import Alert from '@material-ui/lab/Alert';
// import ReportsDataTable from '../../../Components/ReportsDataTable';
import DataTable from '../../../Components/DataTable';
import enums from '../../../enums';
import { HyperTheme } from '../../../Theme/Theme';


interface AccountBookProps {
    theme: Theme
}

interface AccountBookState {
    lstAccountJournal: Journal[]
    selectedAccount: IAccount
    isLoading: boolean
    dateRange: any
    accountBalance: 0

}


class AccountBook extends React.Component<AccountBookProps, AccountBookState> {
    constructor(props: AccountBookProps) {
        super(props);
        let state: AccountBookState = {
            lstAccountJournal: [],
            selectedAccount: ({} as IAccount),
            accountBalance: 0,
            isLoading: false,
            dateRange: { startDate: moment().startOf("month").toDate(), endDate: moment().endOf("month").toDate(), label: "This Month" }

        };
        this.state = state;
        this.modal = new HyperModal(this);
    }
    modal: HyperModal;

    componentDidMount() {

    }
    loadJournals = async () => {
        try {
            this.setState({ isLoading: true })
            // this.setState({ isLoading: true })
            let so = new AccountBookSearchOption();
            // console.log(this.state.selectedAccount, "accountId={rowData.creditAccountId.toString()} accountName={rowData.creditAccount.name}")
            so.accountId = this.state.selectedAccount.id.toString();
            so.fromDate = this.state.dateRange.startDate;
            so.toDate = this.state.dateRange.endDate;
            // console.log(so)
            let lstAccountJournal = await api.getAccountBookById(so);
            console.log(lstAccountJournal)
            this.getAccountBalance()
            this.setState({ lstAccountJournal })
        }
        catch (ex) {
            console.log(ex)
            utils.showError(ex.msg || ex.message)
        }
        finally {
            this.setState({ isLoading: false })
        }

    }
    getAccountBalance = async () => {
        try {
            let accountBalance = await api.getAccountBalance(this.state.selectedAccount.id, this.state.dateRange.endDate);
            // console.log(accountBalance, "accountBalance")
            this.setState({ accountBalance: accountBalance.balance })
        }
        catch (ex) {
            console.log(ex)
            utils.showError(ex.msg || ex.message)
        }
    }
    download = async () => {
        let renderedObj = await scanRUtils.renderToString(<AccountStatementTable
            objAccount={this.state.selectedAccount}
            lstStatement={this.state.lstAccountJournal}
            totalBalance={this.state.accountBalance}
            dateRange={this.state.dateRange}
        />)

        let frame = document.createElement("iframe")
        document.body.appendChild(frame);
        frame.style.display = "none";
        frame.srcdoc = renderedObj.html;

        frame.onload = () => {
            console.log("did");
            frame.contentWindow?.print();
            // frame.remove()
        }
    }

    render() {
        return (
            <Container>
                <Grid container style={{ marginBottom: 20 }}>

                    <Grid item xs={6}>
                        <AccountAutoComplete
                            onChange={async(e: any) => {
                                await this.setState({ selectedAccount: e })
                                this.loadJournals();
                            }}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginBottom: 20, textAlign: 'right' }}>
                        <Button onClick={this.loadJournals} variant="contained" color="primary">Refresh</Button>{" "}
                        <Button onClick={this.download} variant="contained" color="primary">Download</Button>{" "}
                        <HyperDateRangePicker
                            initialDateRange={this.state.dateRange}
                            onChange={async (range: any) => {
                                await this.setState({ dateRange: range });
                                this.loadJournals();
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={12} style={{ marginBottom: 20 }}>
                        <Alert severity="info">Total Account Balance - {scanRUtils.prettyPrice(this.state.accountBalance)}</Alert>
                    </Grid>
                </Grid>



                <DataTable
                    title={`Records Of ` + this.state.selectedAccount.name}
                    columns={[
                        { title: "ID", field: "id" },
                        {
                            title: "Name", render: (rowData: Journal) => {
                                if (rowData.debitAccountId.toString() === this.state.selectedAccount.id.toString())
                                    return `${rowData.creditAccount.name} ${rowData.category === enums.JOURNAL_CATEGORY.SALES || rowData.category === enums.JOURNAL_CATEGORY.PURCHASE ? "(" + winState.state.companySettings.invoicePrefix + "-" + rowData.info.INVNumber + ")" : ""}`;
                                return rowData.debitAccount.name;
                            },
                            sortField: "creditAccount.name"
                        },
                        {
                            title: "Date", render: (rowData: Journal) => {
                                return utils.convertToSystemDateFormate(new Date(rowData.date));
                            },
                            sortField: "date",
                            renderReturnsString: true
                        },
                        {
                            title: "Amount", render: (rowData: Journal) => {
                                if (rowData.debitAccountId.toString() === this.state.selectedAccount.id.toString())
                                    return `Dr ${rowData.amount}`;
                                return `Cr ${rowData.amount}`;
                            },
                            sortField: "amount",
                            cellStyle: (style: any, rowData: Journal) => {
                                if (this.state.selectedAccount.type === enums.ACCOUNT_CATEGORY.REAL) {
                                    if (rowData.debitAccountId.toString() === this.state.selectedAccount.id.toString())
                                        return {
                                            backgroundColor: HyperTheme.palette.success.light,
                                            color: HyperTheme.palette.common.white
                                        }
                                    return {
                                        backgroundColor: HyperTheme.palette.error.light,
                                        color: HyperTheme.palette.common.white
                                    }
                                }
                                else {
                                    if (rowData.debitAccountId.toString() === this.state.selectedAccount.id.toString())
                                        return {
                                            backgroundColor: HyperTheme.palette.error.light,
                                            color: HyperTheme.palette.common.white
                                        }
                                    return {
                                        backgroundColor: HyperTheme.palette.success.light,
                                        color: HyperTheme.palette.common.white
                                    }
                                }

                            },
                        },
                        { title: "Comment", field: "comment" },
                    ]}
                    data={this.state.lstAccountJournal}
                    options={{
                        sorting: true,
                    }}
                    isLoading={this.state.isLoading}
                />
            </Container>
        );
    }
}

export default withTheme(AccountBook);