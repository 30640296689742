import React from 'react';
import CallIcon from '@material-ui/icons/Call';
import DialpadIcon from '@material-ui/icons/Dialpad';
import EmailIcon from '@material-ui/icons/Email';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import DashboardIcon from '@material-ui/icons/Dashboard';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import SortIcon from '@material-ui/icons/Sort';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import SmsIcon from '@material-ui/icons/Sms';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ForumIcon from '@material-ui/icons/Forum';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloseIcon from '@material-ui/icons/Close';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import GroupIcon from '@material-ui/icons/Group';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen';
import CallMadeIcon from '@material-ui/icons/CallMade';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import Person from '@material-ui/icons/Person';
import BlockIcon from '@material-ui/icons/Block';
import RemoveIcon from '@material-ui/icons/Remove';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PostAddIcon from '@material-ui/icons/PostAdd';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import MoneyIcon from '@material-ui/icons/Money';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Icon } from '@material-ui/core';
import CropFreeIcon from '@material-ui/icons/CropFree';
import GetAppIcon from '@material-ui/icons/GetApp';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import FilterHdrIcon from '@material-ui/icons/FilterHdr';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import TelegramIcon from '@material-ui/icons/Telegram';
import CategoryIcon from '@material-ui/icons/Category';
import InfoIcon from '@material-ui/icons/Info';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import CallMissedIcon from '@material-ui/icons/CallMissed';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TextsmsIcon from '@material-ui/icons/Textsms';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IsoIcon from '@material-ui/icons/Iso';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import StorageIcon from '@material-ui/icons/Storage';
import TuneIcon from '@material-ui/icons/Tune';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export const IconAdvance = TuneIcon;
export const IconOperations = SettingsInputComponentIcon;
export const IconFinance = IsoIcon;
export const IconCall = CallIcon;
export const IconWhatsApp = WhatsAppIcon;
export const IconMasters = StorageIcon;
export const IconLess = ExpandLessIcon;
export const IconCheckBox = CheckBoxIcon;
export const IconCheckBoxOutline = CheckBoxOutlineBlankIcon;
export const IconDownArrow = ArrowDownwardIcon;
export const IconTextSms = TextsmsIcon;
export const IconCheckCircle = CheckCircleIcon;
export const IconBankAccount = AccountBalanceIcon;
export const IconSalesReturn = CallMissedIcon;
export const IconPurchaseReturn = CallMissedOutgoingIcon;
export const IconOffer = LocalOfferIcon;
export const IconBulkEntry = PlaylistAddIcon;
export const IconAddCircle = AddCircleIcon;
export const IconView = VisibilityIcon;
export const IconInfo = InfoIcon;
export const IconCategory = CategoryIcon;
export const IconInventory = FilterHdrIcon;
export const IconAnnouncement = TelegramIcon;
export const IconKey = VpnKeyIcon;
export const IconTransfer = SwapHorizIcon;
export const IconDownload = GetAppIcon;
export const IconCalendar = CalendarTodayIcon;
export const IconReports = AssessmentIcon;
export const IconExit = ExitToAppIcon;
export const IconQRCode = CropFreeIcon;
export const IconCart = ShoppingCartIcon;
export const IconSetting = SettingsIcon;
export const IconTax = EuroSymbolIcon;
export const IconItem = EventNoteIcon;
export const IconJournal = PostAddIcon;
export const IconCustomer = GroupIcon;
export const IconAccount = AccountBoxIcon;
export const IconBalanceSheet = MenuBookIcon;
export const IconProfitAndLoss = AssignmentIcon;
export const IconCommunication = ForumIcon;
export const IconClient = PermIdentityIcon;
export const IconCallAClient = DialpadIcon;
export const IconEmail = EmailIcon;
export const IconNote = EventNoteIcon;
export const IconNotification = NotificationsIcon;
export const IconLead = SpeakerNotesIcon;
export const IconHap = DashboardIcon;
export const IconSort = SortIcon;
export const IconFilterList = FilterListIcon;
export const IconSearch = SearchIcon;
export const IconMail = MailOutlineIcon;
export const IconMoreVert = MoreVertIcon;
export const IconAddNote = NoteAddIcon;
export const IconMore = ExpandMoreIcon;
export const IconEdit = EditIcon;
export const IconDelete = DeleteOutlineIcon;
export const IconDeleteFill = DeleteIcon;
export const IconAdd = AddIcon;
export const IconMinus = RemoveIcon;
export const IconClose = CloseIcon;
export const IconAddFilter = LibraryAddIcon;
export const IconList = ListAltIcon;
export const IconGroupAdd = GroupAddIcon;
export const IconAddClient = AddIcon;
export const IconPhoneGone = AddToHomeScreenIcon;
export const IconMadeCall = CallMadeIcon;
export const IconCallForward = PhoneForwardedIcon;
export const IconPhoneInTalk = PhoneInTalkIcon;
export const IconPhoneCallback = PhoneCallbackIcon;
export const IconPerson = Person;
export const IconDismiss = BlockIcon;
export const IconInvoice = ReceiptIcon;
export const IconExpanse = MoneyOffIcon;
export const IconIncome = MonetizationOnIcon;
export const IconSupplier = ChildFriendlyIcon;
export const IconSales = MoneyIcon;
export const IconReceipt = ArrowBackIcon;
export const IconPayment = ArrowForwardIcon;
export const IconPurchase = (props) => <Icon {...props} className="fas fa-money-bill" />;



