import React, { FormEvent } from 'react'
import enums from '../../enums';
import CommonModal from '../../Components/CommonModal';
import { Grid, Button } from '@material-ui/core';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import { ISupplier } from '../../Types/AllTypes';
import HyperForm from '../../Components/HyperInput/HyperForm';
import api from '../../Services/ApiServices';
import utils from '../../Services/Utils';
import StateAutoComplete from '../Customers/StateAutoComplete';
import { AccountInsertObject } from '../../Types/InsertObjects';
import scanRUtils, { winState } from '../../Services/ScanRUtilService';
import HyperSwitchField from '../../Components/HyperInput/HyperSwitchField';

interface AddUpdateSupplierProps {
    objSupplier?: ISupplier
    isOpen: boolean

    onComplete?: (result: any) => void
    toggle: () => void
}

interface AddUpdateSupplierState {
    isOpen: boolean,
    objSupplier: ISupplier
}

const initialSupplier: ISupplier = {
    name: "",
    type: enums.ACCOUNT_TYPE.PERSONAL,
    category: enums.ACCOUNT_CATEGORY.SUPPLIER,
    info: {
        city: "",
        state: "",
        addressLine1: "",
        addressLine2: "",
        mobile: "",
        email: "",
        GST: "",
        pincode: "",
        isSameAsBillTo: true,

        shipAddressLine1: "",
        shipAddressLine2: "",
        shipState: "",
        shipCity: "",
        shipPincode: "",
    }
} as ISupplier;

class AddUpdateSupplier extends React.Component<AddUpdateSupplierProps, AddUpdateSupplierState> {
    constructor(props: AddUpdateSupplierProps) {
        super(props);
        let state: AddUpdateSupplierState = {
            isOpen: false,
            objSupplier: initialSupplier
        };

        if (this.props.objSupplier) {
            state.objSupplier = this.props.objSupplier
            this.isEditing = true;
        }
        if (!state.objSupplier.info) {
            state.objSupplier.info = initialSupplier.info
        }
        if (!this.isEditing) {
            state.objSupplier.info.state = winState.state.companySettings.state;
            state.objSupplier.info.shipState = winState.state.companySettings.state;
        }

        this.state = state;
        this.frm = new HyperForm();
    }
    frm: HyperForm
    isEditing: boolean = false;

    setSupplier(obj: any) {
        this.setState({ objSupplier: { ...this.state.objSupplier, ...obj } });
    }

    setInfo(obj: any) {
        let objSupplier = this.state.objSupplier;
        objSupplier.info = { ...this.state.objSupplier.info, ...obj };
        this.setState({ objSupplier });
    }

    onSubmit = async (e: FormEvent) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.frm.setIsFormSubmitted(true)
            console.log(this.frm.isValid(), "Is Valid", this.state)
            // return;
            if (this.frm.isValid()) {
                let result = null;
                utils.showLoader();
                if (this.isEditing) {
                    let UO = new AccountInsertObject()
                    UO.id = this.state.objSupplier.id;
                    UO.name = this.state.objSupplier.name;
                    UO.type = this.state.objSupplier.type;
                    UO.category = this.state.objSupplier.category;
                    UO.info = this.state.objSupplier.info
                    result = await api.updateAccount(UO);
                }
                else {
                    let IO = new AccountInsertObject()
                    IO.name = this.state.objSupplier.name;
                    IO.type = this.state.objSupplier.type;
                    IO.category = this.state.objSupplier.category;
                    IO.info = this.state.objSupplier.info;

                    result = await api.addAccount(IO);
                }
                this.props.toggle();

                if (this.props.onComplete) {
                    this.props.onComplete(result);
                }
                // console.log(result)
            }
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }
    gstValidation = async (gst: string) => {
        if (!gst)
            return "";
        if (!scanRUtils.checkGST(gst))
            return utils.showError("Please Enter Valid GST Number.");

        let stateName = scanRUtils.getStateNameFromGST(gst);
        let pan = scanRUtils.getPANIntoGST(gst);
        let infoObj: any = { state: stateName, pan };
        try {
            utils.showLoader();
            let gstInfo = await api.getGSTInfo(gst);
            infoObj.city = gstInfo.city;
            infoObj.addressLine1 = gstInfo.addressLine1;
            infoObj.addressLine2 = gstInfo.addressLine2;
            infoObj.pincode = gstInfo.pincode;
            this.setSupplier({ name: gstInfo.customerName })
        }
        catch (ex) {
            // Supress Error
            console.log("Unable to get gst info", ex)
        }
        finally {
            utils.hideLoader();
        }

        await this.setInfo(infoObj)

    }

    render() {
        return (
            <div>
                <CommonModal
                    dialogProps={{
                        open: this.props.isOpen
                    }}
                    toggle={this.props.toggle}
                    title={"Supplier"}
                >
                    <form onSubmit={this.onSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <HyperTextField
                                    required
                                    value={this.state.objSupplier.name}
                                    onChange={(e: any) => { this.setSupplier({ name: e.target.value }) }}
                                    label={"Name"}
                                    frm={this.frm}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <HyperTextField
                                    // required
                                    type="number"
                                    value={this.state.objSupplier.info.mobile}
                                    onChange={(e: any) => { this.setInfo({ mobile: e.target.value }) }}
                                    label={"Mobile"}
                                    frm={this.frm}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <HyperTextField
                                    // required
                                    type="email"
                                    value={this.state.objSupplier.info.email}
                                    onChange={(e: any) => { this.setInfo({ email: e.target.value }) }}
                                    label={"Email"}
                                    frm={this.frm}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <HyperTextField
                                    value={this.state.objSupplier.info.GST}
                                    onChange={(e: any) => { this.setInfo({ GST: e.target.value }) }}
                                    label={"GST"}
                                    onBlur={(e: any) => { this.gstValidation(e.target.value) }}
                                    frm={this.frm}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <HyperTextField
                                    value={this.state.objSupplier.info.pan}
                                    onChange={(e: any) => { this.setInfo({ pan: e.target.value }) }}
                                    label={"PAN  Number"}
                                    frm={this.frm}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <HyperTextField
                                    // required
                                    value={this.state.objSupplier.info.addressLine1}
                                    onChange={(e: any) => { this.setInfo({ addressLine1: e.target.value }) }}
                                    label={"Address Line 1"}
                                    frm={this.frm}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <HyperTextField
                                    // required
                                    value={this.state.objSupplier.info.addressLine2}
                                    onChange={(e: any) => { this.setInfo({ addressLine2: e.target.value }) }}
                                    label={"Address Line 2"}
                                    frm={this.frm}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <StateAutoComplete
                                    label="Your State"
                                    value={this.state.objSupplier.info.state}
                                    onChange={(newValue: string) => { this.setInfo({ state: newValue }) }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <HyperTextField
                                    value={this.state.objSupplier.info.city}
                                    onChange={(e: any) => { this.setInfo({ city: e.target.value }) }}
                                    label={"City"}
                                    frm={this.frm}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <HyperTextField
                                    value={this.state.objSupplier.info.pincode}
                                    onChange={(e: any) => { this.setInfo({ pincode: e.target.value }) }}
                                    label={"Pincode"}
                                    frm={this.frm}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <HyperSwitchField
                                    label={"Shipping Address Is Same As Bill Address?"}
                                    checked={this.state.objSupplier.info.isSameAsBillTo}
                                    onChange={(val: boolean) => this.setInfo({ isSameAsBillTo: val })}
                                />
                            </Grid>
                            {!(this.state.objSupplier.info.isSameAsBillTo) &&
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <HyperTextField
                                                value={this.state.objSupplier.info.shipAddressLine1}
                                                onChange={(e: any) => { this.setInfo({ shipAddressLine1: e.target.value }) }}
                                                label={"Address Line 1"}
                                                frm={this.frm}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <HyperTextField
                                                value={this.state.objSupplier.info.shipAddressLine2}
                                                onChange={(e: any) => { this.setInfo({ shipAddressLine2: e.target.value }) }}
                                                label={"Address Line 2"}
                                                frm={this.frm}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <StateAutoComplete
                                                required
                                                label="Your State"
                                                value={this.state.objSupplier.info.shipState}
                                                frm={this.frm}
                                                onChange={(newValue: string) => { this.setInfo({ shipState: newValue }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <HyperTextField
                                                value={this.state.objSupplier.info.shipCity}
                                                onChange={(e: any) => { this.setInfo({ shipCity: e.target.value }) }}
                                                label={"City"}
                                                frm={this.frm}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <HyperTextField
                                                value={this.state.objSupplier.info.shipPincode}
                                                onChange={(e: any) => { this.setInfo({ shipPincode: e.target.value }) }}
                                                label={"Pincode"}
                                                frm={this.frm}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }


                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Button type="submit" color="primary" variant="contained">
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CommonModal>
            </div>
        );
    }
}

export default AddUpdateSupplier;