class HyperForm {
    lstFields = {};
    isSubmitted = false;
    validate(key, reactElement) {
        if (this.lstFields[key] === undefined) {
            this.lstFields[key] = reactElement;
        }
    }

    setIsFormSubmitted(isSubmitted) {
        this.isSubmitted = isSubmitted;
        for(let key in this.lstFields){
            this.lstFields[key].onFocus()
        }
    }
    
    removeValidation(key) {
        delete this.lstFields[key];
    }

    isValid = () => {
        return Object.keys(this.lstFields).every((key) => {
            return this.lstFields[key].isActuallyValid()
        });
    }

}

export default HyperForm;