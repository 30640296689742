import loadable from '@loadable/component';
// import Loading from './Loading';

export const LoginLoadable = loadable(() => import("../Pages/Login/Login"), {  });
// export const HapLoadable = loadable(() => import("../Pages/HapNew/Hap"), {  });
// export const NoteLoadable = loadable(() => import("../Pages/Notes/Note"), {  });
// export const NotificationLoadable = loadable(() => import("../Pages/Notification/Notification"), {  });
// export const LeadLoadable = loadable(() => import("../Pages/Lead/Lead"), {  });
// export const CommunicationLoadable = loadable(() => import("../Pages/Communication/Communication"), {  });
// export const MenuLoadable = loadable(() => import("../Pages/Menu/Menu"), {  });
// export const HapDetailLoadable = loadable(() => import("../Pages/HapDetails/HapDetail"), {  });
// export const AddUpdateNoteLoadable = loadable(() => import("../Pages/Notes/AddUpdateNote"), {  });
// export const SettingsLoadable = loadable(() => import("../Pages/Menu/Settings"), {  });
// export const ProfileLoadable = loadable(() => import("../Pages/Menu/Profile"), {  });
// export const ClientGroupLoadable = loadable(() => import("../Pages/Menu/ClientGroup/ClientGroup"), {  });
// export const TestApiLoadable = loadable(() => import("../Pages/ApiTest/TestApi"), {  });
// export const UpdateSelfAssignedClientLeadStatusLoadable = loadable(() => import("../Pages/Lead/UpdateSelfAssignedClientLeadStatus"), {  });
