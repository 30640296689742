import React, { FormEvent } from 'react'
import moment from 'moment';
import CommonModal from '../../Components/CommonModal';
import { Grid, Button } from '@material-ui/core';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import { IAccount, Journal } from '../../Types/AllTypes';
import HyperForm from '../../Components/HyperInput/HyperForm';
import api from '../../Services/ApiServices';
import utils from '../../Services/Utils';
import AccountAutoComplete from './AccountAutoComplete';
import { JournalInsertObject } from '../../Types/InsertObjects';
import HyperAmountField from '../../Components/HyperInput/HyperAmountField';
import HyperSwitchField from '../../Components/HyperInput/HyperSwitchField';
import { winState } from '../../Services/ScanRUtilService';

interface AddUpdateJournalProps {
    objJournal?: Journal
    isOpen: boolean
    onComplete?: () => void
    toggle: () => void
}

interface AddUpdateJournalState {
    objJournal: Journal
}


const initialJournal: Journal = {
    _id: "",
    debitAccountId: 0,
    creditAccountId: 0,
    amount: 0,
    comment: "",
    date: "",
    createdAt: "",
    username: "",
    id: 0,
    debitAccount: {} as IAccount,
    creditAccount: {} as IAccount,
} as Journal;


class AddUpdateJournal extends React.Component<AddUpdateJournalProps, AddUpdateJournalState> {
    constructor(props: AddUpdateJournalProps) {
        super(props);
        console.log(props)
        let state: AddUpdateJournalState = {
            objJournal: initialJournal
        };

        if (this.props.objJournal) {
            state.objJournal = this.props.objJournal
            this.isEditing = true;
        }
        if (!state.objJournal.date) {
            state.objJournal.date = moment().format("YYYY-MM-DD")
        }
        else {
            state.objJournal.date = moment(state.objJournal.date).format("YYYY-MM-DD")
        }
        // formate TransactionDate
        if (!state.objJournal.transactionDate) {
            state.objJournal.transactionDate = moment().format("YYYY-MM-DD")
        }
        else {
            state.objJournal.transactionDate = moment(state.objJournal.transactionDate).format("YYYY-MM-DD")
        }


        this.state = state;
        this.frm = new HyperForm();
    }
    frm: HyperForm
    isEditing: boolean = false;

    componentDidMount() {
        // document.addEventListener(enums.EVENT.Journal_ADD_UPDATE_MODEL, this.onModelToggle);
    }

    componentWillUnmount() {
        // document.removeEventListener(enums.EVENT.Journal_ADD_UPDATE_MODEL, this.onModelToggle);
    }

    onModelToggle = (event?: any) => {
        this.props.toggle()
    }

    setJournal(obj: Partial<Journal>) {
        this.setState({ objJournal: { ...this.state.objJournal, ...obj } });
    }

    onSubmit = async (e: FormEvent) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.frm.setIsFormSubmitted(true)
            if (this.frm.isValid()) {
                let result = null;
                let io = new JournalInsertObject();
                io.id = this.state.objJournal.id;
                io.debitAccountId = this.state.objJournal.debitAccountId;
                io.creditAccountId = this.state.objJournal.creditAccountId;
                io.amount = this.state.objJournal.amount;
                io.comment = this.state.objJournal.comment;
                io.date = this.state.objJournal.date;
                io.category = this.state.objJournal.category;
                io.transactionDate = this.state.objJournal.transactionDate;
                io.isDifferentTransactionDate = this.state.objJournal.isDifferentTransactionDate;
                utils.showLoader();
                if (this.isEditing) {
                    result = await api.updateJournal(io);
                }
                else {
                    result = await api.addJournal(io);
                }
                this.onModelToggle();
                if (this.props.onComplete) {
                    this.props.onComplete();
                }
                console.log(result)
            }
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }

    render() {
        const companySettings = winState.state.companySettings;

        return (
            <div>
                <CommonModal
                    dialogProps={{
                        open: this.props.isOpen
                    }}
                    title={"Journal"}
                    toggle={this.props.toggle}
                >
                    <form onSubmit={this.onSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <AccountAutoComplete
                                    accountId={this.state.objJournal.debitAccountId}
                                    onChange={(objAccount: IAccount) => {
                                        if (objAccount)
                                            this.setJournal({ debitAccountId: objAccount.id })
                                    }}
                                    label="Debit Account"
                                    frm={this.frm}
                                    required
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <AccountAutoComplete
                                    accountId={this.state.objJournal.creditAccountId}
                                    onChange={(objAccount: IAccount) => {
                                        if (objAccount)
                                            this.setJournal({ creditAccountId: objAccount.id })
                                    }}
                                    label="Credit Account"
                                    frm={this.frm}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <HyperAmountField
                                    required
                                    value={this.state.objJournal.amount}
                                    onChange={(e: any) => { this.setJournal({ amount: parseFloat(e.target.value) }) }}
                                    label={"Amount"}
                                    type="number"
                                    frm={this.frm}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <HyperTextField
                                    required
                                    value={this.state.objJournal.date}
                                    onChange={(e: any) => {
                                        this.setJournal({ date: (e.target.value) })
                                    }}
                                    label={"Date"}
                                    type="date"
                                    frm={this.frm}
                                />
                            </Grid>
                            {companySettings.hyperAdvanceMode &&
                                <React.Fragment>
                                    <Grid item xs={12} md={6}>
                                        <HyperSwitchField
                                            label={"Your transaction date is different?"}
                                            checked={this.state.objJournal.isDifferentTransactionDate}
                                            onChange={(val: boolean) => this.setJournal({ isDifferentTransactionDate: val })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {this.state.objJournal.isDifferentTransactionDate &&
                                            <HyperTextField
                                                value={this.state.objJournal.transactionDate}
                                                onChange={(e: any) => {
                                                    this.setJournal({ transactionDate: (e.target.value) })
                                                }}
                                                label={"Transaction Date"}
                                                type="date"
                                                frm={this.frm}
                                            />
                                        }
                                    </Grid>
                                </React.Fragment>
                            }
                            <Grid item xs={12}>
                                <br></br>
                                <HyperTextField
                                    required
                                    value={this.state.objJournal.comment}
                                    onChange={(e: any) => {
                                        this.setJournal({ comment: (e.target.value) })
                                    }}
                                    label={"Comment"}
                                    type="text"
                                    multiline
                                    frm={this.frm}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <br></br>
                                <Button type="submit" color="primary" variant="contained">
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CommonModal>
            </div>
        );
    }
}

export default AddUpdateJournal;