import React from 'react'
import { Container, withTheme, Theme, Grid, Paper, Button, Tabs, Tab } from '@material-ui/core'
import HyperModal from '../../../Components/HyperInput/HyperModal';
import api from '../../../Services/ApiServices';
import scanRUtils from '../../../Services/ScanRUtilService';
import { JournalSearchOptions } from '../../../Types/SearchOptions';
import enums from '../../../enums';
import HyperDateRangePicker from '../../../Components/HyperDateRangePicker';
import moment from "moment";
import B2B from './B2B';
import B2CL from './B2CL';
import B2CS from './B2CS';
import EXP from './EXP';
import EXEMP from './EXEMP';
import DOCS from './DOCS';
import HSN from './HSN';
import CDNR from './CDNR';
import CDNUR from './CDNUR';


interface GSTR1ReportProps {
    theme: Theme
}

interface GSTR1ReportState {
    lstSales: any
    objB2B: any
    objB2CL: any
    objB2CS: any
    objEXP: any
    objCDNR: any
    objCDNUR: any
    objDOCS: any
    objEXEMP: any
    objHSN: any
    isLoading: boolean
    dateRange: any
    selectedTab: number
}

class GSTR1Report extends React.Component<GSTR1ReportProps, GSTR1ReportState> {
    constructor(props: GSTR1ReportProps) {
        super(props);
        let state: GSTR1ReportState = {
            selectedTab: 0,
            objB2B: { lstSales: [], totalInvoiceValue: 0, totalTaxableValue: 0, totalInvoice: 0 },
            objB2CL: { lstSales: [], totalInvoiceValue: 0, totalTaxableValue: 0, totalInvoice: 0 },
            objB2CS: { lstSales: [], totalInvoiceValue: 0, totalTaxableValue: 0, totalInvoice: 0 },
            objEXP: { lstSales: [], totalInvoiceValue: 0, totalTaxableValue: 0, totalInvoice: 0 },
            objCDNR: { lstSales: [], totalInvoiceValue: 0, totalTaxableValue: 0, totalInvoice: 0 },
            objCDNUR: { lstSales: [], totalInvoiceValue: 0, totalTaxableValue: 0, totalInvoice: 0 },
            objDOCS: {
                totalNumber: 0, totalCancelled: 0,
                outwardSupply: { fromNo: 0, toNo: 0, totalNumber: 0, totalCancelled: 0, },
                creditNote: { fromNo: 0, toNo: 0, totalNumber: 0, totalCancelled: 0, },
                debitNote: { fromNo: 0, toNo: 0, totalNumber: 0, totalCancelled: 0 },
            },
            objEXEMP: {
                totalNilRatedSupplies: 0, totalExemptedSupplies: 0, totalNonGSTSupplies: 0, totalNilRatedSuppliesOfInterStateRP: 0, totalNilRatedSuppliesOfInterStateURP: 0, totalNilRatedSuppliesOfIntraStateRP: 0, totalNilRatedSuppliesOfIntraStateURP: 0,
                totalExemptedSuppliesOfInterStateRP: 0, totalExemptedSuppliesOfInterStateURP: 0, totalExemptedSuppliesOfIntraStateRP: 0, totalExemptedSuppliesOfIntraStateURP: 0,
                totalNonGSTSuppliesOfInterStateRP: 0, totalNonGSTSuppliesOfInterStateURP: 0, totalNonGSTSuppliesOfIntraStateRP: 0, totalNonGSTSuppliesOfIntraStateURP: 0,
            },
            objHSN: {},
            lstSales: [],
            isLoading: false,
            dateRange: { startDate: moment().startOf("month").toDate(), endDate: moment().endOf("month").toDate(), label: "This Month" }
        };
        this.state = state;
        this.modal = new HyperModal(this);
    }

    modal: HyperModal;

    componentDidMount() {
        this.getReportDetail()
    }

    getReportDetail = async () => {
        try {
            await this.setState({ isLoading: true })
            let so = new JournalSearchOptions();
            so.categories = [enums.JOURNAL_CATEGORY.SALES, enums.JOURNAL_CATEGORY.SALES_RETURN, enums.JOURNAL_CATEGORY.PURCHASE_RETURN];
            so.fromDate = moment(this.state.dateRange.startDate).format("YYYY-MM-DD")
            so.toDate = moment(this.state.dateRange.endDate).format("YYYY-MM-DD")
            let lstSales = await api.getGSTR1(so);
            if (lstSales.length > 0) {
                let objB2B: any = { lstSales: [], totalInvoiceValue: 0, totalTaxableValue: 0, totalInvoice: 0 };
                let objB2CL: any = { lstSales: [], totalInvoiceValue: 0, totalTaxableValue: 0, totalInvoice: 0 };
                let objB2CS: any = { lstSales: [], totalInvoiceValue: 0, totalTaxableValue: 0, totalInvoice: 0 };
                let objEXP: any = { lstSales: [], totalInvoiceValue: 0, totalTaxableValue: 0, totalInvoice: 0 };
                let objDOCS: any = {
                    totalNumber: 0, totalCancelled: 0,
                    outwardSupply: { fromNo: 0, toNo: 0, totalNumber: 0, totalCancelled: 0, },
                    creditNote: { fromNo: 0, toNo: 0, totalNumber: 0, totalCancelled: 0, },
                    debitNote: { fromNo: 0, toNo: 0, totalNumber: 0, totalCancelled: 0 },
                };
                let objCDNR: any = { lstSales: [], totalInvoiceValue: 0, totalTaxableValue: 0, totalInvoice: 0 };
                let objCDNUR: any = { lstSales: [], totalInvoiceValue: 0, totalTaxableValue: 0, totalInvoice: 0 };
                let objHSN: any = { lstHSNCode: {}, totalInvoiceValue: 0, totalTaxableValue: 0, totalIntegratedTax: 0, totalCentralTax: 0, totalStateTax: 0 };
                let objEXEMP: any = {
                    totalNilRatedSupplies: 0, totalExemptedSupplies: 0, totalNonGSTSupplies: 0, totalNilRatedSuppliesOfInterStateRP: 0, totalNilRatedSuppliesOfInterStateURP: 0, totalNilRatedSuppliesOfIntraStateRP: 0, totalNilRatedSuppliesOfIntraStateURP: 0,
                    totalExemptedSuppliesOfInterStateRP: 0, totalExemptedSuppliesOfInterStateURP: 0, totalExemptedSuppliesOfIntraStateRP: 0, totalExemptedSuppliesOfIntraStateURP: 0,
                    totalNonGSTSuppliesOfInterStateRP: 0, totalNonGSTSuppliesOfInterStateURP: 0, totalNonGSTSuppliesOfIntraStateRP: 0, totalNonGSTSuppliesOfIntraStateURP: 0,
                }
                let sn: any = [];
                let cn: any = [];
                let dn: any = [];

                lstSales.forEach((objSales: any) => {
                    if (objSales.category === enums.JOURNAL_CATEGORY.SALES) {
                        sn.push(objSales.info.INVNumber)
                        if (objSales.info.invoiceTaxType === enums.SALES_TAX_TYPE.EXPORT_DEEMED || objSales.info.invoiceTaxType === enums.SALES_TAX_TYPE.EXPORT_UNDER_BOND_LUT || objSales.info.invoiceTaxType === enums.SALES_TAX_TYPE.EXPORT_WITH_IGST) {
                            objEXP.totalInvoice++;
                            objEXP.totalTaxableValue += (objSales.info.totalAfterDiscount || 0);
                            objEXP.totalInvoiceValue += (objSales.amount);
                            objSales.info.items.forEach((salesItem: any) => {
                                if (salesItem.hsnCode)
                                    objHSN = this.updateHSNCodeItem(objHSN, salesItem, objSales)
                                if (salesItem.isExemptedItem)
                                    objEXEMP = this.updateExemptedItem(objEXEMP, salesItem, objSales)

                                objEXP.lstSales.push({ ...objSales, salesItem })
                            })
                        }
                        else if (objSales.debitAccount.info.GST) {
                            objB2B.totalInvoice++;
                            objB2B.totalTaxableValue += (objSales.info.totalAfterDiscount || 0);
                            objB2B.totalInvoiceValue += (objSales.amount);
                            objSales.info.items.forEach((salesItem: any) => {
                                if (salesItem.hsnCode)
                                    objHSN = this.updateHSNCodeItem(objHSN, salesItem, objSales)
                                if (salesItem.isExemptedItem)
                                    objEXEMP = this.updateExemptedItem(objEXEMP, salesItem, objSales)
                                objB2B.lstSales.push({ ...objSales, salesItem })
                            })
                        }
                        else {
                            if (objSales.amount > 249999 && !scanRUtils.isIntraState(objSales.debitAccount.info.state)) {
                                objB2CL.totalInvoice++;
                                objB2CL.totalTaxableValue += (objSales.info.totalAfterDiscount || 0);
                                objB2CL.totalInvoiceValue += (objSales.amount);
                                objSales.info.items.forEach((salesItem: any) => {
                                    if (salesItem.hsnCode)
                                        objHSN = this.updateHSNCodeItem(objHSN, salesItem, objSales)
                                    if (salesItem.isExemptedItem)
                                        objEXEMP = this.updateExemptedItem(objEXEMP, salesItem, objSales)
                                    objB2CL.lstSales.push({ ...objSales, salesItem })
                                })

                            }
                            else {
                                objB2CS.totalInvoice++;
                                objB2CS.totalTaxableValue += (objSales.info.totalAfterDiscount || 0);
                                objB2CS.totalInvoiceValue += (objSales.amount);
                                objSales.info.items.forEach((salesItem: any) => {
                                    if (salesItem.hsnCode)
                                        objHSN = this.updateHSNCodeItem(objHSN, salesItem, objSales)
                                    if (salesItem.isExemptedItem)
                                        objEXEMP = this.updateExemptedItem(objEXEMP, salesItem, objSales)
                                    objB2CS.lstSales.push({ ...objSales, salesItem })
                                })
                            }
                        }
                    }
                    else if (objSales.category === enums.JOURNAL_CATEGORY.SALES_RETURN) {
                        cn.push(objSales.info.CNNumber)
                        if (objSales.debitAccount.info.GST) {
                            objCDNR.totalInvoice++;
                            objCDNR.totalTaxableValue += (objSales.info.totalAfterDiscount || 0);
                            objCDNR.totalInvoiceValue += (objSales.amount);
                            objSales.info.items.forEach((salesItem: any) => {
                                if (salesItem.hsnCode)
                                    objHSN = this.updateHSNCodeItem(objHSN, salesItem, objSales)
                                if (salesItem.isExemptedItem)
                                    objEXEMP = this.updateExemptedItem(objEXEMP, salesItem, objSales)
                                objCDNR.lstSales.push({ ...objSales, salesItem })
                            })
                        }
                        else {
                            objCDNUR.totalInvoice++;
                            objCDNUR.totalTaxableValue += (objSales.info.totalAfterDiscount || 0);
                            objCDNUR.totalInvoiceValue += (objSales.amount);
                            objSales.info.items.forEach((salesItem: any) => {
                                if (salesItem.hsnCode)
                                    objHSN = this.updateHSNCodeItem(objHSN, salesItem, objSales)
                                if (salesItem.isExemptedItem)
                                    objEXEMP = this.updateExemptedItem(objEXEMP, salesItem, objSales)
                                objCDNUR.lstSales.push({ ...objSales, salesItem })
                            })
                        }
                    }
                    else if (objSales.category === enums.JOURNAL_CATEGORY.PURCHASE_RETURN) {
                        // console.log(objSales, "PURCHASE_RETURN")
                        dn.push(objSales.info.DNNumber)
                    }
                })

                // console.log(sn, "SN", dn, "DN", cn, "CN")

                objDOCS.outwardSupply = scanRUtils.invoiceNumberManager(sn)
                objDOCS.debitNote = scanRUtils.invoiceNumberManager(dn)
                objDOCS.creditNote = scanRUtils.invoiceNumberManager(cn)

                this.setState({ objDOCS, objEXEMP, lstSales, objCDNR, objCDNUR, objB2B, objB2CS, objB2CL, objEXP, objHSN })
            }
        }
        catch (ex) {
            console.log(ex)
        }
        finally {
            await this.setState({ isLoading: false })
        }
    }

    updateExemptedItem = (objEXEMP: any, salesItem: any, objSales: any) => {
        let isIntraState = scanRUtils.isIntraState(objSales.debitAccount.info.state)
        let isRegister = scanRUtils.isIntraState(objSales.debitAccount.info.GST) ? true : false;
        let isNilRate = !!(salesItem.taxes[0].key === enums.EXEMPTED_GST_ITEM_KEY.NIL_RATED)
        let isExempted = !!(salesItem.taxes[0].key === enums.EXEMPTED_GST_ITEM_KEY.EXEMPTED)
        let isNonGST = !!(salesItem.taxes[0].key === enums.EXEMPTED_GST_ITEM_KEY.NON_GST)

        if (isNilRate) {
            objEXEMP.totalNilRatedSupplies += scanRUtils.toFixedFloat(salesItem.amount);
            if (isIntraState) {
                if (isRegister)
                    objEXEMP.totalNilRatedSuppliesOfIntraStateRP += scanRUtils.toFixedFloat(salesItem.amount);
                else
                    objEXEMP.totalNilRatedSuppliesOfIntraStateURP += scanRUtils.toFixedFloat(salesItem.amount);
            }
            else {
                if (isRegister)
                    objEXEMP.totalNilRatedSuppliesOfInterStateRP += scanRUtils.toFixedFloat(salesItem.amount);
                else
                    objEXEMP.totalNilRatedSuppliesOfInterStateURP += scanRUtils.toFixedFloat(salesItem.amount);
            }
        }
        if (isExempted) {
            objEXEMP.totalExemptedSupplies += scanRUtils.toFixedFloat(salesItem.amount);
            if (isIntraState) {
                if (isRegister)
                    objEXEMP.totalExemptedSuppliesOfIntraStateRP += scanRUtils.toFixedFloat(salesItem.amount);
                else
                    objEXEMP.totalExemptedSuppliesOfIntraStateURP += scanRUtils.toFixedFloat(salesItem.amount);
            }
            else {
                if (isRegister)
                    objEXEMP.totalExemptedSuppliesOfInterStateRP += scanRUtils.toFixedFloat(salesItem.amount);
                else
                    objEXEMP.totalExemptedSuppliesOfInterStateURP += scanRUtils.toFixedFloat(salesItem.amount);
            }
        }
        if (isNonGST) {
            objEXEMP.totalNonGSTSupplies += scanRUtils.toFixedFloat(salesItem.amount);
            if (isIntraState) {
                if (isRegister)
                    objEXEMP.totalNonGSTSuppliesOfIntraStateRP += scanRUtils.toFixedFloat(salesItem.amount);
                else
                    objEXEMP.totalNonGSTSuppliesOfIntraStateURP += scanRUtils.toFixedFloat(salesItem.amount);
            }
            else {
                if (isRegister)
                    objEXEMP.totalNonGSTSuppliesOfInterStateRP += scanRUtils.toFixedFloat(salesItem.amount);
                else
                    objEXEMP.totalNonGSTSuppliesOfInterStateURP += scanRUtils.toFixedFloat(salesItem.amount);
            }
        }
        return objEXEMP;
    }

    updateHSNCodeItem = (objHSN: any, salesItem: any, objSales: any) => {
        let objHsnItem: any = objHSN.lstHSNCode[salesItem.hsnCode];
        if (!objHsnItem) {
            objHsnItem = {
                HSNCode: "",
                description: "",
                UQC: "",
                totalQty: 0,
                totalValue: 0,
                taxableValue: 0,
                integratedTaxAmount: 0,
                centralTaxAmount: 0,
                stateTaxAmount: 0
            }
        }
        objHsnItem.HSNCode = salesItem.hsnCode;
        objHsnItem.description = salesItem.name;
        objHsnItem.totalQty += salesItem.qty;
        objHsnItem.totalValue += salesItem.amount;
        objHsnItem.taxableValue += salesItem.price;
        if (scanRUtils.isIntraState(objSales.debitAccount.info.state)) {
            objHsnItem.centralTaxAmount += scanRUtils.toFixedFloat(salesItem.tax / 2);
            objHsnItem.stateTaxAmount += scanRUtils.toFixedFloat(salesItem.tax / 2);
            objHSN.totalCentralTax += scanRUtils.toFixedFloat(salesItem.tax / 2);
            objHSN.totalStateTax += scanRUtils.toFixedFloat(salesItem.tax / 2);
        }
        else {
            objHsnItem.integratedTaxAmount += salesItem.tax;
            objHSN.totalIntegratedTax += scanRUtils.toFixedFloat(salesItem.tax);
        }
        objHSN.lstHSNCode[salesItem.hsnCode] = objHsnItem;
        objHSN.totalInvoiceValue += scanRUtils.toFixedFloat(objHsnItem.totalValue);
        objHSN.totalTaxableValue += scanRUtils.toFixedFloat(objHsnItem.taxableValue);
        return objHSN;
    }

    onTabChange = (e: any, newValue: number) => {
        this.setState({ selectedTab: newValue })
    }
    downloadJson = () => {
        // console.log(this.state)
        let { dateRange, objHSN, lstSales } = this.state;
        let data = scanRUtils.prepareGST_R1_JSON(lstSales, objHSN, dateRange);
        scanRUtils.download("GST-R1.json", JSON.stringify(data))
    }
    render() {
        console.log(this.state.objB2B)
        return (
            <Container>
                <Grid container style={{ marginBottom: 20 }} spacing={2}>
                    <Grid item md={12} style={{ textAlign: 'right', margin: 'auto' }}>
                        <Button onClick={this.getReportDetail} variant="contained" color="primary">Refresh</Button>{" "}
                        {/* <Button onClick={this.downloadJson} variant="contained" color="primary">Download JSON</Button>{" "} */}
                        <HyperDateRangePicker
                            initialDateRange={this.state.dateRange}
                            onChange={async (range: any) => {
                                await this.setState({ dateRange: range });
                                this.getReportDetail()
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Paper square elevation={3}>
                            <Tabs
                                value={this.state.selectedTab}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={this.onTabChange}
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab style={{ minWidth: 0 }} label="B2B" />
                                <Tab style={{ minWidth: 0 }} label="B2CL" />
                                <Tab style={{ minWidth: 0 }} label="B2CS" />
                                <Tab style={{ minWidth: 0 }} label="CDNR" />
                                <Tab style={{ minWidth: 0 }} label="CDNUR" />
                                <Tab style={{ minWidth: 0 }} label="EXP" />
                                <Tab style={{ minWidth: 0 }} label="EXEMP" />
                                <Tab style={{ minWidth: 0 }} label="HSN" />
                                <Tab style={{ minWidth: 0 }} label="DOCS" />
                            </Tabs>
                        </Paper>
                    </Grid>

                    {(this.state.selectedTab === 0) &&
                        <B2B
                            objB2B={this.state.objB2B}
                        />
                    }

                    {(this.state.selectedTab === 1) &&
                        <B2CL
                            objB2CL={this.state.objB2CL}
                        />
                    }

                    {(this.state.selectedTab === 2) &&
                        <B2CS
                            objB2CS={this.state.objB2CS}
                        />
                    }
                    {(this.state.selectedTab === 3) &&
                        <CDNR
                            objCDNR={this.state.objCDNR}
                        />
                    }
                    {(this.state.selectedTab === 4) &&
                        <CDNUR
                            objCDNUR={this.state.objCDNUR}
                        />
                    }

                    {(this.state.selectedTab === 5) &&
                        <EXP
                            objEXP={this.state.objEXP}
                        />
                    }

                    {(this.state.selectedTab === 6) &&
                        <EXEMP objEXEMP={this.state.objEXEMP} />
                    }

                    {(this.state.selectedTab === 7) &&
                        <HSN objHSN={this.state.objHSN} />
                    }

                    {(this.state.selectedTab === 8) &&
                        <DOCS objDOCS={this.state.objDOCS} />
                    }

                </Grid>
            </Container>
        );
    }
}

export default withTheme(GSTR1Report);