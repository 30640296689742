import React from 'react'
import HyperSelectField from '../../Components/HyperInput/HyperSelectField';
import withWindowState from '../../Components/withWindowState';
import { IWindowState } from '../../Types/AllTypes';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import api from '../../Services/ApiServices';

interface InvoiceSelectProps extends IWindowState {
    value: string
    label: string
    disabled: boolean
    onChange: (value: string) => void
    isEdit?: boolean
}

interface InvoiceSelectState {
    lstInvoice: string[],
    selectedValue: string
}


class InvoiceSelect extends React.Component<InvoiceSelectProps, InvoiceSelectState> {
    constructor(props: InvoiceSelectProps) {
        super(props);
        let state: InvoiceSelectState = {
            lstInvoice: [],
            selectedValue: ""
        };
        if (this.props.value) {
            state.selectedValue = this.props.value;
        }
        this.state = state;
    }
    async componentDidMount() {
        if (!this.props.isEdit) {
            const lstInvoice = await api.getLstSalesInvoiceNumber();
            if (lstInvoice && lstInvoice.length > 0) {
                this.setState({ lstInvoice: lstInvoice, selectedValue: lstInvoice[0] })
                this.props.onChange(lstInvoice[0])
            }
        }
    }

    render() {
        if (!this.props.disabled) {
            return (
                <HyperSelectField
                    options={this.state.lstInvoice}
                    labelWidth={120}
                    selectedValue={this.state.selectedValue}
                    label={this.props.label}
                    fullWidth
                    disabled={this.props.disabled}
                    onChange={(event: any) => {
                        this.setState({ selectedValue: event.target.value });
                        this.props.onChange(event.target.value);
                    }}
                />
            )
        }
        else {
            return (
                <HyperTextField
                    value={this.props.value}
                    label={"Invoice Number"}
                    type="text"
                    disabled
                />
            )
        }

    }
}

export default withWindowState(InvoiceSelect, ["companySettings"]);