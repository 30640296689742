import React from 'react';
import ExceptionShowComponent from './ExceptionShowComponent';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, ex: null, errorInfo: null };
    }


    componentDidCatch(error, errorInfo) {
        this.setState({ hasError: true, ex: error, errorInfo });
        // You can also log the error to an error reporting service
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ExceptionShowComponent ex={this.state.ex} errorInfo={this.state.errorInfo} />
        }

        return this.props.children;
    }
}
export default ErrorBoundary;