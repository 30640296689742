import React from 'react'
import {  Typography, Grid } from '@material-ui/core';
import ImageUpload from '../../Components/ImageUpload';
import scanRUtils from '../../Services/ScanRUtilService';
import enums from '../../enums';


interface AddUpdateAttachmentProps {
    // documentType: string
    objInfo: any
    journalCategory: string

    onAttachmentDone: (key: string, url: string) => void
}

interface AddUpdateAttachmentState {
    lstAttachment: any
}

class AddUpdateAttachment extends React.Component<AddUpdateAttachmentProps, AddUpdateAttachmentState> {
    constructor(props: AddUpdateAttachmentProps) {
        super(props);
        let state: AddUpdateAttachmentState = {
            lstAttachment: []
        };
        let attachments = scanRUtils.getAttachmentList(this.props.journalCategory);
        if (attachments)
            state.lstAttachment = attachments;
        this.state = state;
    }

    render() {
        if (this.state.lstAttachment.length === 0)
            return null;
        return (
            <Grid container>
                {this.state.lstAttachment.map((objAttachment: any) => {
                    let imagePath = this.props.objInfo[objAttachment.key] || "";
                    return <Grid item xs={3} key={objAttachment.key} style={{ textAlign: 'center' }}>
                        <Typography variant={"subtitle2"} style={{ marginBottom: 5 }}>{objAttachment.label}</Typography>
                        <ImageUpload
                            documentType={enums.FILE_DOCUMENT_TYPE.SALES_PURCHASE}
                            fileName={this.props.journalCategory + "_" + objAttachment.key}
                            onImageSelect={(imageURL: string, extension: string) => {
                                // console.log(imageURL)
                                this.props.onAttachmentDone(objAttachment.key, imageURL)
                            }}
                            height={170}
                            width={170}
                            imagePath={imagePath}
                        />
                    </Grid>
                })}
            </Grid>
        );
    }
}
export default AddUpdateAttachment;