import React from 'react'
import CommonModal from '../../Components/CommonModal';
import QRCode from 'qrcode.react'
import auth from '../../Services/AuthService';
import { Typography } from '@material-ui/core';

interface DeviceIDQRCodeModelProps {
    isOpen: boolean
    toggle: () => void
}

interface DeviceIDQRCodeModelState {

}

class DeviceIDQRCodeModel extends React.Component<DeviceIDQRCodeModelProps, DeviceIDQRCodeModelState> {
    // constructor(props: DeviceIDQRCodeModelProps) {
    //     super(props);
    // }

    render() {
        return (

            <CommonModal
                dialogProps={{
                    open: this.props.isOpen
                }}
                title={"QR Code"}
                toggle={this.props.toggle}
            >
                <div style={{ textAlign: "center" }}>
                    <QRCode value={auth.getDeviceID()} size={256} />
                    <Typography style={{ marginTop: 15 }}>
                        Your Device ID: {auth.getDeviceID()}
                    </Typography>
                </div>
            </CommonModal>

        );
    }
}

export default DeviceIDQRCodeModel;