import React from 'react'
import enums from '../../enums';
import { IItem } from '../../Types/AllTypes';
import api from '../../Services/ApiServices';
import utils from '../../Services/Utils';
import HyperAutoCompleteField from '../../Components/HyperInput/HyperAutoCompleteField';
import HyperModal from '../../Components/HyperInput/HyperModal';
import AddUpdateItem from './AddUpdateItem';

interface ItemAutoCompleteProps {
    itemId?: number
    required?: boolean
    frm?: any
    label?: string
    autoFocus?: boolean
    placeholder?: string
    disabled?: boolean
    onFocus?: () => void
    onChange?: (e: IItem) => void
    onAddClick?: (e?: any) => void
}

interface ItemAutoCompleteState {
    lstItem: IItem[]
    objItem?: IItem
}


class ItemAutoComplete extends React.Component<ItemAutoCompleteProps, ItemAutoCompleteState> {
    constructor(props: ItemAutoCompleteProps) {
        super(props);
        let state: ItemAutoCompleteState = {
            lstItem: [],
            objItem: undefined
        };
        this.state = state;
    }

    isEditing: boolean = false;
    itemModal: HyperModal = new HyperModal(this);

    componentDidMount() {
        this.loadItem();
        document.addEventListener(enums.EVENT.UPDATE_ITEM_AUTO_COMPLETE_LIST, this.onAutoCompleteUpdate)
    }

    componentWillUnmount() {
        document.removeEventListener(enums.EVENT.UPDATE_ITEM_AUTO_COMPLETE_LIST, this.onAutoCompleteUpdate)
    }

    componentDidUpdate(props: ItemAutoCompleteProps) {
        if (props.itemId !== this.props.itemId) {
            let objItem = this.state.lstItem.find((item: IItem) => item.id === this.props.itemId);
            this.setState({ objItem });
        }
    }

    onAutoCompleteUpdate = async (e: any) => {
        await this.loadItem();
        if (e.detail && e.detail.sendResponse) {
            e.detail.sendResponse("done");
        }
    }

    async loadItem() {
        try {
            let lstItem = await api.getItemList_withWindowCache();
            lstItem.forEach((item: any) => {
                item.label = item.name;
            })
            let objItem = undefined;
            if (this.props.itemId) {
                objItem = lstItem.find((item: IItem) => item.id === this.props.itemId);
            }

            this.setState({ lstItem, objItem })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
    }

    render() {

        
        return (
            <React.Fragment>
                <HyperAutoCompleteField
                    options={this.state.lstItem}
                    value={this.state.objItem}
                    onChange={(e: any) => {
                        this.setState({ objItem: e })
                        if (this.props.onChange) {
                            this.props.onChange(e);
                        }
                    }}
                    onFocus={this.props.onFocus}
                    label={this.props.label}
                    required={this.props.required}
                    frm={this.props.frm}
                    autoFocus={this.props.autoFocus}
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled}
                    onAddClick={() => {
                        this.itemModal.toggle(true);
                    }}
                />
                {(this.itemModal.isOpen()) &&
                    <AddUpdateItem
                        isOpen={this.itemModal.isOpen()}
                        onComplete={async (v: any) => {
                            await this.loadItem();
                            let objItem = this.state.lstItem.find((item: IItem) => item.id === v.id);
                            this.setState({ objItem });
                            if (this.props.onChange) {
                                this.props.onChange(objItem as IItem);
                            }
                        }}
                        toggle={this.itemModal.toggle}
                    />
                }
            </React.Fragment>
        );
    }
}

export default ItemAutoComplete;