import utils from "../../Services/Utils";
import auth from "../../Services/AuthService";
import CompanySelectAutoComplete from "../../Pages/Reports/CompanySelectAutoComplete";
import React from 'react';

class HyperCompanySelection {
    constructor(that, defaultSelectedCompany = [{ id: auth.getCompanyId() }]) {
        this.that = that;
        this.selectedCompanies = utils.getUUID();
        if (!this.that.state)
            this.that.state = {};
        if (!auth.isSingleCompany())
            this.that.state[this.selectedCompanies] = defaultSelectedCompany;
            
    }

    getSelectedCompany = () => {
        if (this.that && this.that.state && this.that.state[this.selectedCompanies])
            return this.that.state[this.selectedCompanies];
        return [];
    }

    onChange = (selectedCompanies) => {
        this.that.state[this.selectedCompanies] = selectedCompanies;
    }
    getCompanyFilterObject = () => {
        let selectedCompanies = this.getSelectedCompany();
        return { companyIds: selectedCompanies.map(item => item.id).join(',') }
    }

    renderCompanySelection = () => {
        if (auth.isSingleCompany())
            return null;
        return (
            <CompanySelectAutoComplete
                selectedCompany={this.getSelectedCompany()}
                onChange={(selectedCompanies) => {
                    this.onChange(selectedCompanies)
                }}
            />
        )
    }
}

export default HyperCompanySelection;