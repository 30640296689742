import React from 'react'
import { withTheme, Theme, Grid, Paper} from '@material-ui/core'
import HyperModal from '../../../Components/HyperInput/HyperModal';
import scanRUtils from '../../../Services/ScanRUtilService';
import utils from '../../../Services/Utils';
import enums from '../../../enums';

interface CDNURProps {
    theme: Theme
    objCDNUR: any
}

interface CDNURState {
}

class CDNUR extends React.Component<CDNURProps, CDNURState> {
    constructor(props: CDNURProps) {
        super(props);
        let state: CDNURState = {
        };
        this.state = state;
        this.modal = new HyperModal(this);
    }

    modal: HyperModal;

    componentDidMount() {

    }
    render() {
        return (
            <Grid container style={{ marginBottom: 20, marginTop: 20 }} spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={3} style={{ paddingTop: 30, paddingBottom: 30, padding: 20 }}>
                        <div style={{ display: 'inline-block', paddingLeft: 25 }}>
                            <span>No. of invoice</span><br />
                            <b>{this.props.objCDNUR.totalInvoice}</b>
                        </div>
                        <div style={{ display: 'inline-block', paddingLeft: 25 }}>
                            <span>Total Invoice Value</span><br />
                            <b>{scanRUtils.prettyPrice(this.props.objCDNUR.totalInvoiceValue)}</b>
                        </div>
                        <div style={{ display: 'inline-block', paddingLeft: 25 }}>
                            <span>Total Taxable Value</span><br />
                            <b>{scanRUtils.prettyPrice(this.props.objCDNUR.totalTaxableValue)}</b>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={3} style={{ paddingTop: 30, paddingBottom: 30, padding: 20 }}>
                        <table {...{ border: 1 }} style={{ width: '100%' }} cellPadding={5} cellSpacing={0}>
                            <thead>
                                <tr>
                                    <th>UR Type</th>
                                    <th>Note Number</th>
                                    <th>Note Date</th>
                                    <th>Document type</th>
                                    <th>Invoice Number</th>
                                    <th>Invoice Date</th>
                                    <th>Place Of Supply</th>
                                    <th>Note Value</th>
                                    <th>Rate</th>
                                    <th>Taxable Value</th>
                                    <th>Pre GST</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.objCDNUR.lstSales.map((objSales: any, index: number) => {
                                    let isCN = objSales.info.CNNumber ? true : false;
                                    let isExp = objSales.info.invoiceTaxType === enums.SALES_TAX_TYPE.EXPORT_DEEMED || objSales.info.invoiceTaxType === enums.SALES_TAX_TYPE.EXPORT_UNDER_BOND_LUT || objSales.info.invoiceTaxType === enums.SALES_TAX_TYPE.EXPORT_WITH_IGST ? true : false;
                                    return (
                                        <tr key={index}>
                                            <td>{!isExp ? "B2CL" : `${scanRUtils.countTotalTaxPercentage(objSales.salesItem.taxes) === "0%" ? "WOPAY" : "WPAY"}`}</td>
                                            <td>{isCN ? `CN-${objSales.info.CNNumber}` : `DN-${objSales.info.DNNumber}`}</td>
                                            <td>{utils.convertToSystemDateTimeFormate(objSales.date)}</td>
                                            <td>{isCN ? "C" : "D"}</td>
                                            <td>{utils.getInvoiceNumberWithPrefix(objSales.info.INVNumber)}</td>
                                            <td>{utils.convertToSystemDateTimeFormate(objSales.info.invoiceDate)}</td>
                                            <td>{scanRUtils.getPlaceOfSupply(isCN ? objSales.debitAccount.info.GST : objSales.creditAccount.info.GST)}</td>
                                            <td>{scanRUtils.prettyPrice(objSales.amount)}</td>
                                            <td>{scanRUtils.countTotalTaxPercentage(objSales.salesItem.taxes)}</td>
                                            <td>{scanRUtils.prettyPrice(objSales.salesItem.totalTaxableAmount)}</td>
                                            <td>N</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Paper>
                </Grid>
            </Grid>

        );
    }
}

export default withTheme(CDNUR);