import * as moment from "moment";
import { v4 } from "uuid";
import config from "../config";
import swal from "sweetalert";
import enums from "../enums";
import lsu from "./LocalStorageUtils";
import qs from "querystring";
import auth from "./AuthService";

const xml_special_to_escaped_one_map = {
  "&": "&amp;",
  '"': "&quot;",
  "<": "&lt;",
  ">": "&gt;",
};

const escaped_one_to_xml_special_map = {
  "&amp;": "&",
  "&quot;": "",
  "&lt;": "<",
  "&gt;": ">",
};

export class UtilsService {
  // constructor() {
  //     // window.errorLog = window.errorLog || lsu.lsGet("errorLog") || [];
  // }
  dateInterval = {
    years: "y",
    quarters: "Q",
    months: "M",
    weeks: "w",
    days: "d",
    hours: "h",
    minutes: "m",
    seconds: "s",
    milliseconds: "ms",
  };
  encodeXml(string) {
    return string.replace(/([&"<>])/g, function (str, item) {
      return xml_special_to_escaped_one_map[item];
    });
  }

  decodeXml(string) {
    return string.replace(/(&quot;|&lt;|&gt;|&amp;)/g, function (str, item) {
      return escaped_one_to_xml_special_map[item];
    });
  }

  getScreenPlayArea() {
    const headerHeight = 48;
    const footerHeight = 49;
    return window.innerHeight - headerHeight - footerHeight - 12;
  }

  addDateTime(interval, unitValue, sourceDate = undefined) {
    sourceDate = moment(sourceDate);

    return sourceDate.add(unitValue, interval);
  }

  groupByObject(lstAry, property) {
    var objResult = {};
    lstAry.forEach((item) => {
      if (!objResult[item[property]]) objResult[item[property]] = [item];
      else objResult[item[property]].push(item);
    });

    objResult.toArray = function () {
      return Object.keys(objResult).map((strKey) => {
        return objResult[strKey];
      });
    };
    return objResult;
  }
  prepareErrorMessage = (err) => {
    if (typeof err === "string") {
      return err;
    }
    if (typeof err === "object") {
      if (typeof err.error === "string") return err.error;

      if (typeof (err.error && err.error[0]) === "string") return err.error[0];

      if (typeof err.message === "string") return err.message;

      if (typeof err.msg === "string") return err.msg;
    }
    return "Unexpected Error";
  };
  parseTextFromHTML(s) {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  showLoader() {
    var loader = document.getElementById("divLoader");
    if (loader) loader.style.display = "block";
  }

  hideLoader() {
    var loader = document.getElementById("divLoader");
    if (loader) loader.style.display = "none";
  }

  showAlert(msg, type) {
    try {
      if (typeof msg !== "string") {
        msg = "Error: " + JSON.stringify(msg);
        console.log("showAlert: ", msg, JSON.stringify(msg), type);
        // return swal("Unknown Error: Message must be string", "error")
      }

      if (enums.ALERT_TYPE.SUCCESS === type) swal("", msg, "success");
      if (enums.ALERT_TYPE.ERROR === type) swal("Oops!", msg, "error");
      if (enums.ALERT_TYPE.INFO === type) swal("", msg, "info");
      if (enums.ALERT_TYPE.WARNING === type) swal("Attention!", msg, "warning");
    } catch (ex) {
      console.log(ex, msg, type);
    }
  }
  showError(msg) {
    // this.showAlert(msg, enums.ALERT_TYPE.ERROR)
    this.snackbarError(msg);
  }

  showWarning(msg) {
    this.snackbarWarning(msg);
    //this.showAlert(msg, enums.ALERT_TYPE.WARNING)
  }

  showSuccess(msg) {
    this.snackbarSuccess(msg);
    // this.showAlert(msg, enums.ALERT_TYPE.SUCCESS)
  }

  showConfirm(title, description = "", icon = "warning", dangerMode = false) {
    return new Promise((resolve) => {
      let options = {};
      options = { title, icon, dangerMode, buttons: true };
      if (description) options.text = description;
      swal(options).then((willDelete) => {
        if (!willDelete) resolve(false);
        resolve(true);
      });
    });
  }

  tryJSONParse(string) {
    try {
      return JSON.parse(string);
    } catch (ex) {
      return null;
    }
  }

  isValidEmail(email) {
    if (typeof email !== "string") return false;
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  isValidMobile(mobile) {
    var pattern = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
    if (!pattern.test(mobile)) return false;
    return true;
  }

  randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  convertToCamelCase(str, splitBy = " ", replaceUnderscore = false) {
    if (replaceUnderscore) str = str.replace("_", " ");

    return str
      .split(splitBy)
      .map((word) => {
        return word.substr(0, 1).toUpperCase() + word.substr(1).toLowerCase();
      })
      .join(" ");
  }

  convertFirstCharCap(word) {
    return word.substr(0, 1).toUpperCase() + word.substr(1);
  }

  convertPriceToWord(price) {
    var sglDigit = [
        "Zero",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ],
      dblDigit = [
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ],
      tensPlace = [
        "",
        "Ten",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ],
      handle_tens = function (dgt, prevDgt) {
        return 0 === dgt
          ? ""
          : " " + (1 === dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
      },
      handle_utlc = function (dgt, nxtDgt, denom) {
        return (
          (0 !== dgt && 1 !== nxtDgt ? " " + sglDigit[dgt] : "") +
          (0 !== nxtDgt || dgt > 0 ? " " + denom : "")
        );
      };

    var str = "",
      digitIdx = 0,
      digit = 0,
      nxtDigit = 0,
      words = [];
    price += "";
    if (isNaN(parseInt(price))) str = "";
    else if (parseInt(price) > 0 && price.length <= 10) {
      for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
        switch (
          ((digit = price[digitIdx] - 0),
          (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0),
          price.length - digitIdx - 1)
        ) {
          case 0:
            words.push(handle_utlc(digit, nxtDigit, ""));
            break;
          case 1:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 2:
            words.push(
              0 !== digit
                ? " " +
                    sglDigit[digit] +
                    " Hundred" +
                    (0 !== price[digitIdx + 1] && 0 !== price[digitIdx + 2]
                      ? " and"
                      : "")
                : ""
            );
            break;
          case 3:
            words.push(handle_utlc(digit, nxtDigit, "Thousand"));
            break;
          case 4:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 5:
            words.push(handle_utlc(digit, nxtDigit, "Lakh"));
            break;
          case 6:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 7:
            words.push(handle_utlc(digit, nxtDigit, "Crore"));
            break;
          case 8:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 9:
            words.push(
              0 !== digit
                ? " " +
                    sglDigit[digit] +
                    " Hundred" +
                    (0 !== price[digitIdx + 1] || 0 !== price[digitIdx + 2]
                      ? " and"
                      : " Crore")
                : ""
            );
            break;
          default:
        }
      str = words.reverse().join("");
    } else str = "";
    return str;
  }

  getRandomColor(key) {
    if (key && key.substr) key = key.substr(0, 1).toUpperCase();
    const colors = [
      { key: "A", color: "#00008B" },
      { key: "B", color: "#008B8B" },
      { key: "C", color: "#B8860B" },
      { key: "D", color: "#7B68EE" },
      { key: "E", color: "#66CDAA" },
      { key: "F", color: "#006400" },
      { key: "G", color: "#BDB76B" },
      { key: "H", color: "#8B008B" },
      { key: "I", color: "#556B2F" },
      { key: "J", color: "#FF8C00" },
      { key: "K", color: "#9932CC" },
      { key: "L", color: "#8B0000" },
      { key: "M", color: "#E9967A" },
      { key: "N", color: "#8FBC8F" },
      { key: "O", color: "#483D8B" },
      { key: "P", color: "#2F4F4F" },
      { key: "Q", color: "#2F4F4F" },
      { key: "R", color: "#00CED1" },
      { key: "S", color: "#9400D3" },
      { key: "T", color: "#FF1493" },
      { key: "U", color: "#00BFFF" },
      { key: "V", color: "#696969" },
      { key: "W", color: "#696969" },
      { key: "X", color: "#1E90FF" },
      { key: "Y", color: "#ADFF2F" },
      { key: "Z", color: "#B22222" },
    ];
    let selectedColor = colors.find((item) => item.key === key);
    if (!selectedColor) return "#01579b";
    return selectedColor.color;
  }
  convertDateToAgo(date) {
    try {
      date = this.__glb_fnParseDate(date);
      var dateNow = new Date();
      var dateThen = date;
      var dateDiffInSec = (dateNow.getTime() - dateThen.getTime()) / 1000;
      var units = -1;
      var interval = "Second";
      if (dateDiffInSec < 6) {
        return " Just Now";
      } else if (dateDiffInSec < 60 && dateDiffInSec > 10) {
        units = Math.floor(dateDiffInSec);
        interval = "Second";
      } else if (dateDiffInSec > 60 && dateDiffInSec < 3600) {
        units = Math.floor(dateDiffInSec / 60);
        interval = "Minute";
      } else if (dateDiffInSec > 60 && dateDiffInSec < 3600 * 24) {
        units = Math.floor(dateDiffInSec / 60 / 60);
        interval = "Hour";
      } else if (dateDiffInSec > 3600 * 24 && dateDiffInSec < 3600 * 24 * 7) {
        units = Math.floor(dateDiffInSec / 60 / 60 / 24);
        interval = "Day";
      } else if (
        dateDiffInSec > 3600 * 24 * 7 &&
        dateDiffInSec < 3600 * 24 * 30
      ) {
        units = Math.floor(dateDiffInSec / 60 / 60 / 24 / 7);
        interval = "Week";
      } else if (
        dateDiffInSec > 3600 * 24 * 30 &&
        dateDiffInSec < 3600 * 24 * 30 * 12
      ) {
        units = Math.floor(dateDiffInSec / 60 / 60 / 24 / 30);
        interval = "Month";
      }
      if (units === 1) {
        return `${units} ${interval} Ago`;
      } else {
        return `${units} ${interval}s Ago`;
      }
      // return moment(date).format(config.C_SDF_INCL_HM);
    } catch (ex) {
      return date;
    }
  }

  setTimeout(timeout) {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  }

  RaiseError(ErrorMessage, ShouldAlert) {
    if (ShouldAlert) alert(ErrorMessage);
    throw new Error(ErrorMessage);
  }

  //MANIK ++18032020
  getFormdateTodateFromReletiveDateRangeCode = (rangeCode) => {
    let dateRange = {};
    if (rangeCode === enums.RELATIVE_DATE_RANGE_CODE.TODAY) {
      dateRange.fromDate = moment();
      dateRange.toDate = moment();
    } else if (rangeCode === enums.RELATIVE_DATE_RANGE_CODE.SEVEN_DAYS) {
      dateRange.fromDate = moment().add(-7, "days");
      dateRange.toDate = moment();
    } else if (rangeCode === enums.RELATIVE_DATE_RANGE_CODE.LAST_QUARTER) {
      dateRange.fromDate = moment().add(-3, "months");
      dateRange.toDate = moment();
    } else if (rangeCode === enums.RELATIVE_DATE_RANGE_CODE.LAST_MONTH) {
      dateRange.fromDate = moment().add(-1, "months");
      dateRange.toDate = moment();
    }
    return dateRange;
  };

  __glb_fnParseDate(argDate, DatePatternOptional) {
    if (argDate == null || argDate === "") return null;

    // Idea is to check the argument is object then no need of any parsing.
    if (typeof argDate == "object" && argDate instanceof Date)
      // It's already a JS Object (Of Date Type)
      return argDate; // No need to do any parsing. Return the original value.

    // Check weather it's JSON date or not.
    if (this.IsJsonDate(argDate))
      // Format: /Date(1320259705710)/
      return this.ParseJsonDate(argDate);

    if (typeof argDate != "string")
      // It's not string type.
      this.RaiseError(
        "'" + argDate.toString() + "' is not of string type.",
        false
      );

    // JHL++ 10012019
    if (this.IsISODate(argDate))
      // Format ISO Date: "2011-10-05T14:48:00.000Z"
      return new Date(argDate);

    if (!this.IsValidDate(argDate))
      // It's not valid date.
      this.RaiseError(
        "'" + argDate.toString() + "' is not valid date value.",
        false
      );

    var strDateSeparator = this.GetDateSperator(argDate);
    var aryDateParts = argDate.split(strDateSeparator);

    //	Find sequence inside DatePattern String
    if (DatePatternOptional == null)
      DatePatternOptional = config.C_SYSTEM_DATE_PATTERN;
    DatePatternOptional = DatePatternOptional.toLowerCase();

    var intIndexOfDay = DatePatternOptional.indexOf("d");
    var intIndexOfMonth = DatePatternOptional.indexOf("m");
    var intIndexOfYear = DatePatternOptional.indexOf("y");

    // Validation
    if (
      intIndexOfDay < 0 ||
      intIndexOfMonth < 0 ||
      intIndexOfYear < 0 ||
      intIndexOfDay > 2 ||
      intIndexOfMonth > 2 ||
      intIndexOfYear > 2
    ) {
      this.RaiseError(
        "Unsopported date pattern '" + DatePatternOptional + "' is supplied.",
        true
      );
    }

    var intDay = aryDateParts[intIndexOfDay];
    var intMonth = aryDateParts[intIndexOfMonth];
    var intYear = aryDateParts[intIndexOfYear];

    return new Date(intYear, intMonth - 1, intDay);
  }

  ParseBool(
    SourceValue // MRK-OK
  ) {
    if (arguments.length !== 1)
      // Only one argument needs to supply.
      this.RaiseError(
        "One argument needs to supply to check it's 'true' or not.",
        true
      );

    var strValue = SourceValue.toString().toLowerCase();
    if (strValue === true.toString() || strValue === "1") return true;

    if (strValue === false.toString() || strValue === "0") return false;

    this.RaiseError("Source value is not boolean.", true);
  }

  IsTypeOfNumberValue(
    NumberValue // MRK-OK
  ) {
    if (arguments.length !== 1)
      // Only one argument needs to supply.
      this.RaiseError(
        "One argument needs to supply to check it's type 'Number' or not.",
        true
      );

    return typeof NumberValue == "number";
  }

  IsTypeOfStringValue(
    StringValue // MRK-OK
  ) {
    if (arguments.length !== 1)
      // Only one argument needs to supply.
      this.RaiseError(
        "One argument needs to supply to check it's type 'string' or not.",
        true
      );

    return typeof StringValue == "string";
  }
  IsTypeOfBooleanValue(
    BooleanValue // MRK-OK
  ) {
    if (arguments.length !== 1)
      // Only one argument needs to supply.
      this.RaiseError(
        "One argument needs to supply to check it's type 'Boolean' or not.",
        true
      );

    return typeof BooleanValue == "boolean";
  }

  // KRD++ 29012013
  IsTypeOfDateValue(
    DateValue // MRK-OK
  ) {
    if (arguments.length !== 1)
      // Only one argument needs to supply.
      this.RaiseError(
        "One argument needs to supply to check it's type 'Date' or not.",
        true
      );

    return DateValue instanceof Date;
  }

  // KRD++ 29012013
  IsTypeOfObjectValue(ObjectValue) {
    if (arguments.length !== 1)
      // Only one argument needs to supply.
      this.RaiseError(
        "One argument needs to supply to check it's type 'Date' or not.",
        true
      );

    return typeof ObjectValue == "object";
  }

  IsJsonDate(argDateStr) {
    if (argDateStr == null || argDateStr === "") return false;

    // KRD++ 14022018
    if (typeof argDateStr != "string") return false;

    return argDateStr.indexOf("/Date(") === 0;
  }

  ParseJsonDate(argJsonDateStr) {
    if (!argJsonDateStr) return new Date(0);
    return new Date(parseInt(argJsonDateStr.substr(6), 10));
  }

  IsISODate(argISODateStr) {
    if (argISODateStr == null || argISODateStr === "") return false;

    if (typeof argISODateStr != "string") return false;

    return (
      (argISODateStr.length === 24 || argISODateStr.length === 27) &&
      (argISODateStr.endsWith("Z") || argISODateStr.endsWith("z"))
    );
  }

  IsValidDate() {
    if (arguments.length <= 0 || arguments.length > 3)
      // Expecting arguments count = 1 to 3.
      this.RaiseError(
        "Is valid date function needs argument count between 1 to 3.",
        true
      );

    if (arguments.length <= 0)
      // No argument supplied
      return false;

    // KRD++ 29012013
    if (this.IsNullOrEmpty(arguments[0])) return false;

    // KRD++ 27022013 [START]
    if (arguments[0] instanceof Date) return true;

    // KRD++ 04032013
    if (this.IsJsonDate(arguments[0]))
      // Format: /Date(1320259705710)/
      return true;

    // JHL++ 10012019
    if (this.IsISODate(arguments[0]))
      // Format ISO Date: "2011-10-05T14:48:00.000Z"
      return true;

    /* KRD-- 14022017, As Date.parse is having junk behavior.
        if (!isNaN(Date.parse(arguments[0]))) // It's valid date.
            return true;
        */
    // KRD++ 27022013 [END]

    var strDate = null;
    var strDatePattern = null;
    var strSeparator = null;
    var intDay, intMonth, intYear;

    var blnSplitDateArgument = true;
    if (arguments.length === 1)
      // Only one argument comes. Assume that it's in __glb_C_SYSTEM_DATE_PATTERN format with separator '/',
      strDate = arguments[0];
    else if (arguments.length === 2) {
      //	Exact two arguments come.	First --> Date, Second --> Date Pattern, Default Separator --> '/',
      strDate = arguments[0];
      strDatePattern = arguments[1].toLowerCase(); // It's IMP
    } else if (arguments.length === 3) {
      // Exact three arguments come.
      // All three arguments are integer. i.e. Date, Month and Year parts are supplied seperately.
      if (
        this.IsValidInteger(arguments[0]) &&
        this.IsValidInteger(arguments[1]) &&
        this.IsValidInteger(arguments[2])
      ) {
        intDay = arguments[0];
        intMonth = arguments[1];
        intYear = arguments[2];
        blnSplitDateArgument = false;
      } // First --> Date, Second --> Date Pattern And Third --> Separator.
      else {
        strDate = arguments[0];
        strDatePattern = arguments[1].toLowerCase(); // It's IMP
        strSeparator = arguments[2];
      }
    }

    if (blnSplitDateArgument) {
      if (strDatePattern == null) strDatePattern = config.C_SYSTEM_DATE_PATTERN;

      if (strSeparator == null) strSeparator = this.GetDateSperator(strDate);

      var aryDateParts = strDate.split(strSeparator); // Split Date string
      if (aryDateParts.length !== 3)
        // Three part not found.
        return false;

      var intIndexOfDay = strDatePattern.indexOf("d"); //	Find sequence inside Date String
      var intIndexOfMonth = strDatePattern.indexOf("m");
      var intIndexOfYear = strDatePattern.indexOf("y");

      if (
        intIndexOfDay < 0 ||
        intIndexOfMonth < 0 ||
        intIndexOfYear < 0 ||
        intIndexOfDay > 2 ||
        intIndexOfMonth > 2 ||
        intIndexOfYear > 2
      ) {
        // Validation
        return false;
      }

      intDay = aryDateParts[intIndexOfDay];
      intMonth = aryDateParts[intIndexOfMonth];
      intYear = aryDateParts[intIndexOfYear];

      if (!this.IsValidInteger(intDay)) return false;

      if (!this.IsValidInteger(intMonth)) {
        // It's not integer number.
        if (!this.IsValidMonthName(intMonth)) return false;

        // It's valid month name
        intMonth = this.GetMonthNumber(intMonth);
      }

      if (!this.IsValidInteger(intYear)) return false;
    }

    // KRD++ 29012013
    if (intYear < 100)
      // e.g. Y=13
      intYear = 2000 + intYear; // Y=2013

    var dtTempDate = new Date(intYear, intMonth - 1, intDay); // 2nd argument is month index.

    // Strange logic!!!, That is because of flexible behavior of Date class of Java Script.
    if (
      dtTempDate.getDate() !== parseInt(intDay, 10) ||
      dtTempDate.getMonth() + 1 !== parseInt(intMonth, 10) ||
      dtTempDate.getFullYear() !== parseInt(intYear, 10)
    ) {
      return false; // Invalid Date
    }

    return true; //	Valid Date
  }
  GetDateSperator(DateStr) {
    if (DateStr.indexOf("/") > 0) return "/";

    if (DateStr.indexOf("-") > 0) return "-";

    return config.C_SYSTEM_DATE_SEPARATOR;
  }

  IsNullOrEmpty(
    SourceValue // MRK-OK
  ) {
    if (arguments.length !== 1)
      // Only one argument needs to supply.
      this.RaiseError(
        "One argument needs to supply inside '__glb_Utils_fnIsNullOrEmpty' method.",
        true
      );

    if (SourceValue == null) return true;

    // It's not null (something) and it's not of type 'string'.
    if (typeof SourceValue != "string")
      // MRK++ 26022013, boolean false is considered as empty
      return false;

    // It's string.
    return SourceValue === "";
  }

  IsValidInteger(SourceValue) {
    if (arguments.length !== 1)
      // Only one argument needs to supply.
      throw new Error(
        "One argument needs to supply to check it's valid integer or not."
      );

    //if (__glb_Utils_fnIsNullOrEmpty(SourceValue)) //Zero is considered as empty value.
    //  return false;

    // KRD 21012017, Bug corrected.
    if (SourceValue == null || SourceValue.toString().trim() === "")
      return false;

    if (isNaN(SourceValue)) return false;

    return parseInt(Number(SourceValue), 10) === SourceValue;
  }

  IsValidDouble(
    SourceValue // MRK-OK
  ) {
    return this.IsValidFloat(SourceValue);
  }

  IsValidFloat(
    SourceValue // MRK-OK
  ) {
    if (arguments.length !== 1)
      // Only one argument needs to supply.
      this.RaiseError(
        "One argument needs to supply to check it's valid float.double or not.",
        true
      );

    //if (__glb_Utils_fnIsNullOrEmpty(SourceValue)) //MRK-- 11022013 zero is considered as empty value.
    //  return false;

    // KRD++ 21012017, Bug corrected.
    if (SourceValue == null || SourceValue.toString().trim() === "")
      return false;

    if (isNaN(SourceValue)) return false;

    return parseFloat(Number(SourceValue)) === SourceValue;
  }

  IsValidBoolean(
    SourceValue // MRK-OK
  ) {
    if (arguments.length !== 1)
      // Only one argument needs to supply.
      this.RaiseError(
        "One argument needs to supply to check it's valid boolean or not.",
        true
      );

    if (this.IsNullOrEmpty(SourceValue)) return false;

    var strValue = SourceValue.toString().toLowerCase();
    if (
      strValue === true.toString() ||
      strValue === "1" ||
      strValue === false.toString() ||
      strValue === "0"
    )
      return true;

    return false;
  }

  IsValidEmailID(SourceValue) {
    if (arguments.length !== 1)
      // Only one argument needs to supply.
      this.RaiseError(
        "One argument needs to supply to check it's valid Email-ID or not.",
        true
      );

    var indexOfAtTheRate = SourceValue.indexOf("@");
    var indexOfDot = SourceValue.lastIndexOf(".");
    var indexOfSpace = SourceValue.indexOf(" ");

    if (
      indexOfAtTheRate <= 0 ||
      indexOfDot < 0 ||
      indexOfSpace >= 0 ||
      indexOfDot === SourceValue.length - 1 ||
      indexOfAtTheRate >= indexOfDot ||
      indexOfDot - indexOfAtTheRate === 1
    ) {
      return false;
    }

    return true;
  }

  // HMD++ 27112020
  IsNumber(value) {
    if (typeof value == "number") return true;

    if (typeof value == "string") return /^-?\d+$/.test(value);
    return false;
  }

  // KKR++ 20122019
  IsValidMobileNumber(MobileNumber) {
    if (MobileNumber == null) return false;

    if (MobileNumber.trim() === "") return false;

    if (/^\d{10}$/.test(MobileNumber)) return true;

    return false;
  }

  IsValidMonthName(MonthName) {
    var intMonthNumber = this.GetMonthNumber(MonthName);
    return intMonthNumber >= 1 && intMonthNumber <= 12;
  }

  // KRD++ 18112017
  GetMonthNumber(MonthName) {
    if (typeof MonthName != "string") return 0;

    if (MonthName.length > 3) MonthName = MonthName.substr(0, 3);
    MonthName = MonthName.toLowerCase();

    switch (MonthName) {
      case "jan":
        return 1;
      case "feb":
        return 2;
      case "mar":
        return 3;
      case "apr":
        return 4;
      case "may":
        return 5;
      case "jun":
        return 6;
      case "jul":
        return 7;
      case "aug":
        return 8;
      case "sep":
        return 9;
      case "oct":
        return 10;
      case "nov":
        return 11;
      case "dec":
        return 12;

      default:
        return 0;
    }
  }

  N2ZLS(
    SourceValue // MRK-OK
  ) {
    if (arguments.length !== 1)
      // Only one argument needs to supply.
      this.RaiseError(
        "One argument needs to supply inside 'this.N2ZLS' method.",
        true
      );

    return this.N2DefStr(SourceValue, "");
  }

  // KRD++ 10122012
  N2Z(SourceValue) {
    if (arguments.length !== 1)
      // Only one argument needs to supply.
      this.RaiseError(
        "One argument needs to supply inside 'this.N2Z' method.",
        true
      );

    return this.N2DefInt(SourceValue, 0);
  }

  N2ZD(SourceValue) {
    return this.N2Double(SourceValue);
  }

  // MRK++ 23012013
  N2Double(SourceValue) {
    if (arguments.length !== 1)
      // Only one argument needs to supply.
      this.RaiseError(
        "One argument needs to supply inside 'this.N2Double' method.",
        true
      );

    return this.N2DefDbl(SourceValue, 0);
  }

  // MRK++ 22012013
  N2False(SourceValue) {
    if (arguments.length !== 1)
      // Only one argument needs to supply.
      this.RaiseError(
        "One argument needs to supply inside 'this.N2False' method.",
        true
      );

    return this.N2DefBoolean(SourceValue, false);
  }
  // --- Utils.fnN2*() Functions [END] ----

  // --- Utils.fnN2Def*() Functions [START] ----
  // KRD-OK2
  N2DefStr(
    SourceValue,
    RetrunDefaultValueIfNull // RetrunDefaultValueIfNull should be 'String' type or null.
  ) {
    if (arguments.length !== 2)
      // Only two arguments need to supply.
      this.RaiseError(
        "Two arguments need to supply inside 'this.N2DefStr' method.",
        true
      );

    if (
      RetrunDefaultValueIfNull != null &&
      !this.IsTypeOfStringValue(RetrunDefaultValueIfNull)
    )
      alert("'RetrunDefaultValueIfNull' shoud be of type 'string' or null.");

    if (SourceValue == null) {
      if (RetrunDefaultValueIfNull == null) return null;

      return RetrunDefaultValueIfNull.toString();
    }

    return SourceValue.toString();
  }

  // KRD-OK2
  // MRK++ 22012013
  N2DefInt(
    SourceValue,
    RetrunDefaultValueIfNull // RetrunDefaultValueIfNull should be 'Number' type + Integer.
  ) {
    if (arguments.length !== 2)
      // Only two arguments need to supply.
      this.RaiseError(
        "Two arguments need to supply inside 'this.N2DefInt' method.",
        true
      );

    if (!this.IsTypeOfNumberValue(RetrunDefaultValueIfNull))
      alert("'RetrunDefaultValueIfNull' shoud be of type 'integer'.#1");

    if (!this.IsValidInteger(RetrunDefaultValueIfNull))
      alert("'RetrunDefaultValueIfNull' shoud be of type 'integer'.#2");

    if (!this.IsValidInteger(SourceValue) && !this.IsValidDouble(SourceValue))
      // KRD 26012017
      return parseInt(RetrunDefaultValueIfNull, 10);

    // KRD++ 16022017
    if (isNaN(SourceValue) || !isFinite(SourceValue))
      return parseInt(RetrunDefaultValueIfNull, 10);

    return parseInt(SourceValue, 10);
  }

  // KRD-OK2
  N2DefDbl(
    SourceValue,
    RetrunDefaultValueIfNull // RetrunDefaultValueIfNull should be 'Number' type + Double.
  ) {
    if (arguments.length !== 2)
      // Only two arguments need to supply.
      this.RaiseError(
        "Two arguments need to supply inside 'this.N2DefDbl' method.",
        true
      );

    if (!this.IsTypeOfNumberValue(RetrunDefaultValueIfNull))
      alert("'RetrunDefaultValueIfNull' shoud be of type 'double'.");

    if (!this.IsValidDouble(RetrunDefaultValueIfNull))
      alert("'RetrunDefaultValueIfNull' shoud be of type 'double'.");

    if (!this.IsValidDouble(SourceValue))
      return parseFloat(RetrunDefaultValueIfNull);

    // KRD++ 16022017
    if (isNaN(SourceValue) || !isFinite(SourceValue))
      return parseInt(RetrunDefaultValueIfNull, 10);

    return parseFloat(SourceValue);
  }

  // KRD-OK2
  // KRD++ 29012013
  N2DefDate(
    SourceValue,
    RetrunDefaultValueIfNull // RetrunDefaultValueIfNull should be 'Date' type or null.
  ) {
    if (arguments.length !== 2)
      // Only two arguments need to supply.
      this.RaiseError(
        "Two arguments need to supply inside 'this.N2DefDate' method.",
        true
      );

    if (
      RetrunDefaultValueIfNull != null &&
      !this.IsTypeOfDateValue(RetrunDefaultValueIfNull)
    )
      alert("'RetrunDefaultValueIfNull' shoud be of type 'Date' or null.");

    // KRD++ 04032013
    if (SourceValue == null) return RetrunDefaultValueIfNull;

    // KRD++ 18012017
    try {
      return this.ParseDate(SourceValue, config.C_SYSTEM_DATE_PATTERN);
    } catch (ex) {
      return RetrunDefaultValueIfNull;
    }

    /* KRD-- 18112017
        // KRD: Idea is to check the argument is date object then no need of any parsing.
        if (typeof SourceValue == 'object' && SourceValue instanceof Date) // It's already a JS Object (Of Date Type)
            return SourceValue; // No need to do any parsing. Return the original value.
     
        if (SourceValue.indexOf('/Date(') == 0) //Format: /Date(1320259705710)/
            return new Date(parseInt(SourceValue.substr(6), 10));
     
        if (!this.IsValidDate(SourceValue)) // It's not Date.
            return RetrunDefaultValueIfNull;
     
        // It's sure that value is date.
        // ?? If it's MDY format date then following is fine but if it's other date format then we need to make further proccessing. ??
        return new Date(Date.parse(SourceValue)); // Note: Date.parse recognize value is in date pattern or not but not necessary that it's valid date. 
        // i.e '29-Feb-2011' or '31-Apr-2012' are not valid date though it's parsed by above function.
        // But we have made sure that it's valid date using 'IsValidDate' function before parsing.
        // KRD++ 27022013, Date.parse returns the numbervalue but we need to return the date, so used new Date() function.
        */
  }

  // KRD-OK2
  N2DefBoolean(
    SourceValue,
    RetrunDefaultValueIfNull // RetrunDefaultValueIfNull should be 'Boolean' type.
  ) {
    if (arguments.length !== 2)
      // Only two arguments need to supply.
      this.RaiseError(
        "Two arguments need to supply inside 'this.N2DefBoolean' method.",
        true
      );

    //KRD--29012013 if (!this.IsValidBoolean(RetrunDefaultValueIfNull))
    if (!this.IsTypeOfBooleanValue(RetrunDefaultValueIfNull))
      alert("'RetrunDefaultValueIfNull' shoud be of type 'boolean'.");

    if (!this.IsValidBoolean(SourceValue))
      // It's not boolean.
      return RetrunDefaultValueIfNull;

    // It's sure that value is boolean.
    return this.ParseBool(SourceValue);
  }

  fireEvent(eventName, data, sendResponse) {
    let event = new CustomEvent(eventName, { detail: { data, sendResponse } });
    document.dispatchEvent(event);
    return event;
  }

  insertErrorLogInLS(ex, extraObject = {}) {
    try {
      let obj = {
        message: ex.message,
        time: new Date(),
        stackTrace: ex.stack,
        ...extraObject,
      };

      window.errorLog = window.errorLog || lsu.lsGet("errorLog") || [];
      window.errorLog.push(obj);
      lsu.lsSet("errorLog", window.errorLog);
    } catch (ex) {
      console.log("Error occured while logging error log in Local Storage", ex);
    }
  }
  convertToSystemDateFormate = (date) => {
    if (!date) return "";
    let parseDate = date;
    // if (typeof date === "string")
    //     parseDate = utils.ParseJsonDate(date);
    // console.log(parseDate)
    if (!parseDate || parseDate === "Invalid Date") return "";
    return moment(parseDate).format("DD/MM/YYYY");
  };

  convertToSystemDateTimeFormate = (date) => {
    if (!date) return "";
    let parseDate = date;
    // console.log(parseDate, "")
    // if (typeof date === "string")
    //     parseDate = utils.ParseJsonDate(date);
    // console.log(parseDate, "parseDate")
    if (!parseDate || parseDate === "Invalid Date") return "";
    return moment(parseDate).format("DD/MM/YYYY hh:mm a");
  };

  convertToAPIDateFormat(date) {
    if (!date) return "";
    return moment(date).format("DD/MM/YYYY");
  }

  convertToJSONDate(date) {
    if (!(date instanceof Date)) date = new Date(date);
    if (!date.getTime()) return "";
    return "/Date(" + date.getTime() + ")/";
  }

  isStringValidJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (ex) {
      return false;
    }
  }

  isDesktop() {
    return document.body.clientWidth > 600;
  }

  isBitActive(BWs, BW) {
    return (BWs & BW) === BW;
  }
  fnScanRBridge(
    HapTypeInternalCode,
    ContextSystemObjectIDsOrCodes,
    HapTypeContextMenuInternalCode,
    RedirectActionCode,
    StatJSON
  ) {
    window.fnScanRBridge(
      HapTypeInternalCode,
      ContextSystemObjectIDsOrCodes,
      HapTypeContextMenuInternalCode,
      RedirectActionCode,
      StatJSON
    );
  }

  getUUID() {
    return v4();
  }

  locationAssign(str = "") {
    const pkg = require("../../package.json");
    if (str === "/") str = "";
    window.location.assign(`${pkg.homepage}${str}` || "/");
  }

  toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  getFileText = (file, readAs = "text") => {
    // "text"|"arrayBuffer"
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (readAs === "arrayBuffer") reader.readAsArrayBuffer(file);
      else reader.readAsText(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  askForFile = () => {
    return new Promise((resolve, reject) => {
      let input = document.createElement("input");
      input.type = "file";
      input.onchange = () => {
        input.remove();
        resolve(input.files);
      };
      input.onerror = (err) => {
        input.remove();
        reject(err);
      };
      document.body.appendChild(input);
      input.click();
    });
  };

  // It clears service worker cache. and return promise.
  async clearServiceWorkerCache() {
    let keys = await caches.keys();
    return Promise.all(
      keys.map(function (key) {
        return caches.delete(key);
      })
    );
  }

  async getAppVersion() {
    return await (await fetch("/version.txt?" + Date.now())).text();
  }

  parseQueryString(str) {
    return qs.parse(str.toString().replace("?", ""));
  }

  encodeQueryObject(searchObject) {
    try {
      return btoa(JSON.stringify(searchObject));
    } catch (ex) {
      return "";
    }
  }

  decodeQueryObject(strSearch) {
    try {
      let query = this.parseQueryString(strSearch);
      return JSON.parse(atob(query.q));
    } catch (ex) {
      return null;
    }
  }

  sendWhatsappMessage(mobile, message) {
    return new Promise((resolve, reject) => {
      try {
        let btn = document.getElementById("waHelperBtn");
        if (!btn) return false;
        btn.setAttribute("data-mobile", mobile);
        btn.setAttribute("data-message", message);
        window.onWaHelperMessageSendComplete = () => {
          if (btn.getAttribute("error"))
            return reject(new Error(btn.getAttribute("error")));
          resolve(true);
        };
        btn.click();
      } catch (ex) {
        reject(ex);
      }
    });
  }
  isWAExtensionInstall() {
    let waBtn = document.getElementById("waHelperBtn");
    if (!waBtn) return false;
    return true;
  }

  isExtensionConnected() {
    let waBtn = document.querySelector("#waHelperBtn");
    if (!waBtn) return false;
    if (waBtn.dataset.running) {
      if (waBtn.dataset.running === "1") return true;
      else return false;
    }
    return false;
  }

  downloadScanner = () => {
    return window.open(config.SCANNER_APP_URL);
  };
  downloadExtension = () => {
    return window.open(config.EXTENSION_ZIP_URL);
  };

  getDisplayableAccountCategories() {
    let restrictedCat = [
      enums.ACCOUNT_CATEGORY.CUSTOMER,
      enums.ACCOUNT_CATEGORY.SUPPLIER,
      enums.ACCOUNT_CATEGORY.CASH_BANK,
      enums.ACCOUNT_CATEGORY.SALES,
      enums.ACCOUNT_CATEGORY.PURCHASE,
      enums.ACCOUNT_CATEGORY.SALES_RETURN,
      enums.ACCOUNT_CATEGORY.PURCHASE_RETURN,
    ];
    let filterAccountCategory = Object.keys(enums.ACCOUNT_CATEGORY).filter(
      (catKey) => {
        if (!restrictedCat.includes(enums.ACCOUNT_CATEGORY[catKey]))
          return catKey;
        return false;
      }
    );
    return filterAccountCategory;
  }

  snackbar(message, variant) {
    this.fireEvent(enums.EVENT.ENQUEUE_SNACKBAR, {
      message: message,
      options: {
        variant: variant,
      },
    });
  }

  snackbarSuccess(message) {
    this.snackbar(message, "success");
  }

  snackbarError(message) {
    this.snackbar(message, "error");
  }

  snackbarWarning(message) {
    this.snackbar(message, "warning");
  }
  snackbarInfo(message) {
    this.snackbar(message, "info");
  }

  sendWAMessage(message, number) {
    if (number.length === 10) number = "91" + number;
    // console.log(number, message)
    window.open(
      `https://web.whatsapp.com/send?phone=${number}&text=${message}`
    );
  }

  getInvoiceNumberWithPrefix(invoiceNumber, prefix) {
    if (invoiceNumber.split("-").length > 1) {
      return invoiceNumber;
    }
    if (!prefix) prefix = auth.getCompanySettings().invoicePrefix;
    return prefix + "-" + invoiceNumber;
  }
}
const utils = new UtilsService();
window.utils = utils;
export default utils;
