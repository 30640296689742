import React, { FormEvent } from 'react'
import CommonModal from '../../Components/CommonModal';
import { Grid, Button } from '@material-ui/core';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import HyperForm from '../../Components/HyperInput/HyperForm';
import api from '../../Services/ApiServices';
import utils from '../../Services/Utils';


interface ChangePasswordProps {

    modalTitle?: string
    isOpen: boolean

    onComplete?: (result: any) => void
    toggle: () => void

}

interface ChangePasswordState {
    oldPassword: string,
    newPassword: string
    confirmPassword: string


}

class ChangePassword extends React.Component<ChangePasswordProps, ChangePasswordState> {
    constructor(props: ChangePasswordProps) {
        super(props);
        let state: ChangePasswordState = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        };
        this.state = state;
        this.frm = new HyperForm();
    }
    frm: HyperForm



    onSubmit = async (e: FormEvent) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.frm.setIsFormSubmitted(true)

            if (this.frm.isValid()) {
                let result = null;
                if (this.state.newPassword !== this.state.confirmPassword)
                    return utils.showError("Both Password Must be Same!")

                utils.showLoader();
                result = await api.changePassword(this.state.oldPassword, this.state.confirmPassword);
                utils.showSuccess("Your Password Changed Successfully.")
                this.props.toggle();
                if (this.props.onComplete) {
                    this.props.onComplete(result);
                }
                console.log(result)
            }
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }

    render() {
        return (
            <div>
                <CommonModal
                    dialogProps={{
                        open: this.props.isOpen
                    }}
                    title={this.props.modalTitle || "Change Password"}
                    toggle={this.props.toggle}
                >
                    <form onSubmit={this.onSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <HyperTextField
                                    required
                                    type="password"
                                    value={this.state.oldPassword}
                                    onChange={(e: any) => { this.setState({ oldPassword: e.target.value }) }}
                                    label={"Old Password"}
                                    frm={this.frm}
                                />

                            </Grid>
                            <Grid item xs={12}>
                                <HyperTextField
                                    required
                                    type="password"
                                    value={this.state.newPassword}
                                    onChange={(e: any) => { this.setState({ newPassword: e.target.value }) }}
                                    label={"New Password"}
                                    frm={this.frm}
                                />

                            </Grid>
                            <Grid item xs={12}>
                                <HyperTextField
                                    required
                                    type="password"
                                    value={this.state.confirmPassword}
                                    onChange={(e: any) => { this.setState({ confirmPassword: e.target.value }) }}
                                    label={"Confirm Password"}
                                    frm={this.frm}
                                />

                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <br />

                                <Button type="submit" color="primary" variant="contained">
                                    Change Password
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CommonModal>
            </div>
        );
    }
}

export default ChangePassword;