import {  Paper, Typography, Grid, IconButton } from '@material-ui/core';
import React from 'react'
import { HyperTheme } from '../../Theme/Theme';
import scanRUtils from '../../Services/ScanRUtilService';

interface ItemCardProps {
    backgroundColor: string
    title: string
    gradientColor: string
    openBalance?: number
    overDueBalance: number
    subTitle: string
    iconComponent: any
}

interface ItemCardState { }
const theme = HyperTheme;
class ItemCard extends React.Component<ItemCardProps, ItemCardState> {
    render() {
        
        const { title, backgroundColor, gradientColor,  overDueBalance, subTitle, iconComponent } = this.props;
        return (
            <Paper
                elevation={3}
                style={{
                    padding: 20,
                    paddingTop: 20,
                    paddingBottom: 20,
                    backgroundImage: `linear-gradient(87deg,${backgroundColor} 0,${gradientColor} 100%)`,
                    color: theme.palette.common.white,
                    position: 'relative'
                }}
            >
                <Grid container >
                    <Grid item xs={8} >
                        <Typography variant="button" style={{fontWeight:600}}>{title}</Typography>
                        <Typography style={{ fontWeight: 'bold', fontSize: 20,marginTop:3 }}>{scanRUtils.prettyPrice(overDueBalance)}</Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'end', margin: 'auto' }}>
                        <IconButton style={{ backgroundColor: theme.palette.common.white }}>
                            {iconComponent}
                        </IconButton>
                        {/* <IconButton style={{ position: "absolute", top: -8, right: -11 }}>
                            <IconMoreVert style={{ color: theme.palette.common.white }} />
                        </IconButton> */}
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                        <Typography variant="subtitle2">{subTitle}</Typography>
                    </Grid>
                    {/* <Grid item xs={8} style={{ textAlign: 'end', marginTop: 10 }}>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                            {openBalance ? scanRUtils.prettyPrice(openBalance) : ""} / {overDueBalance ? scanRUtils.prettyPrice(overDueBalance) : ""}
                        </Typography>
                    </Grid> */}
                </Grid>
            </Paper>
        );
    }
}

export default ItemCard;