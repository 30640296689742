import React from 'react'
import BaseInput from './BaseInput'
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import { InputAdornment, Tooltip } from '@material-ui/core';
import { IconInfo } from '../Icons/Icons';

export default class HyperAmountField extends BaseInput {
    render() {
        let InputProps = {};
        if (this.props.infoText) {
            InputProps.endAdornment = (
                <Tooltip title={this.props.infoText} >
                    <InputAdornment position="end" style={{ cursor: "pointer" }}>
                        <IconInfo />
                    </InputAdornment>
                </Tooltip>
            )
        }
        return (
            <TextField
                variant={this.props.variant || "outlined"}
                error={!this.isValid()}
                helperText={this.getHelperText()}
                onFocus={this.onFocus}
                value={this.props.value}
                label={this.props.label}
                onChange={this.onChange}
                multiline={this.props.multiline}
                fullWidth
                disabled={this.props.disabled}
                size="small"
                InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: { prefix: this.props.prefix },
                    ...InputProps
                }}
            />
        )
    }
}

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix={props.prefix ? props.prefix : "₹ "}
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    // name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
