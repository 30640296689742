import moment from "moment";
import utils from "../../Services/Utils";
import { IImportLine } from "../../Types/AllTypes";
import Papa from "papaparse";
import * as xlsx from "xlsx";

export async function readKotakStatement(file: File): Promise<IImportLine[]> {
  let text: string = await utils.getFileText(file);
  const json: any[] = Papa.parse(text).data;

  let lines = json.filter((item) => {
    return moment(item[1], "DD-MM-YYYY").isValid() && parseInt(item[0]);
  });
  console.log("json", lines);
  let lstLines = lines.map((item) => {
    let date = moment(item[1], "DD-MM-YYYY").toISOString();
    let isExpanse = item[6] === "DR";
    let obj: IImportLine = {
      date: date,
      transactionDate: date,
      amount: parseFloat(item[5]?.replace(/\,/gi, "")),
      comment: `Desc: ${item[3]}\nRef: ${item[4]}`,
      category: isExpanse ? "EXPENSE" : "INCOME",
      isExpanse: isExpanse,
    };
    return obj;
  });

  return lstLines;
}

export async function readBobXLSStatement(file: File): Promise<IImportLine[]> {
  let arrayBuffer: any = await utils.getFileText(file, "arrayBuffer");

  let wc = await xlsx.read(arrayBuffer, { type: "array" });
  console.log(wc);
  if (!wc.SheetNames[0]) {
    throw new Error("File do not contain any data.");
  }
  let json: any[] = xlsx.utils.sheet_to_json(wc.Sheets[wc.SheetNames[0]], {
    header: 1,
    raw: true,
    rawNumbers: true,
  });
  console.log("json", json);
  let lines = json.filter((item) => {
    return moment(item["1"], "DD/MM/YYYY").isValid() && item["25"];
  });
  console.log("json", lines);
  let lstLines = lines.map((item) => {
    let date = moment(item[1], "DD/MM/YYYY").toISOString();
    let isExpanse = !!item["12"];
    let amountText = item["12"] || item["18"];
    let obj: IImportLine = {
      date: date,
      transactionDate: date,
      amount: parseFloat(amountText?.replace(/\,/gi, "")),
      comment: `Desc: \nRef: ${item["4"]}`,
      category: isExpanse ? "EXPENSE" : "INCOME",
      isExpanse: isExpanse,
    };
    return obj;
  });

  return lstLines;
}

export async function readSbiXLSStatement(file: File): Promise<IImportLine[]> {
  let arrayBuffer: any = await utils.getFileText(file, "arrayBuffer");
  let wc = await xlsx.read(arrayBuffer, { type: "array" });
  console.log(wc);
  if (!wc.SheetNames[0]) {
    throw new Error("File do not contain any data.");
  }
  let json: any[] = xlsx.utils.sheet_to_json(wc.Sheets[wc.SheetNames[0]], {
    header: 1,
    raw: true,
    rawNumbers: true,
  });
  console.log("json", json);
  let lines = json.filter((item) => {
    return (
      Number.isFinite(item[0]) &&
      moment(new Date(1899, 11, 30)).add(item[0], "day").isValid()
    );
  });
  console.log("json", lines);
  let lstLines = lines.map((item) => {
    let date = moment(new Date(1899, 11, 30))
      .add(item[0], "day")
      .toISOString();
    let isExpanse = Number.isFinite(parseFloat(item[4]));
    let obj: IImportLine = {
      date: date,
      transactionDate: date,
      amount: parseFloat(item[4]) || parseFloat(item[5]),
      comment: `Desc: \nRef: ${item[2]?.trim()}`,
      category: isExpanse ? "EXPENSE" : "INCOME",
      isExpanse: isExpanse,
    };
    return obj;
  });

  return lstLines;
}

export async function iciciCreditCardStatement(
  file: File
): Promise<IImportLine[]> {
  let text: string = await utils.getFileText(file);
  const json: any[] = Papa.parse(text).data;

  let lines = json.filter((item) => {
    return moment(item[0], "DD/MM/YYYY").isValid() && item[5];
  });
  console.log("json", lines);
  let lstLines = lines.map((item) => {
    let date = moment(item[0], "DD/MM/YYYY").toISOString();
    let isExpanse = item[6] !== "CR";
    let obj: IImportLine = {
      date: date,
      transactionDate: date,
      amount: parseFloat(item[5]?.replace(/\,/gi, "")),
      comment: `Desc: ${item[3]}\nRef: ${item[2]}`,
      category: isExpanse ? "EXPENSE" : "INCOME",
      isExpanse: isExpanse,
    };
    return obj;
  });

  return lstLines;
}
