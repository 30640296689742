import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import api from './Services/ApiServices';
import auth from './Services/AuthService';
import { winState } from './Services/ScanRUtilService';
import utils from './Services/Utils';
import config from './config';
import ExceptionShowComponent from './Components/ExceptionShowComponent';

const win: any = window;
win.console._log = win.console.log;
win.console.log = win.console.debug;



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

const checkForAppVersionUpdate = async () => {
    if (process.env.NODE_ENV === "development")
        return;
    try {

        let latestVersion = await utils.getAppVersion();
        if (latestVersion.length > 10)
            return console.log("Version.txt contains version grater then 10 char");

        if (latestVersion.trim() !== config.APP_VERSION.trim()) {
            let cacheCleared = await utils.clearServiceWorkerCache();
            // alert("Please refresh content. ");
            console.log("version mismatch. latestVersion: " + latestVersion + " appVersion: " + config.APP_VERSION + " cleared?: " + cacheCleared);
            window.location.reload(true);
        }
        else {
            // console.log("app is currently latest")
        }
    }
    catch (ex) {
        alert("Error occurred while fetching app version. " + ex.message);
        console.log("Error occurred while fetching app version. ", ex)
    }
}


export const loadInitialConfigAndUser = async function () {
    try {
        // console.log("try getting dbconfig");
        // win.dbConfig = await api.getDbConfig();
        await checkForAppVersionUpdate()
        // config.update(); // An Important statement. need to be fired whenever dbconfig change
        if (auth.getToken()) { // && !win.user
            let result = await api.getUserData();
            win.user = result.data;
            winState.state.user = result.data;
            winState.state.companySettings = result.data.company;
            
        }
        win.dbConfig = true;
    }
    catch (ex) {
        console.log("Handle exception", ex);
        throw ex;
    }
}



async function initApp() {
    try {
        if (!win.dbConfig) {
            await loadInitialConfigAndUser();
        }
        if (document.getElementById('root'))
            ReactDOM.render(
                // <Provider store={store}>
                <App />
                // </Provider>
                , document.getElementById('root'));
        let divSplash = document.getElementById("divSplash");
        if (divSplash) {
            divSplash.style.display = "none";
        }
    }
    catch (ex) {
        let divSplash = document.getElementById("divSplash");
        if (divSplash) {
            divSplash.style.display = "none";
        }
        ReactDOM.render(<ExceptionShowComponent ex={ex} />, document.getElementById('root'));
    }
}

export function reInitApp() {
    let divSplash = document.getElementById("divSplash");
    let root = document.getElementById("root");
    if (divSplash) {
        divSplash.style.display = "block";
    }
    if (root) {
        ReactDOM.unmountComponentAtNode(root);
    }
    win.dbConfig = undefined;
    initApp();
}

initApp();

if (process.env.REACT_APP_SERVICE_WORKDER_OFF)
    serviceWorker.unregister();
else
    serviceWorker.unregister();
