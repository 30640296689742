import React, { Component } from 'react'
import { Typography } from '@material-ui/core';


interface SeeMoreProps {
    text?: string,
    length?: number,
    replaceNewLineWithBR?:boolean
}

interface BottomNavState { }

export default class SeeMore extends Component<SeeMoreProps> {
    state = {
        isExtended: false
    }
    render() {
        let text = this.props.text || '';
        let textToDisplay = this.state.isExtended ? text : text.substr(0, this.props.length || 250);
        
        if(this.props.replaceNewLineWithBR !== false){
            textToDisplay = textToDisplay.replace(/\n/g, "\n<br>");
        }
        let textComponent = (
            <Typography display="inline" variant="body2" style={{ textAlign: "justify" }} >
                <span dangerouslySetInnerHTML={{ __html: textToDisplay }}></span>
            </Typography>
        )
        if (text?.length < ((this.props.length || 250)))
            return textComponent;

        if (!this.state.isExtended) {
            return (
                <span>
                    {textComponent}
                    <Typography display="inline" gutterBottom variant="body2" color="primary" onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault()
                        this.setState({ isExtended: !this.state.isExtended })
                    }}
                        style={{ cursor: 'pointer' }}
                    >{"... "}See More</Typography>
                </span>
            )
        }
        else {
            return (
                <span>
                    {textComponent}
                    <Typography display="inline" gutterBottom variant="body2" color="primary" onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault()
                        this.setState({ isExtended: !this.state.isExtended })
                    }}
                        style={{ cursor: 'pointer' }}
                    >{" "}See Less</Typography>
                </span>
            )
        }
    }
}
