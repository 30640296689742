import React from 'react'
import { Grid, Button } from '@material-ui/core';
import HyperForm from '../../Components/HyperInput/HyperForm';
import HyperAmountField from '../../Components/HyperInput/HyperAmountField';
import { Journal, IAccount } from '../../Types/AllTypes';
import AccountAutoComplete from '../Journal/AccountAutoComplete';
import moment from 'moment';
import enums from '../../enums';
import { HyperTheme } from '../../Theme/Theme';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import utils from '../../Services/Utils';

interface PaymentItemProps {
    objPayment: Journal
    totalDueAmount: number
    isAddingExpense: boolean
    onRemoveItem: () => void
    onSave: (objPayment: Journal, isEditing: boolean) => void
    checkPaymentAmount: (currentAmount: number, isEditing: boolean) => boolean
}

interface PaymentItemState {
    objPayment: Journal
}


class PaymentItem extends React.Component<PaymentItemProps, PaymentItemState> {
    constructor(props: PaymentItemProps) {
        super(props);
        let state: PaymentItemState = {
            objPayment: {} as Journal,
        };
        if (this.props.totalDueAmount) {
            state.objPayment.amount = this.props.totalDueAmount
        }
        if (this.props.objPayment) {
            state.objPayment = { ...this.props.objPayment }
            this.isEditing = true;
        }
        if (!state.objPayment.date) {
            state.objPayment.date = moment().format("YYYY-MM-DD")
        }
        else {
            state.objPayment.date = moment(state.objPayment.date).format("YYYY-MM-DD")
        }

        this.state = state;
        this.frm = new HyperForm();
    }
    frm: HyperForm;
    isEditing: boolean = false;

    setPayment(obj: Partial<Journal>) {
        this.setState({ objPayment: { ...this.state.objPayment, ...obj } });
    }
    onSubmit = async () => {
        try {
            // console.log(this.frm, this.frm.isValid(), "On Submmit")
            this.frm.setIsFormSubmitted(true)
            if (this.frm.isValid()) {
                let isValidAmount = this.props.checkPaymentAmount(this.state.objPayment.amount, this.isEditing)
                if (!isValidAmount)
                    return utils.showError("Your Enter Amount is Grater then your Bill Amount.")
                this.props.onSave(this.state.objPayment, this.isEditing)
            }
        }
        catch (ex) {

        }
        finally {

        }
    }

    render() {
        //console.log(this.props.objPayment, "amt ObjPayment")
        return (

            <Grid container style={{ marginTop: 10 }} spacing={1}>
                <Grid item xs={10}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <AccountAutoComplete
                                accountId={this.state.objPayment.debitAccountId}
                                onChange={(objAccount: IAccount) => {
                                    if (objAccount)
                                        this.setPayment({ debitAccountId: objAccount.id, debitAccount: objAccount })
                                }}
                                label="Payment Option"
                                frm={this.frm}
                                required
                                autoFocus
                                category={this.props.isAddingExpense ? enums.ACCOUNT_CATEGORY.EXPENSE : enums.ACCOUNT_CATEGORY.CASH_BANK}
                                addBtnLabel={this.props.isAddingExpense ? "Add New Expense" : "Add New Bank Account"}
                                autoCompleteAddModalType={this.props.isAddingExpense ? enums.AUTO_COMPLETE_ADD_TYPE.ADD_EXPENSE : enums.AUTO_COMPLETE_ADD_TYPE.ADD_PAYMENT_OPTION}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <HyperAmountField
                                required
                                value={this.state.objPayment.amount}
                                onChange={(e: any) => { this.setPayment({ amount: parseFloat(e.target.value) }) }}
                                label="Amount"
                                frm={this.frm}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <HyperTextField
                                value={this.state.objPayment.comment || ""}
                                onChange={(e: any) => {
                                    this.setPayment({ comment: (e.target.value) })
                                }}
                                multiline
                                label={"Payment Note"}
                                type="text"
                                frm={this.frm}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <HyperTextField
                                required
                                value={this.state.objPayment.date}
                                onChange={(e: any) => {
                                    this.setPayment({ date: (e.target.value) })
                                }}
                                label={"Date"}
                                type="date"
                                frm={this.frm}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={2} style={{ margin: 'auto' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ backgroundColor: HyperTheme.palette.error.main, color: HyperTheme.palette.common.white }}
                        onClick={this.props.onRemoveItem}
                    >
                        Remove
                    </Button>{" "}
                    <Button onClick={this.onSubmit} style={{ marginTop: 8 }} variant="contained" fullWidth color="primary" >Save</Button>
                </Grid>
            </Grid>


        );
    }
}

export default PaymentItem;