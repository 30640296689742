import React from 'react';
import { Subtract } from 'utility-types';

export interface IWithEventType{

}

const withEvent = <P extends IWithEventType>(Component: React.ComponentType<P>, aryEvents: string[] = []) => {
    return class WithEvent extends React.Component<Subtract<P, IWithEventType>> {
        ref = React.createRef<any>();
        onEvent = (e:any) => {
            if(this.ref.current && typeof this.ref.current.onEvent === "function"){
                this.ref.current.onEvent(e);
            }
        }
        
        componentDidMount() {
            for(let event of aryEvents){
                document.addEventListener(event, this.onEvent);
            }
        }

        componentWillUnmount() {
            for(let event of aryEvents){
                document.removeEventListener(event, this.onEvent);
            }
        }

        render() {
            return (
                <Component ref={this.ref} {...this.props as P} ></Component >
            )
        }
    }
}

export default withEvent;
