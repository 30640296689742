import { ISalesInvoiceTemplate, ISalesInvoiceTemplateItem } from "../../../Types/AllTypes";

let css = `
@page {
  margin-left: 0.32cm;
  margin-right: 1.32cm;
  margin-top: 0.32cm;
  margin-bottom: 0.48cm
}

p {
  margin-bottom: 0.25cm;
  direction: ltr;
  line-height: 115%;
  text-align: left;
  orphans: 2;
  widows: 2
}

a:link {
  so-language: zxx
}

table td,
table tr {
  border-collapse: collapse;
}

.item-values {}
.small-font{
	font-size:14px
}

.item-values td {
 text-align: centera;
}
.text-right{
	text-align: right;
}
.t-center{
	text-align: center;
}
`


function getItem(objItem: ISalesInvoiceTemplateItem, index: number, showSN: boolean, shouldShowInvoiceColumns: boolean) {

	return `

	<tr class="item-values">
				<td colspan="4">
				<b>${objItem.itemName}</b>
				${objItem.itemDefaultDescription ? `<br/><span style="font-size:14px;">${objItem.itemDefaultDescription}</span>` : ''} 
				${showSN && objItem.itemSN ? `<br/><span style="font-size:14px;">S/N : ${objItem.itemSN}</span>` : ''} 
				</td>
				<td class="small-font text-right">
				${objItem.itemPrice}
				</td>
				<td class="qty small-font t-center" >
				${objItem.itemQty}
				</td>
				${shouldShowInvoiceColumns ? `
					<td class="tax small-font text-right">
					${objItem.showTaxOnInvoice ? objItem.itemTax : "-"}
					</td>	
				`: "<td></td>"}
				<td class="total small-font text-right" >
				${objItem.itemAmount}
				</td>
			</tr>
        `
}

export default function template1(obj: ISalesInvoiceTemplate) {
	let shouldShowInvoiceColumns: boolean = obj.items.some(item => item.showTaxOnInvoice);
	let html = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="utf-8">
      <title>Example 2</title>
      <style>
    ${css}
      </style>
    </head>
    <body >
    <table border="1"  cellpadding="7" cellspacing="0" style="page-break-before: always">

		<tbody>
			<tr>
				<td colspan="6" bgcolor="#c5d9f1" style="">
					<p>
						<font face="Eras Bold ITC, serif">
							${shouldShowInvoiceColumns ?
			`<font size="5" style="font-size: 22pt;text-align: center;"><b>TAX INVOICE</b></font>`
			:
			`<font size="5" style="font-size: 22pt;text-align: center;"><b>INVOICE</b></font>`}
							
						</font>
					</p>
				</td>
				<td colspan="2" width="20%" bgcolor="#c5d9f1" style="background: #c5d9f1"
					style="border-top: 1.00pt solid #000000; border-bottom: 1.00pt solid #000000; border-left: none; border-right: 1.00pt solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm">
					<p style="text-align: end;">
						<font>
							<b>INVOICE NO : ${obj.invoiceNumber}<br />
								DATE :  </b>
						</font>
						<font>${obj.dateOfInvoice}</font>
					</p>
				</td>
			</tr>

			<tr>
				<td colspan="8" width="743" height="142" valign="bottom" style="border: none; padding: 0cm">

					<table width="720" cellpadding="0" cellspacing="0">
						<tr>
							<td>
								<p style="margin-bottom: 0cm">
								${obj.companyLogoURL ? `<img src=${obj.companyLogoURL} name="Picture 1" align="left" hspace="13" width="131" height="127" border="0">` : ""}
									<br />

								</p>
							</td>
							<td width="720" height="156" valign="center" style="border: none; padding: 0cm">
								<p style="text-align: center;">
									<font face="Algerian, serif">
										<font style="font-size: 25pt">${obj.companyName}</font>
									</font>
									<br />
									<font>
										<font size="6" style="font-size: 26pt"><br />
										</font>
									</font>
									<font>
										<font  style="font-size: 13pt">${obj.companyAddress || ""}</font><br />
									</font>
									${obj.companyMobile
			? `<font>
										<font  style="font-size: 13pt">Mobile :  ${obj.companyMobile || ""}</font><br />
										</font>`
			: ''}
									${obj.companyEmail
			? `<font>
											<font  style="font-size: 13pt">
											Email : <a href="mailto:${obj.companyEmail || ""}">${obj.companyEmail || ""}</a><br />
											</font>
										</font>`
			: ''}
								</p>
							</td>
						</tr>
					</table>
				</td>
			</tr>

			<tr>
				<td colspan="8" width="743" height="12" style="border: 1.00pt solid #000000; padding: 0.30cm 0.05cm">
					<p style="margin-bottom: 0cm; margin-top: 0cm;padding-left:5px;padding-bottom:5px;">
						<font color="#000000">
							<font>
								<font  style="font-size: 13pt">
									<b>
										PARTY'S/CUSTOMER NAME :- <br />
									</b>
								</font>
							</font>
						</font>
						</p>
						<p style="margin-bottom: 0cm; margin-top: 0cm;padding-left:5px;">
						<font color="#000000">
							<font>
								<font style="font-size: 13pt;">
									${obj.customerName}<br />
								</font>
								<font style="font-size: 12pt;">
									${obj.customerAddress ? `${obj.customerAddress} </br>` : ''}
								</font>
								<font style="font-size: 12pt;">
									${obj.customerMobile ? `${obj.customerMobile} </br>` : ''}
								</font>
								<font style="font-size: 12pt;">
									${obj.customerEmail ? `${obj.customerEmail} </br>` : ''}		
								</font>
								<font style="font-size: 12pt;">
									${obj.customerGST ? `${obj.customerGST} </br>` : ''}
								</font>
							</font>
						</font>
						</p>
					
				</td>
			</tr>


			<tr class="item-header">
				<th colspan="4" style="width: 55%;">
					<p>
						<font color="#000000">
							<font><b>Particulars(Descriptions &amp; Specifications)</b></font>
						</font>
					</p>
				</th>
			
				<th style="width:50px" >
					<p style="margin-bottom: 0cm; line-height: 100%;" class="t-center">
						<font color="#000000">
							<font><b>Rate</b></font>
						</font>
					</p>
				</th>
				<th style="width:35px" >
					<p style="margin-bottom: 0cm; line-height: 100%" class="t-center">
						<font color="#000000">
							<font><b>Qty</b></font>
						</font>
					</p>
				</th>
				${shouldShowInvoiceColumns ? `
					<th style="width:125px" >
						<p style="margin-bottom: 0cm; line-height: 100%" class="t-center">
							<font color="#000000">
								<font><b>Tax</b></font>
							</font>
						</p>
					</th>
				`: "<th></th>"}
				<th width="175" style="" >
					<p style="margin-bottom: 0cm; line-height: 100%" class="t-center">
						<font color="#000000">
							<font><b>Amount</b></font>
						</font>
					</p>
				</th>
			</tr>

			${obj.items.map((objItem: any, index: any) => {
				return getItem(objItem, index, obj.showItemSerialNo, shouldShowInvoiceColumns);
			}).join("")}


			<tr>
				<td rowspan="5" colspan="4" width="474" height="22">

					<b>Warranty related Terms &amp; conditions</b>
					<b><br />
						1.
						An Invoice Must accompany products returned for warranty.<br />
						2.
						Goods damaged During transit voids warranty.<br />
						3. 90 days
						limited warranty unless otherwise stated.<br />
						4. 30 days limited
						warranty on OEM processor ( an internal parts of the product)
						exchange the same items only.<br />
						5. All items carry MFG
						Warranty only No return or exchange.</b>
				</td>
				<td colspan="3" width="84">
					<b>Sub Total</b>
				</td>
				<td width="111" class="text-right">
					${obj.subTotal}
				</td>
			</tr>

			<tr>
				<td colspan="3" width="84">
					<b>Discount(${obj.discountPercentage}%)</b>
				</td>
				<td width="111" class="text-right">
					${obj.totalDiscount}
				</td>
			</tr>

			${shouldShowInvoiceColumns ?
			`
					<tr>
					${(obj.showIGst) ? `
						<td colspan="3" width="84">
							<b>IGST</b>
						</td>
						<td width="111" class="text-right">
							${obj.totalIGst}
						</td>
					`:
				`
						<td colspan="3" width="84">
						<b>CGST</b>
						</td>
						
						<td width="111" class="text-right">
						${obj.totalCGst}
						</td>
					`
			}
					</tr>
					<tr>
					${!obj.showIGst ? `
						<td colspan="3" width="84">
							<b>SGST</b>
						</td>
						<td width="111" class="text-right">
							${obj.totalSGst}
						</td>
					`: ""}
					</tr>
				`:
			`<tr></tr><tr></tr>`}
				
			
		
			<tr>
				<td colspan="3" width="84" bgcolor="#c5d9f1" style="background: #c5d9f1">
					<b>Grand Total</b>
				</td>

				<td width="111" bgcolor="#c5d9f1" style="background: #c5d9f1" class="text-right">
				<b>${obj.totalAmountWithTax}</b>
				</td>
			</tr>
			<tr>
				<td colspan="8" width="84" valign="bottom" style=" padding: 0cm;padding-top:7px;padding-bottom:7px;padding-left:5px;">
				Due Date : <b>${obj.dueDateOfInvoice}</b>,
				Total Due Amount : <b>${obj.totalDueAmount}</b>,
				Total Paid Amount : <b>${obj.totalPaidAmount}</b>	
				</td>
			</tr>

			<tr>
				<td colspan="8" width="743" height="49" bgcolor="#ffffff" style="background: #ffffff"
					style="border: 1.00pt solid #000000; padding: 0cm 0.19cm">
					<p style="margin-bottom: 0cm; line-height: 100%">
						<font>
							<font size="2" style="font-size: 10pt"><i><u><b>Total
											Amount (INR - In Words) : </b></u></i></font>
						</font>
						<br/>
						<font>
							<font size="2" style="font-size: 10pt"><i><b> ${obj.totalAmountInWord}</b></i></font>
						</font>
						<font>
							<font size="2" style="font-size: 10pt">
							</font>
						</font>
					</p>
				</td>
			</tr>

			<tr>
				<td width="387" height="16" style="border: none; padding: 0cm">
					<p style="margin-bottom: 0cm; line-height: 100%">
						<font>
							<font size="3" style="font-size: 12pt"><i><b>For
										${obj.companyName}</b></i></font>
						</font>
					</p>
				</td>
				<td width="73" style="border: none; padding: 0cm">
					<p style="margin-bottom: 0cm; line-height: 100%"><br />

					</p>
				</td>
				<td colspan="2" width="84" style="border: none; padding: 0cm">
					<p style="margin-bottom: 0cm; line-height: 100%"><br />

					</p>
				</td>
				<td colspan="2" width="32" style="border: none; padding: 0cm">
					<p style="margin-bottom: 0cm; line-height: 100%"><br />

					</p>
				</td>
				<td colspan="2" width="111" style="border: none; padding: 0cm">
					<p style="margin-bottom: 0cm; line-height: 100%"><br />

					</p>
				</td>
			</tr>
			<tr>
				<td width="387" height="16" style="border: none; padding: 0cm">
					<p style="margin-bottom: 0cm; line-height: 100%"><br />

					</p>
				</td>
				<td width="73" style="border: none; padding: 0cm">
					<p style="margin-bottom: 0cm; line-height: 100%"><br />

					</p>
				</td>
				<td colspan="2" width="84" style="border: none; padding: 0cm">
					<p style="margin-bottom: 0cm; line-height: 100%"><br />

					</p>
				</td>
				<td colspan="2" width="32" style="border: none; padding: 0cm">
					<p style="margin-bottom: 0cm; line-height: 100%"><br />

					</p>
				</td>
				<td colspan="2" width="111" style="border: none; padding: 0cm">
					<p style="margin-bottom: 0cm; line-height: 100%"><br />

					</p>
				</td>
			</tr>
			
			<tr>
				<td width="387" height="6" valign="bottom" style="border: none; padding: 0cm;text-align:center;">
					${obj.companySignatureURL ? `
						<img src="${obj.companySignatureURL}" style="height:30px;" />
					`: ""}
				</td>
				<td width="73" style="border: none; padding: 0cm">
					<p style="margin-bottom: 0cm; line-height: 100%"><br />

					</p>
				</td>
				<td colspan="2" width="84" valign="bottom" style="border: none; padding: 0cm">
					<p style="margin-bottom: 0cm; line-height: 100%"><br />

					</p>
				</td>
				<td colspan="2" width="32" valign="bottom" style="border: none; padding: 0cm">
					<p style="margin-bottom: 0cm; line-height: 100%"><br />

					</p>
				</td>
				<td colspan="2" width="111" valign="bottom" style="border: none; padding: 0cm">
					<p style="margin-bottom: 0cm; line-height: 100%"><br />

					</p>
				</td>
			</tr>
			<tr valign="bottom">
				<td width="387" height="7" style="border: none; padding: 0cm">
					<p style="margin-bottom: 0cm; line-height: 100%">
						<font>
							<font size="3" style="font-size: 12pt"><i><b>Authorised
										Signatory</b></i></font>
						</font>
					</p>
				</td>
				<td width="73" style="border: none; padding: 0cm">
					<p style="margin-bottom: 0cm; line-height: 100%"><br />

					</p>
				</td>
				<td colspan="2" width="84" style="border: none; padding: 0cm">
					<p style="margin-bottom: 0cm; line-height: 100%"><br />

					</p>
				</td>
				<td colspan="2" width="32" style="border: none; padding: 0cm">
					<p style="margin-bottom: 0cm; line-height: 100%"><br />

					</p>
				</td>
				<td colspan="2" width="111" style="border: none; padding: 0cm">
					<p style="margin-bottom: 0cm; line-height: 100%"><br />

					</p>
				</td>
			</tr>
		</tbody>
	</table>
    </body>
  </html>
  `

	return html;
}