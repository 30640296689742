

export class PaginationSearchOptions {
	limit: number = 0;
	offset: number = 0;
}

export class SortingSearchOption {
	sort: string = "";
	sortOrder: "ASC" | "DESC" = "ASC";
}

export class PageAndSortingSearchOptions extends PaginationSearchOptions {
	sort: string = "";
	sortOrder: "ASC" | "DESC" = "ASC";
	getPaginationAndSortingQueryString() {
		let obj: any = {};
		if (this.sort)
			obj.sort = this.sort;
		if (this.sortOrder)
			obj.sortOrder = this.sortOrder;
		if (this.limit)
			obj.limit = this.limit;
		if (this.offset)
			obj.offset = this.offset;

		return Object.keys(obj).map(key => `${key}=${obj[key]}`).join("&");
	}
}

export class JournalSearchOptions extends PageAndSortingSearchOptions {
	fromDate?: string = "";
	toDate?: string = "";
	category?: string = "";
	categories?: string[] = [];
	info?: any = "";
	id?: number;
	creditAccountId?: number;
	debitAccountId?: number;
	companyIds?: string
}

export class SalesSearchOptions extends PageAndSortingSearchOptions {
	fromDate?: string = "";
	toDate?: string = "";
}

export class ItemSearchOption extends PageAndSortingSearchOptions {

}
export class CategorySearchOption extends PageAndSortingSearchOptions {

}

export class TaxSearchOption extends PageAndSortingSearchOptions {

}

export class AccountSearchOption extends PageAndSortingSearchOptions {
	category?: string = "";
}
export class AccountBookSearchOption extends PageAndSortingSearchOptions {
	accountId: string = "";
	fromDate?: string = "";
	toDate?: string = "";
}

export class StockSummaryByCategorySearchOption extends PageAndSortingSearchOptions { }

export class StockSummarySearchOption extends PageAndSortingSearchOptions {
	companyIds?: string
}
export class StockBookSearchOption extends PageAndSortingSearchOptions {
	itemId?: number
}
export class SerialNoExistSearchOption extends PageAndSortingSearchOptions {
	serialNo: string = ""
	category: string = ""
}
export class DueCustomerSupplierInvoiceSearchOption extends PageAndSortingSearchOptions {
	lstCategory: string[] = []
	fromDate?: string = "";
	toDate?: string = "";
	companyIds?: string
}

export class BalanceSheetSearchOptions {
	fromDate?: string = "";
	toDate?: string = "";
}

export class ProfitAndLossSearchOptions {
	fromDate?: string = "";
	toDate?: string = "";
}
export class SupplierWisePurchaseSearchOptions extends PageAndSortingSearchOptions {
	fromDate?: string = "";
	toDate?: string = "";
	companyIds?: string;
}
export class ReportSearchOptions extends PageAndSortingSearchOptions {
	fromDate?: string = "";
	toDate?: string = "";
	companyIds?: string;
}

export class DashboardSearchOptions {
	fromDate?: string = "";
	toDate?: string = "";
}