import { Container, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom'
import React from 'react'
import moment from 'moment';
import ItemCard from './ItemCard';
import CashFlow from './CashFlow';
import SalesPurchaseChart from './SalesPurchaseChart';
import ProfitExpenseChart from './ProfitExpenseChart';
import { IconSales, IconCart, IconIncome } from '../../Components/Icons/Icons';
import { IDashboard, ISales, IPurchase } from '../../Types/AllTypes';
import { DashboardSearchOptions, DueCustomerSupplierInvoiceSearchOption } from '../../Types/SearchOptions';
import api from '../../Services/ApiServices';
import utils from '../../Services/Utils';
import enums from '../../enums';
import HyperModal from '../../Components/HyperInput/HyperModal';
import SearchBar from './SearchBar';
import DueSales from './DueSales';
import DuePurchase from './DuePurchase';
import ProfitLossFlow from './ProfitLossFlow';

interface DashboardProps { }
interface DashboardState {
    dashboard: IDashboard
    dateRange: any
    shouldShowSkeleton: boolean
    lstDueSalesInvoice: ISales[]
    lstDuePurchaseInvoice: IPurchase[]
}

class Dashboard extends React.Component<DashboardProps, DashboardState> {
    constructor(props: DashboardProps) {
        super(props);
        let state: DashboardState = {
            dashboard: {} as IDashboard,
            shouldShowSkeleton: true,
            dateRange: {
                label: "This Month",
                startDate: moment().startOf("month").toDate(),
                endDate: moment().endOf("month").toDate()
            },
            lstDueSalesInvoice: [],
            lstDuePurchaseInvoice: [],
        }
        this.state = state;
        this.snTransactionModal = new HyperModal(this);
    }

    snTransactionModal: HyperModal;

    componentDidMount() {
        this.getDashboard();
        this.getDueSales();
        this.getDuePurchase();
    }

    getDashboard = async () => {
        try {
            let so = new DashboardSearchOptions();
            // so.fromDate = moment(this.state.dateRange.startDate).format("YYYY-MM-DD")
            // so.toDate = moment(this.state.dateRange.endDate).format("YYYY-MM-DD")
            utils.showLoader();
            let dashboard = await api.getDashboard1(so);
            this.setState({ dashboard, shouldShowSkeleton: false })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }
    getDueSales = async () => {
        try {
            utils.showLoader();
            let so = new DueCustomerSupplierInvoiceSearchOption();
            so.limit = 5
            so.lstCategory = [enums.JOURNAL_CATEGORY.SALES]
            let data = await api.getDueSupplierAndCustomerRecords(so)
            this.setState({ lstDueSalesInvoice: data })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }
    getDuePurchase = async () => {
        try {
            utils.showLoader();
            let so = new DueCustomerSupplierInvoiceSearchOption();
            so.limit = 5
            so.lstCategory = [enums.JOURNAL_CATEGORY.PURCHASE]
            let data = await api.getDueSupplierAndCustomerRecords(so)
            this.setState({ lstDuePurchaseInvoice: data })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }
    render() {
        // console.log(this.state, "This State");
        if (this.state.shouldShowSkeleton) {
            return null;
        }
        return (
            <Container>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <SearchBar />
                    </Grid>


                    <Grid item xs={12} md={4}>
                        <ItemCard
                            iconComponent={<IconSales style={{ color: "#328aef" }} />}
                            subTitle="Cash on hand"
                            openBalance={100}
                            overDueBalance={this.state.dashboard.liquidCash}
                            title={"Liquid Cash"}
                            backgroundColor={"#328aef"}
                            gradientColor={"#66afff"}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Link to={`/purchases?paymentType=${[enums.SALES_PURCHASE_STATUS.UNPAID, enums.SALES_PURCHASE_STATUS.PARTIALLY_PAID].join()}`}>
                            <ItemCard
                                iconComponent={<IconCart style={{ color: "#ef3232" }} />}
                                subTitle="Purchase Bills"
                                // openBalance={100}
                                overDueBalance={this.state.dashboard.purchase.unpaid}
                                title={"Bills Payable"}
                                backgroundColor={"#ef3232"}
                                gradientColor={"#ff6666"}
                            />
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Link to={`/sales?paymentType=${[enums.SALES_PURCHASE_STATUS.UNPAID, enums.SALES_PURCHASE_STATUS.PARTIALLY_PAID].join()}`}>
                            <ItemCard
                                iconComponent={<IconIncome style={{ color: "#6da252" }} />}
                                subTitle="Pending Receipts"
                                openBalance={100}
                                overDueBalance={this.state.dashboard.sales.unpaid}
                                title={"Bills Receivable"}
                                backgroundColor={"#6da252"}
                                gradientColor={"#b8d3a9"}
                            />
                        </Link>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {!!(this.state.lstDueSalesInvoice.length > 0) &&
                        <Grid item xs={12} md={6}>
                            <DueSales lstDueSales={this.state.lstDueSalesInvoice} />
                        </Grid>
                    }
                    {!!(this.state.lstDuePurchaseInvoice.length > 0) &&
                        <Grid item xs={12} md={6}>
                            <DuePurchase lstDuePurchase={this.state.lstDuePurchaseInvoice} />
                        </Grid>
                    }
                </Grid>
                <Grid container style={{ marginTop: 20 }} spacing={2}>
                    <Grid item xs={12}>
                        {this.state.dashboard.cashFlow ?
                            <CashFlow dashboard={this.state.dashboard} />
                            : null}
                    </Grid>
                    <Grid item xs={12}>
                        {this.state.dashboard.cashFlow ?
                            <ProfitLossFlow />
                            : null}
                    </Grid>
                    {(this.state.dashboard.sales && this.state.dashboard.sales.unpaid !== 0 && this.state.dashboard.sales.total !== 0) ?
                        <Grid item xs={12} md={6}>
                            <SalesPurchaseChart
                                paid={this.state.dashboard.sales.total - this.state.dashboard.sales.unpaid}
                                unpaid={this.state.dashboard.sales.unpaid}
                                title={"Sales"}
                            />
                        </Grid>
                        :
                        null}
                    {(this.state.dashboard.purchase && this.state.dashboard.purchase.unpaid !== 0 && this.state.dashboard.purchase.total !== 0) ?
                        <Grid item xs={12} md={6}>
                            <ProfitExpenseChart
                                paid={this.state.dashboard.purchase.total - this.state.dashboard.purchase.unpaid}
                                unpaid={this.state.dashboard.purchase.unpaid}
                                title={"Purchase"}
                            />
                        </Grid>
                        :
                        null}
                </Grid>
            </Container>
        );
    }
}

export default Dashboard;