import React from 'react'
import { TextField } from '@material-ui/core'
import BaseInput from './BaseInput'
import PropTypes from 'prop-types';


class HyperTextField extends BaseInput {
    render() {
        // console.log(!this.state.isValid, this.state.isFocused, "HyperTechField", this.props.label)
        return (
            <TextField
                variant={this.props.variant || "outlined"}
                error={!this.isValid()}
                helperText={this.getHelperText()}
                onFocus={this.onFocus}
                value={this.props.value}
                type={this.props.type || "text"}
                label={this.props.label}
                onChange={this.onChange}
                onBlur={this.onBlur}
                multiline={this.props.multiline}
                rows={this.props.rows}
                rowsMax={this.props.rowsMax}
                readOnly={this.props.readOnly}
                disabled={this.props.disabled}
                autoFocus={this.props.autoFocus}
                size="small"
                fullWidth
            />
        )
    }
}

HyperTextField.propTypes = {
    variant: PropTypes.any,
    value: PropTypes.any,
    type: PropTypes.any,
    label: PropTypes.any,
    multiline: PropTypes.bool,
    readOnly: PropTypes.bool,
    autoFocus: PropTypes.any,
}

export default HyperTextField;
