import React from 'react'
import { withTheme, Theme, Grid, Paper } from '@material-ui/core'
import HyperModal from '../../../Components/HyperInput/HyperModal';
import scanRUtils from '../../../Services/ScanRUtilService';

interface EXEMPProps {
    theme: Theme
    objEXEMP: any
}

interface EXEMPState { }

class EXEMP extends React.Component<EXEMPProps, EXEMPState> {
    constructor(props: EXEMPProps) {
        super(props);
        let state: EXEMPState = {};
        this.state = state;
        this.modal = new HyperModal(this);
    }
    modal: HyperModal;
    componentDidMount() { }
    render() {
        return (
            <Grid container style={{ marginBottom: 20, marginTop: 20 }} spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={3} style={{ paddingTop: 30, paddingBottom: 30, padding: 20 }}>

                        <div style={{ display: 'inline-block' }}>
                            <span>Total Nil Rated Supplies</span><br />
                            <b>{scanRUtils.prettyPrice(this.props.objEXEMP.totalNilRatedSupplies)}</b>
                        </div>
                        <div style={{ display: 'inline-block', paddingLeft: 25 }}>
                            <span>Total Exempted Supplies</span><br />
                            <b>{scanRUtils.prettyPrice(this.props.objEXEMP.totalExemptedSupplies)}</b>
                        </div>
                        <div style={{ display: 'inline-block', paddingLeft: 25 }}>
                            <span>Total non-GST Supplies</span><br />
                            <b>{scanRUtils.prettyPrice(this.props.objEXEMP.totalNonGSTSupplies)}</b>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={3} style={{ paddingTop: 30, paddingBottom: 30, padding: 20 }}>
                        <table {...{ border: 1 }} style={{ width: '100%' }} cellPadding={5} cellSpacing={0}>
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Nil Rated Supplies</th>
                                    <th>Exempted(other than nil rated/non-GST supply)</th>
                                    <th>Non-GST supplies</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr >
                                    <td>Inter-State supplies to registered persons</td>
                                    <td>{scanRUtils.prettyPrice(this.props.objEXEMP.totalNilRatedSuppliesOfInterStateRP)}</td>
                                    <td>{scanRUtils.prettyPrice(this.props.objEXEMP.totalExemptedSuppliesOfInterStateRP)}</td>
                                    <td>{scanRUtils.prettyPrice(this.props.objEXEMP.totalNonGSTSuppliesOfInterStateRP)}</td>
                                </tr>
                                <tr >
                                    <td>Intra-State supplies to registered persons</td>
                                    <td>{scanRUtils.prettyPrice(this.props.objEXEMP.totalNilRatedSuppliesOfIntraStateRP)}</td>
                                    <td>{scanRUtils.prettyPrice(this.props.objEXEMP.totalExemptedSuppliesOfIntraStateRP)}</td>
                                    <td>{scanRUtils.prettyPrice(this.props.objEXEMP.totalNonGSTSuppliesOfIntraStateRP)}</td>
                                </tr>
                                <tr >
                                    <td>Inter-State supplies to unregistered persons</td>
                                    <td>{scanRUtils.prettyPrice(this.props.objEXEMP.totalNilRatedSuppliesOfInterStateURP)}</td>
                                    <td>{scanRUtils.prettyPrice(this.props.objEXEMP.totalExemptedSuppliesOfInterStateURP)}</td>
                                    <td>{scanRUtils.prettyPrice(this.props.objEXEMP.totalNonGSTSuppliesOfInterStateURP)}</td>
                                </tr>
                                <tr >
                                    <td>Intra-State supplies to unregistered persons</td>
                                    <td>{scanRUtils.prettyPrice(this.props.objEXEMP.totalNilRatedSuppliesOfIntraStateURP)}</td>
                                    <td>{scanRUtils.prettyPrice(this.props.objEXEMP.totalExemptedSuppliesOfIntraStateURP)}</td>
                                    <td>{scanRUtils.prettyPrice(this.props.objEXEMP.totalNonGSTSuppliesOfIntraStateURP)}</td>
                                </tr>

                            </tbody>
                        </table>
                    </Paper>
                </Grid>
            </Grid >

        );
    }
}

export default withTheme(EXEMP);