import React from 'react'
import { Button, TableContainer, Table, TableBody, TableRow, TableCell, Paper, Grid } from '@material-ui/core';
import { IPurchaseItem, IDiscount } from '../../Types/AllTypes';
import HyperForm from '../../Components/HyperInput/HyperForm';
import Alert from '@material-ui/lab/Alert';
import PurchaseItem from './PurchaseItem';
import utils from '../../Services/Utils';
import scanRUtils from '../../Services/ScanRUtilService';
import enums from '../../enums';
import HyperSelectField from '../../Components/HyperInput/HyperSelectField';
import HyperTextField from '../../Components/HyperInput/HyperTextField';

interface PurchaseItemsProps {
    frm: HyperForm
    lstPurchaseItem: IPurchaseItem[]
    totalPaymentAmount: number
    objDiscount: IDiscount
    taxInvoiceType: string

    onChange?: () => void
    setDiscount: (data: Partial<IDiscount>) => void
}

interface PurchaseItemsState {

}

const objDefaultPurchaseItem = {
    objItem: {},
    qty: 1,
    price: 0,
    lstSerialNo: ([] as string[]),
} as IPurchaseItem;

class PurchaseItems extends React.Component<PurchaseItemsProps, PurchaseItemsState> {
    // constructor(props: PurchaseItemsProps) {
    //     super(props);
    // }

    currentFocusedItem: any = {};

    componentDidMount() {
        document.addEventListener(enums.EVENT.BARCODE_SCANNED, this.onBarcodeScanned)
    }

    componentWillUnmount() {
        document.removeEventListener(enums.EVENT.BARCODE_SCANNED, this.onBarcodeScanned)
    }

    onBarcodeScanned = (event: any) => {
        if (!this.currentFocusedItem.current) {
            console.log("currently no focused item found");
            return;
        }
        let data = event.detail?.data;
        if (data && this.currentFocusedItem.current.onBarcodeInput) {
            this.currentFocusedItem.current.onBarcodeInput(data.data);
        }
    }

    addItem = () => {
        this.props.lstPurchaseItem.push(objDefaultPurchaseItem)
        this.forceUpdate();
    }

    removeItem = (index: number) => {
        if (this.props.lstPurchaseItem.length <= 1) {
            utils.showWarning("You must have at least one item. ")
            return;
        }

        this.props.lstPurchaseItem.splice(index, 1);
        this.forceUpdate();
    }

    getDueAmount = () => {
        let itemTotals = scanRUtils.getTotalCounts(this.props.lstPurchaseItem, this.props.objDiscount)
        return itemTotals.amountWithTax - this.props.totalPaymentAmount;
    }
    onDiscountChange = (val: any, subTotal: number) => {
        let discountValue = val || "0";
        if (this.props.objDiscount.discountType === enums.DISCOUNT_OPTION.FIXED) {
            let discountPercentage = scanRUtils.getPercentageOfFixeDiscount(subTotal, parseFloat(discountValue))
            this.props.setDiscount({ discountAmount: parseFloat(discountValue), discountPercentage: discountPercentage })
        }
        else {
            this.props.setDiscount({ discountPercentage: parseFloat(discountValue) })
        }
    }

    render() {
        let objTotalCount = scanRUtils.getTotalCounts(this.props.lstPurchaseItem, this.props.objDiscount)
        // console.log(this.props.objDiscount, "Obj Discount");
        return (
            <div>
                <div style={{ textAlign: 'end' }}>
                    <Button variant="contained" color="primary" onClick={this.addItem}>Add Item</Button>
                </div>
                {this.props.lstPurchaseItem.map((objPurchaseItem: IPurchaseItem, index: number) => {
                    return <PurchaseItem
                        key={index}
                        objPurchaseItem={objPurchaseItem}
                        removeItem={() => { this.removeItem(index) }}
                        frm={this.props.frm}
                        focusedItemRef={this.currentFocusedItem}
                        objDiscount={this.props.objDiscount}
                        onChange={(objItem: IPurchaseItem) => {
                            this.props.lstPurchaseItem[index] = { ...objPurchaseItem, ...objItem };
                            this.forceUpdate();
                        }}
                    />
                })}
                <br />
                <TableContainer component={Paper} elevation={3}>
                    <Table aria-label="customized table">
                        <TableBody>

                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right">Sub Total</TableCell>
                                <TableCell align="right"> {scanRUtils.prettyPrice(objTotalCount.subTotal)} </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell style={{ width: "35%", paddingBottom: 0, paddingTop: 5 }}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={3}>
                                            <HyperSelectField
                                                options={[enums.DISCOUNT_OPTION.PERCENTAGE, enums.DISCOUNT_OPTION.FIXED]}
                                                selectedValue={this.props.objDiscount.discountType}
                                                onChange={(e: any) => { this.props.setDiscount({ discountType: e.target.value }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <HyperTextField
                                                placeholder="Enter Your Discount Here"
                                                value={(this.props.objDiscount.discountType === enums.DISCOUNT_OPTION.FIXED
                                                    ? this.props.objDiscount.discountAmount
                                                    : this.props.objDiscount.discountPercentage) || ""
                                                }
                                                type="number"
                                                onChange={(e: any) => this.onDiscountChange(e.target.value, objTotalCount.subTotal)}
                                            />
                                        </Grid>
                                        <Grid item xs={3} style={{ textAlign: 'right', margin: 'auto' }}>
                                            <span>Discount</span>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell align="right" style={{ width: '20%' }}>
                                    {scanRUtils.prettyPrice(objTotalCount.discountAmount)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right">Total After Discount </TableCell>
                                <TableCell align="right">{scanRUtils.prettyPrice(objTotalCount.totalAfterDiscount)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right">Total Tax</TableCell>
                                <TableCell align="right">{scanRUtils.prettyPrice(objTotalCount.tax)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right">Total Amount With Tax</TableCell>
                                <TableCell align="right">{scanRUtils.prettyPrice(objTotalCount.amountWithTax)} </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {this.getDueAmount() ?
                    <Alert style={{ marginTop: 20 }} severity="info">Total Due Amount - {scanRUtils.prettyPrice(this.getDueAmount())}</Alert>
                    :
                    ""
                }
            </div>
        );
    }
}

export default PurchaseItems;