import React, { Component } from "react";
import { withStyles, IconButton, Typography, CircularProgress } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import utils from "../Services/Utils";
import AddIcon from '@material-ui/icons/Add';
import api from "../Services/ApiServices";
import enums from "../enums";
import { v4 as uuid_v4 } from 'uuid'
import { IconView } from './Icons/Icons'

const style = (theme) => ({
    container: {
        borderStyle: "dotted",
        textAlign: "center",
        borderRadius: 8
    },
    containerImageSelected: {
        textAlign: "center",
        borderRadius: 8,
        boxShadow: '0px 0px 5px 0px'
    },
});


class ImageUpload extends Component {

    state = {
        onMouseHover: false,
        imageURI: "",
        loading: false
    }

    defaultDocumentType = enums.FILE_DOCUMENT_TYPE.OTHER;

    onImageSelect = async (base64, extension) => {
        if (base64) {
            await this.setState({ loading: true })
            let fileName = "";

            if (this.props.fileName)
                fileName = this.props.fileName + "_" + uuid_v4();
            // else
            //     fileName = uuid_v4();

            var pathName = await api.uploadFile2(this.props.documentType || this.defaultDocumentType, fileName, extension, base64);
            await this.setState({ imageURI: pathName.data.data, loading: false })
        }
        else {
            await this.setState({ imageURI: "" })
        }

        if (this.props.onImageSelect)
            this.props.onImageSelect(this.state.imageURI, extension);
    };


    render() {
        const { classes } = this.props;
        // console.log(this.state)
        return (
            <div>
                <div style={{ width: this.props.width || 200, height: this.props.height || 200, position: "relative", margin: 'auto' }}>
                    {!this.props.imagePath ?
                        <React.Fragment>
                            <div
                                className={classes.container}
                                style={{ width: this.props.width || 200, height: this.props.height || 200 }}
                                onClick={() => { document.getElementById('input').click(); }}
                            >
                                {this.state.loading ?
                                    <React.Fragment>
                                        <CircularProgress style={{ marginTop: "50px" }} />
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <IconButton style={{ marginTop: "30px" }} ><AddIcon /></IconButton>
                                        <Typography variant="body1">Add Image</Typography>
                                    </React.Fragment>
                                }
                            </div>
                            <input type="file" accept="image/*" id="input" hidden onChange={async (e) => {
                                var base64 = await utils.toBase64(e.target.files[0]);
                                var extension = e.target?.value.split('.').pop();

                                this.onImageSelect(base64, extension);
                            }} />
                            <br />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div
                                style={{ width: this.props.width || 150, height: this.props.height || 150 }}
                                className={classes.containerImageSelected}
                            // onMouseEnter={() => this.setState({ onMouseHover: true })}
                            // onMouseLeave={() => this.setState({ onMouseHover: false })}
                            >
                                {/* <div>
                                    {(this.state.onMouseHover) ?
                                        <DeleteIcon onClick={(e) => {
                                            e.stopPropagation();
                                            this.onImageSelect("", "");
                                        }} style={{ color: "red", position: "absolute", top: 0, right: 0 }} />
                                        : null}
                                </div> */}
                                <img
                                    src={this.props.imagePath}
                                    style={{ width: this.props.width || 200, height: this.props.height || 200, maxWidth: this.props.maxWidth || 200, maxHeight: this.props.maxHeight || 200, borderRadius: 8 }}
                                    alt="imagePath"
                                />
                                <span style={{ position: "absolute", bottom: -30, left: 0, right: 0, padding: 5 }}>
                                    <DeleteIcon
                                        style={{ marginRight: 3, backgroundColor: "white", borderRadius: 50, padding: 10, color: "red", boxShadow: "0px 0px 5px 0px lightgrey", cursor: "pointer" }}
                                        onClick={(e) => { e.stopPropagation(); this.onImageSelect("", ""); }}
                                    />
                                    <IconView
                                        style={{ marginLeft: 3, backgroundColor: "white", borderRadius: 50, padding: 10, boxShadow: "0px 0px 5px 0px lightgrey", cursor: "pointer" }}
                                        onClick={() => window.open(this.props.imagePath)}

                                    />
                                </span>
                            </div>
                            {/* </div> */}
                        </React.Fragment>
                    }
                </div>
            </div>
        )
    }

}

export default withStyles(style)(ImageUpload);