import React from 'react'
import { Grid } from '@material-ui/core';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import { ISalesItem, IItem, IDiscount, IWindowState } from '../../Types/AllTypes';
import HyperForm from '../../Components/HyperInput/HyperForm';
import HyperAmountField from '../../Components/HyperInput/HyperAmountField';
import ItemAutoComplete from '../Item/ItemAutoComplete';
import scanRUtils from '../../Services/ScanRUtilService';
import HyperModal from '../../Components/HyperInput/HyperModal';
import BunchEntryModal from '../Purchases/BunchEntryModal';
import enums from '../../enums';
import SalesItemActionPopover from './SalesItemActionPopover';
import withWindowState from '../../Components/withWindowState';

interface SalesItemProps extends IWindowState {
    objSalesItem: ISalesItem
    frm: HyperForm
    focusedItemRef: any
    taxInvoiceType: string
    objDiscount: IDiscount

    removeItem?: () => void
    onComplete?: () => void
    onChange?: (objSalesItem: ISalesItem) => void
}

interface SalesItemState {
    // objSalesItem: ISalesItem
}


class SalesItem extends React.Component<SalesItemProps, SalesItemState> {
    constructor(props: SalesItemProps) {
        super(props);
        let state: SalesItemState = {};
        this.state = state;
        this.bunchEntryModal = new HyperModal(this);
    }
    bunchEntryModal: HyperModal


    setSalesItem = async (obj: Partial<ISalesItem>) => {
        if (this.props.onChange)
            this.props.onChange(obj as ISalesItem);
    }

    onBarcodeInput = (barcode: string) => {
        //PTH_NIK
        if (this.props.objSalesItem?.objItem?.maintainSerialWiseInventory) {
            this.setSalesItem({ lstSerialNo: [barcode] });
        }
    }
    onFinishBunchEntry = (lstSerialNo: string[]) => {
        this.props.setGlobal({ lstSerialNo: this.props.global.lstSerialNo.concat(lstSerialNo) })
        this.props.objSalesItem.lstSerialNo = lstSerialNo;
        this.props.objSalesItem.qty = lstSerialNo.length;
        this.props.objSalesItem.amount = scanRUtils.toFixedFloat(this.props.objSalesItem.objItem.defaultSalesPrice * lstSerialNo.length)
        this.forceUpdate();
    }

    render() {
        if (this.props.objSalesItem?.objItem?.isTaxInclusive) {
            if (this.props.objSalesItem.objItem) {
                this.props.objSalesItem.tax = scanRUtils.countReverseTax(this.props.objSalesItem.amount, scanRUtils.getTaxes(this.props.objSalesItem, this.props.taxInvoiceType), this.props.objSalesItem.qty);
                this.props.objSalesItem.priceAfterDiscount = scanRUtils.toFixedFloat((this.props.objSalesItem.amount - this.props.objSalesItem.tax) / this.props.objSalesItem.qty);
                this.props.objSalesItem.price = this.props.objSalesItem.priceAfterDiscount;
                this.props.objSalesItem.discountAmountPerItem = scanRUtils.countDiscountAmount(this.props.objSalesItem.price, this.props.objDiscount)
                this.props.objSalesItem.totalTaxableAmount = scanRUtils.toFixedFloat(this.props.objSalesItem.amount - this.props.objSalesItem.tax)
            }
        }
        else {
            this.props.objSalesItem.amount = scanRUtils.toFixedFloat(this.props.objSalesItem.price * this.props.objSalesItem.qty);
            if (this.props.objSalesItem.objItem) {
                this.props.objSalesItem.discountAmountPerItem = scanRUtils.countDiscountAmount(this.props.objSalesItem.price, this.props.objDiscount)
                this.props.objSalesItem.priceAfterDiscount = scanRUtils.toFixedFloat(this.props.objSalesItem.price - this.props.objSalesItem.discountAmountPerItem);

                this.props.objSalesItem.tax = scanRUtils.countTax(this.props.objSalesItem.priceAfterDiscount, scanRUtils.getTaxes(this.props.objSalesItem, this.props.taxInvoiceType), this.props.objSalesItem.qty);
                this.props.objSalesItem.amount = scanRUtils.toFixedFloat((this.props.objSalesItem.priceAfterDiscount * this.props.objSalesItem.qty) + this.props.objSalesItem.tax);
                this.props.objSalesItem.totalDiscountPerItem = scanRUtils.toFixedFloat(this.props.objSalesItem.discountAmountPerItem * this.props.objSalesItem.qty)
                this.props.objSalesItem.totalTaxableAmount = scanRUtils.toFixedFloat(this.props.objSalesItem.priceAfterDiscount * this.props.objSalesItem.qty)
            }
        }
        this.props.objSalesItem.isExemptedItem = scanRUtils.isExemptedItem(this.props.objSalesItem.taxes)

        return (
            <Grid container style={{ marginTop: 10 }} spacing={1}>
                <Grid item xs={3}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} >
                            <ItemAutoComplete
                                itemId={this.props.objSalesItem.objItem?.id}
                                label="Search Item"
                                onChange={(objItem: IItem) => {
                                    if (objItem) {
                                        // console.log(objItem, "objItem")
                                        if (objItem.isTaxInclusive)
                                            this.setSalesItem({ objItem: objItem, taxes: objItem.defaultTaxes, amount: objItem.defaultSalesPrice } as any)
                                        else
                                            this.setSalesItem({ objItem: objItem, taxes: objItem.defaultTaxes, price: objItem.defaultSalesPrice } as any)
                                    }
                                }}
                                onFocus={() => { this.props.focusedItemRef.current = this; }}
                                frm={this.props.frm}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={9}>
                    <Grid container spacing={1}>
                        <Grid item xs={2} style={{ margin: 'auto' }}>
                            {this.props.objSalesItem?.objItem?.maintainSerialWiseInventory ?
                                <HyperTextField
                                    required
                                    value={this.props.objSalesItem.lstSerialNo ? this.props.objSalesItem.lstSerialNo.join(',') : ""}
                                    label="Serial No"
                                    // onChange={(e: any) => { this.setSalesItem({ serialNo: e.target.value }) }}
                                    disabled={true}
                                    onFocus={() => { this.props.focusedItemRef.current = this; }}
                                    frm={this.props.frm}
                                />
                                :
                                <HyperTextField
                                    required
                                    value={this.props.objSalesItem.qty}
                                    label="Qty"
                                    onChange={(e: any) => { this.setSalesItem({ qty: parseFloat(e.target.value), amount: scanRUtils.toFixedFloat(this.props.objSalesItem.objItem.defaultSalesPrice * parseFloat(e.target.value || "1")) }) }}
                                    type="number"
                                    onFocus={() => { this.props.focusedItemRef.current = this; }}
                                    frm={this.props.frm}
                                />
                            }
                        </Grid>
                        <Grid item xs={2} style={{ margin: 'auto' }}>
                            <HyperAmountField
                                required
                                value={scanRUtils.toFixedFloat(this.props.objSalesItem.price)}
                                onChange={(e: any) => { this.setSalesItem({ price: parseFloat(e.target.value) }) }}
                                type="number"
                                label="Price"
                                onFocus={() => { this.props.focusedItemRef.current = this; }}
                                frm={this.props.frm}
                                disabled={this.props.objSalesItem?.objItem?.isTaxInclusive ? true : false}

                                infoText={`${scanRUtils.prettyPrice(this.props.objSalesItem.price)} - ${scanRUtils.prettyPrice(this.props.objSalesItem.discountAmountPerItem)} = ${scanRUtils.prettyPrice(this.props.objSalesItem.priceAfterDiscount)} (${this.props.objDiscount.discountPercentage}% Discount Applied).`}
                            />
                        </Grid>
                        <Grid item xs={2} style={{ margin: 'auto' }}>
                            <HyperAmountField
                                required
                                value={scanRUtils.toFixedFloat(this.props.objSalesItem.discountAmountPerItem)}
                                onChange={(e: any) => { this.setSalesItem({ discountAmountPerItem: parseFloat(e.target.value) }) }}
                                type="number"
                                label="Discount"
                                onFocus={() => { this.props.focusedItemRef.current = this; }}
                                frm={this.props.frm}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={2} style={{ margin: 'auto' }}>
                            <HyperAmountField
                                value={scanRUtils.toFixedFloat(this.props.objSalesItem.tax)}
                                label="Tax"
                                frm={this.props.frm}
                                disabled={true}

                                infoText={`${scanRUtils.countTotalTaxPercentage(scanRUtils.getTaxes(this.props.objSalesItem, this.props.taxInvoiceType))} Tax Applied.`}
                            />
                        </Grid>
                        <Grid item xs={3} style={{ margin: 'auto' }}>
                            <HyperAmountField
                                value={scanRUtils.toFixedFloat(this.props.objSalesItem.amount)}
                                label="Total"
                                frm={this.props.frm}

                                onChange={(e: any) => { this.setSalesItem({ amount: parseFloat(e.target.value) }) }}
                                disabled={this.props.objSalesItem?.objItem?.isTaxInclusive ? false : true}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <SalesItemActionPopover
                                onBatchBarcode={this.bunchEntryModal.toggle}
                                onAddDiscount={() => { }}
                                onDelete={this.props.removeItem}
                                showBatchEntry={this.props.objSalesItem?.objItem?.maintainSerialWiseInventory}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {(this.bunchEntryModal.isOpen()) &&
                    <BunchEntryModal
                        isOpen={this.bunchEntryModal.isOpen()}
                        cat={enums.ACCOUNT_CATEGORY.SALES}
                        toggleModal={this.bunchEntryModal.toggle}
                        onFinishBunchEntry={this.onFinishBunchEntry}
                        lstSerialNo={this.props.objSalesItem.lstSerialNo || []}
                    />
                }
            </Grid>
        );
    }
}

export default withWindowState(SalesItem, ["global"]);