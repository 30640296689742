import React, { FormEvent } from 'react'
import enums from '../../enums';
import CommonModal from '../../Components/CommonModal';
import { Grid, Button, } from '@material-ui/core';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import { ISales, IAccount, ISalesItem, Journal, IWindowState, IDiscount } from '../../Types/AllTypes';
import HyperForm from '../../Components/HyperInput/HyperForm';
import api from '../../Services/ApiServices';
import utils from '../../Services/Utils';
import AccountAutoComplete from '../Journal/AccountAutoComplete';
import SalesItems from './SalesItems';
import scanRUtils from '../../Services/ScanRUtilService';
import { JournalInsertObject } from '../../Types/InsertObjects';
import { JournalSearchOptions } from '../../Types/SearchOptions';
import withWindowState from '../../Components/withWindowState';
import { IconAdd } from '../../Components/Icons/Icons';
import PaymentItem from './PaymentItem';
import PaymentCard from './PaymentCard';
import HyperDateTimeField from '../../Components/HyperInput/HyperDateTimeField';
import HyperSelectField from '../../Components/HyperInput/HyperSelectField';
import SalesCustomerInfo from './SalesCustomerInfo';
import Alert from '@material-ui/lab/Alert';
import AddUpdateAttachment from './AddUpdateAttachment';
import InvoiceSelect from './InvoiceSelect';

interface AddUpdateSalesProps extends IWindowState {
    objSales?: ISales
    isOpen: boolean
    onComplete?: (e?: any) => void
    toggle: () => void
}

interface AddUpdateSalesState {
    objSales: ISales
    lstPayment: Journal[]
    currentObjPaymentIndex: number
    showAddPayment: boolean
    totalPaymentAmount: number
    salesActionList: any
    objDiscount: IDiscount
    showInModalError: boolean
    inModalErrorMsg: string
    isAddingExpense: boolean
}

const objDefaultSalesItem = {
    objItem: {},
    qty: 1,
    price: 0,
} as ISalesItem;

const SALES_TAX_TYPE_NAME: any = enums.SALES_TAX_TYPE_NAME;
const salesTaxType: any = Object.keys(SALES_TAX_TYPE_NAME).map((item: any) => {
    return { id: item, value: item, name: SALES_TAX_TYPE_NAME[item] }
})

class AddUpdateSales extends React.Component<AddUpdateSalesProps, AddUpdateSalesState> {
    constructor(props: AddUpdateSalesProps) {
        super(props);
        let state: AddUpdateSalesState = {
            objSales: {} as ISales,
            currentObjPaymentIndex: -1,
            lstPayment: [] as Journal[],
            showAddPayment: false,
            showInModalError: false,
            isAddingExpense: false,
            totalPaymentAmount: 0,
            inModalErrorMsg: "",
            salesActionList: [],
            objDiscount: { discountType: enums.DISCOUNT_OPTION.PERCENTAGE, discountAmount: 0, discountPercentage: 0 }
        };

        if (this.props.objSales) {
            state.objSales = this.props.objSales
            this.isEditing = true;
        }
        if (!state.objSales.date) {
            state.objSales.date = new Date().toString();
        }
        else {
            state.objSales.date = new Date(state.objSales.date).toString();
        }

        if (state.objSales.info) {
            state.objSales.lstSalesItem = [];
            state.objSales.INVNumber = state.objSales.info.INVNumber;
            state.objSales.invoiceTaxType = state.objSales.info.invoiceTaxType || enums.SALES_TAX_TYPE.LOCAL;
            // state.objSales.dueDate = moment(state.objSales.info.dueDate).format("YYYY-MM-DD hh:mm a");
            state.objSales.dueDate = state.objSales.info.dueDate || new Date().toString();;
            state.objSales.isPaid = state.objSales.info.isPaid;
            state.objSales.customerInfo = state.objSales.info.customerInfo;

            state.objDiscount.discountAmount = state.objSales.info.discountAmount;
            state.objDiscount.discountPercentage = state.objSales.info.discountPercentage;
            state.objDiscount.discountType = state.objSales.info.discountType;
        }
        else {
            state.objSales.lstSalesItem = [objDefaultSalesItem];
            state.objSales.dueDate = new Date().toString();
            state.objSales.invoiceTaxType = enums.SALES_TAX_TYPE.LOCAL
        }
        // console.log(state.objSales, "state.objSales")
        this.state = state;
        this.frm = new HyperForm();
    }
    frm: HyperForm
    isEditing: boolean = false;

    getReceipt = async () => {
        try {
            let so = new JournalSearchOptions();
            so.info = {
                "info.salesId": this.state.objSales.id
            };
            let receipts = await api.getJournals(so);
            if (receipts.length > 0) {
                let totalPaymentAmount = 0;
                receipts.forEach((item: any) => {
                    totalPaymentAmount = totalPaymentAmount + scanRUtils.toFixedFloat(item.amount);
                });;

                this.setState({ lstPayment: receipts, totalPaymentAmount });
            }
        }
        catch (ex) {
            // console.log("error while getting receipt0", ex)
            utils.showError(ex.message)
        }
    }

    async componentDidMount() {
        // let attachments = scanRUtils.getAttachmentList(enums.JOURNAL_CATEGORY.SALES);
        // // console.log(attachments, "attachments");
        let state = this.state;
        let lstSalesItem = null;

        if (state.objSales.info) {
            lstSalesItem = state.objSales.info.items;
            for (let objSalesItem of lstSalesItem) {
                objSalesItem.objItem = await scanRUtils.getItem(objSalesItem.id) as any;
            }
            state.objSales.lstSalesItem = lstSalesItem;
            this.forceUpdate();
        }

        if (this.isEditing) {
            this.getReceipt()
        }
        // document.addEventListener(enums.EVENT.Journal_ADD_UPDATE_MODEL, this.onModelToggle);
    }

    componentWillUnmount() {
        // document.removeEventListener(enums.EVENT.Journal_ADD_UPDATE_MODEL, this.onModelToggle);
    }

    setSales(obj: Partial<ISales>) {
        this.setState({ objSales: { ...this.state.objSales, ...obj } });
    }

    setPayment(obj: Partial<Journal>) {
        this.setState({ lstPayment: { ...this.state.lstPayment, ...obj } });
    }
    setDiscount = (obj: Partial<IDiscount>) => {
        this.setState({ objDiscount: { ...this.state.objDiscount, ...obj } });
    }

    onSubmit = async (e: FormEvent) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.frm.setIsFormSubmitted(true)
            if (this.frm.isValid()) {
                let objTotalCount = scanRUtils.getTotalCounts(this.state.objSales.lstSalesItem, this.state.objDiscount)
                this.state.objSales.amount = objTotalCount.amountWithTax;

                let io = new JournalInsertObject();
                io.id = this.state.objSales.id;
                io.debitAccountId = this.state.objSales.debitAccountId;
                let defaultSalesAccount: any = await scanRUtils.getDefaultAccountByCategory(enums.JOURNAL_CATEGORY.SALES);
                // console.log(defaultSalesAccount, "defaultSalesAccount")
                if (!this.state.objSales.creditAccountId && defaultSalesAccount)
                    io.creditAccountId = defaultSalesAccount.id
                io.amount = this.state.objSales.amount;
                io.comment = this.state.objSales.comment || " ";
                io.date = this.state.objSales.date;
                io.info = this.getInfo(this.state.objSales.lstSalesItem);
                io.category = enums.JOURNAL_CATEGORY.SALES;
                utils.showLoader();

                let lstPayment = await this.updatePaymentList(io);

                let result = null;
                result = await api.addUpdateSales(io, lstPayment)


                this.props.toggle();
                if (this.props.onComplete) {
                    this.props.onComplete(result);
                }
            }
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader()
        }
    }
    updatePaymentList = async (salesIo: JournalInsertObject) => {
        let actionList = [];
        for (let objPayment of this.state.lstPayment) {
            let io = new JournalInsertObject();
            io.id = objPayment.id;
            io.debitAccountId = objPayment.debitAccountId;
            io.creditAccountId = salesIo.debitAccountId; // Customer's account id
            io.amount = objPayment.amount;
            io.comment = objPayment.comment || "  ";
            io.date = objPayment.date;
            io.info = objPayment.info
            if (!io.id) {
                io.category = objPayment.debitAccount.category === enums.ACCOUNT_CATEGORY.EXPENSE ? enums.JOURNAL_CATEGORY.EXPENSE : enums.JOURNAL_CATEGORY.RECEIPT;
                actionList.push({ actionCode: enums.DB_ACTION.INSERT, data: io });
            }
            else if (io.id && (objPayment as any).isUpdated) {
                io.category = objPayment.category;
                actionList.push({ actionCode: enums.DB_ACTION.UPDATE, data: io });
            }
        }
        return actionList.concat(this.state.salesActionList)
    }

    getInfo(lstSalesItem: ISalesItem[]) {
        // console.log(lstSalesItem, "lstSalesItem")
        let obj: any = {};
        let objTotalCount = scanRUtils.getTotalCounts(this.state.objSales.lstSalesItem, this.state.objDiscount);
        obj = { ...objTotalCount };

        let lstAttachment = scanRUtils.getAttachmentList(enums.JOURNAL_CATEGORY.SALES);
        let stateInfo: any = this.state.objSales.info || {};
        if (lstAttachment && lstAttachment.length > 0) {
            lstAttachment.forEach((objAttachment: any) => {
                obj[objAttachment.key] = stateInfo[objAttachment.key];
            })
        }

        obj.discountPercentage = this.state.objDiscount.discountPercentage;
        obj.discountType = this.state.objDiscount.discountType;
        obj.amount = objTotalCount.amountWithTax;
        obj.INVNumber = this.state.objSales.INVNumber;
        obj.customerInfo = this.state.objSales.customerInfo;
        obj.invoiceTaxType = this.state.objSales.invoiceTaxType;
        obj.isIntraState = scanRUtils.isIntraState(this.state.objSales.invoiceTaxType)
        obj.dueDate = new Date(this.state.objSales.dueDate).toISOString();
        obj.paymentStatus = this.state.objSales.paymentStatus;

        obj.items = lstSalesItem.map(item => {
            let item1: any = item;
            item1.id = item.objItem.id;
            item1.name = item.objItem.name;
            item1.defaultDescription = item.objItem.defaultDescription;
            item1.showTaxOnInvoice = item.objItem.showTaxOnInvoice;
            item1.hsnCode = item.objItem.hsnCode;
            item1.productCode = item.objItem.productCode;
            item1.sacCode = item.objItem.sacCode;
            // delete item1.objItem;
            return item1;
        })
        return obj;
    }
    toggleShowPayment = () => {
        this.setState({ showAddPayment: !this.state.showAddPayment })
    }

    onPaymentSave = (objPayment: Journal, isEditing: boolean) => {
        if (isEditing) {
            (this.state.lstPayment as any)[this.state.currentObjPaymentIndex] = { ...objPayment, isUpdated: true };
        }
        else {
            this.state.lstPayment.push(objPayment)
        }
        const totalPaymentAmount = this.state.lstPayment.reduce((sum, { amount }) => sum + amount, 0);
        this.setState({ showAddPayment: false, totalPaymentAmount, currentObjPaymentIndex: -1 })
        // console.log(this.state)
    }

    isThisPaymentAmountValid = (currentAmount: number, isEditing: boolean) => {
        let currentTotalPaymentAmount = this.state.totalPaymentAmount + currentAmount;
        let objTotalCount = scanRUtils.getTotalCounts(this.state.objSales.lstSalesItem, this.state.objDiscount)
        if (isEditing)
            currentTotalPaymentAmount = currentTotalPaymentAmount - this.state.lstPayment[this.state.currentObjPaymentIndex].amount;
        if (scanRUtils.toFixedFloat(currentTotalPaymentAmount) <= scanRUtils.toFixedFloat(objTotalCount.amountWithTax)) {
            return true;
        }
        return false;
    }
    onEditPayment = (currentObjPaymentIndex: number) => {
        this.setState({ currentObjPaymentIndex, showAddPayment: true })
    }
    onDeletePayment = (objPaymentIndex: number) => {
        let objPayment = this.state.lstPayment[objPaymentIndex];
        this.state.salesActionList.push({ actionCode: enums.DB_ACTION.DELETE, data: { id: objPayment.id } });
        this.state.lstPayment.splice(objPaymentIndex, 1);
        this.setState({ totalPaymentAmount: this.state.totalPaymentAmount - scanRUtils.toFixedFloat(objPayment.amount) })
    }

    getDueAmount = () => {
        let itemTotals = scanRUtils.getTotalCounts(this.state.objSales.lstSalesItem, this.state.objDiscount)
        return scanRUtils.toFixedFloat(itemTotals.amountWithTax - this.state.totalPaymentAmount);
    }

    validateInvoiceTaxType = (invoiceTaxType: string) => {
        // console.log(invoiceTaxType, this.state.objSales)
        let customerInfo = this.state.objSales.customerInfo;
        if (!customerInfo)
            return false;
        let isIntraState = scanRUtils.isIntraState(customerInfo.info?.state)
        if (isIntraState) {
            // this.setState({ showInModalError: true })
        }
    }
    render() {
        return (
            <div>
                <CommonModal
                    dialogProps={{
                        open: this.props.isOpen
                    }}
                    maxWidth="lg"
                    title={"Sales"}
                    toggle={this.props.toggle}
                    isFullScreen={true}
                    onSave={this.onSubmit}
                    disableOnSave={this.state.showAddPayment ? true : false}
                // disableEscapeKey={true}
                // primaryTitle={true}
                >

                    <form onSubmit={this.onSubmit}>
                        <Grid container spacing={2} style={{ marginTop: 25 }}>
                            {!!(this.state.showInModalError) &&
                                <Grid item xs={12}>
                                    <Alert style={{ marginBottom: 20 }} severity="error">{this.state.inModalErrorMsg}</Alert>
                                </Grid>
                            }
                            <Grid item xs={6}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <AccountAutoComplete
                                            accountId={this.state.objSales.debitAccountId}
                                            onChange={(objAccount: IAccount) => {
                                                if (objAccount)
                                                    this.setSales({ debitAccountId: objAccount.id, customerInfo: objAccount });
                                            }}
                                            label="Customer"
                                            frm={this.frm}
                                            required
                                            autoFocus
                                            category={enums.ACCOUNT_CATEGORY.CUSTOMER}
                                            addBtnLabel="Add New Customer"
                                            autoCompleteAddModalType={enums.AUTO_COMPLETE_ADD_TYPE.ADD_CUSTOMER}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <HyperDateTimeField
                                            required
                                            selected={this.state.objSales.date}
                                            onChange={(date: any) => { this.setSales({ date }) }}
                                            label={"Date"}
                                            type="date"
                                            frm={this.frm}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <HyperDateTimeField
                                            required
                                            selected={this.state.objSales.dueDate}
                                            onChange={(dueDate: any) => { this.setSales({ dueDate }) }}
                                            label={"Due Date"}
                                            type="date"
                                            frm={this.frm}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <HyperSelectField
                                            labelWidth={120}
                                            required
                                            selectedValue={this.state.objSales.invoiceTaxType}
                                            onChange={(e: any) => { this.setSales({ invoiceTaxType: e.target.value }); this.validateInvoiceTaxType(e.target.value) }}
                                            options={salesTaxType}
                                            optionIdField={"value"}
                                            optionNameField={"name"}
                                            label="Invoice Tax Type"
                                            fullWidth
                                            frm={this.frm}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <InvoiceSelect
                                            value={this.state.objSales.INVNumber}
                                            label={"Invoice Number"}
                                            disabled={this.isEditing}
                                            isEdit={this.isEditing}
                                            onChange={(value: any) => { this.setSales({ INVNumber: value }) }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {(this.state.objSales.customerInfo) &&
                                <Grid item xs={12} md={6}>
                                    <SalesCustomerInfo customerInfo={this.state.objSales.customerInfo} title={"Customer Info"} />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <SalesItems
                                    lstSalesItem={this.state.objSales.lstSalesItem}
                                    frm={this.frm}
                                    objDiscount={this.state.objDiscount}
                                    setDiscount={this.setDiscount}
                                    totalPaymentAmount={this.state.totalPaymentAmount}
                                    taxInvoiceType={this.state.objSales.invoiceTaxType}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <HyperTextField
                                    value={this.state.objSales.comment}
                                    onChange={(e: any) => { this.setSales({ comment: (e.target.value) }) }}
                                    multiline
                                    rows={2}
                                    label={"Comment"}
                                    type="text"
                                    frm={this.frm}
                                />
                            </Grid>
                            {(!this.state.showAddPayment) &&
                                <Grid item xs={12} style={{ textAlign: 'center', paddingTop: 20 }}>
                                    <Button variant="contained" color="default" onClick={async () => { await this.setState({ isAddingExpense: false }); this.toggleShowPayment() }}>
                                        <IconAdd />
                                        Payment
                                    </Button> {" "}
                                    <Button variant="contained" color="default" onClick={async () => { await this.setState({ isAddingExpense: true }); this.toggleShowPayment() }}>
                                        <IconAdd />
                                        Expense
                                    </Button>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {this.state.lstPayment.map((objPayment: any, index: number) => {
                                        // console.log(objPayment);
                                        return (
                                            <Grid item xs={6} key={index}>
                                                <PaymentCard
                                                    objPayment={objPayment}
                                                    onEdit={async () => {
                                                        await this.setState({ isAddingExpense: objPayment.category === enums.JOURNAL_CATEGORY.EXPENSE ? true : false });
                                                        this.onEditPayment(index);
                                                    }}
                                                    onDelete={() => { this.onDeletePayment(index); }}
                                                />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>

                            {this.state.showAddPayment &&
                                <Grid item xs={12}>
                                    <PaymentItem
                                        key={this.state.currentObjPaymentIndex}
                                        onRemoveItem={this.toggleShowPayment}
                                        isAddingExpense={this.state.isAddingExpense}
                                        onSave={this.onPaymentSave}
                                        totalDueAmount={this.getDueAmount()}
                                        objPayment={this.state.lstPayment[this.state.currentObjPaymentIndex]}
                                        checkPaymentAmount={this.isThisPaymentAmountValid}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <AddUpdateAttachment
                                    objInfo={this.state.objSales.info || {}}
                                    journalCategory={enums.JOURNAL_CATEGORY.SALES}
                                    onAttachmentDone={(key: string, url: string) => {
                                        let info: any = this.state.objSales.info || {};
                                        info[key] = url;
                                        this.setSales({ info });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </CommonModal>
            </div>
        );
    }
}

export default withWindowState(AddUpdateSales, ["companySettings", "user"])