import React from 'react'
import { withTheme, Theme, Grid, Paper } from '@material-ui/core'
import HyperModal from '../../../Components/HyperInput/HyperModal';
import utils from '../../../Services/Utils';

interface DOCSProps {
    theme: Theme
    objDOCS: any
}

interface DOCSState { }

class DOCS extends React.Component<DOCSProps, DOCSState> {
    constructor(props: DOCSProps) {
        super(props);
        let state: DOCSState = {};
        this.state = state;
        this.modal = new HyperModal(this);
    }

    modal: HyperModal;
    componentDidMount() {

    }

    render() {
        let { totalNumber, outwardSupply, creditNote, debitNote, totalCancelled } = this.props.objDOCS;
        totalNumber = outwardSupply.totalNumber + creditNote.totalNumber + debitNote.totalNumber;
        totalCancelled = outwardSupply.totalCancelled + creditNote.totalCancelled + debitNote.totalCancelled;
        return (
            <Grid container style={{ marginBottom: 20, marginTop: 20 }} spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={3} style={{ paddingTop: 30, paddingBottom: 30, padding: 20 }}>
                        <div style={{ display: 'inline-block' }}>
                            <span>Total Number</span><br />
                            <b>{totalNumber}</b>
                        </div>
                        <div style={{ display: 'inline-block', paddingLeft: 25 }}>
                            <span>Total Cancelled</span><br />
                            <b>{totalCancelled}</b>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={3} style={{ paddingTop: 30, paddingBottom: 30, padding: 20 }}>
                        <table {...{ border: 1 }} style={{ width: '100%' }} cellPadding={5} cellSpacing={0}>
                            <thead>
                                <tr>
                                    <th>Nature of Document</th>
                                    <th>Sr. No From</th>
                                    <th>Sr. No To</th>
                                    <th>Total Number</th>
                                    <th>Cancelled</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Invoices for outward supply</td>
                                    <td>{utils.getInvoiceNumberWithPrefix(outwardSupply.fromNo)}</td>
                                    <td>{utils.getInvoiceNumberWithPrefix(outwardSupply.toNo)}</td>
                                    <td>{outwardSupply.totalNumber}</td>
                                    <td>{outwardSupply.totalCancelled}</td>
                                </tr>
                                <tr>
                                    <td>Credit Note</td>
                                    <td>CN-{creditNote.fromNo}</td>
                                    <td>CN-{creditNote.toNo}</td>
                                    <td>{creditNote.totalNumber}</td>
                                    <td>{creditNote.totalCancelled}</td>
                                </tr>
                                <tr>
                                    <td>Debit Note</td>
                                    <td>DN-{debitNote.fromNo}</td>
                                    <td>DN-{debitNote.toNo}</td>
                                    <td>{debitNote.totalNumber}</td>
                                    <td>{debitNote.totalCancelled}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Paper>
                </Grid>
            </Grid>

        );
    }
}

export default withTheme(DOCS);