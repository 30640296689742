import { Container, Grid, Paper, IconButton, InputBase } from '@material-ui/core';
import React from 'react'
import { IconSearch } from '../../Components/Icons/Icons';
import { IWindowState } from '../../Types/AllTypes';
import { HyperTheme } from '../../Theme/Theme';
import HyperModal from '../../Components/HyperInput/HyperModal';
import SnTransactionsModal from './SnTransactionsModal';
import withWindowState from '../../Components/withWindowState';

interface SearchBarProps extends IWindowState { }
interface SearchBarState {
    searchText: string
}

class SearchBar extends React.Component<SearchBarProps, SearchBarState> {
    constructor(props: SearchBarProps) {
        super(props);
        let state: SearchBarState = {
            searchText: "",

        }
        this.state = state;
        this.snTransactionModal = new HyperModal(this);
    }
    snTransactionModal: HyperModal;

    render() {
        if (!this.props.companySettings.showItemSerialNo)
            return null;
        return (
            <Container>

                <Grid container style={{ justifyContent: 'center', paddingTop: 20, paddingBottom: 20 }} >
                    <Grid item xs={12} md={6}>
                        <Paper component="form" onSubmit={(e: any) => { e.preventDefault(); this.snTransactionModal.toggle() }} elevation={3} style={{
                            padding: '2px 4px',
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <InputBase
                                style={{
                                    marginLeft: HyperTheme.spacing(1),
                                    flex: 1
                                }}
                                value={this.state.searchText}
                                onChange={(e: any) => this.setState({ searchText: e.target.value })}
                                placeholder="Search Here..."
                                inputProps={{ 'aria-label': 'search google maps' }}
                            />
                            <IconButton type="submit" aria-label="search" style={{ padding: 10 }} >
                                <IconSearch />
                            </IconButton>
                        </Paper>
                    </Grid>
                </Grid>

                {(this.snTransactionModal.isOpen()) &&
                    <SnTransactionsModal
                        isOpen={this.snTransactionModal.isOpen()}
                        toggleModal={this.snTransactionModal.toggle}
                        searchText={this.state.searchText}
                    />
                }
            </Container>
        );
    }
}

export default withWindowState(SearchBar, ["companySettings"]);