import React from 'react';
import { Box } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

interface ScrollToTopButtonProps {
    hapDetailTabRef?: React.RefObject<HTMLDivElement>
}
interface ScrollToTopButtonState {
    isButtonShown: boolean
}

class ScrollToTopButton extends React.Component<ScrollToTopButtonProps, ScrollToTopButtonState> {
    scrollElement: any = document;
    constructor(props: ScrollToTopButtonProps) {
        super(props)
        this.state = {
            isButtonShown: false
        }
    }
    componentDidMount() {
        if (this.props.hapDetailTabRef?.current) {
            this.scrollElement = this.props.hapDetailTabRef.current;
        }

        this.scrollElement.addEventListener("scroll", this.onScroll.bind(this));
    }
    componentWillUnmount() {
        this.scrollElement.removeEventListener("scroll", this.onScroll);
    }

    onScroll() {
        if (!this.props.hapDetailTabRef) {
            if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > (window.innerHeight / 2)) {
                this.setState({ isButtonShown: true })
            } else {
                this.setState({ isButtonShown: false })
            }
        }
        else {
            if (this.scrollElement.scrollTop > (this.scrollElement.clientHeight / 2)) {
                this.setState({ isButtonShown: true })
            } else {
                this.setState({ isButtonShown: false })
            }
        }
    }

    scrollToTop = () => {
        if (!this.props.hapDetailTabRef)
            window.scrollTo({ top: 0, behavior: 'smooth' });
        else
            this.scrollElement.scrollTo({ top: 0, behavior: 'smooth' });
    }

    render() {
        if (!this.state.isButtonShown)
            return null;

        return (
            <Box id="hmd2" onClick={this.scrollToTop} padding={1} zIndex={100}
                position={this.props.hapDetailTabRef ? "absolute" : "fixed"}
                bottom={55} right={10}
                color="common.white" borderRadius={7} style={{ backgroundColor: 'red', cursor: 'pointer' }}>
                <ArrowUpwardIcon />
            </Box>
        );
    }
}

export default ScrollToTopButton;
