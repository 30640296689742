import React from 'react'
import { ISales, ISalesInvoiceTemplate, ISalesInvoiceTemplateItem,  ICompanySettings, ICustomer } from '../../Types/AllTypes';
import { Grid, Button, IconButton } from '@material-ui/core';
import HyperSelectField from '../../Components/HyperInput/HyperSelectField';
import scanRUtils, { winState } from '../../Services/ScanRUtilService';
import utils from '../../Services/Utils';
import api from '../../Services/ApiServices';
import HalfPageTemplate from './templates/HalfPageTemplate';
import FullPageTemplate from './templates/FullPageTemplate';
import ImageHeaderTemplate from './templates/ImageHeaderTemplate';
import Sticker4X6Template from './templates/Sticker4X6Template';
import { IconWhatsApp } from '../../Components/Icons/Icons';
import GSTTemplate1 from './templates/GSTTemplate1';
import GSTMiniTemplate1 from './templates/GSTMiniTemplate1';
import InvoiceIFrame from './InvoiceIFrame';


interface InvoiceProps {
    objSales: ISales
    objCompany: ICompanySettings
    objCustomer: ICustomer
    showTemplateSelection?: boolean
    isReport?: boolean
}
interface InvoiceState {
    template: string
    htmlTemplate: any
}

class Invoice extends React.Component<InvoiceProps, InvoiceState> {
    constructor(props: InvoiceProps) {
        super(props);
        let state: InvoiceState = {
            template: winState.state.companySettings.defaultInvoiceTemplate || "fullPageTemplate",
            htmlTemplate: ""
        };
        this.state = state;
    }

    componentDidMount() {
        if (this.state.template === "template5")
            this.getTemplate();
    }

    printInvoice = () => {
        var iFrame: any = document.getElementById("invoiceIFrame")
        iFrame.contentWindow?.focus(); //IE fix
        iFrame.contentWindow?.print();
    }

    async getTemplate() {
        if (this.state.template !== "template5")
            return;
        let html = "";
        try {

            const file = require("./templates/" + this.state.template);
            html += file.default(this.getSalesInvoiceObject());
            html = this.getExtraStyles() + html;
            await this.setState({ htmlTemplate: html })
            // return html;
        }
        catch (ex) {
            // console.log(ex);
            html += "Error occurred";
            await this.setState({ htmlTemplate: html })
        }
    }

    getSalesInvoiceObject() {
        try {


            let company = this.props.objCompany;
            let objSales = this.props.objSales;
            let objCustomer = this.props.objCustomer;

            let obj: ISalesInvoiceTemplate = {
                companyLogoURL: company.companyLogoURL,
                companyName: company.companyName,
                companyAddress: company.address,
                companyMobile: company.companyPhone,
                companyMobile1: company.companyPhone1,
                companyEmail: company.companyEmail,
                companyGST: company.gstNo,
                companySignatureURL: company.companySignatureURL,
                companyTerms: company.companyTerms ? company.companyTerms.split("\n") : [],
                showItemSerialNo: company.showItemSerialNo,

                customerName: objCustomer?.name || "Customer",
                customerAddress: "",
                shipTo: "",
                pos: "",
                customerMobile: objCustomer.info?.mobile || "",
                customerEmail: objCustomer.info?.email || "",
                customerGST: objCustomer.info?.GST || "",
                invoiceNumber: utils.getInvoiceNumberWithPrefix(objSales?.info.INVNumber) || "000000",

                dateOfInvoice: objSales?.date ? utils.convertToSystemDateFormate(new Date(objSales?.date)) : "",
                dueDateOfInvoice: objSales?.info.dueDate ? utils.convertToSystemDateFormate(new Date(objSales?.info.dueDate)) : "",
                totalAmount: objSales?.info.amount ? scanRUtils.toFixedFloat(objSales?.info.amount) : scanRUtils.toFixedFloat(0),
                totalTax: objSales?.info.tax ? scanRUtils.toFixedFloat(objSales?.info.tax) : scanRUtils.toFixedFloat(0),
                totalAmountWithTax: objSales?.info.amountWithTax ? scanRUtils.toFixedFloat(objSales?.info.amountWithTax) : scanRUtils.toFixedFloat(0),
                totalDueAmount: objSales?.info.totalDueAmount ? scanRUtils.toFixedFloat(objSales?.info.totalDueAmount) : scanRUtils.toFixedFloat(0),
                totalPaidAmount: objSales?.info.totalDueAmount ? scanRUtils.toFixedFloat(objSales?.info.amountWithTax - objSales?.info.totalDueAmount) : scanRUtils.toFixedFloat(0),

                subTotal: objSales?.info.subTotal ? scanRUtils.toFixedFloat(objSales?.info.subTotal) : scanRUtils.toFixedFloat(0),
                totalAfterDiscount: objSales?.info.totalAfterDiscount ? scanRUtils.toFixedFloat(objSales?.info.totalAfterDiscount) : scanRUtils.toFixedFloat(0),
                totalDiscount: objSales?.info.discountAmount ? scanRUtils.toFixedFloat(objSales?.info.discountAmount) : scanRUtils.toFixedFloat(0),
                discountPercentage: objSales?.info.discountPercentage ? objSales?.info.discountPercentage : 0,
                note: objSales?.comment ? objSales?.comment : "",

                // note?: objs,
                items: [],
                showIGst: false,
                totalIGst: scanRUtils.toFixedFloat(0),
                totalCGst: scanRUtils.toFixedFloat(0),
                totalSGst: scanRUtils.toFixedFloat(0),
                totalQty: 0,
                totalCGSTPercentage: 0,
                totalSGSTPercentage: 0,
                totalIGSTPercentage: 0,
                totalAmountInWord: objSales?.info.amountWithTax ? utils.convertPriceToWord(parseInt(objSales?.info.amountWithTax.toString())) : "",
                totalGSTInWord: ""
            };
            if (objSales?.info.items) {
                obj.items = objSales?.info.items.map((item: any) => {
                    console.log(item, ", fontSize: ")
                    let objItem: ISalesInvoiceTemplateItem = {
                        itemName: item.name,
                        itemPrice: item.price ? scanRUtils.toFixedFloat(item.price) : scanRUtils.toFixedFloat(0),
                        itemAmount: item.amount ? scanRUtils.toFixedFloat(item.amount) : scanRUtils.toFixedFloat(0),
                        itemTax: item.tax ? scanRUtils.toFixedFloat(item.tax) : scanRUtils.toFixedFloat(0),
                        itemQty: item.qty,
                        itemHSN: item.hsnCode,
                        itemSAC: item.sacCode,
                        itemDefaultDescription: item.defaultDescription,
                        itemSN: item.lstSerialNo && item.lstSerialNo.length > 0 ? item.lstSerialNo.join(', ') : "",
                        showTaxOnInvoice: item.showTaxOnInvoice,

                        itemCGSTAmount: item.tax ? scanRUtils.toFixedFloat(item.tax / 2) : scanRUtils.toFixedFloat(0),
                        itemCGSTPercentage: scanRUtils.toFixedFloat(parseFloat(scanRUtils.countTotalTaxPercentage(item.taxes)) / 2),

                        itemSGSTAmount: item.tax ? scanRUtils.toFixedFloat(item.tax / 2) : scanRUtils.toFixedFloat(0),
                        itemSGSTPercentage: scanRUtils.toFixedFloat(parseFloat(scanRUtils.countTotalTaxPercentage(item.taxes)) / 2),

                        itemIGSTAmount: item.tax ? scanRUtils.toFixedFloat(item.tax) : scanRUtils.toFixedFloat(0),
                        itemIGSTPercentage: scanRUtils.toFixedFloat(parseFloat(scanRUtils.countTotalTaxPercentage(item.taxes))),
                    };
                    obj.totalQty += item.qty;
                    // console.log(objItem.itemDefaultDescription, "objItem.itemDefaultDescription")
                    return objItem;
                });
            }

            if (objCustomer.info?.addressLine1) {
                let address = `${objCustomer.info?.addressLine1 || ""},
                            ${objCustomer.info?.addressLine2 || ""},
                            ${objCustomer.info?.city || ""}-${objCustomer.info?.pincode || ""},
                            ${objCustomer.info?.state || ""}`
                obj.customerAddress = address;
            }
            if (!objCustomer.info?.isSameAsBillTo) {
                let address = `${objCustomer.info?.addressLine1 || ""},
                            ${objCustomer.info?.addressLine2 || ""},
                            ${objCustomer.info?.city || ""}-${objCustomer.info?.pincode || ""},
                            ${objCustomer.info?.state || ""}`
                obj.shipTo = address;
            }

            if ((objCustomer.info?.state && objCustomer.info?.state === company.state) || !objCustomer.info?.state) {
                obj.showIGst = false;
                obj.pos = scanRUtils.getPlaceOfSupply(company.state)
                let totalTax = objSales?.info.tax;

                if (totalTax) {
                    obj.totalCGSTPercentage = scanRUtils.toFixedFloat(parseFloat(scanRUtils.countTotalTaxPercentage(this.props.objSales.info.items[0].taxes)) / 2);
                    obj.totalSGSTPercentage = scanRUtils.toFixedFloat(parseFloat(scanRUtils.countTotalTaxPercentage(this.props.objSales.info.items[0].taxes)) / 2);

                    obj.totalCGst = scanRUtils.toFixedFloat(totalTax / 2)
                    obj.totalSGst = scanRUtils.toFixedFloat(totalTax / 2)
                }
            }
            else {
                obj.showIGst = true;
                obj.pos = scanRUtils.getPlaceOfSupply(objCustomer.info?.state)
                obj.totalIGSTPercentage = scanRUtils.toFixedFloat(parseFloat(scanRUtils.countTotalTaxPercentage(this.props.objSales.info.items[0].taxes)));
                obj.totalIGst = obj.totalTax
            }
            // console.log(obj, "obj", this.state)
            return obj;
        }
        catch (ex) {
            console.log(ex);
            utils.showError(ex.message)
        }
    }

    sendSMS = async () => {
        try {
            let salesId = this.props.objSales?.id;
             await api.sendSalesSMS(salesId || 0);
            utils.showSuccess("SMS Sent")
        }
        catch (ex) {
            console.log(ex)
            utils.showError(ex.message)
        }

    }

    onCompleteRender = (html: string) => {
        // console.log(html);
        html = this.getExtraStyles() + html;
        this.setState({ htmlTemplate: html })
    }

    getExtraStyles = () => {
        let html = ""
        html += `
        <style>
            body {
                -webkit-print-color-adjust: exact !important;
                
            }
            @media print { 
                @page { 
                    margin-top: 0; 
                    margin-bottom: 0;
                    margin:0;
                } 
                
            }
            .tblBill {
                border-collapse: collapse;
            }
    
            table {
                page-break-inside: auto; !important;
                border-collapse: collapse !important;
            }
    
            tr {
                page-break-inside: avoid;
                page-break-after: auto
            }
    
            thead {
                display: table-header-group
            }
    
            tfoot {
                display: table-footer-group
            }
    
            .tblBill td {
                border-right: 1px solid black;
                border-left: 1px solid black;
                border-collapse: collapse;
            }
    
            .tblBill th {
                border: 1px solid black;
                border-collapse: collapse;
            } 
        </style>
        `
        return html;
    }
    sendWAMessage = () => {
        let objCustomer = this.props.objCustomer;
        if (objCustomer.info.mobile)
            utils.sendWAMessage(this.makeSalesMessage(), objCustomer.info.mobile)
        else
            utils.showError("Please enter customer mobile number.");
    }
    makeSalesMessage = () => {
        let company = this.props.objCompany;
        let objSales = this.props.objSales;


        let invoiceLink = `${window.location.origin}/invoice/${objSales._id}`;
        const invoiceNumber = utils.getInvoiceNumberWithPrefix(objSales.info.INVNumber)
        return encodeURIComponent(`Thank you for your business with *${company.companyName}*. Your Invoice Number *${invoiceNumber}* of *Rs.${scanRUtils.toFixedFloat(objSales.amount)}* View Invoice ${invoiceLink} \n\n*${company.companyName}*`);

    }
    render() {
        // const { objSales } = this.props;
        // console.log(objSales)
        // if(this.props.isReport){
        //     return (

        //     )
        // }
        return (
            <>
                <Grid container spacing={2}>
                    {!this.props.isReport &&
                        <React.Fragment>
                            <Grid item xs={6} style={{ textAlign: 'left' }}>
                                {this.props.showTemplateSelection &&
                                    <HyperSelectField
                                        options={scanRUtils.getInvoiceTemplateList()}
                                        optionIdField="link"
                                        optionNameField="name"
                                        labelWidth={120}
                                        selectedValue={this.state.template}
                                        label="Select Template"
                                        onChange={async (event: any) => { await this.setState({ template: event.target.value }); this.getTemplate() }}
                                    />
                                }
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                <IconButton onClick={this.sendWAMessage}>
                                    <IconWhatsApp />
                                </IconButton>
                                <Button onClick={() => { this.sendSMS() }} color="primary" variant="contained" >SMS</Button>{" "}
                                <Button onClick={() => { this.printInvoice() }} color="primary" variant="contained" >Print</Button>{" "}
                            </Grid>
                        </React.Fragment>
                    }
                    <Grid item xs={12} style={{ width: '100%' }}>

                        <InvoiceIFrame htmlTemplate={this.state.htmlTemplate} />

                        {(this.state.template === "miniTemplate") &&
                            <HalfPageTemplate onComplete={this.onCompleteRender} objInvoice={this.getSalesInvoiceObject()} />
                        }
                        {(this.state.template === "fullPageTemplate") &&
                            <FullPageTemplate onComplete={this.onCompleteRender} objInvoice={this.getSalesInvoiceObject()} />
                        }
                        {(this.state.template === "GSTTemplate1") &&
                            <GSTTemplate1 onComplete={this.onCompleteRender} objInvoice={this.getSalesInvoiceObject()} />
                        }
                        {(this.state.template === "GSTMiniTemplate1") &&
                            <GSTMiniTemplate1 onComplete={this.onCompleteRender} objInvoice={this.getSalesInvoiceObject()} />
                        }
                        {(this.state.template === "ImageHeaderTemplate") &&
                            <ImageHeaderTemplate onComplete={this.onCompleteRender} objInvoice={this.getSalesInvoiceObject()} />
                        }
                        {(this.state.template === "Sticker4X6Template") &&
                            <Sticker4X6Template onComplete={this.onCompleteRender} objInvoice={this.getSalesInvoiceObject()} />
                        }

                    </Grid>
                </Grid>
            </>
        );
    }
}

export default Invoice;
