import { Paper, List, ListItem, ListItemText, Typography, ListItemSecondaryAction, Tooltip, Button } from '@material-ui/core';
import React from 'react'
import { IconTextSms } from '../../Components/Icons/Icons';
import { IWindowState, ISales } from '../../Types/AllTypes';
import { HyperTheme } from '../../Theme/Theme';
import withWindowState from '../../Components/withWindowState';
import utils from '../../Services/Utils';
import scanRUtils from '../../Services/ScanRUtilService';
import { Link } from 'react-router-dom';

interface DueSalesProps extends IWindowState {
    lstDueSales: ISales[]
}
interface DueSalesState {
    searchText: string
}

class DueSales extends React.Component<DueSalesProps, DueSalesState> {
    constructor(props: DueSalesProps) {
        super(props);
        let state: DueSalesState = {
            searchText: "",

        }
        this.state = state;
    }
    render() {
        return (
            <Paper elevation={3} style={{ marginTop: 25 }}>
                <List>
                    <ListItem divider style={{ backgroundColor: HyperTheme.palette.secondary.light, color: HyperTheme.palette.common.white }} >
                        <ListItemText
                            primary={<Typography>Due Customer Sales Invoice</Typography>}
                        />
                        <ListItemSecondaryAction>
                            <Tooltip title={`Show All`} placement="top">
                                <Link to={`/reports/billsReceivable`}>
                                    <Button variant="text" style={{ color: HyperTheme.palette.common.white }}>Show All</Button>
                                </Link>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </ListItem>
                    {this.props.lstDueSales.map((objSales: ISales, index: number) => {
                        return (
                            <ListItem key={index} divider style={{ paddingTop: 0, paddingBottom: 0 }}>
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle2">
                                            {`${objSales.debitAccount.name} | ${utils.getInvoiceNumberWithPrefix(objSales.info.INVNumber)}`}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography variant="caption">
                                            Due Date: {utils.convertToSystemDateFormate(objSales.info.dueDate)} | Due Amount: <b>{scanRUtils.prettyPrice(objSales.info.totalDueAmount)}</b>
                                        </Typography>
                                    }
                                />
                                {objSales.debitAccount.info?.mobile &&
                                    <ListItemSecondaryAction>
                                        <Tooltip title={`Send SMS to ${objSales.debitAccount.name}`} placement="top">
                                            <IconTextSms style={{ fontSize: 20, cursor: 'pointer' }} onClick={() => scanRUtils.sendPaymentRemainderSMS(objSales)} />
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                }
                            </ListItem>
                        )
                    })}
                </List>
            </Paper>
        );
    }
}

export default withWindowState(DueSales, ["companySettings"]);