import { Button, Grid } from "@material-ui/core";
import moment from "moment";
import React, { FormEvent } from "react";
import CommonModal from "../../Components/CommonModal";
import DataTable from "../../Components/DataTable";
import HyperForm from "../../Components/HyperInput/HyperForm";
import HyperSelectField from "../../Components/HyperInput/HyperSelectField";
import HyperTextField from "../../Components/HyperInput/HyperTextField";
import enums from "../../enums";
import api from "../../Services/ApiServices";
import { winState } from "../../Services/ScanRUtilService";
import utils from "../../Services/Utils";
import { IAccount, IImportLine, Journal } from "../../Types/AllTypes";
import { JournalInsertObject } from "../../Types/InsertObjects";
import AccountAutoComplete from "./AccountAutoComplete";
import {
  iciciCreditCardStatement,
  readBobXLSStatement,
  readKotakStatement,
  readSbiXLSStatement,
} from "./statementReader";

interface AddBulkJournalProps {
  lstJournal?: Journal[];
  isOpen: boolean;
  onComplete?: () => void;
  toggle: () => void;
}

interface AddBulkJournalState {
  objJournal: Journal;
  objCashAccount?: IAccount;
  lstLines?: IImportLine[];
}

const initialJournal: Journal = {
  _id: "",
  debitAccountId: 0,
  creditAccountId: 0,
  amount: 0,
  comment: "",
  date: "",
  createdAt: "",
  username: "",
  id: 0,
  debitAccount: {} as IAccount,
  creditAccount: {} as IAccount,
} as Journal;

class AddBulkJournal extends React.Component<
  AddBulkJournalProps,
  AddBulkJournalState
> {
  constructor(props: AddBulkJournalProps) {
    super(props);
    console.log(props);
    let state: AddBulkJournalState = {
      objJournal: initialJournal,
    };

    this.state = state;
    this.frm = new HyperForm();
  }
  frm: HyperForm;
  isEditing: boolean = false;

  componentDidMount() {
    // document.addEventListener(enums.EVENT.Journal_ADD_UPDATE_MODEL, this.onModelToggle);
  }

  componentWillUnmount() {
    // document.removeEventListener(enums.EVENT.Journal_ADD_UPDATE_MODEL, this.onModelToggle);
  }

  onModelToggle = (event?: any) => {
    this.props.toggle();
  };

  setJournal(obj: Partial<Journal>) {
    this.setState({ objJournal: { ...this.state.objJournal, ...obj } });
  }

  onSubmit = async (e: FormEvent) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      this.frm.setIsFormSubmitted(true);
      //   console.log("submit, ", this.state.lstLines);
      if (!this.state.lstLines) throw new Error("Upload file first");
      let filteredLines = this.state.lstLines
        .filter((item: any) => item?.tableData?.checked)
        .map((item) => {
          let io = new JournalInsertObject();
          io.debitAccountId = item.debitAccount?.id;
          io.creditAccountId = item.creditAccount?.id;
          io.amount = item.amount;
          io.comment = item.comment;
          io.date = moment(item.date).format("YYYY-MM-DD");
          io.category = item.category;
          io.id = 0;
          return io;
        });
      //   console.log("checked", filteredLines, this.frm.isValid());
      if (this.frm.isValid()) {
        let results = await Promise.all(
          filteredLines.map(async (io) => {
            try {
              return await api.addJournal(io);
            } catch (ex) {
              console.log("Error occurred while inserting journal", ex);
              return false;
            }
          })
        );
        console.log(results);
        utils.showSuccess(
          `Success: ${results.filter((item) => item).length}, Failed: ${
            results.filter((item) => !item).length
          }`
        );
      }
    } catch (ex) {
      utils.showError(ex.msg || ex.message);
    } finally {
      utils.hideLoader();
    }
  };

  uploadFile = async (type: string) => {
    try {
      let files = await utils.askForFile();
      if (!files?.length) throw new Error("Please upload file");
      let lstLines: IImportLine[] = [];
      if (type === "KOTAK") {
        lstLines = await readKotakStatement(files[0]);
      } else if (type === "JSON") {
      } else if (type === "BOB") {
        lstLines = await readBobXLSStatement(files[0]);
      } else if (type === "SBI") {
        lstLines = await readSbiXLSStatement(files[0]);
      } else if (type === "ICICI") {
        lstLines = await iciciCreditCardStatement(files[0]);
      }
      await this.setState({ lstLines });
      this.assignCashAccount();
      console.log(lstLines);
    } catch (ex) {
      utils.showError(ex.message);
    }
  };

  assignCashAccount = () => {
    if (this.state.lstLines && this.state.objCashAccount) {
      this.state.lstLines.forEach((item) => {
        if (item.isExpanse) {
          item.creditAccount = this.state.objCashAccount;
        } else {
          item.debitAccount = this.state.objCashAccount;
        }
      });
      this.forceUpdate();
    }
  };

  render() {
    // const companySettings = winState.state.companySettings;

    return (
      <div>
        <CommonModal
          dialogProps={{
            open: this.props.isOpen,
            fullScreen: true,
          }}
          title={"Bulk Add Journal"}
          toggle={this.props.toggle}
        >
          <form onSubmit={this.onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <AccountAutoComplete
                  category={enums.JOURNAL_CATEGORY.CASH_BANK}
                  accountId={this.state.objCashAccount?.id}
                  onChange={(objAccount: IAccount) => {
                    if (objAccount) {
                      this.setState({ objCashAccount: objAccount });
                      this.assignCashAccount();
                    }
                  }}
                  label="Cash Bank Account"
                  frm={this.frm}
                  required
                />
                <Button type="submit">Add Journals</Button>
              </Grid>
              <Grid item xs={12}>
                {this.state.lstLines && this.state.objCashAccount && (
                  <DataTable
                    data={this.state.lstLines}
                    title="Journals"
                    columns={[
                      {
                        title: "Category",
                        render: (item: IImportLine) => {
                          return (
                            <>
                              <HyperSelectField
                                required
                                selectedValue={item.category}
                                labelWidth={130}
                                onChange={(e: any) => {
                                  item.category = e.target.value;
                                  this.forceUpdate();
                                }}
                                options={utils.getDisplayableAccountCategories()}
                                label="Account Category"
                                fullWidth
                                frm={this.frm}
                              />
                            </>
                          );
                        },
                      },
                      {
                        title: "Date",
                        field: "date",
                        render: (item: IImportLine) => {
                          return utils.convertToSystemDateFormate(item.date);
                        },
                      },
                      {
                        title: "Amount",
                        field: "amount",
                      },
                      {
                        title: "Debit Account",
                        render: (item: IImportLine) => {
                          return (
                            <>
                              {item.isExpanse ? (
                                <AccountAutoComplete
                                  accountId={item.debitAccount?.id}
                                  onChange={(objAccount) => {
                                    item.debitAccount = objAccount;
                                    this.forceUpdate();
                                  }}
                                  frm={this.frm}
                                  required={(item as any)?.tableData?.checked}
                                />
                              ) : (
                                item.debitAccount?.name
                              )}
                            </>
                          );
                        },
                      },
                      {
                        title: "Credit Account",
                        render: (item: IImportLine) => {
                          return (
                            <>
                              {!item.isExpanse ? (
                                <AccountAutoComplete
                                  accountId={item.creditAccount?.id}
                                  onChange={(objAccount) => {
                                    item.creditAccount = objAccount;
                                    this.forceUpdate();
                                  }}
                                  frm={this.frm}
                                  category={item.category}
                                  required={(item as any)?.tableData?.checked}
                                />
                              ) : (
                                item.creditAccount?.name
                              )}
                            </>
                          );
                        },
                      },
                      {
                        title: "Comment",
                        render: (item: IImportLine) => {
                          return (
                            <>
                              <HyperTextField
                                required
                                value={item.comment}
                                onChange={(e: any) => {
                                  item.comment = e.target.value;
                                  this.forceUpdate();
                                }}
                                label={"Comment"}
                                type="text"
                                multiline
                                frm={this.frm}
                              />
                            </>
                          );
                        },
                      },
                    ]}
                    options={{ pageSize: 50, selection: true }}
                  />
                )}
              </Grid>

              {this.state.objCashAccount && (
                <>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Button
                      onClick={() => this.uploadFile("KOTAK")}
                      type="button"
                      color="primary"
                      variant="contained"
                    >
                      Upload Kotak CSV
                    </Button>{" "}
                    <Button
                      onClick={() => this.uploadFile("BOB")}
                      type="button"
                      color="primary"
                      variant="contained"
                    >
                      Upload BOB XLS
                    </Button>{" "}
                    <Button
                      onClick={() => this.uploadFile("SBI")}
                      type="button"
                      color="primary"
                      variant="contained"
                    >
                      Upload SBI XLX
                    </Button>{" "}
                    <Button
                      onClick={() => this.uploadFile("ICICI")}
                      type="button"
                      color="primary"
                      variant="contained"
                    >
                      Upload ICICI CSV
                    </Button>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Button type="button" color="primary" variant="contained">
                      Upload Account++ JSON (Pending)
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </CommonModal>
      </div>
    );
  }
}

export default AddBulkJournal;
