import React from 'react'
import { Button, Container, Grid } from '@material-ui/core'
import utils from '../../Services/Utils';
import NotificationSkeleton from '../../Components/Skeletons/NotificationSkeleton';
import DataTable from '../../Components/DataTable';
import { TaxSearchOption } from '../../Types/SearchOptions';
import api from '../../Services/ApiServices';
import AddUpdateTax from './AddUpdateTax';
import HyperModal from '../../Components/HyperInput/HyperModal';
import { ITax } from '../../Types/AllTypes';
import { IconAdd } from '../../Components/Icons/Icons';

interface TaxesProps {

}

interface TaxesState {
    shouldShowSkeleton: boolean,
    lstTaxes: ITax[],
    currentData?: ITax
}

class Taxes extends React.Component<TaxesProps, TaxesState> {
    constructor(props: TaxesProps) {
        super(props);
        let state: TaxesState = {
            shouldShowSkeleton: true,
            lstTaxes: [],
            currentData: undefined
        };

        this.state = state;
        this.modal = new HyperModal(this);
    }
    modal: HyperModal;

    componentDidMount() {
        this.loadTaxes();
    }

    async loadTaxes() {
        try {
            let so = new TaxSearchOption();
            let lstTaxes = await api.getTaxList(so);
            this.setState({ lstTaxes, shouldShowSkeleton: false })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }

    }

    onDeleteClick = async (objTax: ITax) => {
        let confirmed = await utils.showConfirm("Do you really want to delete this?", "");
        if (confirmed) {
            try {
                utils.showLoader();
                let data = await api.deleteTax(objTax.id);
                if (data.isUsed)
                    return utils.showWarning("You can't delete this tax because it's already used.s")
                this.loadTaxes();
            }
            catch (ex) {
                utils.showError(ex.msg || ex.message)
            }
            finally {
                utils.hideLoader();
            }
        }
    }

    onAddClick = () => {
        this.modal.toggle(true, { currentData: null })
    }

    onEditClick = (objTax: ITax) => {
        this.modal.toggle(true, { currentData: objTax });
    }

    render() {
        return (
            <Container>
                <div style={{ textAlign: 'end', marginBottom: 20 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={this.onAddClick}
                        startIcon={<IconAdd />}
                    >
                        New
                    </Button>
                </div>
                <Grid item xs={12}>
                    {this.state.shouldShowSkeleton ? (
                        <NotificationSkeleton />
                    ) : (
                            <DataTable
                                title="Taxes"
                                columns={[
                                    {
                                        title: "ID", render: (rowData: ITax) => {
                                            return `#${rowData.id}`;
                                        },
                                        sortField:"id"
                                    },
                                    { title: "Name", field: "name" },
                                    { title: "Percentage", field: "percentage" },
                                    { title: "Key", field: "key" },
                                ]}
                                data={this.state.lstTaxes}
                                options={{
                                    sorting: true,
                                }}
                                onEdit={(rowData: ITax) => { this.onEditClick(rowData) }}
                                onDelete={(rowData: ITax) => { this.onDeleteClick(rowData) }}
                            // onView={this.viewUser}
                            />
                        )}
                </Grid>
                {(this.modal.isOpen()) &&
                    <AddUpdateTax
                        isOpen={this.modal.isOpen()}
                        objTax={this.state.currentData}
                        onComplete={this.loadTaxes.bind(this)}
                        toggle={this.modal.toggle}
                    />
                }
            </Container>
        );
    }
}

export default Taxes;