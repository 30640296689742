import React from 'react'
import { Button, Grid, Typography, Stepper, Step, StepLabel } from '@material-ui/core'
import utils from '../../Services/Utils';
import NotificationSkeleton from '../../Components/Skeletons/NotificationSkeleton';
import DataTable from '../../Components/DataTable';
import api from '../../Services/ApiServices';
import { ICustomer, IAccount } from '../../Types/AllTypes';
import { AccountSearchOption } from '../../Types/SearchOptions';
import enums from '../../enums';
import AccountText from '../Accounts/AccountText';
import CommonModal from '../../Components/CommonModal';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import { IconDownArrow } from '../../Components/Icons/Icons'
import Alert from '@material-ui/lab/Alert';


interface PromotionProps {
    isOpen: boolean
    toggle: () => void
}
interface PromotionState {
    shouldShowSkeleton: boolean,
    needToSetup: boolean,
    stepActiveStepup: number,
    lstPromotion: ICustomer[],
    promotionMessage: string
    errorMsg: string
    selectedCustomer: ICustomer[]
}
const steps = ["Install Extension", "Connect Your Whatsapp"]

class Promotion extends React.Component<PromotionProps, PromotionState> {
    constructor(props: PromotionProps) {
        super(props);
        let state: PromotionState = {
            shouldShowSkeleton: true,
            lstPromotion: [],
            promotionMessage: "",
            selectedCustomer: [],
            needToSetup: false,
            stepActiveStepup: 0,
            errorMsg: "",

        };

        this.state = state;
    }

    componentDidMount() {
        // this.validateExtension();
        this.loadCustomer();
    }


    validateExtension = () => {
        if (!utils.isWAExtensionInstall()) {
            this.setState({ needToSetup: true, stepActiveStepup: 0, errorMsg: "Extension not install please follow the step." })
        }
        else if (!utils.isExtensionConnected()) {
            this.setState({ needToSetup: true, stepActiveStepup: 1, errorMsg: "Extension not connected with Whatsapp please open your Whatsapp." })
        }
        else {
            this.setState({ needToSetup: false, errorMsg: "" })
        }
    }
    async loadCustomer() {
        try {
            let SO = new AccountSearchOption()
            SO.category = enums.ACCOUNT_CATEGORY.CUSTOMER;
            let lstPromotion = await api.getAccountsList(SO)
            lstPromotion = lstPromotion.map((customer: any) => {
                return { ...customer, ...customer.info }
            });
            this.setState({ lstPromotion, shouldShowSkeleton: false })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
    }
    sendPromotion = async () => {
        if (!this.state.promotionMessage)
            return utils.showError("Please Enter Your Promotion message.");
        if (this.state.selectedCustomer.length === 0)
            return utils.showError("Please Selected Customer For Promotion.");

        try {
            utils.showLoader();
            for (let customer of this.state.selectedCustomer) {
                if (customer.info?.mobile) {
                    await utils.sendWhatsappMessage(customer.info?.mobile, this.state.promotionMessage)
                }
            }
            utils.showSuccess("Promotion Message Send to your Selected Customer.");
            this.props.toggle();
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader()
        }

    }
    smsPromotion = async () => {

        let msgList: any = [];
        for (let customer of this.state.selectedCustomer) {
            if (customer.info?.mobile)
                msgList.push({ mobile: customer.info?.mobile, msg: this.state.promotionMessage });
        }
        if (msgList.length === 0)
            return false;

        try {
            utils.showLoader();
            await api.sendPromotionMsg(msgList);
            utils.showSuccess("Promotion Message Send to your Selected Customer.");
            this.props.toggle();
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader()
        }
    }
    sendPromotionSMSWithSBP = async () => {
        if (!this.state.promotionMessage)
            return utils.showError("Please Enter Your Promotion message.");
        if (this.state.selectedCustomer.length === 0)
            return utils.showError("Please Selected Customer For Promotion.");

        let lstNumber: any = [];
        for (let customer of this.state.selectedCustomer) {
            if (customer.info?.mobile)
                lstNumber.push(customer.info?.mobile);
        }
        if (lstNumber.length === 0)
            return utils.showError("please Select Customer those have mobile number.");

        try {
            utils.showLoader();
            await api.sendPromotionSMSWithSBP(lstNumber, this.state.promotionMessage);
            utils.showSuccess("Promotion Message Send to your Selected Customer.");
            this.props.toggle();
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader()
        }
    }
    getStepContent = (step: number) => {
        if (step === 0) {
            return (
                <Grid xs={12} item style={{ textAlign: "center" }}>
                    <Button color="secondary" variant="contained" onClick={() => { utils.downloadExtension(); }}>Click here to download extension</Button>
                    <Typography style={{ marginTop: 10 }}>
                        <IconDownArrow />
                    </Typography>
                    <Typography>
                        Unzip Extension
                    </Typography>
                    <Typography>
                        <IconDownArrow />
                    </Typography>
                    <Typography>
                        Goto Chrome Browser and search <Button color="secondary" onClick={() => window.open("chrome://extensions")}> chrome://extensions</Button>
                    </Typography>
                    <Typography>
                        <IconDownArrow />
                    </Typography>
                    <Typography>
                        Load unpacked and Select unzip extension folder.
                    </Typography>
                    <Typography>
                        <IconDownArrow />
                    </Typography>
                    <Typography>
                        Refresh the page
                    </Typography>
                    <Typography>
                        <IconDownArrow />
                    </Typography>
                    <Button variant="contained" color="primary" onClick={this.handleNext}>Next</Button>
                </Grid>
            )
        }
        else if (step === 1) {
            return (
                <Grid xs={12} item style={{ textAlign: "center" }}>
                    <Button color="secondary" variant="contained" onClick={() => { window.open("https://web.whatsapp.com/"); this.validateExtension(); }}>Open Your Web Whatsapp</Button>
                </Grid>
            )
        }
        return ""
    }

    handleNext = () => {
        this.setState({ stepActiveStepup: this.state.stepActiveStepup + 1 })
        this.validateExtension();
    };

    handleBack = () => {
        this.setState({ stepActiveStepup: this.state.stepActiveStepup - 1 })
    };

    handleReset = () => {
        this.setState({ stepActiveStepup: 0 })
    };

    render() {
        // console.log(this.state.stepActiveStepup, "{!this.state.needToSetup &&")
        return (
            <CommonModal
                dialogProps={{
                    open: this.props.isOpen
                }}
                title={"Promotion"}
                toggle={this.props.toggle}
            >
                {!!(this.state.errorMsg) &&
                    <Grid item xs={12}>
                        <Alert style={{ marginBottom: 20 }} severity="error">{this.state.errorMsg}</Alert>
                    </Grid>
                }
                {this.state.needToSetup &&
                    <div>
                        <Stepper activeStep={this.state.stepActiveStepup} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <div>
                            {this.state.stepActiveStepup === steps.length ? (
                                <div>
                                    <Typography>All steps completed</Typography>
                                </div>
                            ) : (
                                    <div>
                                        <Grid container>
                                            {this.getStepContent(this.state.stepActiveStepup)}
                                        </Grid>
                                    </div>
                                )}
                        </div>
                    </div>
                }

                {!this.state.needToSetup &&
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={9} style={{ textAlign: 'right' }}>
                            <HyperTextField
                                value={this.state.promotionMessage}
                                onChange={(e: any) => this.setState({ promotionMessage: e.target.value })}
                                label="Promotion Message"
                                multiline
                            />
                        </Grid>
                        <Grid item xs={12} md={3} style={{ margin: 'auto', textAlign: 'center' }}>
                            <Button variant="contained" color="primary" size="large" onClick={this.sendPromotionSMSWithSBP}>
                                Send Message
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            {this.state.shouldShowSkeleton ?
                                (<NotificationSkeleton />) : (
                                    <DataTable
                                        title="Promotion"
                                        columns={[
                                            {
                                                title: "ID", render: (rowData: IAccount) => {
                                                    return `#${rowData.id}`;
                                                }, sortField: "id"
                                            },
                                            {
                                                title: "Name", render: (rowData: IAccount) => {
                                                    return <AccountText objAccount={rowData} />
                                                }, sortField: "name"
                                            },
                                            { title: "Mobile", field: "mobile" },
                                            { title: "Email", field: "email" },

                                        ]}
                                        data={this.state.lstPromotion}
                                        options={{
                                            sorting: true,
                                            selection: true
                                        }}
                                        onSelectionChange={(selectedRows: any) => { this.setState({ selectedCustomer: selectedRows }) }}
                                    />
                                )}
                        </Grid>
                    </Grid>
                }
            </CommonModal>
        );
    }
}

export default Promotion;