import React from 'react'
import { Grid, Card, CardContent, Typography, IconButton } from '@material-ui/core';
import { Journal } from '../../Types/AllTypes';
import { IconAccount, IconIncome, IconCalendar, IconNote, IconDeleteFill, IconEdit } from '../../Components/Icons/Icons';
import scanRUtils from '../../Services/ScanRUtilService';
import utils from '../../Services/Utils';

interface PaymentCardProps {
    objPayment: Journal

    onEdit: () => void
    onDelete: () => void
}

interface PaymentCardState {
}


class PaymentCard extends React.Component<PaymentCardProps, PaymentCardState> {
    constructor(props: PaymentCardProps) {
        super(props);
        let state: PaymentCardState = {

        };
        this.state = state;
    }

    render() {
        
        return (
            <Card elevation={3} style={{ position: 'relative' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <IconAccount color="primary" style={{ verticalAlign: 'middle' }} />
                        </Grid>
                        <Grid item xs={10} >
                            <Typography variant="subtitle1">
                                {this.props.objPayment.debitAccount.name}
                            </Typography>
                        </Grid>

                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <IconIncome color="primary" style={{ verticalAlign: 'middle' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="subtitle1">
                                {scanRUtils.prettyPrice(this.props.objPayment.amount)}
                            </Typography>
                        </Grid>

                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <IconCalendar color="primary" style={{ verticalAlign: 'middle' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="subtitle1">
                                {utils.convertToSystemDateFormate(new Date(this.props.objPayment.date))}
                            </Typography>
                        </Grid>

                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <IconNote color="primary" style={{ verticalAlign: 'middle' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="subtitle2">
                                {this.props.objPayment.comment}
                            </Typography>
                        </Grid>
                        <div style={{ position: "absolute", right: 10, top: 5, }}>
                            <IconButton onClick={this.props.onEdit}>
                                <IconEdit />
                            </IconButton>
                            <IconButton onClick={this.props.onDelete}>
                                <IconDeleteFill color="error" />
                            </IconButton>
                        </div>
                    </Grid>
                </CardContent>
            </Card>
        );
    }
}

export default PaymentCard;