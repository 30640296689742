import React from 'react'
import { Button, Grid, Container, Chip } from '@material-ui/core'
import AddUpdatePurchase from './AddUpdatePurchase';
import utils from '../../Services/Utils';
import NotificationSkeleton from '../../Components/Skeletons/NotificationSkeleton';
import DataTable from '../../Components/DataTable';
import { IPurchase, IWindowState } from '../../Types/AllTypes';
import api from '../../Services/ApiServices';
import HyperForm from '../../Components/HyperInput/HyperForm';
import { JournalSearchOptions } from '../../Types/SearchOptions';
import enums from '../../enums';
import HyperModal from '../../Components/HyperInput/HyperModal';
import AccountText from '../Accounts/AccountText';
import { HyperTheme } from '../../Theme/Theme';
import { IconAdd } from '../../Components/Icons/Icons';
import AddUpdatePayment from '../Payments/AddUpdatePayment';
import scanRUtils, { winState } from '../../Services/ScanRUtilService';
import withWindowState from '../../Components/withWindowState';
import HyperAutoCompleteField from '../../Components/HyperInput/HyperAutoCompleteField';
import { RouteComponentProps } from 'react-router-dom';
import SeeMore from '../../Components/SeeMore';


interface PurchaseProps extends IWindowState, RouteComponentProps { }

interface PurchaseState {
    shouldShowSkeleton: boolean,
    lstPurchase: IPurchase[],
    currentData?: IPurchase
    paymentData?: IPurchase
    isDialogOpen: boolean
    name: any
    selectedStatus: any[]
    isStatusOptionOpen: boolean
}
const theme = HyperTheme;
const SALES_PURCHASE_STATUS: any = enums.SALES_PURCHASE_STATUS;


class Purchase extends React.Component<PurchaseProps, PurchaseState> {
    constructor(props: PurchaseProps) {
        super(props);
        let state: PurchaseState = {
            shouldShowSkeleton: true,
            lstPurchase: [],
            currentData: undefined,
            paymentData: undefined,
            isDialogOpen: false,
            name: "",
            isStatusOptionOpen: false,
            selectedStatus: []
        };
        this.state = state;
        this.frm = new HyperForm();
        this.modal = new HyperModal(this);
        this.paymentModal = new HyperModal(this);
        this.showItemSerialNo = winState.state.companySettings.showItemSerialNo;
    }
    frm: HyperForm;
    modal: HyperModal;
    showItemSerialNo: boolean;
    paymentModal: HyperModal;

    componentDidMount() {
        let query = utils.parseQueryString(this.props.location.search);
        if (query.paymentType) {
            let aryPaymentTypes = query.paymentType.toString().split(",");
            this.setState({ selectedStatus: aryPaymentTypes.map(item => ({ label: item, value: item })) })
        }
        this.loadPurchase();
    }

    loadPurchase = async () => {
        try {
            let so = new JournalSearchOptions();
            so.category = enums.JOURNAL_CATEGORY.PURCHASE;
            let lstPurchase: any = await api.getJournals(so);
            this.setState({ lstPurchase, shouldShowSkeleton: false })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
    }

    onAddClick = () => {
        this.setState({ isDialogOpen: true, currentData: undefined });
    }

    onEditClick = (objJournal: IPurchase) => {
        this.setState({ isDialogOpen: true, currentData: objJournal })
    }

    onDeleteClick = async (objJournal: IPurchase) => {
        let confirmed = await utils.showConfirm("Do you really want to delete this?", "");
        if (confirmed) {
            try {
                await api.deleteJournal(objJournal.id);

                this.loadPurchase();
            }
            catch (ex) {
                utils.showError(ex.msg || ex.message)
            }
        }
    }

    toggleAddUpdateJournalModal = () => {
        // console.log("this is called")
        this.setState({ isDialogOpen: !this.state.isDialogOpen })
    }

    onInvoiceClick = async (objPurchase: IPurchase) => {
        await this.setState({ currentData: objPurchase })
        this.modal.toggle(true)
    }

    _renderIsPaid = (rowData: IPurchase) => {
        if (rowData.info.paymentStatus === enums.PAYMENT_STATUS.PAID)
            return <Chip label={enums.PAYMENT_STATUS.PAID} color="primary" />
        else if (rowData.info.paymentStatus === enums.PAYMENT_STATUS.PARTIALLY_PAID)
            return <Chip onClick={() => this.onPayment(rowData)} label={enums.PAYMENT_STATUS.PARTIALLY_PAID} style={{ backgroundColor: theme.palette.warning.main, color: theme.palette.common.white }} />
        return <Chip
            label={enums.PAYMENT_STATUS.UNPAID}
            clickable
            onClick={() => this.onPayment(rowData)}
            style={{ backgroundColor: theme.palette.error.dark, color: theme.palette.common.white }}
        />
    }

    onPayment = async (rowData: IPurchase) => {
        let paymentData: any = {}
        paymentData.debitAccountId = rowData.creditAccountId;
        paymentData.amount = rowData.info.totalDueAmount || rowData.amount;
        paymentData.info = {
            purchaseId: rowData.id
        }
        // console.log(rowData, "Row", paymentData)
        await this.setState({ paymentData, currentData: rowData });
        this.paymentModal.toggle(true)
    }

    updateCurrentPurchase = async () => {
        try {
            let uo: any = {}
            uo.id = this.state.currentData?.id
            uo.info = this.state.currentData?.info
            uo.info.isPaid = true;
            utils.showLoader();
            // console.log(uo, "UO")
            await api.updateJournal(uo);
            // console.log(result)
            this.loadPurchase();
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader()
        }
    }

    renderItemName = (rowData: IPurchase) => {
        if (rowData.info) {
            let itemName = rowData.info.items.map((item: any) => item.name).join(", ");
            (rowData as any).itemName = itemName;
            return <div style={{ width: 100 }}><SeeMore text={itemName} length={20} /></div>
        }
        return ""
    }

    getSN = (rowData: IPurchase) => {
        if (rowData.info) {
            let serialNos = rowData.info.items.map((item: any) => item.lstSerialNo ? item.lstSerialNo.join(", ") : "").filter((item: any) => item).join(", ");
            (rowData as any).serialNos = serialNos;
            return <div style={{ width: 100 }}><SeeMore text={serialNos} length={20} /></div>
        }
        return "";
    }

    render() {
        const lstPurchase = this.state.lstPurchase.filter(purchase => {
            if (this.state.selectedStatus.length === 0) {
                return purchase
            }
            return (this.state.selectedStatus.some(item => item.value === purchase.info.paymentStatus))
        });

        let lstExtendColumns = [];
        if (this.props.companySettings.showItemSerialNo) {
            lstExtendColumns.push({ title: "S/N", render: this.getSN, sortField: "serialNos" })
        }

        return (
            <Container>
                <Grid container >
                    <Grid item xs={6} style={{ textAlign: 'left', margin: 'auto' }}>
                        <HyperAutoCompleteField
                            options={Object.keys(SALES_PURCHASE_STATUS).map((statusKey: string) => {
                                return {
                                    label: SALES_PURCHASE_STATUS[statusKey],
                                    value: SALES_PURCHASE_STATUS[statusKey]
                                };
                            })}
                            value={this.state.selectedStatus}
                            onChange={(e: any) => {
                                this.setState({ selectedStatus: e })
                            }}
                            multiple
                            label={"Payment Status"}
                        />
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={this.onAddClick}
                            startIcon={<IconAdd />}
                        >
                            New
                    </Button>
                    </Grid>
                </Grid>

                <Grid item xs={12} style={{ marginTop: 20 }}>
                    {this.state.shouldShowSkeleton ? (
                        <NotificationSkeleton />
                    ) : (
                            <DataTable
                                title="Purchase"
                                columns={([
                                    {
                                        title: "Invoice No.", render: (rowData: IPurchase) => {
                                            return utils.getInvoiceNumberWithPrefix(rowData.info.INVNumber);
                                        },
                                        sortField: "info.INVNumber",
                                        renderReturnsString: true
                                    },
                                    // {
                                    //     title: "Debit Account", render: (rowData: IPurchase) => {
                                    //         return <AccountText accountId={rowData.debitAccountId.toString()} accountName={rowData.debitAccount.name} />
                                    //     }
                                    // },
                                    {
                                        title: "Supplier", render: (rowData: IPurchase) => {
                                            return <AccountText objAccount={rowData.creditAccount} />
                                        },
                                        sortField: "creditAccount.name",
                                    },
                                    {
                                        title: "Date", render: (rowData: IPurchase) => {
                                            return utils.convertToSystemDateFormate(new Date(rowData.date));
                                        },
                                        sortField: "date",
                                        renderReturnsString: true,
                                    },
                                    {
                                        title: "Due Date", render: (rowData: IPurchase) => {
                                            return utils.convertToSystemDateFormate(new Date(rowData.info.dueDate || rowData.date));
                                        },
                                        sortField: "info.dueDate",
                                        renderReturnsString: true,
                                    },
                                    {
                                        title: "Amount", render: (rowData: IPurchase) => {
                                            return scanRUtils.prettyPrice(rowData.amount);
                                        },
                                        sortField: "amount",
                                    },
                                    {
                                        title: "Due Amount", render: (rowData: IPurchase) => {
                                            return scanRUtils.prettyPrice(rowData.info.totalDueAmount || 0);
                                        },
                                        sortField: "info.totalDueAmount",
                                    },
                                    {
                                        title: "Status", render: this._renderIsPaid, sortField: "info.paymentStatus"
                                    },
                                    {
                                        title: "Items", render: this.renderItemName, sortField: "itemName"
                                    },
                                ] as any).concat(lstExtendColumns)}
                                data={lstPurchase}
                                options={{
                                    sorting: true,
                                }}
                                onEdit={(rowData: IPurchase) => { this.onEditClick(rowData) }}
                                onDelete={(rowData: IPurchase) => { this.onDeleteClick(rowData) }}
                            // onView={this.viewUser}
                            />
                        )}
                </Grid>
                {this.state.isDialogOpen &&
                    <AddUpdatePurchase
                        onComplete={this.loadPurchase.bind(this)}
                        isOpen={this.state.isDialogOpen}
                        toggle={() => { this.toggleAddUpdateJournalModal(); this.props.setGlobal({ lstSerialNo: [] }) }}
                        objPurchase={this.state.currentData}
                    />
                }

                {/* {(this.modal.isOpen()) &&
                    <Invoice
                        isOpen={this.modal.isOpen()}
                        objSales={this.state.currentData}
                        toggle={this.modal.toggle}
                    />
                } */}

                {(this.paymentModal.isOpen()) &&
                    <AddUpdatePayment
                        isOpen={this.paymentModal.isOpen()}
                        objJournal={this.state.paymentData}
                        onComplete={this.loadPurchase}
                        toggle={this.paymentModal.toggle}
                        // hideAmountField={true}
                        hideDebitField={true}
                        showDueAmount={true}
                    />
                }

            </Container>
        );
    }
}

export default withWindowState(Purchase, ["companySettings", "global"]);