import React from 'react'
import CommonModal from '../../Components/CommonModal';
import { Button, Grid } from '@material-ui/core';
import scanRUtils from '../../Services/ScanRUtilService';
import HyperSelectField from '../HyperInput/HyperSelectField';
import utils from '../../Services/Utils';
import withWindowState from '../withWindowState';
import { IWindowState } from '../../Types/AllTypes';
import api from '../../Services/ApiServices';

interface GenerateBarcodeModelProps extends IWindowState {
    isOpen: boolean
    toggle: () => void
}

interface GenerateBarcodeModelState {
    barcodeHTML: string,
    pageConfig: string
}

const lstBarcodeQty = [
    // { label: "48/page", rows: 12, cols: 4, height: 21.1, width:45.7  },
    { label: "65/page", rows: 13, cols: 5, height: 21.2, width:38.1 },
    // { label: "84/page", rows: 21, cols: 4, height: 11, width:46  },
    // { label: "110/page", rows: 27, cols: 5 },
]



class GenerateBarcodeModel extends React.Component<GenerateBarcodeModelProps, GenerateBarcodeModelState> {
    constructor(props: GenerateBarcodeModelProps) {
        super(props);
        this.state = {
            barcodeHTML: "",
            pageConfig: lstBarcodeQty[0].label
        }
    }

    onClick = async () => {
        try {
            let config = lstBarcodeQty.find(item => item.label === this.state.pageConfig);
            if (!config)
                return utils.showWarning("Invalid page config");

            let height = config.height || 0;// (pageHW.height) / config.rows;
            let width = config.width || 0; // (pageHW.width) / config.cols;
            let barcodeIndex = this.props.companySettings.currentBarcodeIndex || 0;
            let prefix = this.props.companySettings.id.toString().padStart(5, "0");
            let html = scanRUtils.generateBarcodeList(config.rows, config.cols, height, width, prefix, barcodeIndex);
            let newIndex = barcodeIndex + (config.rows * config.cols)
            await api.updateCompanySettings({ currentBarcodeIndex: newIndex });
            this.props.setCompanySettings({ currentBarcodeIndex: newIndex })
            this.setState({ barcodeHTML: html })
        }
        catch (ex) {
            console.log(ex);
            utils.showError(ex.message)
        }
    }

    onPrintClick = async () => {
        // let worker = html2PdfJs();
        // (window as any).wk = worker;
        // await worker.from(this.state.barcodeHTML)
        // window.open(await worker.output('bloburl', { filename: "barlist.pdf" }), '_blank');

        var iFrame: any = document.getElementById("barcodeFrame")
        iFrame.contentWindow?.focus(); //IE fix
        iFrame.contentWindow?.print();
    }

    render() {
        return (
            <CommonModal
                dialogProps={{
                    open: this.props.isOpen
                }}
                title={"QR Code"}
                toggle={this.props.toggle}
            >
                <Grid container justify="space-evenly">
                    <Grid item>
                        <HyperSelectField
                            options={lstBarcodeQty}
                            optionNameField={"label"}
                            optionIdField={"label"}
                            value={this.state.pageConfig}
                            onChange={(event: any) => {
                                this.setState({ pageConfig: event.target.value })
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={this.onClick} >Generate</Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={this.onPrintClick} >Print</Button>
                    </Grid>
                </Grid>
                <div style={{ textAlign: "center" }}>
                    <iframe id="barcodeFrame" srcDoc={this.state.barcodeHTML} title="Barcode"
                        scrolling="no"
                        style={{
                            display: "block",
                            width: "23cm",
                            boxShadow: "1px 1px 5x grey",
                            height: "30cm",
                            border: "none",
                            margin: "auto",
                        }}></iframe>
                </div>
            </CommonModal>

        );
    }
}

export default withWindowState(GenerateBarcodeModel, ["companySettings"]);