import React, { Component } from 'react';
import { SnackbarProvider, withSnackbar } from 'notistack';
import enums from '../enums';
import withEvent from './withEvent';



class SnackbarStackChild extends Component {
    onEvent(event) {
        try {
            let message = event.detail.data.message
            let options = event.detail.data.options
            this.props.enqueueSnackbar(message, options)
        }
        catch (ex) {
            console.log("Error occurred while showing snackbar");
        }
    }

    render() {
        return (
            <React.Fragment></React.Fragment>
        );
    }
}

const SnackBarChild = withEvent(withSnackbar(SnackbarStackChild), [enums.EVENT.ENQUEUE_SNACKBAR]);

class SnackbarStack extends Component {
    render() {
        return (
            <SnackbarProvider maxSnack={4}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <SnackBarChild />
            </SnackbarProvider>
        );
    }
}



export default SnackbarStack;