import React from 'react'
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import { ISalesItem, IItem, IDiscount, IPurchaseItem } from '../../Types/AllTypes';
import HyperForm from '../../Components/HyperInput/HyperForm';
import HyperAmountField from '../../Components/HyperInput/HyperAmountField';
import { IconDeleteFill, IconBulkEntry, } from '../../Components/Icons/Icons';
import ItemAutoComplete from '../Item/ItemAutoComplete';
import scanRUtils from '../../Services/ScanRUtilService';
import HyperModal from '../../Components/HyperInput/HyperModal';
import BunchEntryModal from '../Purchases/BunchEntryModal';
import enums from '../../enums';


interface PurchaseReturnItemProps {
    objPurchaseReturnItem: IPurchaseItem
    frm: HyperForm
    focusedItemRef: any
    objDiscount: IDiscount
    taxInvoiceType?: string

    removeItem?: () => void
    onComplete?: () => void
    onChange?: (objPurchaseReturnItem: ISalesItem) => void
}

interface PurchaseReturnItemState {
    // objSalesReturnItem: ISalesItem
    purchaseQty: number
}


class PurchaseReturnItem extends React.Component<PurchaseReturnItemProps, PurchaseReturnItemState> {
    constructor(props: PurchaseReturnItemProps) {
        super(props);
        let state: PurchaseReturnItemState = {
            purchaseQty: 0
        };
        state.purchaseQty = this.props.objPurchaseReturnItem.qty;
        this.state = state;
        this.bunchEntryModal = new HyperModal(this);
    }
    bunchEntryModal: HyperModal


    setPurchaseReturnItem = async (obj: Partial<ISalesItem>) => {
        if (this.props.onChange)
            this.props.onChange(obj as ISalesItem);
    }

    onBarcodeInput = (barcode: string) => {
        //PTH_NIK
        if (this.props.objPurchaseReturnItem?.objItem?.maintainSerialWiseInventory) {
            this.setPurchaseReturnItem({ lstSerialNo: [barcode] });
        }
    }
    onFinishBunchEntry = (lstSerialNo: string[]) => {
        this.props.objPurchaseReturnItem.lstSerialNo = lstSerialNo;
        this.props.objPurchaseReturnItem.qty = lstSerialNo.length
        this.forceUpdate();
    }
    render() {
        if (this.props.objPurchaseReturnItem?.objItem?.isTaxInclusive) {
            if (this.props.objPurchaseReturnItem.objItem) {
                this.props.objPurchaseReturnItem.tax = scanRUtils.countReverseTax(this.props.objPurchaseReturnItem.amount, this.props.objPurchaseReturnItem.taxes, this.props.objPurchaseReturnItem.qty);
                this.props.objPurchaseReturnItem.priceAfterDiscount = scanRUtils.toFixedFloat((this.props.objPurchaseReturnItem.amount - this.props.objPurchaseReturnItem.tax) / this.props.objPurchaseReturnItem.qty);
                this.props.objPurchaseReturnItem.price = scanRUtils.countReverseDiscount(this.props.objPurchaseReturnItem.priceAfterDiscount, this.props.objDiscount);
                this.props.objPurchaseReturnItem.discountAmountPerItem = scanRUtils.toFixedFloat(this.props.objPurchaseReturnItem.price - this.props.objPurchaseReturnItem.priceAfterDiscount)
                this.props.objPurchaseReturnItem.totalTaxableAmount = scanRUtils.toFixedFloat(this.props.objPurchaseReturnItem.priceAfterDiscount * this.props.objPurchaseReturnItem.qty)

            }
        }
        else {
            this.props.objPurchaseReturnItem.amount = scanRUtils.toFixedFloat(this.props.objPurchaseReturnItem.price * this.props.objPurchaseReturnItem.qty);
            if (this.props.objPurchaseReturnItem.objItem) {
                this.props.objPurchaseReturnItem.discountAmountPerItem = scanRUtils.countDiscountAmount(this.props.objPurchaseReturnItem.price, this.props.objDiscount)
                this.props.objPurchaseReturnItem.priceAfterDiscount = scanRUtils.toFixedFloat(this.props.objPurchaseReturnItem.price - this.props.objPurchaseReturnItem.discountAmountPerItem);

                this.props.objPurchaseReturnItem.tax = scanRUtils.countTax(this.props.objPurchaseReturnItem.priceAfterDiscount, this.props.objPurchaseReturnItem.taxes, this.props.objPurchaseReturnItem.qty);
                this.props.objPurchaseReturnItem.amount = scanRUtils.toFixedFloat((this.props.objPurchaseReturnItem.priceAfterDiscount * this.props.objPurchaseReturnItem.qty) + this.props.objPurchaseReturnItem.tax);
                this.props.objPurchaseReturnItem.totalDiscountPerItem = scanRUtils.toFixedFloat(this.props.objPurchaseReturnItem.discountAmountPerItem * this.props.objPurchaseReturnItem.qty)
                this.props.objPurchaseReturnItem.totalTaxableAmount = scanRUtils.toFixedFloat(this.props.objPurchaseReturnItem.priceAfterDiscount * this.props.objPurchaseReturnItem.qty)
            }
        }

        return (
            <Grid container style={{ marginTop: 10 }} spacing={1}>

                <Grid item xs={4}>
                    <Grid container>
                        <Grid item xs={4} style={{ textAlign: 'center' }}>
                            <IconButton onClick={this.props.removeItem} >
                                <IconDeleteFill color={"error"} />
                            </IconButton>
                            {this.props.objPurchaseReturnItem?.objItem?.maintainSerialWiseInventory &&
                                <Tooltip title="Bulk Entry" >
                                    <IconButton onClick={this.bunchEntryModal.toggle}>
                                        <IconBulkEntry color="primary" />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Grid>
                        <Grid item xs={8} style={{ margin: 'auto' }}>
                            <ItemAutoComplete
                                itemId={this.props.objPurchaseReturnItem.objItem?.id}
                                label="Search Item"
                                onChange={(objItem: IItem) => {
                                    if (objItem) {
                                        if (objItem.isTaxInclusive)
                                            this.setPurchaseReturnItem({ objItem: objItem, taxes: objItem.defaultTaxes, amount: objItem.defaultSalesPrice })
                                        else
                                            this.setPurchaseReturnItem({ objItem: objItem, taxes: objItem.defaultTaxes, price: objItem.defaultSalesPrice })
                                    }
                                }}
                                disabled={true}
                                onFocus={() => { this.props.focusedItemRef.current = this; }}
                                frm={this.props.frm}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    <Grid container spacing={1}>
                        <Grid item xs={3} style={{ margin: 'auto' }}>
                            {this.props.objPurchaseReturnItem?.objItem?.maintainSerialWiseInventory ?
                                <HyperTextField
                                    required
                                    value={this.props.objPurchaseReturnItem.lstSerialNo ? this.props.objPurchaseReturnItem.lstSerialNo.join(',') : ""}
                                    label="Serial No"
                                    // onChange={(e: any) => { this.setPurchaseReturnItem({ serialNo: e.target.value }) }}
                                    onFocus={() => { this.props.focusedItemRef.current = this; }}
                                    frm={this.props.frm}
                                    disabled={true}
                                />
                                :
                                <HyperTextField
                                    required
                                    value={this.props.objPurchaseReturnItem.qty}
                                    label="Qty"
                                    onChange={(e: any) => { this.setPurchaseReturnItem({ qty: parseFloat(e.target.value) }) }}
                                    max={this.state.purchaseQty}
                                    type="number"
                                    onFocus={() => { this.props.focusedItemRef.current = this; }}
                                    frm={this.props.frm}
                                />
                            }
                        </Grid>
                        <Grid item xs={3} style={{ margin: 'auto' }}>
                            <HyperAmountField
                                required
                                value={scanRUtils.toFixedFloat(this.props.objPurchaseReturnItem.price)}
                                onChange={(e: any) => { this.setPurchaseReturnItem({ price: parseFloat(e.target.value) }) }}
                                type="number"
                                label="Price"
                                onFocus={() => { this.props.focusedItemRef.current = this; }}
                                frm={this.props.frm}
                                disabled={this.props.objPurchaseReturnItem?.objItem?.isTaxInclusive ? true : false}

                                infoText={`${scanRUtils.prettyPrice(this.props.objPurchaseReturnItem.price)} - ${scanRUtils.prettyPrice(this.props.objPurchaseReturnItem.discountAmountPerItem)} = ${scanRUtils.prettyPrice(this.props.objPurchaseReturnItem.priceAfterDiscount)} (${this.props.objDiscount.discountPercentage}% Discount Applied).`}
                            />
                        </Grid>

                        <Grid item xs={3} style={{ margin: 'auto' }}>
                            <HyperAmountField
                                value={scanRUtils.toFixedFloat(this.props.objPurchaseReturnItem.tax)}
                                label="Tax"
                                frm={this.props.frm}
                                disabled={true}

                                infoText={`${scanRUtils.countTotalTaxPercentage(this.props.objPurchaseReturnItem.taxes)} Tax Applied.`}
                            />
                        </Grid>
                        <Grid item xs={3} style={{ margin: 'auto' }}>
                            <HyperAmountField
                                value={scanRUtils.toFixedFloat(this.props.objPurchaseReturnItem.amount)}
                                label="Total"
                                frm={this.props.frm}


                                onChange={(e: any) => { this.setPurchaseReturnItem({ amount: parseFloat(e.target.value) }) }}
                                disabled={this.props.objPurchaseReturnItem?.objItem?.isTaxInclusive ? false : true}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {(this.bunchEntryModal.isOpen()) &&
                    <BunchEntryModal
                        isOpen={this.bunchEntryModal.isOpen()}
                        cat={enums.ACCOUNT_CATEGORY.SALES}
                        toggleModal={this.bunchEntryModal.toggle}
                        onFinishBunchEntry={this.onFinishBunchEntry}
                        lstSerialNo={this.props.objPurchaseReturnItem.lstSerialNo || []}
                        isNewDisabled={true}
                    />
                }

            </Grid>

        );
    }
}

export default PurchaseReturnItem;