import React from "react";
import { withStyles } from "@material-ui/styles";
import {
    Theme,
    WithStyles,
    createStyles,
    ListItem,
    ListItemIcon,
    ListItemText,
    WithTheme,
    Divider,
    List,
    Tooltip,
    ListItemSecondaryAction,
    Typography,
} from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer';
import { IconJournal, IconHap, IconCustomer, IconAccount, IconProfitAndLoss, IconInvoice, IconItem, IconSupplier, IconPurchase, IconIncome, IconExpanse, IconReceipt, IconPayment, IconReports, IconTransfer, IconCategory, IconSalesReturn, IconPurchaseReturn, IconBankAccount, IconMore, IconLess, IconAdvance, IconFinance, IconMasters, IconInventory, IconOperations, IconBalanceSheet } from '../Components/Icons/Icons';

import { winState } from "../Services/ScanRUtilService";
import withWindowState from "../Components/withWindowState";
import utils from "../Services/Utils";
import { IWindowState } from "../Types/AllTypes";
import { HyperTheme } from "../Theme/Theme";
import Mousetrap from 'mousetrap';
import SideMenuPopover from "./SideMenuPopover";

const drawerWidth = 240;
const styles = (theme: Theme) =>
    createStyles({
        drawerColor: {
            background: `linear-gradient(${theme.palette.primary.dark},${theme.palette.primary.light})`,
            color: theme.palette.common.white
        },
        drawer: {

            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        selectedMenu: {
            transition: theme.transitions.create('height', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: 0,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(7) + 1,
            },
        },
        drawerBoxShadow: {
            boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        primaryText: {
            fontSize: 13
        }
    })

const lstTabs = [
    {
        route: "/dashboard", icon: IconHap, label: "Dashboard", isSelected: false
    },
    {
        route: "/sales",
        icon: IconInvoice,
        label: "Sales",
        isAdvanceMenu: false,
        isSelected: false,
        shortcutKey: "f8"
    },
    {
        route: "/purchases",
        icon: IconPurchase,
        label: "Purchase",
        isAdvanceMenu: false,
        isSelected: false,
        shortcutKey: "f9"
    },
    {
        route: "/Inventory",
        label: "Inventory",
        icon: IconInventory,
        isSelected: false,
        subMenu: [
            { route: "/items", icon: IconItem, label: "Items" },
            { route: "/category", icon: IconCategory, label: "Category" },
        ]
    },
    {
        route: "/Masters",
        label: "Masters",
        icon: IconMasters,
        isSelected: false,
        subMenu: [
            { route: "/customers", icon: IconCustomer, label: "Customer" },
            { route: "/suppliers", icon: IconSupplier, label: "Suppliers" },
            { route: "/bankAccounts", icon: IconBankAccount, label: "Bank Accounts" },
        ]
    },

    {
        route: "/Operations",
        label: "Operations",
        icon: IconOperations,
        isSelected: false,
        subMenu: [
            { route: "/sReturn", icon: IconSalesReturn, label: "Sales Return" },
            { route: "/pReturn", icon: IconPurchaseReturn, label: "Purchase Return" },
        ]
    },

    {
        route: "/Finance",
        label: "Finance",
        icon: IconFinance,
        isSelected: false,
        subMenu: [
            { route: "/expanses", icon: IconExpanse, label: "Expanses" },
            { route: "/incomes", icon: IconIncome, label: "Income" },
            { route: "/contra", icon: IconTransfer, label: "Bank Transfer", shortcutKey: "f3" },
            { route: "/payments", icon: IconPayment, label: "Payment", shortcutKey: "f4" },
            { route: "/receipts", icon: IconReceipt, label: "Receipt", shortcutKey: "f6" },
        ]
    },

    {
        route: "/Advance",
        label: "Advance",
        icon: IconAdvance,
        isSelected: false,
        subMenuRoutes: ["/journal", "/accounts"],
        subMenu: [
            { route: "/journal", icon: IconJournal, label: "Journal", shortcutKey: "f7" },
            { route: "/accounts", icon: IconAccount, label: "Accounts" },
        ]
    },
    {
        route: "/reports",
        icon: IconReports,
        label: "Reports",
        isAdvanceMenu: false,
        isSelected: false,
    },
    {
        route: "/pnl",
        icon: IconProfitAndLoss,
        label: "P & L",
        isSelected: false,
        isAdvanceMenu: true,
    },
    {
        route: "/bs",
        icon: IconBalanceSheet,
        label: "Balance Sheet",
        isAdvanceMenu: true,
        isSelected: false,
    },
]

interface ISideBarProps extends WithTheme, WithStyles, RouteComponentProps, IWindowState {
    isOpenDrawer: boolean,
}

interface ISideBarState {
    anchorEl: any
    lstCurrentSubMenu: any
}

class SideBar extends React.Component<ISideBarProps, ISideBarState> {
    constructor(props: ISideBarProps) {
        super(props);
        this.state = {
            anchorEl: null,
            lstCurrentSubMenu: []
        };
    }

    onItemClick = (item: any) => {
        if (item.subMenu) {
            item.isSelected = !item.isSelected
            this.forceUpdate();
        }
        else {
            this.props.history.push(item.route);
            if (!utils.isDesktop())
                winState.setGlobal({ isMenuOpen: false });
        }
    }

    componentDidMount() {
        for (let item of lstTabs) {
            if (item.subMenu) {
                for (let subItem of item.subMenu) {
                    if (subItem.shortcutKey)
                        Mousetrap.bind([subItem.shortcutKey], () => {
                            this.onItemClick(subItem)
                            return false;
                        });
                }
            }
            else {
                if (item.shortcutKey)
                    Mousetrap.bind([item.shortcutKey], () => {
                        this.onItemClick(item)
                        return false;
                    });
            }
        }
    }

    handleEnterHover = (event: any, item: any) => {
        if (!this.props.isOpenDrawer)
            if (item.subMenu)
                this.setState({ anchorEl: event.currentTarget, lstCurrentSubMenu: item.subMenu })
    }

    handleLeaveHover = () => {
        if (!this.props.isOpenDrawer)
            this.setState({ anchorEl: null, lstCurrentSubMenu: [] })
    }

    render() {
        const { classes } = this.props;
        return (
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, this.props.isOpenDrawer ? classes.drawerOpen : classes.drawerClose)}
                classes={{
                    paper: clsx(this.props.isOpenDrawer ? classes.drawerOpen : classes.drawerClose, {
                        [classes.drawerBoxShadow]: true,
                        [classes.drawerColor]: true,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                </div>
                <Divider />
                <List>
                    {lstTabs.map((item, index) => {
                        // console.log(this.props.location)
                        let isSelected = this.props.location.pathname.includes(item.route);
                        // let isSelectedSubMenuItem = (item.subMenuRoutes && item.subMenuRoutes.includes(this.props.location.pathname)) || false;

                        // console.log(isSelected, "isSelected")
                        if (!winState.state.companySettings.showAdvanceMenu && item.isAdvanceMenu) {
                            return null;
                        }

                        return (
                            <Tooltip title={item.label} key={index} placement="right">
                                <React.Fragment>
                                    <ListItem
                                        style={isSelected ? { background: "linear-gradient(90deg, transparent, rgba(256,256,256,0.5))", borderRadius: 5, paddingTop: 3, paddingBottom: 3 } : { paddingTop: 3, paddingBottom: 3 }}
                                        button
                                        onClick={(e: any) => { e.stopPropagation(); e.preventDefault(); this.onItemClick(item) }}
                                        onMouseEnter={(event: any) => this.handleEnterHover(event, item)}
                                    >
                                        <ListItemIcon >
                                            <item.icon style={{ color: HyperTheme.palette.common.white, fontSize: 22 }} />
                                        </ListItemIcon>
                                        <ListItemText primary={item.label} classes={{ primary: classes.primaryText }} style={{ color: HyperTheme.palette.common.white, fontSize: 12 }} />
                                        {this.props.isOpenDrawer &&
                                            <ListItemSecondaryAction>
                                                {item.shortcutKey &&
                                                    <Typography variant="caption">
                                                        {item.shortcutKey.toUpperCase()}
                                                    </Typography>
                                                }
                                                {item.subMenu &&
                                                    <React.Fragment>
                                                        {item.isSelected ? <IconLess /> : <IconMore />}
                                                    </React.Fragment>
                                                }
                                            </ListItemSecondaryAction>
                                        }
                                    </ListItem>
                                    {(item.subMenu && item.isSelected && this.props.isOpenDrawer) &&
                                        <React.Fragment>
                                            <List style={{ paddingLeft: 30 }}>
                                                {item.subMenu.map((subMenuItem: any, subItemIndex: number) => {
                                                    let isSubItemSelected = this.props.location.pathname.includes(subMenuItem.route);

                                                    return <ListItem
                                                        button
                                                        key={subItemIndex}
                                                        style={isSubItemSelected ? { background: "linear-gradient(90deg, transparent, rgba(256,256,256,0.5))", borderRadius: 5, paddingTop: 3, paddingBottom: 3 } : { paddingTop: 3, paddingBottom: 3 }}
                                                        onClick={(e: any) => { this.onItemClick(subMenuItem) }}>
                                                        <ListItemIcon>
                                                            <subMenuItem.icon style={{ color: HyperTheme.palette.common.white, fontSize: 22 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={subMenuItem.label} classes={{ primary: classes.primaryText }} style={{ color: HyperTheme.palette.common.white, fontSize: 12 }} />
                                                        {subMenuItem.shortcutKey &&
                                                            <ListItemSecondaryAction>
                                                                <Typography variant="caption">
                                                                    {subMenuItem.shortcutKey.toUpperCase()}
                                                                </Typography>
                                                            </ListItemSecondaryAction>
                                                        }
                                                    </ListItem>
                                                })}
                                            </List>
                                            <Divider style={{ backgroundColor: 'white' }} />
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            </Tooltip>
                        )
                    })}
                </List>
                <Divider />
                <SideMenuPopover
                    anchorEl={this.state.anchorEl}
                    handleClose={this.handleLeaveHover}
                    lstSubMenu={this.state.lstCurrentSubMenu}

                />
            </Drawer>
        );
    }
}


export default withWindowState(withStyles(styles, { withTheme: true })(withRouter(SideBar)), ["companySettings", "global"]);