import React from 'react'
import { Button, Container, Grid, withTheme, Theme } from '@material-ui/core'
import AddUpdatePayment from './AddUpdatePayment';
import utils from '../../Services/Utils';
import NotificationSkeleton from '../../Components/Skeletons/NotificationSkeleton';
import DataTable from '../../Components/DataTable';
import { Journal } from '../../Types/AllTypes';
import api from '../../Services/ApiServices';
import { JournalSearchOptions } from '../../Types/SearchOptions';
import AccountText from '../Accounts/AccountText';
import enums from '../../enums';
import { IconAdd } from '../../Components/Icons/Icons';
import AddUpdatePurchasePayment from './AddUpdatePurchasePayment';
import HyperModal from '../../Components/HyperInput/HyperModal';

interface PaymentProps {
    theme: Theme
}

interface PaymentState {
    shouldShowSkeleton: boolean,
    lstJournal: Journal[],
    currentData?: Journal
    isDialogOpen: boolean
    name: any
}

class Payments extends React.Component<PaymentProps, PaymentState> {
    constructor(props: PaymentProps) {
        super(props);
        let state: PaymentState = {
            shouldShowSkeleton: true,
            lstJournal: [],
            currentData: undefined,
            isDialogOpen: false,
            name: ""
        };

        this.state = state;
        this.purchasePaymentModal = new HyperModal(this);
    }
    purchasePaymentModal: HyperModal;
    componentDidMount() {
        this.loadPayments();
    }

    async loadPayments() {
        try {
            let so = new JournalSearchOptions();
            so.category = enums.JOURNAL_CATEGORY.PAYMENT;
            let lstJournal = await api.getJournals(so);
            this.setState({ lstJournal, shouldShowSkeleton: false })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }

    }

    onAddClick = () => {
        this.setState({ isDialogOpen: true, currentData: undefined });
    }

    onEditClick = (objJournal: Journal) => {
        this.setState({ isDialogOpen: true, currentData: objJournal })
    }

    onDeleteClick = async (objJournal: Journal) => {
        if (objJournal.info?.purchaseId)
            return utils.showWarning("You can not delete system generated receipt.")
        let confirmed = await utils.showConfirm("Do you really want to delete this?", "");
        if (confirmed) {
            try {
                utils.showLoader();
                await api.deleteJournal(objJournal.id);
                this.loadPayments();
            }
            catch (ex) {
                utils.showError(ex.msg || ex.message)
            }
            finally {
                utils.hideLoader();
            }
        }
    }

    toggleAddUpdateJournalModal = () => {
        this.setState({ isDialogOpen: !this.state.isDialogOpen })
    }

    render() {

        return (
            <Container>
                <div style={{ textAlign: 'end', marginBottom: 20 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={this.purchasePaymentModal.toggle}
                        startIcon={<IconAdd />}
                    >
                        Purchase Payment
                    </Button>{"  "}
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={this.onAddClick}
                        startIcon={<IconAdd />}
                    >
                        New
                    </Button>
                </div>
                {/* <AccountAutoComplete
                    // accountId={1}
                    onChange={console.log}
                    required
                /> */}
                <Grid container>
                    {/* <Grid item xs={12} style={{ marginBottom: theme.spacing(2) }}>
                        <MuiAlert elevation={6} variant="filled" severity="info">
                            If you 
                        </MuiAlert>
                    </Grid> */}

                    <Grid item xs={12}>
                        {this.state.shouldShowSkeleton ? (
                            <NotificationSkeleton />
                        ) : (
                                <DataTable
                                    title="Payments"
                                    columns={[
                                        {
                                            title: "ID", render: (rowData: Journal) => {
                                                return `#${rowData.id}`;
                                            },
                                            sortField: "id"
                                        },
                                        {
                                            title: "Payment To", render: (rowData: Journal) => {
                                                return <AccountText objAccount={rowData.debitAccount} />
                                            },
                                            sortField: "debitAccount.name"
                                        },
                                        {
                                            title: "Paid Using", render: (rowData: Journal) => {
                                                return <AccountText objAccount={rowData.creditAccount} />
                                            },
                                            sortField: "creditAccount.name"
                                        },

                                        {
                                            title: "Date", render: (rowData: Journal) => {
                                                return utils.convertToSystemDateFormate(new Date(rowData.date));
                                            },
                                            sortField: "date",
                                            renderReturnsString: true
                                        },
                                        { title: "Amount", field: "amount" },
                                        { title: "Comment", field: "comment" },
                                    ]}
                                    data={this.state.lstJournal}
                                    options={{
                                        sorting: true,
                                    }}
                                    onEdit={(rowData: Journal) => { this.onEditClick(rowData) }}
                                    onDelete={(rowData: Journal) => { this.onDeleteClick(rowData) }}
                                // onView={this.viewUser}
                                />
                            )}
                    </Grid>
                </Grid>
                {this.state.isDialogOpen &&
                    <AddUpdatePayment
                        onComplete={this.loadPayments.bind(this)}
                        isOpen={this.state.isDialogOpen}
                        toggle={this.toggleAddUpdateJournalModal}
                        objJournal={this.state.currentData}
                    />
                }
                {!!(this.purchasePaymentModal.isOpen()) &&
                    <AddUpdatePurchasePayment
                        onComplete={this.loadPayments.bind(this)}
                        isOpen={this.purchasePaymentModal.isOpen()}
                        toggle={this.purchasePaymentModal.toggle}
                        objJournal={this.state.currentData}
                    />
                }
            </Container>
        );
    }
}

export default withTheme(Payments);