import React from "react";
import { Button, Container, Grid } from "@material-ui/core";
import AddUpdateJournal from "./AddUpdateJournal";
import utils from "../../Services/Utils";
import NotificationSkeleton from "../../Components/Skeletons/NotificationSkeleton";
import DataTable from "../../Components/DataTable";
import { Journal } from "../../Types/AllTypes";
import api from "../../Services/ApiServices";
import { JournalSearchOptions } from "../../Types/SearchOptions";
import AccountText from "../Accounts/AccountText";
import { IconAdd } from "../../Components/Icons/Icons";
import scanRUtils, { winState } from "../../Services/ScanRUtilService";
import HyperSelectField from "../../Components/HyperInput/HyperSelectField";
import HyperModal from "../../Components/HyperInput/HyperModal";
import AddBulkJournal from "./AddBulkJournal";

interface JournalsProps {}

interface JournalsState {
  shouldShowSkeleton: boolean;
  lstJournal: Journal[];
  currentData?: Journal;
  isDialogOpen: boolean;
  name: any;
  sortBy: string;
}

class Journals extends React.Component<JournalsProps, JournalsState> {
  constructor(props: JournalsProps) {
    super(props);
    let state: JournalsState = {
      shouldShowSkeleton: true,
      lstJournal: [],
      currentData: undefined,
      isDialogOpen: false,
      name: "",
      sortBy: "",
    };

    this.state = state;
    this.bulkModal = new HyperModal(this);
  }
  bulkModal: HyperModal;

  componentDidMount() {
    this.loadJournals();
  }

  async loadJournals() {
    try {
      let so = new JournalSearchOptions();
      so.sort = this.state.sortBy;

      let lstJournal = await api.getJournals(so);
      this.setState({ lstJournal, shouldShowSkeleton: false });
    } catch (ex) {
      utils.showError(ex.msg || ex.message);
    }
  }

  onAddClick = () => {
    this.setState({ isDialogOpen: true, currentData: undefined });
  };

  onEditClick = (objJournal: Journal) => {
    this.setState({ isDialogOpen: true, currentData: objJournal });
  };

  onDeleteClick = async (objJournal: Journal) => {
    let confirmed = await utils.showConfirm(
      "Do you really want to delete this?",
      ""
    );
    if (confirmed) {
      try {
        utils.showLoader();
        await api.deleteJournal(objJournal.id);
        this.loadJournals();
      } catch (ex) {
        utils.showError(ex.msg || ex.message);
      } finally {
        utils.hideLoader();
      }
    }
  };

  toggleAddUpdateJournalModal = () => {
    this.setState({ isDialogOpen: !this.state.isDialogOpen });
  };

  render() {
    const companySettings = winState.state.companySettings;

    return (
      <Container>
        <div style={{ textAlign: "end", marginBottom: 20 }}>
          {companySettings.hyperAdvanceMode && (
            <>
              <Button
                style={{ marginRight: 8 }}
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => this.bulkModal.toggle()}
                startIcon={<IconAdd />}
              >
                Add Bulk
              </Button>
              <HyperSelectField
                selectedValue={this.state.sortBy}
                labelWidth={100}
                onChange={async (e: any) => {
                  await this.setState({ sortBy: e.target.value });
                  this.loadJournals();
                }}
                options={scanRUtils.getJournalSortOption()}
                optionIdField="key"
                optionNameField="name"
                label="Sort By"
              />
            </>
          )}
          <Button
            style={{ marginLeft: 8 }}
            variant="contained"
            color="primary"
            size="large"
            onClick={this.onAddClick}
            startIcon={<IconAdd />}
          >
            New
          </Button>
        </div>
        <Grid item xs={12}>
          {this.state.shouldShowSkeleton ? (
            <NotificationSkeleton />
          ) : (
            <DataTable
              title="Journals"
              columns={[
                {
                  title: "#",
                  render: (rowData: Journal) => {
                    return `#${rowData.id}`;
                  },
                  sortField: "id",
                },
                {
                  title: "Debit Account",
                  render: (rowData: Journal) => {
                    return <AccountText objAccount={rowData.debitAccount} />;
                  },
                  sortField: "debitAccount.name",
                },

                {
                  title: "Credit Account",
                  render: (rowData: Journal) => {
                    return <AccountText objAccount={rowData.creditAccount} />;
                  },
                  sortField: "creditAccount.name",
                },

                {
                  title: "Date",
                  render: (rowData: Journal) => {
                    return utils.convertToSystemDateFormate(
                      new Date(rowData.date)
                    );
                  },
                  sortField: "date",
                  renderReturnsString: true,
                },
                {
                  title: "Transaction Date",
                  render: (rowData: Journal) => {
                    return rowData.transactionDate
                      ? utils.convertToSystemDateFormate(
                          new Date(rowData.transactionDate)
                        )
                      : "-";
                  },
                  sortField: "transactionDate",
                  renderReturnsString: true,
                  hidden: !companySettings.hyperAdvanceMode,
                },
                { title: "Amount", field: "amount" },
                { title: "Comment", field: "comment" },
              ]}
              data={this.state.lstJournal}
              options={{
                sorting: true,
              }}
              onEdit={(rowData: Journal) => {
                this.onEditClick(rowData);
              }}
              onDelete={(rowData: Journal) => {
                this.onDeleteClick(rowData);
              }}
              // onView={this.viewUser}
            />
          )}
        </Grid>
        {this.state.isDialogOpen && (
          <AddUpdateJournal
            onComplete={this.loadJournals.bind(this)}
            isOpen={this.state.isDialogOpen}
            toggle={this.toggleAddUpdateJournalModal}
            objJournal={this.state.currentData}
          />
        )}

        {this.bulkModal.isOpen() && (
          <AddBulkJournal
            onComplete={() => {}}
            isOpen={this.bulkModal.isOpen()}
            toggle={this.bulkModal.toggle}
            lstJournal={this.state.lstJournal}
          />
        )}
      </Container>
    );
  }
}

export default Journals;
