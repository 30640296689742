import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { Document } from '@react-pdf/renderer'
import { Page } from '@react-pdf/renderer'
import { Font } from '@react-pdf/renderer';
import {  withStyles, Theme, createStyles } from '@material-ui/core';
import { ISales } from '../../Types/AllTypes';
import moment from 'moment';

interface InvoiceTemplateProps {
    objSales?: ISales
    isPDFMode: boolean
    theme: Theme
}
interface InvoiceTemplateState {
}

Font.register({
    family: 'Nunito',
    fonts: [
        { src: 'https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofINeaE.ttf' },
        { src: 'https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKUYevN.ttf', fontWeight: 600 },
    ],
})

const styles = StyleSheet.create({
    page: { backgroundColor: 'tomato' },
    logo: { marginTop: 50 },
    section: { color: 'white', textAlign: 'center', margin: 30 },
    container: {
        padding: "20px",
        fontFamily: "Nunito",
    },
    header: {
        display: 'flex',
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        paddingBottom: "20px",
        borderBottomWidth: 2,
        borderBottomColor: 'black',
        borderBottomStyle: 'solid',
    },
    middle: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        marginTop: "20px",
        paddingBottom: "20px",
        fontSize: "13pt",
        borderBottomWidth: 1,
        borderBottomColor: 'black',
        borderBottomStyle: 'solid',

    },
    table: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        fontSize: "15pt",
        padding: "5px",

    },
    footer: {
        marginTop: "60px",
        paddingTop: "10px",
        borderTopWidth: 1,
        borderTopColor: 'black',
        borderTopStyle: 'solid',
    },
    total: {
        width: "50%",
        display: 'flex',
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "space-around",
        borderTopWidth: 1,
        borderTopColor: 'black',
        borderTopStyle: 'solid',
    },
    fontBold: {
        fontWeight: 'bold'
    }

});

const style = (theme: Theme) =>
    createStyles({
        cell: {
            border: "solid 1px rgb(238,238,238)",
            margin: "0.5px",
        },
        page: {

        }
    });


class InvoiceTemplate extends React.Component<InvoiceTemplateProps, InvoiceTemplateState> {
    constructor(props: InvoiceTemplateProps) {
        super(props);
        let state: InvoiceTemplateState = {
        };
        this.state = state;
    }

    componentDidMount() {
    }

    async loadAccounts() {
        try {
        }
        catch (ex) {
        }
    }

    render() {
        // const InvoiceTemplateWidget = React.createElement(Text);
        console.log(this.props.objSales)
        const { objSales, theme } = this.props;

        if (!this.props.isPDFMode)
            return (
                <div
                    style={{
                        width: '210mm',
                        height: '297mm'
                    }}
                    id="invoiceForPrint"
                >
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td style={{ width: "100%" }}>
                                    <div style={{ marginTop: 25, textAlign: 'center' }}>
                                        <h1 style={{ color: theme.palette.primary.main }}>The Hyper Tech</h1>
                                        <span style={{ fontSize: "0.9rem", color: theme.palette.grey[500] }}>
                                            408, Sahajanand Business Hub, NR. Red and White, Yogi chowk
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "100%" }}>
                                    <div
                                        style={{
                                            color: theme.palette.primary.main,
                                            marginTop: 25,
                                            textAlign: 'center',
                                            border: `3px solid ${theme.palette.primary.main}`,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            borderRadius: 5
                                        }}
                                    >
                                        <h1>INVOICE</h1>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <table style={{ width: "100%", marginTop: 25, paddingBottom: 25, borderBottom: '2px solid lightgrey' }}>
                                        <tbody>
                                            <tr>
                                                <td rowSpan={4} style={{ width: '50%' }}>
                                                    <span>Bill To</span>
                                                    <br />
                                                    <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                                                        {objSales?.debitAccount.name}
                                                    </span> <br />
                                                    <span>{objSales?.debitAccount.info?.mobile}</span><br />
                                                    <span>{objSales?.debitAccount.info?.email}</span>
                                                </td>
                                                <td style={{ width: '25%' }}>

                                                </td>
                                                <td style={{ width: '25%' }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h4 style={{ textAlign: 'right', margin: 0 }}>#</h4>
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {objSales?.info.INVNumber}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h4 style={{ textAlign: 'right', margin: 0 }}>Sales Date</h4>
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {objSales ? moment(objSales.date).format('DD/MM/YYYY') : ''}
                                                </td>
                                            </tr>
                                            <tr style={{ visibility: 'hidden' }}>
                                                <td>Sales Date</td>
                                                <td>{objSales ? moment(objSales.date).format('DD/MM/YYYY') : ''} </td>
                                            </tr>
                                        </tbody>
                                    </table>


                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "100%" }}>
                                    <table style={{ width: "100%", marginTop: 25 }}>
                                        <thead style={{ backgroundColor: "rgb(238, 238, 238)" }}>
                                            <tr>
                                                <th style={{ border: "solid 1px rgb(238,238,238)", margin: "0.5px", paddingTop: 15, paddingBottom: 15 }}>Name</th>
                                                <th style={{ border: "solid 1px rgb(238,238,238)", margin: "0.5px", }}>Quantity</th>
                                                <th style={{ border: "solid 1px rgb(238,238,238)", margin: "0.5px", }}>Price</th>
                                                <th style={{ border: "solid 1px rgb(238,238,238)", margin: "0.5px", }}>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: "center" }}>
                                            {objSales?.info.items.map((item: any, index: number) => {
                                                return <tr key={index}>
                                                    <td style={{ border: "solid 1px rgb(238,238,238)", margin: "0.5px", paddingTop: 5, paddingBottom: 5 }}>{item.name}</td>
                                                    <td style={{ border: "solid 1px rgb(238,238,238)", margin: "0.5px", paddingTop: 5, paddingBottom: 5 }}>{item.qty}</td>
                                                    <td style={{ border: "solid 1px rgb(238,238,238)", margin: "0.5px", paddingTop: 5, paddingBottom: 5 }}>{item.price}</td>
                                                    <td style={{ border: "solid 1px rgb(238,238,238)", margin: "0.5px", paddingTop: 5, paddingBottom: 5 }}>{item.amount}</td>
                                                </tr>
                                            })}
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td style={{ border: "solid 1px rgb(238,238,238)", margin: "0.5px", paddingBottom: 8, paddingTop: 8, fontSize: 20, textAlign: "right", paddingRight: "5px" }}>
                                                    <b>Amount</b>
                                                </td>
                                                <td style={{ border: "solid 1px rgb(238,238,238)", margin: "0.5px", textAlign: "right", paddingRight: "5px" }}>{objSales?.info.amount}</td>
                                            </tr>

                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td style={{ border: "solid 1px rgb(238,238,238)", margin: "0.5px", paddingBottom: 8, paddingTop: 8, fontSize: 20, textAlign: "right", paddingRight: "5px" }}>
                                                    <b>Tax</b>
                                                </td>
                                                <td style={{ border: "solid 1px rgb(238,238,238)", margin: "0.5px", textAlign: "right", paddingRight: "5px" }}>{objSales?.info.tax}</td>
                                            </tr>

                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td style={{ border: "solid 1px rgb(238,238,238)", margin: "0.5px", paddingBottom: 8, paddingTop: 8, fontSize: 20, textAlign: "right", paddingRight: "5px" }}>
                                                    <b>Grand Total</b>
                                                </td>
                                                <td style={{ border: "solid 1px rgb(238,238,238)", margin: "0.5px", textAlign: "right", paddingRight: "5px" }}>{objSales?.amount}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        return (
            <Document>
                <Page size="A4" style={styles.container}>
                    <View style={styles.header}>
                        <View>
                            <Text style={{ fontSize: "20pt" }}>The HyperTech </Text>
                            <Text style={{ fontSize: "10pt", color: "grey" }}>408, Sahajanand Business hub, Surat-395006 </Text>
                        </View>
                        <View>
                            <Text style={{ fontSize: "25pt", fontWeight: "bold" }}>INVOICE</Text>
                        </View>
                    </View>

                    <View style={styles.middle}>
                        <View style={{ width: "50%" }}>
                            <Text style={{ color: "grey", fontSize: "11pt" }}>Bill To</Text>
                            <Text style={{ fontSize: "17pt" }}>{objSales?.debitAccount.name}</Text>
                            <Text style={{}}>{objSales?.debitAccount.info?.mobile}</Text>
                            <Text style={{}}>{objSales?.debitAccount.info?.email}</Text>
                        </View>

                        <View style={{ flexDirection: "column", alignItems: "flex-end" }}>
                            <Text style={{}}>#</Text>
                            <Text style={{}}>Date</Text>
                            <Text style={{}}>Vendor</Text>
                            <Text style={{}}>Payment Mode</Text>
                        </View>

                        <View>
                            <Text style={{}}>{objSales?.info.INVNumber}</Text>
                            <Text style={{}}>{objSales ? moment(objSales.date).format('DD/MM/YYYY') : ''}</Text>
                            <Text style={{}}>Vendor name</Text>
                            <Text style={{}}>Card</Text>
                        </View>

                    </View>

                    <View style={{ width: "100%", marginTop: 25 }}>
                        <View style={{ flex: 1, flexDirection: 'row', backgroundColor: 'lightgrey', padding: 5 }}>
                            <View style={{ flex: 5, }}>
                                <Text style={styles.fontBold}>Name </Text>
                            </View>
                            <View style={{ flex: 1, alignItems: 'center' }}>
                                <Text style={styles.fontBold}>Qty </Text>
                            </View>
                            <View style={{ flex: 2, alignItems: 'center' }}>
                                <Text style={styles.fontBold}>Price </Text>
                            </View>
                            <View style={{ flex: 2, alignItems: 'flex-end' }}>
                                <Text style={styles.fontBold}>Amount </Text>
                            </View>
                        </View>
                        {objSales?.info.items.map((item: any, index: number) => {
                            return <View key={index} style={{
                                borderBottomWidth: 1,
                                borderBottomColor: '#e3e3e3',
                                borderBottomStyle: 'solid',
                                flex: 1,
                                flexDirection: "row",
                                alignItems: 'center',
                                padding: 5
                            }}>
                                <View style={{ flex: 5, paddingVertical: 5 }}>
                                    <Text style={{ fontSize: 15 }}> {item.name}</Text>
                                </View>
                                <View style={{ flex: 1, alignItems: 'center', paddingVertical: 5 }}>
                                    <Text style={{ fontSize: 13 }}>{item.qty}</Text>
                                </View>
                                <View style={{ flex: 2, alignItems: 'center', paddingVertical: 5 }}>
                                    <Text style={{ fontSize: 13 }}>{item.price}</Text>
                                </View>
                                <View style={{ flex: 2, alignItems: 'flex-end', paddingVertical: 5 }}>
                                    <Text style={{ fontSize: 13 }}>{item.amount}</Text>
                                </View>
                            </View>
                        })}


                        <View style={[styles.table, { marginTop: "20px" }]}>
                            <Text style={{}}> </Text>
                            <Text style={{}}></Text>
                            <Text style={{}}> Amount </Text>
                            <Text style={{}}> {objSales?.info.amount} </Text>
                        </View>

                        <View style={styles.table}>
                            <Text style={{}}> </Text>
                            <Text style={{}}></Text>
                            <Text style={{}}> Tax </Text>
                            <Text style={{}}> {objSales?.info.tax} </Text>
                        </View>

                        <View style={styles.table}>
                            <Text style={{}}></Text>
                            <Text style={{}}></Text>
                            <Text style={{}}>Total Amount </Text>
                            <Text style={{}}> {objSales?.amount} </Text>
                        </View>
                    </View>

                    <View style={styles.footer}>
                        <Text style={{ fontSize: "13pt" }}>Terms and Conditions</Text>
                        <Text style={{ color: "grey", fontSize: "11pt" }}>Please make the payment by due date.</Text>
                    </View>
                </Page>
            </Document>

        );
    }
}

export default withStyles(style, { withTheme: true })(InvoiceTemplate);