import utils from "../../Services/Utils";


class HyperModal {
    constructor(that, defaultOpen = false) {
        this.that = that;
        this.isOpenId = utils.getUUID();
        if (!this.that.state)
            this.that.state = {};
        this.that.state[this.isOpenId] = defaultOpen;
    }

    isOpen = () => {
        if (this.that && this.that.state)
            return this.that.state[this.isOpenId];
        return false;
    }

    toggle = (open = undefined, objStateChange = {}) => {
        try {
            let isOpen = !this.isOpen();
            if (open === true) {
                isOpen = true;
            }
            if (open === false) {
                isOpen = false;
            }
            this.that.setState({ [this.isOpenId]: isOpen, ...objStateChange })
            this.onToggle(isOpen);
        }
        catch (ex) {
            console.log(ex)
            // Suppress Error. 
        }
    }

    setOpen = (isOpen) => {
        this.that.state[this.isOpenId] = isOpen;
    }

    onToggle = (isOpen) => {
        // You can override it. 
    }
}

export default HyperModal;