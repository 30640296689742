import React from 'react'
import { Container, withTheme, Theme, Grid, Button, Typography, Paper } from '@material-ui/core'
// import HyperModal from '../../../Components/HyperInput/HyperModal';
import api from '../../../Services/ApiServices';
import { JournalSearchOptions } from '../../../Types/SearchOptions';
import enums from '../../../enums';
import {  ICompanySettings } from '../../../Types/AllTypes';
import moment from "moment";
import HyperDateRangePicker from '../../../Components/HyperDateRangePicker';
import ReportsDataTable from '../../../Components/ReportsDataTable';
import scanRUtils from '../../../Services/ScanRUtilService';
import HyperCompanySelection from '../../../Components/HyperInput/HyperCompanySelection';
import auth from '../../../Services/AuthService';

interface SupplierWisePurchaseProps {
    theme: Theme
}

interface SupplierWisePurchaseState {
    lstSupplierWisePurchase: any
    totalPurchase: number
    isLoading: boolean
    dateRange: any
}


class SupplierWisePurchase extends React.Component<SupplierWisePurchaseProps, SupplierWisePurchaseState> {
    constructor(props: SupplierWisePurchaseProps) {
        super(props);
        let state: SupplierWisePurchaseState = {
            lstSupplierWisePurchase: [],
            totalPurchase: 0,
            isLoading: false,
            dateRange: { startDate: moment().startOf("month").toDate(), endDate: moment().endOf("month").toDate(), label: "This Month" }

        };
        this.state = state;
        this.companySelection = new HyperCompanySelection(this);
    }
    companySelection: HyperCompanySelection;

    componentDidMount() {
        this.getReportDetail()
    }
    getReportDetail = async () => {
        try {
            await this.setState({ isLoading: true })
            let so = new JournalSearchOptions();
            so.category = enums.JOURNAL_CATEGORY.INCOME;
            so.fromDate = moment(this.state.dateRange.startDate).format("YYYY-MM-DD")
            so.toDate = moment(this.state.dateRange.endDate).format("YYYY-MM-DD")
            so.companyIds = this.companySelection.getSelectedCompany().map((item: ICompanySettings) => item.id).join(",")
            // console.log(so, "so")
            let lstSupplierWisePurchase = await api.getSupplierWisePurchase(so);

            let totalAmount = 0;
            lstSupplierWisePurchase.forEach((objSupplier: any) => {
                if (objSupplier.totalPurchase && objSupplier.totalPurchase?.totalAmount) {
                    totalAmount += objSupplier.totalPurchase.totalAmount;
                    objSupplier.totalPurchase.totalAmount = scanRUtils.prettyPrice(objSupplier.totalPurchase.totalAmount);
                }
                objSupplier.companyName = auth.getCompanyNameById(objSupplier.companyId);
            })
            this.setState({ lstSupplierWisePurchase, totalPurchase: totalAmount })
        }
        catch (ex) {
            console.log(ex)
        }
        finally {
            await this.setState({ isLoading: false })
        }
    }

    render() {
        // console.log(this.state.dateRange)
        return (
            <Container>
                <Grid container spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item md={6}>
                        {this.companySelection.renderCompanySelection()}
                    </Grid>
                    <Grid item md={6} style={{ textAlign: 'right', margin: 'auto' }}>
                        <Button onClick={this.getReportDetail} variant="contained" color="primary">Refresh</Button>{" "}
                        <HyperDateRangePicker
                            initialDateRange={this.state.dateRange}
                            onChange={async (range: any) => {
                                await this.setState({ dateRange: range });
                                this.getReportDetail()
                            }}
                        />
                    </Grid>
                </Grid>

                <ReportsDataTable
                    key={Date.now()}
                    title={`Supplier Wise Purchase`}
                    columns={[
                        { title: "Supplier Id", field: "id" },
                        { title: "Supplier Name", field: "name" },
                        { title: "Purchase Amount", field: "totalPurchase.totalAmount" },
                        { title: "Company Name",hidden:auth.isSingleCompany(), field: 'companyName' },
                    ]}
                    data={this.state.lstSupplierWisePurchase}
                    options={{
                        sorting: true,

                    }}
                    isLoading={this.state.isLoading}
                    onRowClick={() => { }}
                />
                <Paper elevation={3} style={{ marginTop: 20, paddingTop: 30, paddingBottom: 30, padding: 20 }}>
                    <Grid container>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6">Total Purchase</Typography>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6" color="primary">{scanRUtils.prettyPrice(this.state.totalPurchase)}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        );
    }
}

export default withTheme(SupplierWisePurchase);