import React from 'react'
import { Container, withTheme, Theme, Grid, Paper, Typography, Button } from '@material-ui/core'
import api from '../../../Services/ApiServices';
import ReportsDataTable from '../../../Components/ReportsDataTable';
import scanRUtils from '../../../Services/ScanRUtilService';
import { JournalSearchOptions } from '../../../Types/SearchOptions';
import enums from '../../../enums';
import { ISales, IWindowState, ICustomer } from '../../../Types/AllTypes';
import utils from '../../../Services/Utils';
import HyperDateRangePicker from '../../../Components/HyperDateRangePicker';
import moment from "moment";
import Invoice from '../../Invoice/Invoice';
import withWindowState from '../../../Components/withWindowState';
import { ICompanySettings } from '../../../Types/AllTypes';
import HyperCompanySelection from '../../../Components/HyperInput/HyperCompanySelection';
import auth from '../../../Services/AuthService';

interface SalesReportProps extends IWindowState {
    theme: Theme
}

interface SalesReportState {
    lstSales: ISales[]
    totalSales: number
    isLoading: boolean
    dateRange: any
}
const paymentStatus: any = {
    [enums.PAYMENT_STATUS.PAID]: 'green',
    [enums.PAYMENT_STATUS.UNPAID]: 'red',
    [enums.PAYMENT_STATUS.PARTIALLY_PAID]: 'orange',
}

class SalesReport extends React.Component<SalesReportProps, SalesReportState> {
    constructor(props: SalesReportProps) {
        super(props);
        let state: SalesReportState = {
            lstSales: [],
            totalSales: 0,
            isLoading: false,
            dateRange: { startDate: moment().startOf("month").toDate(), endDate: moment().endOf("month").toDate(), label: "This Month" }

        };
        this.state = state;
        this.companySelection = new HyperCompanySelection(this);
    }
    companySelection: HyperCompanySelection;

    componentDidMount() {
        this.getReportDetail()

    }
    getReportDetail = async () => {
        try {
            await this.setState({ isLoading: true })
            let so = new JournalSearchOptions();
            so.category = enums.JOURNAL_CATEGORY.SALES;
            so.fromDate = moment(this.state.dateRange.startDate).format("YYYY-MM-DD")
            so.toDate = moment(this.state.dateRange.endDate).format("YYYY-MM-DD")
            so.companyIds = this.companySelection.getSelectedCompany().map((item: ICompanySettings) => item.id).join(",")
            // console.log(so, "so")
            let lstSales = await api.getJournals(so);

            let totalAmount = 0;
            lstSales.forEach((objSales: any) => {
                totalAmount = totalAmount + objSales.amount;
                objSales.companyName = auth.getCompanyNameById(objSales.companyId)
            })
            this.setState({ lstSales, totalSales: totalAmount })
        }
        catch (ex) {
            console.log(ex)
        }
        finally {
            await this.setState({ isLoading: false })
        }
    }

    downloadPDF = async () => {
        // console.log("doing")
        let longString = "";
        let lstFrames: any = document.getElementsByClassName("invoiceIframe")
        for (let frame of lstFrames) {
            longString += frame.contentDocument.body.innerHTML;
            longString += `<div style="page-break-after: always;" id="pageBreak"></div>`;
        }

        let frame = document.createElement("iframe")
        document.body.appendChild(frame);
        frame.style.display = "none";
        frame.srcdoc = longString;

        frame.onload = () => {
            console.log("did");
            frame.contentWindow?.print();
            // frame.remove()
        }
    }

    render() {
        // console.log(this.state.lstSales)
        return (
            <Container>
                <Grid container style={{ marginBottom: 20 }}>
                    <Grid item md={6}>
                        {this.companySelection.renderCompanySelection()}
                    </Grid>
                    <Grid item md={6} style={{ textAlign: 'right', margin: 'auto' }}>

                        <Button onClick={this.getReportDetail} variant="contained" color="primary">Refresh</Button>{" "}
                        <Button onClick={this.downloadPDF} variant="contained" color="primary">Download PDF</Button>{" "}
                        <HyperDateRangePicker
                            initialDateRange={this.state.dateRange}
                            onChange={async (range: any) => {
                                await this.setState({ dateRange: range });
                                this.getReportDetail()
                            }}
                        />
                    </Grid>
                </Grid>
                <ReportsDataTable
                    key={Date.now()}
                    title="Sales Report"
                    columns={[
                        {
                            title: "Invoice No.", render: (rowData: ISales) => {
                                return `#${rowData.info.INVNumber}`;
                            },
                            sortField: "info.INVNumber"
                        },
                        { title: "Customer Name", field: "debitAccount.name" },
                        {
                            title: "Sales Date",
                            render: (rowData: any) => {
                                return utils.convertToSystemDateTimeFormate(rowData.date)
                            },
                            sortField: "date",
                            renderReturnsString: true,
                        },
                        {
                            title: "Due Date",
                            render: (rowData: any) => {
                                return utils.convertToSystemDateTimeFormate(rowData.info.dueDate || rowData.date)
                            },
                            sortField: "info.dueDate",
                            renderReturnsString: true,
                        },
                        {
                            title: "Amount",
                            render: (rowData: ISales) => {
                                return scanRUtils.prettyPrice(rowData.amount)
                            },
                            sortField: "amount"
                        },
                        {
                            title: "Due Amount",
                            render: (rowData: ISales) => {
                                return scanRUtils.prettyPrice(rowData.info.totalDueAmount)
                            },
                            sortField: "info.totalDueAmount"
                        },
                        {
                            title: "Payment Status",
                            render: (rowData: ISales) => {

                                return <Typography style={{ color: paymentStatus[rowData.info.paymentStatus] }}>{rowData.info.paymentStatus}</Typography>
                            },
                            sortField: "info.totalDueAmount"
                        },
                        { title: "Company Name", hidden: auth.isSingleCompany(), field: 'companyName' },
                    ]}
                    data={this.state.lstSales}
                    options={{
                        sorting: true,
                    }}
                    isLoading={this.state.isLoading}
                    onRowClick={() => { }}
                />
                <Paper elevation={3} style={{ marginTop: 20, paddingTop: 30, paddingBottom: 30, padding: 20 }}>
                    <Grid container>
                        <Grid item md={6} xs={6}>
                            <Typography variant="h6">Total Sales</Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Typography variant="h6" align="right" color="primary">{scanRUtils.prettyPrice(this.state.totalSales)}</Typography>
                        </Grid>
                    </Grid>
                </Paper>

                <div id="salesInvoice" style={{ display: "none" }}>
                    {this.state.lstSales.map((objSales: ISales) => {
                        return (
                            <Invoice
                                key={objSales._id}
                                objSales={objSales}
                                objCompany={this.props.companySettings}
                                objCustomer={objSales?.debitAccount as ICustomer}
                                isReport={true}
                            />
                        )
                    })}
                </div>
            </Container>
        );
    }
}

export default withWindowState(withTheme(SalesReport), ["companySettings", "user"]);
