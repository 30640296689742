import React from 'react'
import { Typography } from '@material-ui/core'
import AccountDetail from './AccountDetail';
import { IAccount } from '../../Types/AllTypes';

interface AccountTextProps {
    objAccount: IAccount
}

interface AccountTextState {
    isOpenAccountDetailModal: boolean
}

class AccountText extends React.Component<AccountTextProps, AccountTextState> {
    constructor(props: AccountTextProps) {
        super(props);
        let state: AccountTextState = {
            isOpenAccountDetailModal: false
        };

        this.state = state;
    }

    componentDidMount() {

    }

    toggleAccountDetailModal = async () => {
        this.setState({ isOpenAccountDetailModal: !this.state.isOpenAccountDetailModal })
    }


    render() {
        return (
            <React.Fragment>
                <Typography color={"primary"} style={{ cursor: "pointer" }} onClick={this.toggleAccountDetailModal}>
                    {this.props.objAccount.name}
                </Typography>
                {this.state.isOpenAccountDetailModal &&
                    <AccountDetail
                        isOpen={this.state.isOpenAccountDetailModal}
                        objAccount={this.props.objAccount}
                        toggleModal={this.toggleAccountDetailModal}
                    />
                }
            </React.Fragment>
        );
    }
}

export default AccountText;