import React from 'react'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import Template from './InvoiceTemplate';


interface InvoiceDownloadProps {

}
interface InvoiceDownloadState {
  productLines: any
}


class InvoiceDownload extends React.Component<InvoiceDownloadProps, InvoiceDownloadState> {
  constructor(props: InvoiceDownloadProps) {
    super(props);
    let state: InvoiceDownloadState = {
      productLines: []
    };
    this.state = state;
  }

  componentDidMount() {
  }

  render() {
    return (
      <div>
        <PDFDownloadLink
          document={<Template isPDFMode={true} />}
          fileName={`invoice.pdf`}
          aria-label="Save PDF"
        >
          {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
        </PDFDownloadLink>

        <PDFViewer style={{ width: '100%', height: 750 }}>
          <Template isPDFMode={true} />
        </PDFViewer>
      </div>
    );
  }
}

export default InvoiceDownload;
