import React, { FormEvent } from 'react'
import enums from '../../enums';
import CommonModal from '../../Components/CommonModal';
import { Grid, Button } from '@material-ui/core';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import { IAccount, ISalesItem, IWindowState, IDiscount, IPurchaseReturn, IPurchase } from '../../Types/AllTypes';
import HyperForm from '../../Components/HyperInput/HyperForm';
import api from '../../Services/ApiServices';
import utils from '../../Services/Utils';
import AccountAutoComplete from '../Journal/AccountAutoComplete';
import scanRUtils, { winState } from '../../Services/ScanRUtilService';
import { JournalInsertObject } from '../../Types/InsertObjects';
import withWindowState from '../../Components/withWindowState';
import HyperDateTimeField from '../../Components/HyperInput/HyperDateTimeField';
import HyperSelectField from '../../Components/HyperInput/HyperSelectField';
import Alert from '@material-ui/lab/Alert';
import PurchaseReturnItems from './PurchaseReturnItems';
import SalesReturnCustomerInfo from '../SalesReturn/SalesReturnCustomerInfo';

interface AddUpdatePurchaseReturnProps extends IWindowState {
    objPurchaseReturn?: IPurchaseReturn
    invNumber?: string
    isOpen: boolean


    onComplete?: (e?: any) => void
    toggle: () => void
}

interface AddUpdatePurchaseReturnState {
    objPurchaseReturn: IPurchaseReturn
    objPurchase: IPurchase
    invNumber: string
    objDiscount: IDiscount
    showInModalError: boolean,
    showSales: boolean,
    inModalErrorMsg: string,
    supplierId: number,
}

const objDefaultSalesItem = {
    objItem: {},
    qty: 1,
    price: 0,
} as ISalesItem;

const SALES_RETURN_TAX_TYPE_NAME: any = enums.PURCHASE_TAX_TYPE_NAME;
const salesReturnTaxType: any = Object.keys(SALES_RETURN_TAX_TYPE_NAME).map((item: any) => {
    return { id: item, value: item, name: SALES_RETURN_TAX_TYPE_NAME[item] }
})

class AddUpdatePurchaseReturn extends React.Component<AddUpdatePurchaseReturnProps, AddUpdatePurchaseReturnState> {
    constructor(props: AddUpdatePurchaseReturnProps) {
        super(props);
        let state: AddUpdatePurchaseReturnState = {
            objPurchaseReturn: {} as IPurchaseReturn,
            objPurchase: {} as IPurchase,
            showInModalError: false,
            showSales: false,
            supplierId: 0,
            invNumber: "",
            inModalErrorMsg: "",
            objDiscount: { discountType: enums.DISCOUNT_OPTION.PERCENTAGE, discountAmount: 0, discountPercentage: 0 }
        };
        // console.log(this.props.objPurchaseReturn)
        if (this.props.objPurchaseReturn) {
            state.objPurchaseReturn = this.props.objPurchaseReturn
            state.showSales = true
            this.isEditing = true;
        }
        else {
            state.objPurchaseReturn.DNNumber = (winState.state.companySettings.currentDNNumber + 1).toString();
            state.objPurchaseReturn.date = new Date().toString();
            state.objPurchaseReturn.lstPurchaseItem = [objDefaultSalesItem];
            state.objPurchaseReturn.invoiceTaxType = enums.PURCHASE_TAX_TYPE.NO_TAX
        }
        if (state.objPurchaseReturn.info) {

            state.objPurchaseReturn.lstPurchaseItem = [];
            state.objPurchaseReturn.invoiceDate = state.objPurchaseReturn.info.invoiceDate
            state.objPurchaseReturn.invoiceTaxType = state.objPurchaseReturn.info.invoiceTaxType
            state.objPurchaseReturn.DNNumber = state.objPurchaseReturn.info.DNNumber
            state.objPurchaseReturn.supplierInfo = state.objPurchaseReturn.info.supplierInfo
            state.invNumber = state.objPurchaseReturn.info.INVNumber
            state.supplierId = state.objPurchaseReturn.debitAccountId
        }

        this.state = state;
        this.frm = new HyperForm();
    }
    frm: HyperForm
    isEditing: boolean = false;


    async componentDidMount() {
        let state = this.state;
        let lstPurchaseItem = null;
        if (state.objPurchaseReturn.info) {
            lstPurchaseItem = state.objPurchaseReturn.info.items;
            for (let objSalesItem of lstPurchaseItem) {
                objSalesItem.objItem = await scanRUtils.getItem(objSalesItem.id) as any;
            }
            state.objPurchaseReturn.lstPurchaseItem = lstPurchaseItem;
            this.forceUpdate();
        }
    }

    setPurchaseReturn(obj: Partial<IPurchaseReturn>) {
        this.setState({ objPurchaseReturn: { ...this.state.objPurchaseReturn, ...obj } });
    }

    onSearchINVNumber = async () => {
        try {
            if (!this.state.supplierId)
                return utils.showError("Please Select Supplier.")

            if (!this.state.invNumber)
                return utils.showError("Please Enter Invoice Number.")

            utils.showLoader()

            let lstPurchase = await api.getPurchaseByINVAndId(this.state.invNumber, this.state.supplierId, enums.JOURNAL_CATEGORY.PURCHASE);

            console.log(lstPurchase, "lstPurchase")

            let objPurchaseReturn: any = {};
            let objPurchase;
            if (!lstPurchase || !lstPurchase[0])
                return this.setState({ showInModalError: true, showSales: false, inModalErrorMsg: "Purchase Not Found in given Supplier Name and Invoice Number." });

            objPurchase = lstPurchase[0];
            if (this.isEditing) {
                await this.setState({ objPurchase, showSales: true, showInModalError: false })
                return;
            }
            let lstPurchaseItem = null;
            if (objPurchase.info) {
                lstPurchaseItem = objPurchase.info.items;
                for (let objSalesItem of lstPurchaseItem) {
                    objSalesItem.objItem = await scanRUtils.getItem(objSalesItem.id) as any;
                }
                objPurchaseReturn.lstPurchaseItem = lstPurchaseItem;
            }

            objPurchaseReturn.INVNumber = objPurchase.info.INVNumber;
            objPurchaseReturn.debitAccountId = objPurchase.creditAccountId;
            objPurchaseReturn.debitAccount = objPurchase.creditAccount;
            objPurchaseReturn.creditAccount = objPurchase.debitAccount;
            objPurchaseReturn.creditAccountId = objPurchase.debitAccountId;
            objPurchaseReturn.supplierInfo = objPurchase.creditAccount;
            if (objPurchase.info.invoiceTaxType)
                objPurchaseReturn.invoiceTaxType = objPurchase.info.invoiceTaxType;
            else
                objPurchaseReturn.invoiceTaxType = enums.PURCHASE_TAX_TYPE_NAME.NO_TAX;
            objPurchaseReturn.invoiceDate = objPurchase.date;
            objPurchaseReturn.date = new Date();
            // console.log(objPurchaseReturn, "objPurchase", objPurchase)
            await this.setPurchaseReturn(objPurchaseReturn)
            await this.setState({ objPurchase, showSales: true, showInModalError: false })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }

    onSubmit = async (e: FormEvent) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.frm.setIsFormSubmitted(true);
            if (this.frm.isValid()) {
                let objTotalCount = scanRUtils.getTotalCounts(this.state.objPurchaseReturn.lstPurchaseItem, this.state.objDiscount)
                this.state.objPurchaseReturn.amount = objTotalCount.amountWithTax;

                let io = new JournalInsertObject();
                io.id = this.state.objPurchaseReturn.id;
                io.debitAccountId = this.state.objPurchaseReturn.debitAccountId;
                io.creditAccountId = this.state.objPurchaseReturn.creditAccountId;
                io.amount = this.state.objPurchaseReturn.amount;
                io.comment = this.state.objPurchaseReturn.comment || "Sales Return";
                io.date = this.state.objPurchaseReturn.date;
                io.info = this.getInfo(this.state.objPurchaseReturn.lstPurchaseItem);
                io.category = enums.JOURNAL_CATEGORY.PURCHASE_RETURN;
                utils.showLoader();
                // console.log(io)
                // return
                let result = null;
                result = await api.addUpdatePurchaseReturn(io, [])

                if (!io.id)
                    this.updateInvoiceNumber();

                this.props.toggle();
                if (this.props.onComplete) {
                    this.props.onComplete(result);
                }
            }
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader()
        }
    }

    updateInvoiceNumber = async () => {
        try {
            await api.updateCompanySettings({ currentDNNumber: this.state.objPurchaseReturn.DNNumber });
            winState.setCompanySettings({ currentDNNumber: parseInt(this.state.objPurchaseReturn.DNNumber) });
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message);
        }
    }

    getInfo(lstPurchaseItem: ISalesItem[]) {
        let obj: any = {};
        let objTotalCount = scanRUtils.getTotalCounts(this.state.objPurchaseReturn.lstPurchaseItem, this.state.objDiscount);
        obj = { ...objTotalCount };
        obj.amount = objTotalCount.amountWithTax;

        obj.INVNumber = this.state.objPurchaseReturn.INVNumber || this.state.invNumber;
        obj.DNNumber = this.state.objPurchaseReturn.DNNumber;
        obj.supplierInfo = this.state.objPurchaseReturn.supplierInfo;
        obj.invoiceTaxType = this.state.objPurchaseReturn.invoiceTaxType;
        obj.invoiceDate = this.state.objPurchaseReturn.invoiceDate;
        obj.paymentStatus = this.state.objPurchaseReturn.paymentStatus;

        obj.items = lstPurchaseItem.map(item => {
            let item1: any = item;
            item1.id = item.objItem.id;
            item1.name = item.objItem.name;
            item1.defaultDescription = item.objItem.defaultDescription;
            item1.showTaxOnInvoice = item.objItem.showTaxOnInvoice;

            item1.hsnCode = item.objItem.hsnCode;
            item1.productCode = item.objItem.productCode;
            item1.sacCode = item.objItem.sacCode;
            delete item1.objItem;
            return item1;
        })
        // console.log(obj, "Obj")
        return obj;
    }
    validateInvoiceTaxType = (invoiceTaxType: string) => {
        // console.log(invoiceTaxType, this.state.objSales)
        let supplierInfo = this.state.objPurchaseReturn.supplierInfo;
        if (!supplierInfo)
            return false;
        let isIntraState = scanRUtils.isIntraState(supplierInfo.info?.state)
        if (isIntraState) {
            this.setState({ showInModalError: true })
        }
    }

    render() {
        return (
            <div>
                <CommonModal
                    dialogProps={{ open: this.props.isOpen }}
                    maxWidth="lg"
                    title={"Purchase Return"}
                    toggle={this.props.toggle}
                >
                    <Grid container spacing={2}>
                        {!!(this.state.showInModalError) &&
                            <Grid item xs={12}>
                                <Alert style={{ marginBottom: 20 }} severity="error">{this.state.inModalErrorMsg}</Alert>
                            </Grid>
                        }

                        <Grid item xs={5} style={{ marginTop: 10, marginBottom: 10 }}>
                            <AccountAutoComplete
                                accountId={this.state.supplierId}
                                onChange={(objAccount: IAccount) => {
                                    if (objAccount)
                                        this.setState({ supplierId: objAccount.id });
                                }}
                                label="Supplier"
                                autoFocus
                                category={enums.ACCOUNT_CATEGORY.SUPPLIER}
                                disabled={this.isEditing ? true : false}
                            />
                        </Grid>
                        <Grid item xs={5} style={{ marginTop: 10, marginBottom: 10 }}>
                            <HyperTextField
                                value={this.state.invNumber}
                                onChange={(e: any) => this.setState({ invNumber: e.target.value })}
                                label={"Invoice Number"}
                                type="text"
                                disabled={this.isEditing ? true : false}
                            />
                        </Grid>
                        <Grid item xs={2} style={{ margin: 'auto' }}>
                            <Button variant="contained" color="primary" onClick={this.onSearchINVNumber}>Search</Button>
                        </Grid>
                        <form onSubmit={this.onSubmit}>
                            <Grid container spacing={2}>
                                {this.state.showSales &&
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <AccountAutoComplete
                                                        accountId={this.state.objPurchaseReturn.debitAccountId}
                                                        onChange={(objAccount: IAccount) => {
                                                            if (objAccount)
                                                                this.setPurchaseReturn({ debitAccountId: objAccount.id, supplierInfo: objAccount });
                                                        }}
                                                        label="Supplier"
                                                        frm={this.frm}
                                                        required
                                                        autoFocus
                                                        category={enums.ACCOUNT_CATEGORY.SUPPLIER}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <HyperDateTimeField
                                                        required
                                                        selected={this.state.objPurchaseReturn.date}
                                                        onChange={(date: any) => { this.setPurchaseReturn({ date }) }}
                                                        label={"Date"}
                                                        type="date"
                                                        frm={this.frm}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <HyperDateTimeField
                                                        selected={this.state.objPurchaseReturn.invoiceDate}
                                                        label={"Invoice Date"}
                                                        type="date"
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <HyperSelectField
                                                        labelWidth={120}
                                                        required
                                                        selectedValue={this.state.objPurchaseReturn.invoiceTaxType}
                                                        onChange={(e: any) => { this.setPurchaseReturn({ invoiceTaxType: e.target.value }); this.validateInvoiceTaxType(e.target.value) }}
                                                        options={salesReturnTaxType}
                                                        optionIdField={"value"}
                                                        optionNameField={"name"}
                                                        label="Invoice Tax Type"
                                                        fullWidth
                                                        frm={this.frm}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <HyperTextField
                                                        value={this.state.objPurchaseReturn.DNNumber}
                                                        onChange={(e: any) => this.setPurchaseReturn({ DNNumber: e.target.value })}
                                                        label={"Credit Note Number"}
                                                        type="text"
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {(this.state.objPurchaseReturn.supplierInfo) &&
                                            <Grid item xs={12} md={6}>
                                                <SalesReturnCustomerInfo customerInfo={this.state.objPurchaseReturn.supplierInfo} />
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <PurchaseReturnItems
                                                lstPurchaseItem={this.state.objPurchaseReturn.lstPurchaseItem}
                                                frm={this.frm}
                                                objDiscount={this.state.objDiscount}
                                                taxInvoiceType={this.state.objPurchaseReturn.invoiceTaxType}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <HyperTextField
                                                value={this.state.objPurchaseReturn.comment}
                                                onChange={(e: any) => { this.setPurchaseReturn({ comment: (e.target.value) }) }}
                                                multiline
                                                rows={2}
                                                label={"Comment"}
                                                type="text"
                                                frm={this.frm}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                                            <Button variant="contained" color="primary" type="submit">Save</Button>
                                        </Grid>
                                    </React.Fragment>
                                }
                            </Grid>
                        </form>
                    </Grid>
                </CommonModal>
            </div>
        );
    }
}


export default withWindowState(AddUpdatePurchaseReturn, ["companySettings", "user"])