import React, { FormEvent } from 'react'
import CommonModal from '../../../Components/CommonModal';
import { Grid, Button } from '@material-ui/core';
import HyperTextField from '../../../Components/HyperInput/HyperTextField';
import { ICategory } from '../../../Types/AllTypes';
import HyperForm from '../../../Components/HyperInput/HyperForm';
import api from '../../../Services/ApiServices';
import utils from '../../../Services/Utils';
import { CategoryInsertObject } from '../../../Types/InsertObjects';

interface AddUpdateCategoryProps {
    isOpen: boolean
    objCategory?: ICategory
    toggle: () => void
    onComplete?: (data?: ICategory) => void
}

interface AddUpdateCategoryState {
    objCategory: ICategory
}

const initialItem: ICategory = {
    name: "",
    info: {}
} as ICategory;

class AddUpdateCategory extends React.Component<AddUpdateCategoryProps, AddUpdateCategoryState> {
    constructor(props: AddUpdateCategoryProps) {
        super(props);
        let state: AddUpdateCategoryState = {
            objCategory: initialItem
        };

        if (this.props.objCategory) {
            state.objCategory = this.props.objCategory
            this.isEditing = true;
        }

        this.state = state;
        this.frm = new HyperForm();

    }

    frm: HyperForm;
    isEditing: boolean = false;

    setTax(obj: any) {
        this.setState({ objCategory: { ...this.state.objCategory, ...obj } });
    }

    onSubmit = async (e: FormEvent) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.frm.setIsFormSubmitted(true)
            if (this.frm.isValid()) {
                let result = null;
                let io = new CategoryInsertObject();
                io.name = this.state.objCategory.name;
                io.id = this.state.objCategory.id;
                utils.showLoader();
                if (this.isEditing) {
                    result = await api.updateCategory(io);
                }
                else {
                    result = await api.addCategory(io);
                }

                this.props.toggle();
                if (this.props.onComplete) {
                    this.props.onComplete(result);
                }

            }
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }

    render() {
        console.log(this.state)
        return (
            <div>
                <CommonModal
                    dialogProps={{
                        open: this.props.isOpen
                    }}
                    toggle={this.props.toggle}
                    title={"Category"}
                >
                    <form onSubmit={this.onSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <HyperTextField
                                    required
                                    value={this.state.objCategory.name}
                                    onChange={(e: any) => { this.setTax({ name: e.target.value }) }}
                                    label={"Category Name"}
                                    frm={this.frm}
                                />
                            </Grid>

                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Button type="submit" color="primary" variant="contained">
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CommonModal>
            </div>
        );
    }
}

export default AddUpdateCategory;