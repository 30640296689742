import scanRUtils from "../../../Services/ScanRUtilService";
import { ICompanySettings, IItem } from "../../../Types/AllTypes";

let css = `
@font-face {
  font-family: Junge;
  src: url(Junge-Regular.ttf);
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

a {
  color: #001028;
  text-decoration: none;
}

body {
  font-family: Junge;
  position: relative;
  width: 21cm;  
  height: 29.7cm; 
  margin: 0 auto; 
  color: #001028;
  background: #FFFFFF; 
  font-size: 14px; 
}

.arrow {
  margin-bottom: 4px;
}

.arrow.back {
  text-align: right;
}

.inner-arrow {
  padding-right: 10px;
  height: 30px;
  display: inline-block;
  background-color: rgb(233, 125, 49);
  text-align: center;

  line-height: 30px;
  vertical-align: middle;
}

.arrow.back .inner-arrow {
  background-color: rgb(233, 217, 49);
  padding-right: 0;
  padding-left: 10px;
}

.arrow:before,
.arrow:after {
  content:'';
  display: inline-block;
  width: 0; height: 0;
  border: 15px solid transparent;
  vertical-align: middle;
}

.arrow:before {
  border-top-color: rgb(233, 125, 49);
  border-bottom-color: rgb(233, 125, 49);
  border-right-color: rgb(233, 125, 49);
}

.arrow.back:before {
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: rgb(233, 217, 49);
  border-left-color: transparent;
}

.arrow:after {
  border-left-color: rgb(233, 125, 49);
}

.arrow.back:after {
  border-left-color: rgb(233, 217, 49);
  border-top-color: rgb(233, 217, 49);
  border-bottom-color: rgb(233, 217, 49);
  border-right-color: transparent;
}

.arrow span { 
  display: inline-block;
  width: 80px; 
  margin-right: 20px;
  text-align: right; 
}

.arrow.back span { 
  margin-right: 0;
  margin-left: 20px;
  text-align: left; 
}

h1 {
  color: #5D6975;
  font-family: Junge;
  font-size: 2.4em;
  line-height: 1.4em;
  font-weight: normal;
  text-align: center;
  border-top: 1px solid #5D6975;
  border-bottom: 1px solid #5D6975;
  
}

h1 small { 
  font-size: 0.45em;
  line-height: 1.5em;
  float: left;
} 

h1 small:last-child { 
  float: right;
} 

#project { 
  float: left; 
}

#company { 
  float: right; 
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 30px;
}

table th,
table td {
  text-align: center;
}

table th {
  padding: 5px 20px;
  color: #5D6975;
  border-bottom: 1px solid #C1CED9;
  white-space: nowrap;        
  font-weight: normal;
}

table .service,
table .desc {
  text-align: left;
}

table td {
  padding: 20px;
  text-align: right;
}

table td.service,
table td.desc {
  vertical-align: top;
}

table td.unit,
table td.qty,
table td.total {
  font-size: 1.2em;
}

table td.sub {
  border-top: 1px solid #C1CED9;
}

table td.grand {
  border-top: 1px solid #5D6975;
}

table tr:nth-child(2n-1) td {
  background: #EEEEEE;
}

table tr:last-child td {
  background: #DDDDDD;
}

#details {
  margin-bottom: 30px;
}

footer {
  color: #5D6975;
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #C1CED9;
  padding: 8px 0;
  text-align: center;
}
`


function getItem(objItem: IItem, index: number) {

  return `
          <tr>
            <td class="no">${index + 1}</td>
            <td class="desc"><h3>${objItem.name}</h3></td>
            <td class="unit">${scanRUtils.prettyPrice((objItem as any).price)}</td>
            <td class="qty">${(objItem as any).qty}</td>
            <td class="tax">${scanRUtils.prettyPrice((objItem as any).tax)}</td>
            <td class="total">${scanRUtils.prettyPrice((objItem as any).amount)}</td>
          </tr>
        `
}

export default function template3(objSales: any, companySettings: ICompanySettings) {
  let html = `
  
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8">
    <title>Example 3</title>
    <style>
    ${css}
      </style>
  </head>
  <body style="padding-left:10px;padding-right:10px;">
    <main>
      <h1  class="clearfix"><small><span>INVOICE DATE</span><br />${new Date(objSales.date).toLocaleDateString()}</small> ${companySettings.invoicePrefix}-${objSales.info.INVNumber} </h1>
      <div id="details" class="clearfix">
      <div id="project">
        <div class="arrow"><div class="inner-arrow"><span>CLIENT</span>${objSales.debitAccount.name}</div></div>
        <div class="arrow"><div class="inner-arrow"><span>ADDRESS</span>${objSales.debitAccount.info?.addressLine1}${objSales.debitAccount.info?.addressLine2}</div></div>
        ${objSales.debitAccount.info?.city ? `<div class="arrow"><div class="inner-arrow"><span>CITY</span>${objSales.debitAccount.info?.city}</div></div>` : ``}
        ${objSales.debitAccount.info?.pincode ? `<div class="arrow"><div class="inner-arrow"><span>PINCODE</span>${objSales.debitAccount.info?.pincode}</div></div>` : ``}
        ${objSales.debitAccount.info?.state ? `<div class="arrow"><div class="inner-arrow"><span>STATE</span>${objSales.debitAccount.info?.state}</div></div>` : ``}
        ${objSales.debitAccount.info?.email ?
    `<div class="arrow"><div class="inner-arrow"><span>EMAIL</span> <a href=${objSales.debitAccount.info?.email}>${objSales.debitAccount.info?.email}</a></div></div>`
    :
    ''
  }
        ${objSales.debitAccount.info?.mobile ? `<div class="arrow"><div class="inner-arrow"><span>MOBILE</span>${objSales.debitAccount.info?.mobile}</div></div>` : ``}
        
        
        <div class="arrow"><div class="inner-arrow"><span>GSTIN</span>${objSales.debitAccount.info?.GST ? objSales.debitAccount.info?.GST : 'N/A'}</div></div>
      </div>
      <div id="company">

      
        <div class="arrow back"><div class="inner-arrow">${companySettings.companyName}<span>COMPANY</span></div></div>
        ${companySettings.address ? `<div class="arrow back"><div class="inner-arrow">${companySettings.address || ""}<span>ADDRESS</span></div></div>` : ""}
        ${companySettings.companyEmail ? `<div class="arrow back"><div class="inner-arrow">${companySettings.companyEmail || ""}<span>EMAIL</span></div></div>` : ""}
        ${companySettings.companyPhone ? `<div class="arrow back"><div class="inner-arrow">${companySettings.companyPhone || ""}<span>PHONE</span></div></div>` : ""}
      </div>
    </div>
      <table>
        <thead>
          <tr>
            <th style="text-align:right;">#</th>
            <th style="text-align:left;">ITEM</th>
            <th style="text-align:right;">PRICE</th>
            <th style="text-align:right;">QTY</th>
            <th style="text-align:right;">TAX</th>
            <th style="text-align:right;">TOTAL</th>
          </tr>
        </thead>
        <tbody>
        ${objSales.info.items.map((objItem: any, index: any) => {
    return getItem(objItem, index);
  }).join("")}
        </tbody>
        <tfoot style="border-top:1px solid grey">
        <tr>
          <td colspan="3"></td>
          <td colspan="2">SUBTOTAL</td>
          <td>${scanRUtils.prettyPrice(objSales.info.amount)}</td>
        </tr>
        <tr>
          <td colspan="3"></td>
          <td colspan="2">TAX</td>
          <td>${scanRUtils.prettyPrice(objSales.info.tax)}</td>
        </tr>
        <tr>
          <td colspan="3"></td>
          <td colspan="2">GRAND TOTAL</td>
          <td>${scanRUtils.prettyPrice(objSales.info.amountWithTax)}</td>
        </tr>
      </tfoot>
      </table>
    </main>
    <footer>
      Invoice was created on a computer and is valid without the signature and seal.
    </footer>
  </body>
</html>`

  return html;
}