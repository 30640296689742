import React from 'react'
import HyperAutoCompleteField from '../../Components/HyperInput/HyperAutoCompleteField';
import enums from '../../enums';
import utils from '../../Services/Utils';

interface ItemUnitAutoCompleteProps {
    required?: boolean
    frm?: any
    value: string
    label?: string
    autoFocus?: boolean
    placeholder?: string

    onChange?: (e: any) => void
    onAddClick?: (e?: any) => void
}

interface ItemUnitAutoCompleteState {
    lstItemUnit: object[]
    objUnit: any
}
const ITEM_UNIT_TYPE: any = enums.ITEM_UNIT_TYPE;

class ItemUnitAutoComplete extends React.Component<ItemUnitAutoCompleteProps, ItemUnitAutoCompleteState> {
    constructor(props: ItemUnitAutoCompleteProps) {
        super(props);
        let state: ItemUnitAutoCompleteState = {
            lstItemUnit: [],
            objUnit: undefined
        };

        Object.keys(ITEM_UNIT_TYPE).forEach((unitKey: any) => {
            state.lstItemUnit.push({ key: unitKey, label: utils.convertToCamelCase(ITEM_UNIT_TYPE[unitKey], "_", true) });
        });

        this.state = state;
    }
    componentDidMount() {
        let findObj = this.state.lstItemUnit.filter((item: any) => item.key === this.props.value)
        if (this.props.value && findObj.length > 0)
            this.setState({ objUnit: findObj[0] })
    }

    render() {
        // console.log(this.state)
        return (
            <React.Fragment>
                <HyperAutoCompleteField
                    options={this.state.lstItemUnit}
                    value={this.state.objUnit || this.props.value || ""}
                    onChange={(value: string) => {
                        this.setState({ objUnit: value })
                        if (this.props.onChange) {
                            this.props.onChange(value);
                        }
                    }}
                    // optionIdField="key"
                    // optionNameField="value"
                    label={this.props.label}
                    required={this.props.required}
                    frm={this.props.frm}
                    autoFocus={this.props.autoFocus}
                    placeholder={this.props.placeholder}
                />
            </React.Fragment>
        );
    }
}

export default ItemUnitAutoComplete;