import React from 'react'
import { ISalesInvoiceTemplate, ISalesInvoiceTemplateItem } from '../../../Types/AllTypes';

interface ImageHeaderTemplateProps {
    objInvoice?: ISalesInvoiceTemplate

    onComplete: (html: string) => void
}
interface ImageHeaderTemplateState {
}

class ImageHeaderTemplate extends React.Component<ImageHeaderTemplateProps, ImageHeaderTemplateState> {
    constructor(props: ImageHeaderTemplateProps) {
        super(props);
        let state: ImageHeaderTemplateState = {};
        this.state = state;
    }

    ref = React.createRef<HTMLDivElement>();

    componentDidMount() {
        // let mainHTML = document.getElementById("divTemplate");
        let mainHTML = this.ref.current
        this.props.onComplete(mainHTML?.innerHTML || "")
    }
    getTitleFontSize = (title: string) => {
        if (!title)
            return 15;
        let titleLength = title.length;
        if (titleLength <= 10)
            return 90
        else if (titleLength <= 12)
            return 80
        else if (titleLength <= 20)
            return 60
        else if (titleLength <= 22)
            return 45
        else if (titleLength <= 25)
            return 38
        else if (titleLength <= 30)
            return 31
        else if (titleLength <= 35)
            return 25
        return 20
    }
    getRowSpan = () => {
        return 0
    }

    render() {
        const { objInvoice } = this.props;
        // console.log(objInvoice)
        let shouldShowInvoiceColumns: boolean | undefined = objInvoice?.items.some((item: any) => item.showTaxOnInvoice);
        return (
            <div ref={this.ref} id="divTemplate" style={{ display: 'none' }}>
                <table style={{ width: "97%", margin: 'auto', maxHeight: "28cm" }}>
                    <tbody>
                        <tr style={{ height: "4.4cm" }}>
                            <td>
                                <table style={{ width: "100%" }} {...{ border: 0 }} cellSpacing="0" cellPadding="0">
                                    <thead>
                                        <tr>
                                            <td valign="middle">
                                                {!!(objInvoice?.companyLogoURL) &&
                                                    <img src={objInvoice?.companyLogoURL} alt="CompanyLogo"
                                                        style={{ width: "100%", height: 150 }} />
                                                }
                                            </td>
                                        </tr>
                                    </thead>
                                </table>
                            </td>
                        </tr>
                        <tr style={{ height: "2.8cm" }}>
                            <td>
                                <table style={{ width: "100%" }} {...{ border: 1 }} cellSpacing="0" cellPadding="5" >
                                    <tbody>
                                        <tr>
                                            <td valign="top" rowSpan={3} style={{ width: "400px" }}>
                                                <b>Mr/Mrs. </b> : - {objInvoice?.customerName}
                                                {!!(objInvoice?.customerGST) && <div> <b>&#8226; GST</b> : {objInvoice?.customerGST} </div>}
                                                {!!(objInvoice?.customerAddress) && <div><b>&#8226;</b> {objInvoice?.customerAddress} </div>}
                                                {!!(objInvoice?.customerMobile) && <div><b>&#8226;</b> {objInvoice?.customerMobile} </div>}
                                                {!!(objInvoice?.customerEmail) && <div><b>&#8226;</b> {objInvoice?.customerEmail} </div>}
                                            </td>
                                            <td style={{ width: "200px" }}>
                                                <b>
                                                    <span>Bill No. : </span>
                                                </b>
                                                <span>{objInvoice?.invoiceNumber}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "200px" }}>
                                                <b>
                                                    <span>Date : </span>
                                                </b>
                                                <span>{objInvoice?.dateOfInvoice}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr style={{ height: "14cm" }}>
                            <td valign="top">
                                <table className="tblBill" style={{ width: "100%", minHeight: "100%", border: "1px solid black", height: "14.2cm" }} {...{ border: 1 }} cellSpacing="0" cellPadding="5" >
                                    <thead>
                                        <tr>
                                            <th>Particulars</th>
                                            <th style={{ width: "40px" }}> Qty.</th>
                                            <th style={{ width: "79px" }}> Rates</th>
                                            {shouldShowInvoiceColumns &&
                                                <React.Fragment>
                                                    {objInvoice?.showIGst
                                                        ?
                                                        <th style={{ width: "78px" }}>IGST</th>
                                                        :
                                                        <React.Fragment>
                                                            <th style={{ width: "78px" }}>CGST</th>
                                                            <th style={{ width: "78px" }}>SGST</th>
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>

                                            }
                                            <th style={{ width: "120px" }}> Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {objInvoice?.items.map((item: ISalesInvoiceTemplateItem, index: number) => {
                                            return (
                                                <tr key={index} style={{ height: "10px" }}>
                                                    <td>
                                                        <b>{item.itemName}</b>
                                                        {item.itemDefaultDescription ? <span style={{ fontSize: 14 }}> <br />{item.itemDefaultDescription}</span> : ''}
                                                        {item.itemSN ? <span style={{ fontSize: 14 }}><br /><b>&#8226; S/N</b>  : {item.itemSN}</span> : ''}
                                                    </td>
                                                    <td align="center" valign="top">{item.itemQty}</td>
                                                    <td align="right" valign="top">{item.itemPrice}</td>
                                                    {shouldShowInvoiceColumns &&
                                                        <React.Fragment>
                                                            {objInvoice?.showIGst
                                                                ?
                                                                <td align="right" valign="top" >{item.itemIGSTAmount}({item.itemIGSTPercentage}%)</td>
                                                                :
                                                                <React.Fragment>
                                                                    <td align="right" valign="top">{item.itemCGSTAmount}({item.itemCGSTPercentage}%)</td>
                                                                    <td align="right" valign="top">{item.itemSGSTAmount}({item.itemSGSTPercentage}%)</td>
                                                                </React.Fragment>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                    <td align="right" valign="top">{item.itemAmount}</td>
                                                </tr>)
                                        })}

                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            {shouldShowInvoiceColumns &&
                                                <React.Fragment>
                                                    {objInvoice?.showIGst
                                                        ?
                                                        <td align="right" valign="top" ></td>
                                                        :
                                                        <React.Fragment>
                                                            <td align="right" valign="top"></td>
                                                            <td align="right" valign="top"></td>
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>

                                            }
                                        </tr>
                                        <tr style={{ height: 10 }}>
                                            <td>Sub Total</td>
                                            <td align="center"><b>{objInvoice?.totalQty}</b></td>
                                            <td align="right"><b>{objInvoice?.subTotal}</b></td>
                                            {shouldShowInvoiceColumns &&
                                                <React.Fragment>
                                                    {objInvoice?.showIGst
                                                        ?
                                                        <td align="right"><b>{objInvoice?.totalIGst}</b></td>
                                                        :
                                                        <React.Fragment>
                                                            <td align="right"><b>{objInvoice?.totalCGst}</b></td>
                                                            <td align="right"><b>{objInvoice?.totalSGst}</b></td>
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>
                                            }
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr style={{ height: "3.4cm" }}>
                            <td>
                                <table style={{ width: "100%" }} {...{ border: 1 }} cellSpacing="0" cellPadding="5" >
                                    <tbody>
                                        <tr>
                                            <td valign="top" style={{ height: "2.8cm", width: "350px" }} rowSpan={this.getRowSpan()} >
                                                <b>Amount In Word :- </b>
                                                <div>{objInvoice?.totalAmountInWord}</div>
                                                <b>Note :- </b>
                                                <div>{objInvoice?.note}</div>
                                            </td>
                                            <td style={{ width: "100px" }}> <b>Sub Total</b></td>
                                            <td style={{ width: "150px", textAlign: 'right' }}><b>{objInvoice?.subTotal}</b></td>
                                        </tr>
                                        {!!(objInvoice?.discountPercentage) &&
                                            <tr>
                                                <td><b>Discount({objInvoice?.discountPercentage}%)</b></td>
                                                <td style={{ textAlign: 'right' }}><b>{objInvoice?.totalDiscount}</b></td>
                                            </tr>
                                        }

                                        {shouldShowInvoiceColumns &&
                                            <tr>
                                                <td><b>Total Tax</b></td>
                                                <td align="right"><b>{objInvoice?.totalTax}</b></td>
                                            </tr>
                                        }
                                        <tr>
                                            <td><b>Grand Total</b></td>
                                            <td align="right"><b>{objInvoice?.totalAmountWithTax}</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr style={{ height: "3cm" }}>
                            <td >
                                <table style={{ width: "100%" }} {...{ border: 1 }} cellSpacing="0" cellPadding="5" >
                                    <tbody>
                                        <tr>
                                            <td valign="top" rowSpan={2} style={{ width: "400px", height: "2.9cm" }} >
                                                <b>Terms & Conditions :- </b>
                                                {objInvoice?.companyTerms.map((term: string, index: number) => <div key={index}>{term}</div>)}
                                            </td>
                                            <td valign="top" rowSpan={3} style={{ width: "200px" }}>
                                                <b>
                                                    Signature :-
                                                </b>
                                                {!!(objInvoice?.companySignatureURL) &&
                                                    <p style={{ textAlign: 'center', margin: 0 }}>
                                                        <img
                                                            src={objInvoice?.companySignatureURL}
                                                            alt="companySignatureURL"
                                                            style={{ width: 175, height: 50 }}
                                                        />
                                                    </p>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                     </tbody>
                </table>
            </div >
        );
    }
}

export default ImageHeaderTemplate;
