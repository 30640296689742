import React from 'react'
import { Button, Grid, Container, Chip } from '@material-ui/core'
import utils from '../../Services/Utils';
import NotificationSkeleton from '../../Components/Skeletons/NotificationSkeleton';
import DataTable from '../../Components/DataTable';
import { IWindowState, ISalesReturn } from '../../Types/AllTypes';
import api from '../../Services/ApiServices';
import HyperForm from '../../Components/HyperInput/HyperForm';
import { JournalSearchOptions } from '../../Types/SearchOptions';
import enums from '../../enums';
import HyperModal from '../../Components/HyperInput/HyperModal';
import AccountText from '../Accounts/AccountText';
import { HyperTheme } from '../../Theme/Theme';
import { IconAdd } from '../../Components/Icons/Icons';
import scanRUtils from '../../Services/ScanRUtilService';
import withWindowState from '../../Components/withWindowState';
import HyperAutoCompleteField from '../../Components/HyperInput/HyperAutoCompleteField';
import { RouteComponentProps } from 'react-router-dom';
import SeeMore from '../../Components/SeeMore';
import AddUpdateSalesReturn from './AddUpdateSalesReturn';
import AddUpdatePayment from '../Payments/AddUpdatePayment';

interface SalesReturnProps extends IWindowState, RouteComponentProps { }

interface SalesReturnState {
    shouldShowSkeleton: boolean,
    lstSalesReturn: ISalesReturn[],
    currentData?: ISalesReturn
    paymentData?: ISalesReturn
    isDialogOpen: boolean
    name: any
    selectedStatus: any[]
    isStatusOptionOpen: boolean
}
const theme = HyperTheme;
const SALES_PURCHASE_STATUS: any = enums.SALES_PURCHASE_STATUS;

class SalesReturn extends React.Component<SalesReturnProps, SalesReturnState> {
    constructor(props: SalesReturnProps) {
        super(props);
        let state: SalesReturnState = {
            shouldShowSkeleton: true,
            lstSalesReturn: [],
            currentData: undefined,
            paymentData: undefined,
            isDialogOpen: false,
            name: "",
            isStatusOptionOpen: false,
            selectedStatus: []
        };
        this.state = state;
        this.frm = new HyperForm();
        this.modal = new HyperModal(this);
        this.paymentModal = new HyperModal(this);
    }
    frm: HyperForm;
    modal: HyperModal;
    paymentModal: HyperModal;

    componentDidMount() {
        let query = utils.parseQueryString(this.props.location.search);
        if (query.paymentType) {
            let aryPaymentTypes = query.paymentType.toString().split(",");
            this.setState({ selectedStatus: aryPaymentTypes.map(item => ({ label: item, value: item })) })
        }
        this.loadSalesReturn();
    }

    loadSalesReturn = async () => {
        try {
            let so = new JournalSearchOptions();
            so.category = enums.JOURNAL_CATEGORY.SALES_RETURN;
            let lstSalesReturn: any = await api.getJournals(so);
            this.setState({ lstSalesReturn, shouldShowSkeleton: false, currentData: undefined, paymentData: undefined })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
    }

    onAddClick = () => {
        this.setState({ isDialogOpen: true, currentData: undefined });
    }

    onEditClick = (objJournal: ISalesReturn) => {
        this.setState({ isDialogOpen: true, currentData: objJournal })
    }

    onDeleteClick = async (objJournal: ISalesReturn) => {
        let confirmed = await utils.showConfirm("Do you really want to delete this?", "If You Delete this sales then it's related receipt also deleted.");
        if (confirmed) {
            try {
                await api.journalMultiAction([{ actionCode: enums.DB_ACTION.DELETE, data: { id: objJournal.id } }]);
                this.loadSalesReturn();
            }
            catch (ex) {
                utils.showError(ex.msg || ex.message)
            }
        }
    }

    toggleAddUpdateJournalModal = () => {
        this.setState({ isDialogOpen: !this.state.isDialogOpen })
    }

    onInvoiceClick = async (objSales: ISalesReturn) => {
        await this.setState({ currentData: { ...objSales } })
        this.modal.toggle(true)
    }

    _renderIsPaid = (rowData: ISalesReturn) => {
        if (rowData.info.paymentStatus === enums.PAYMENT_STATUS.PAID)
            return <Chip label={enums.PAYMENT_STATUS.PAID} color="primary" />
        else if (rowData.info.paymentStatus === enums.PAYMENT_STATUS.PARTIALLY_PAID)
            return <Chip onClick={() => { this.onPayment(rowData) }} label={enums.PAYMENT_STATUS.PARTIALLY_PAID} style={{ backgroundColor: theme.palette.warning.main, color: theme.palette.common.white }} />
        return <Chip
            label={enums.PAYMENT_STATUS.UNPAID}
            clickable
            onClick={() => { this.onPayment(rowData) }}
            style={{ backgroundColor: theme.palette.error.dark, color: theme.palette.common.white }}
        />
    }

    onPayment = async (rowData: ISalesReturn) => {
        let paymentData: any = {}
        paymentData.debitAccountId = rowData.creditAccountId;
        paymentData.amount = rowData.info.totalDueAmount || rowData.amount;
        paymentData.info = {
            salesReturnId: rowData.id
        }
        // console.log(rowData, "Row", paymentData)
        await this.setState({ paymentData, currentData: rowData });
        this.paymentModal.toggle(true)
    }

    renderItemName = (rowData: ISalesReturn) => {
        if (rowData.info) {
            let uniqueItem = rowData.info.items.reduce(
                (accumulator: any, current: any) => {
                    if (!accumulator.some((x: any) => x.name === current.name)) {
                        accumulator.push(current)
                    }
                    return accumulator;
                }, []
            )
            let itemName = uniqueItem.map((item: any) => item.name).join(", ");
            (rowData as any).itemName = itemName;
            return <div style={{ width: 100, paddingRight: 5 }}><SeeMore text={itemName} length={10} /></div>
        }
        return ""
    }

    getSN = (rowData: ISalesReturn) => {
        if (rowData.info) {
            let serialNos = rowData.info.items.map((item: any) => item.serialNo ? item.serialNo.split(",").join(", ") : "").filter((item: any) => item).join(", ");
            (rowData as any).serialNos = serialNos;
            return <div style={{ width: 100 }}><SeeMore text={serialNos} length={20} /></div>
        }
        return "";
    }

    render() {
        // console.log("state sales", this.state.lstSalesReturn)
        const lstSalesReturn = this.state.lstSalesReturn.filter(sales => {
            if (this.state.selectedStatus.length === 0) {
                return sales
            }
            return (this.state.selectedStatus.some(item => item.value === sales.info.paymentStatus))
        });

        let lstExtendColumns = [];
        if (this.props.companySettings.showItemSerialNo) {
            lstExtendColumns.push({ title: "S/N", render: this.getSN, sortField: "info.INVNumber", renderReturnsString: true })
        }

        return (
            <Container>
                <Grid container >
                    <Grid item xs={6} style={{ textAlign: 'left', margin: 'auto' }}>
                        <HyperAutoCompleteField
                            options={Object.keys(SALES_PURCHASE_STATUS).map((statusKey: string) => {
                                return {
                                    label: SALES_PURCHASE_STATUS[statusKey],
                                    value: SALES_PURCHASE_STATUS[statusKey]
                                };
                            })}
                            value={this.state.selectedStatus}
                            onChange={(e: any) => {
                                this.setState({ selectedStatus: e })
                            }}
                            multiple
                            label={"Payment Status"}
                        />
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right', margin: 'auto' }}>

                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={this.onAddClick}
                            startIcon={<IconAdd />}
                            style={{ margin: 'auto' }}
                        >
                            New
                        </Button>
                    </Grid>
                </Grid>

                <Grid item xs={12} style={{ marginTop: 20 }}>
                    {this.state.shouldShowSkeleton ? (
                        <NotificationSkeleton />
                    ) : (
                            <DataTable
                                title="Sales Return"
                                columns={([
                                    {
                                        title: "CN Number", render: (rowData: ISalesReturn) => {
                                            return `CN-${rowData.info.CNNumber}`;
                                        },
                                        sortField: "info.CNNumber",
                                        renderReturnsString: true,
                                    },
                                    {
                                        title: "Invoice No.", render: (rowData: ISalesReturn) => {
                                            return utils.getInvoiceNumberWithPrefix(rowData.info.INVNumber);
                                        },
                                        sortField: "info.INVNumber",
                                        renderReturnsString: true,
                                    },

                                    {
                                        title: "Date", render: (rowData: ISalesReturn) => {
                                            return utils.convertToSystemDateTimeFormate(new Date(rowData.date));
                                        },
                                        sortField: "date",
                                        renderReturnsString: true,
                                    },
                                    {
                                        title: "Customer", render: (rowData: ISalesReturn) => {
                                            return <AccountText objAccount={rowData.creditAccount} />
                                        },
                                        sortField: "creditAccount.name",
                                        renderReturnsString: true,
                                    },
                                    {
                                        title: "Amount", render: (rowData: ISalesReturn) => {
                                            return scanRUtils.prettyPrice(rowData.amount);
                                        },
                                        sortField: "amount",
                                        renderReturnsString: true,
                                    },
                                    {
                                        title: "Due Amount", render: (rowData: ISalesReturn) => {
                                            return scanRUtils.prettyPrice(rowData.info.totalDueAmount || 0);
                                        },
                                        sortField: "info.totalDueAmount",
                                        renderReturnsString: true,
                                    },
                                    {
                                        title: "Status", render: this._renderIsPaid, sortField: "info.paymentStatus"
                                    },
                                ] as any).concat(lstExtendColumns)}
                                data={lstSalesReturn}
                                options={{
                                    sorting: true,
                                }}
                                onEdit={(rowData: ISalesReturn) => { this.onEditClick(rowData) }}
                                onDelete={(rowData: ISalesReturn) => { this.onDeleteClick(rowData) }}
                            />
                        )}
                </Grid>
                {!!(this.state.isDialogOpen) &&
                    <AddUpdateSalesReturn
                        onComplete={this.loadSalesReturn.bind(this)}
                        isOpen={this.state.isDialogOpen}
                        toggle={this.toggleAddUpdateJournalModal}
                        objSalesReturn={this.state.currentData}
                    />
                }

                {!!(this.paymentModal.isOpen()) &&
                    <AddUpdatePayment
                        isOpen={this.paymentModal.isOpen()}
                        objJournal={this.state.paymentData}
                        onComplete={this.loadSalesReturn}
                        toggle={this.paymentModal.toggle}
                        // hideAmountField={true}
                        hideDebitField={true}
                        showDueAmount={true}
                    />
                }
            </Container>
        );
    }
}

export default withWindowState(SalesReturn, ["companySettings"]);