import { ISalesInvoiceTemplate, ISalesInvoiceTemplateItem } from "../../../Types/AllTypes";

let css = `
@font-face {
  font-family: SourceSansPro;
  src: url(SourceSansPro-Regular.ttf);
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

a {
  color: #0087C3;
  text-decoration: none;
}

body {
  position: relative;
  width: 21cm;  
  height: 28.7cm; 
  margin: 0 auto; 
  color: #555555;
  background: #FFFFFF; 
  font-family: Arial, sans-serif; 
  font-size: 14px; 
  font-family: SourceSansPro;
}

header {
  padding: 10px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #AAAAAA;
}

#logo {
  float: left;
  margin-top: 8px;
}

#logo img {
  height: 70px;
}

#company {
  float: right;
  text-align: right;
}


#details {
  margin-bottom: 50px;
}

#client {
  padding-left: 6px;
  border-left: 6px solid #0087C3;
  float: left;
}

#client .to {
  color: #777777;
}

h2.name {
  font-size: 1.4em;
  font-weight: normal;
  margin: 0;
}

#invoice {
  float: right;
  text-align: right;
}

#invoice h1 {
  color: #0087C3;
  font-size: 2.4em;
  line-height: 1em;
  font-weight: normal;
  margin: 0  0 10px 0;
}

#invoice .date {
  font-size: 1.1em;
  color: #777777;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
}

table th,
table td {
  padding: 20px;
  background: #EEEEEE;
  text-align: center;
  border-bottom: 1px solid #FFFFFF;
}

table th {
  white-space: nowrap;        
  font-weight: normal;
}

table td {
  text-align: right;
}

table td h3{
  color: #57B223;
  font-size: 1.2em;
  font-weight: normal;
  margin: 0 0 0.2em 0;
}

table .no {
  color: #FFFFFF;
  font-size: 1.6em;
  background: #57B223;
}

table .desc {
  text-align: left;
}

table .unit {
  background: #DDDDDD;
}

table .qty {
}

table .total {
  background: #57B223;
  color: #FFFFFF;
}

table td.unit,
table td.qty,
table td.total {
  font-size: 1.2em;
}

table tbody tr:last-child td {
  border: none;
}

table tfoot td {
  padding: 10px 20px;
  background: #FFFFFF;
  border-bottom: none;
  font-size: 1.2em;
  white-space: nowrap; 
  border-top: 1px solid #AAAAAA; 
}

table tfoot tr:first-child td {
  border-top: none; 
}

table tfoot tr:last-child td {
  color: #57B223;
  font-size: 1.4em;
  border-top: 1px solid #57B223; 

}

table tfoot tr td:first-child {
  border: none;
}

#thanks{
  font-size: 2em;
  margin-bottom: 50px;
}

#notices{
  padding-left: 6px;
  border-left: 6px solid #0087C3;  
}

#notices .notice {
  font-size: 1.2em;
}

footer {
  color: #777777;
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #AAAAAA;
  padding: 8px 0;
  text-align: center;
}
`


function getItem(objItem: ISalesInvoiceTemplateItem, index: number) {

  return `
          <tr>
            <td class="no">${index + 1}</td>
            <td class="desc"><h3>${objItem.itemName}</h3></td>
            <td class="unit">${objItem.itemPrice}</td>
            <td class="qty">${objItem.itemQty}</td>
            <td class="tax">${objItem.itemTax}</td>
            <td class="total">${objItem.itemAmount}</td>
          </tr>
        `
}

export default function template1(obj: ISalesInvoiceTemplate) {
  let html = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="utf-8">
      <title>Example 2</title>
      <style>
    ${css}
      </style>
    </head>
    <body style="padding-left:10px;padding-right:10px;">
      <header class="clearfix">
        <div id="logo">
        ${obj.companyLogoURL ? `<img src=${obj.companyLogoURL}>` : ""}
        </div>
        <div id="company">
          <h2 class="name">${obj.companyName}</h2>
          <div style="width:200px">
            ${obj.companyAddress || ""}
          </div>
          <div><a href="mailto:${obj.companyEmail || ""}">${obj.companyEmail || ""}</a></div>
        </div>
        </div>
      </header>
      <main>
        <div id="details" class="clearfix">
          <div id="client">
            <div class="to">INVOICE TO:</div>
            <h2 class="name">${obj.customerName}</h2>
            <!--<div class="address">796 Silver Harbour, TX 79273, US</div>
            <div class="email"><a href="mailto:john@example.com">john@example.com</a></div>-->
          </div>
          <div id="invoice">
            <h1>${obj.invoiceNumber}</h1>
            <div class="date">Date of Invoice: ${obj.dateOfInvoice}</div>
            <!--div class="date">Due Date: 30/06/2014</div-->
          </div>
        </div>
        <table border="0" cellspacing="0" cellpadding="0">
          <thead>
            <tr>
              <th class="no">#</th>
              <th class="desc">ITEM</th>
              <th class="unit">Unit PRICE</th>
              <th class="qty">QTY</th>
              <th class="tax">TAX</th>
              <th class="total">TOTAL</th>
            </tr>
          </thead>
          <tbody>
            ${obj.items.map((objItem: any, index: any) => {
    return getItem(objItem, index);
  }).join("")}
          </tbody>
          <tfoot>
            <tr>
              <td colspan="3"></td>
              <td colspan="2">SUBTOTAL</td>
              <td>${obj.totalAmount}</td>
            </tr>
            ${obj.showIGst ?
      `<tr>
              <td colspan="3"></td>
              <td colspan="2">IGST</td>
              <td>${obj.totalIGst}</td>
            </tr>`:
      `
              <tr>
              <td colspan="3"></td>
              <td colspan="2">CGST</td>
              <td>${obj.totalCGst}</td>
            </tr>

            <tr>
              <td colspan="3"></td>
              <td colspan="2">SGST</td>
              <td>${obj.totalSGst}</td>
            </tr>
              `}
            
            <tr>
              <td colspan="3"></td>
              <td colspan="2">GRAND TOTAL</td>
              <td>${obj.totalAmountWithTax}</td>
            </tr>
          </tfoot>
        </table>
        <div id="thanks">Thank you!</div>
        <!--div id="notices">
          <div>NOTICE:</div>
          <div class="notice">A finance charge of 1.5% will be made on unpaid balances after 30 days.</div>
        </div-->
      </main>
      <!--footer>
        Invoice was created on a computer and is valid without the signature and seal.
      </footer-->
    </body>
  </html>`

  return html;
}