import React from 'react'
import { Container, Grid, Paper, Typography, Button } from '@material-ui/core'
// import HyperModal from '../../../Components/HyperInput/HyperModal';
import api from '../../../Services/ApiServices';
import ReportsDataTable from '../../../Components/ReportsDataTable';
import scanRUtils from '../../../Services/ScanRUtilService';
import history from '../../../Main/history';
import utils from '../../../Services/Utils';
import { IWindowState, ICompanySettings } from '../../../Types/AllTypes';
import withWindowState from '../../../Components/withWindowState';
import { IconView } from '../../../Components/Icons/Icons'
import { StockSummarySearchOption } from '../../../Types/SearchOptions';
import moment from "moment";
// import CompanySelectAutoComplete from '../CompanySelectAutoComplete';
import auth from '../../../Services/AuthService';
import HyperCompanySelection from '../../../Components/HyperInput/HyperCompanySelection';

interface StockSummaryProps extends IWindowState {
}

interface StockSummaryState {
    lstStockSummary: any
    totalPcs: number
    totalAmount: number
    totalPurchaseAmount: number
    dateRange: any
    selectedCompanies: any[]
}


class StockSummary extends React.Component<StockSummaryProps, StockSummaryState> {
    constructor(props: StockSummaryProps) {
        super(props);
        let state: StockSummaryState = {
            lstStockSummary: [],
            totalPcs: 0,
            totalAmount: 0,
            totalPurchaseAmount: 0,
            dateRange: { startDate: moment().startOf("month").toDate(), endDate: moment().endOf("month").toDate(), label: "This Month" },
            selectedCompanies: [{ id: auth.getCompanyId() }]
        };
        this.state = state;
        this.companySelection = new HyperCompanySelection(this);
    }
    companySelection: HyperCompanySelection;
    

    componentDidMount() {
        this.getReportDetail()
    }
    getReportDetail = async () => {
        try {
            let so = new StockSummarySearchOption();
            so.companyIds = this.companySelection.getSelectedCompany().map((item: ICompanySettings) => item.id).join(",")
            let data = await api.getStockSummary(so);
            let totalPcs = 0;
            let totalAmount = 0;
            let totalPurchaseAmount = 0;

            data.forEach((item: any) => {
                if (!item.sales) {
                    item.currentStock = item.purchase?.totalQty || 0;
                }
                else {
                    item.currentStock = (item.purchase?.totalQty || 0) - (item.sales?.totalQty || 0);
                }
                item.totalAmount = (item.currentStock * item.defaultSalesPrice);
                totalPcs = totalPcs + item.currentStock;
                totalAmount = totalAmount + (item.currentStock * item.defaultSalesPrice);
                item.companyName = auth.getCompanyNameById(item.companyId)
                if(item?.purchase?.totalAmount && item.currentStock && item?.purchase?.totalQty)  {
                    let itemWisePrice = item.purchase.totalAmount/item?.purchase?.totalQty;
                    totalPurchaseAmount+= itemWisePrice * item.currentStock
                }
            })
            // console.log(data, "data")
            this.setState({ lstStockSummary: data, totalPcs, totalAmount,totalPurchaseAmount })
        }
        catch (ex) {
            console.log(ex)
        }
        finally {

        }
    }
    onVewClick = (rowData: any) => {
        history.push(`/reports/stockBook?q=${utils.encodeQueryObject({ id: rowData.id, name: rowData.name, showSN: true })}`)
    }

    render() {
        let lstExtendColumns = [];
        if (this.props.companySettings.showItemSerialNo) {
            lstExtendColumns.push({
                title: "View",
                render: (rowData: any) => <IconView onClick={(e: any) => { e.stopPropagation(); this.onVewClick(rowData) }} />,
            })
        }
        return (
            <Container>
                <Grid container spacing={2} style={{ marginTop: 20, marginBottom: 20 }}>
                    <Grid item xs={12} md={6}>
                        {this.companySelection.renderCompanySelection()}
                    </Grid>
                    <Grid item md={6} style={{ textAlign: 'right' }}>
                        <Button onClick={this.getReportDetail}>Refresh</Button>
                    </Grid>
                </Grid>
                <ReportsDataTable
                    key={Date.now()}
                    title="Stock Report"
                    columns={([
                        { title: "Item Name", field: "name" },
                        { title: "Current Stock", field: "currentStock" },
                        { title: "Price", field: "defaultSalesPrice" },
                        { title: "Total Amount", field: "totalAmount" },
                        { title: "Company Name",hidden:auth.isSingleCompany(), field: "companyName" },
                    ] as any).concat(lstExtendColumns)}
                    data={this.state.lstStockSummary}
                    options={{
                        sorting: true,
                    }}
                    onRowClick={(e: any, rowData: any) => { history.push(`/reports/stockBook?q=${utils.encodeQueryObject({ id: rowData.id, name: rowData.name })}`) }}
                />

                <Paper elevation={3} style={{ marginTop: 20, paddingTop: 30, paddingBottom: 30, padding: 20 }}>
                    <Grid container>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6">Total Quantity</Typography>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6" align="center" color="primary">{this.state.totalPcs}</Typography>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6">Total Amount</Typography>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6" align="center" color="primary">{scanRUtils.prettyPrice(this.state.totalAmount)}</Typography>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6">Total Purchase Amount</Typography>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6" align="center" color="primary">{scanRUtils.prettyPrice(this.state.totalPurchaseAmount)}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        );
    }
}

export default withWindowState(StockSummary, ["companySettings"]);