import React from 'react';
import { Button, Container, Grid } from '@material-ui/core';
import utils from '../../../Services/Utils';
import NotificationSkeleton from '../../../Components/Skeletons/NotificationSkeleton';
import DataTable from '../../../Components/DataTable';
import api from '../../../Services/ApiServices';
import HyperModal from '../../../Components/HyperInput/HyperModal';
import { ICategory } from '../../../Types/AllTypes';
import { IconAdd } from '../../../Components/Icons/Icons';
import { CategorySearchOption } from '../../../Types/SearchOptions';
import AddUpdateCategory from './AddUpdateCategory';
import AddUpdateSubCategory from './AddUpdateSubCategory';

interface CategoryProps {

}

interface CategoryState {
    shouldShowSkeleton: boolean,
    lstCategory: ICategory[],
    currentData?: ICategory
    parentId: number
}

class Category extends React.Component<CategoryProps, CategoryState> {
    constructor(props: CategoryProps) {
        super(props);
        let state: CategoryState = {
            shouldShowSkeleton: true,
            lstCategory: [],
            currentData: undefined,
            parentId: -1
        };

        this.state = state;
        this.modal = new HyperModal(this);
        this.subCategoryModal = new HyperModal(this);
    }
    modal: HyperModal;
    subCategoryModal: HyperModal;

    componentDidMount() {
        this.loadCategory();
    }

    async loadCategory() {
        try {
            let so = new CategorySearchOption();
            let lstCategory = await api.getCategoryList(so);
            this.setState({ lstCategory, shouldShowSkeleton: false, currentData: undefined })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }

    }

    onDeleteClick = async (objItem: ICategory) => {
        let confirmed = await utils.showConfirm("Do you really want to delete this?", "");
        if (confirmed) {
            try {
                let data = await api.deleteItem(objItem.id);
                if (data.isUsed)
                    return utils.showWarning("You can't delete this item because it's already used in Sales Invoice.")
                this.loadCategory();
            }
            catch (ex) {
                utils.showError(ex.msg || ex.message)
            }
        }
    }

    onAddClick = () => {
        this.modal.toggle(true, { currentData: undefined })
    }

    onEditClick = (objItem: ICategory) => {
        this.modal.toggle(true, { currentData: objItem });
    }

    render() {
        return (
            <Container>
                <div style={{ textAlign: 'end', marginBottom: 20 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={this.onAddClick}
                        startIcon={<IconAdd />}
                    >
                        New
                    </Button>
                </div>
                <Grid item xs={12} >
                    {this.state.shouldShowSkeleton ? (
                        <NotificationSkeleton />
                    ) : (
                            <DataTable
                                title="Category"
                                columns={[
                                    {
                                        title: "ID", render: (rowData: ICategory) => {
                                            return `#${rowData.id}`;
                                        },
                                        sortField: "id"
                                    },
                                    { title: "Name", field: "name" },
                                ]}
                                data={this.state.lstCategory}
                                options={{
                                    sorting: true,
                                }}
                                onEdit={(rowData: ICategory) => { this.onEditClick(rowData) }}
                                //onDelete={(rowData: ICategory) => { this.onDeleteClick(rowData) }}
                                onAdd={async (rowData: ICategory) => { await this.setState({ parentId: rowData.id }); this.subCategoryModal.toggle() }}
                                parentChildData={(row: any, rows: any) => rows.find((a: any) => a.id === row.parentId)}
                            // onView={this.viewUser}
                            />
                        )}
                </Grid>

                {(this.modal.isOpen()) &&
                    <AddUpdateCategory
                        isOpen={this.modal.isOpen()}
                        objCategory={this.state.currentData}
                        onComplete={this.loadCategory.bind(this)}
                        toggle={this.modal.toggle}
                    />
                }
                {(this.subCategoryModal.isOpen()) &&
                    <AddUpdateSubCategory
                        isOpen={this.subCategoryModal.isOpen()}
                        parentId={this.state.parentId}
                        objSubCategory={this.state.currentData}
                        onComplete={this.loadCategory.bind(this)}
                        toggle={this.subCategoryModal.toggle}
                    />
                }
            </Container>
        );
    }
}

export default Category;