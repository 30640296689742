import { Card, CardContent } from '@material-ui/core';
import React from 'react'
import { HyperTheme } from '../../Theme/Theme';
import { Line } from 'react-chartjs-2';
// import { IDashboard } from '../../Types/AllTypes';
import api from '../../Services/ApiServices';
import utils from '../../Services/Utils';

interface ProfitLossFlowProps {
    
}
interface ProfitLossFlowState {
    data: any
}

const theme = HyperTheme;

class ProfitLossFlow extends React.Component<ProfitLossFlowProps, ProfitLossFlowState> {
    constructor(props: ProfitLossFlowProps) {
        super(props);
        this.state = {
            data: undefined
        }
    }
    componentDidMount() {
        this.getProfitLossGraph()
    }

    getProfitLossGraph = async () => {
        try {
            utils.showLoader();
            let data = await api.getProfitLossGraphData();
            this.setState({ data })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }


    getGraphData = () => {
        let graphData = {
            labels: this.state.data.months,
            datasets: [
                {
                    label: 'Profit',
                    fill: false,
                    lineTension: 0.5,
                    backgroundColor: 'rgba(75,192,192,1)',
                    borderColor: '#328aef',
                    borderWidth: 2,
                    data: this.state.data.profits
                },
                {
                    label: 'Loss',
                    fill: false,
                    lineTension: 0.5,
                    backgroundColor: 'rgba(75,192,192,1)',
                    borderColor: '#ef3232',
                    borderWidth: 2,
                    data: this.state.data.losses
                },
            ]
        }

        return graphData;
    }



    render() {
        if(!this.state.data)
            return null;

        return (
            <Card elevation={3} >
                <CardContent>
                    <Line
                        data={this.getGraphData()}
                        options={{
                            title: {
                                display: true,
                                text: 'Profit/Loss',
                                fontSize: 25,
                                fontColor: theme.palette.primary.main,
                                padding: 10
                            },
                            legend: {
                                display: true,
                                position: 'top',
                                align: 'end'
                            }

                        }}
                    />
                </CardContent>
            </Card>
        );
    }
}

export default ProfitLossFlow;
