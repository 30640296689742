import React from 'react'
import { Container, withTheme, Theme, Paper, Typography, Button } from '@material-ui/core'

import {  ICompanySettings } from '../../Types/AllTypes';

import auth from '../../Services/AuthService';
import { RouteComponentProps } from 'react-router-dom';
import { reInitApp } from '../..';

interface CompanySelectionProps extends RouteComponentProps {
    theme: Theme
}

interface CompanySelectionState {
    shouldShowSkeleton: boolean,
    lstCompany: ICompanySettings[]
}

class CompanySelection extends React.Component<CompanySelectionProps, CompanySelectionState> {
    constructor(props: CompanySelectionProps) {
        super(props);
        let state: CompanySelectionState = {
            shouldShowSkeleton: true,
            lstCompany: auth.getUserCompanies()
        };

        this.state = state;
    }

    componentDidMount() {
        if (this.state.lstCompany && this.state.lstCompany.length === 1) {
            this.onCompanySelect(this.state.lstCompany[0])
        }
    }

    onCompanySelect = (selectCompany: ICompanySettings) => {
        auth.setCompanyId(selectCompany.id)
        this.props.history.push("/")
        reInitApp();
    }

    render() {
        if (this.state.lstCompany && this.state.lstCompany.length === 1) {
            return null;
        }

        return (
            <Container>
                <Paper style={{ padding: 40, paddingTop: 60, paddingBottom: 60 }} elevation={3}>
                    <Typography variant="h4" style={{ textAlign: 'center' }}>
                        <b>
                            Select Company
                        </b>
                    </Typography>
                    <div style={{ marginTop: 30, textAlign: 'center' }}>
                        {this.state.lstCompany.map((objCompany: ICompanySettings, index: number) => {
                            return <div key={index}>
                                <Button
                                    style={{ margin: 5 }}
                                    variant={objCompany.isSelected ? "contained" : "outlined"}
                                    color="primary"
                                    onClick={() => { this.onCompanySelect(objCompany) }}
                                >
                                    {objCompany.companyName}
                                </Button>
                            </div>
                        })}
                    </div>
                </Paper>
            </Container>
        );
    }
}

export default withTheme(CompanySelection);