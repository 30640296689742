import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import {
  Theme,
  WithStyles,
  createStyles,
  IconButton,
  WithTheme,
  Tooltip,
} from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import MenuIcon from '@material-ui/icons/Menu';

import UserMenu from "../UserMenu";
import { IconAnnouncement } from "../../Components/Icons/Icons";
import { HyperTheme } from "../../Theme/Theme";
import HyperModal from "../../Components/HyperInput/HyperModal";
import Promotion from "../../Pages/Promotion/Promotion";

const drawerWidth = 240;

const styles = (theme: Theme) =>
  createStyles({

    appBar: {
      backgroundColor: theme.palette.primary.dark,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    }
  })


interface ITopBarProps extends WithTheme, WithStyles, RouteComponentProps {
  onMenuChange: () => void
}

interface ITopBarState {
  isFilterModelOpen: boolean
  isClientCommunicationModalOpen: boolean
  isInitiateCommunicationByMailSMS: boolean
  mobileMenuAnchorElement: any
}

class TopBar extends React.Component<ITopBarProps, ITopBarState> {
  constructor(props: ITopBarProps) {
    super(props);
    this.state = {
      isFilterModelOpen: false,
      isClientCommunicationModalOpen: false,
      isInitiateCommunicationByMailSMS: false,
      mobileMenuAnchorElement: null,
    };
    this.announcementModal = new HyperModal(this);
  }
  announcementModal: HyperModal;

  render() {
    const { classes, theme } = this.props;
    return (
      <React.Fragment>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.props.onMenuChange}
              edge="start"
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title} style={{ cursor: 'pointer', flexGrow: 1 }} >
              Accounts++
            </Typography>

            <Tooltip title={"Announcement"} >
              <IconButton style={{ marginRight: 10, color: HyperTheme.palette.common.white }} onClick={this.announcementModal.toggle}>
                <IconAnnouncement />
              </IconButton>
            </Tooltip>

            <UserMenu />
          </Toolbar>
        </AppBar>
        <AppBar position="static" style={{ visibility: 'hidden', backgroundColor: theme.palette.primary.light }}>
          <Toolbar>
          </Toolbar>
        </AppBar>
        {(this.announcementModal.isOpen()) &&
          <Promotion
            isOpen={this.announcementModal.isOpen()}
            toggle={this.announcementModal.toggle}
          />
        }

      </React.Fragment>
    );
  }
}


export default (withStyles(styles, { withTheme: true })(withRouter(TopBar)));