import React from 'react'
import { Journal, IAccount } from '../../Types/AllTypes';
import utils from '../../Services/Utils';
import enums from '../../enums';
import scanRUtils, { winState } from '../../Services/ScanRUtilService';

interface AccountStatementTableProps {
    lstStatement: Journal[]
    objAccount: IAccount
    dateRange:any
    totalBalance: number
}
interface AccountStatementTableState {
    html: any
}

class AccountStatementTable extends React.Component<AccountStatementTableProps, AccountStatementTableState> {
    constructor(props: AccountStatementTableProps) {
        super(props);
        let state: AccountStatementTableState = {
            html: ""
        };
        this.state = state;
    }

    ref = React.createRef<HTMLDivElement>();

    renderDescription = (objStatement: Journal) => {
        const invoicePrefix = winState.state.companySettings.invoicePrefix;

        if (objStatement.category === enums.JOURNAL_CATEGORY.RECEIPT) {
            if (objStatement.info.salesId)
                return "Receipt of #SALES-" + objStatement.info.salesId
            else
                return "Receipt"
        }
        else if (objStatement.category === enums.JOURNAL_CATEGORY.PAYMENT) {
            if (objStatement.info.purchaseId)
                return "Payment of #PURCHASE-" + objStatement.info.salesId
            else
                return "Payment"
        }
        else if (objStatement.debitAccountId.toString() === this.props.objAccount.id.toString()) {
            if (objStatement.category === enums.JOURNAL_CATEGORY.SALES || objStatement.category === enums.JOURNAL_CATEGORY.PURCHASE)
                return `${objStatement.creditAccount.name} ${"(" + invoicePrefix + "-" + objStatement.info.INVNumber + ")"}`
            return objStatement.creditAccount.name
        }
        return objStatement.debitAccount.name;
    }

    renderCredit = (objStatement: Journal) => {
        if (objStatement.debitAccountId.toString() !== this.props.objAccount.id.toString())
            return `Cr ${scanRUtils.prettyPrice(objStatement.amount)}`;
        return ""
    }

    renderDebit = (objStatement: Journal) => {
        if (objStatement.debitAccountId.toString() === this.props.objAccount.id.toString())
            return `Dr ${scanRUtils.prettyPrice(objStatement.amount)}`;
        return ""
    }
    renderRef = (objStatement: Journal) => {
        if (objStatement.category === enums.JOURNAL_CATEGORY.SALES)
            return `#${enums.JOURNAL_CATEGORY.SALES}-${objStatement.id}`
        else if (objStatement.category === enums.JOURNAL_CATEGORY.PURCHASE)
            return `#${enums.JOURNAL_CATEGORY.PURCHASE}-${objStatement.id}`
        return ""

    }

    render() {
        let currentStatementBalance = this.props.totalBalance;
        (this.props.lstStatement[this.props.lstStatement.length - 1] as any).currentStatementBalance = this.props.totalBalance;

        for (let i = this.props.lstStatement.length - 1; i >= 0; i--) {
            let transactionBalance = 0;

            let obj = this.props.lstStatement[i];
            if (obj.debitAccountId.toString() === this.props.objAccount.id.toString()) {
                transactionBalance = obj.amount;
            }
            else {
                transactionBalance = 0 - obj.amount;
            }


            if (this.props.lstStatement[i - 1])
                (this.props.lstStatement[i - 1] as any).currentStatementBalance = currentStatementBalance - transactionBalance;
            currentStatementBalance -= transactionBalance
        }

        return (
            <React.Fragment>
                <div ref={this.ref}>
                    <div>
                        <h1 style={{ textAlign: "center" }}>
                            {winState.state.companySettings.companyName}
                        </h1>
                    </div>

                    <div>
                        <p>
                            Account Statement of <b>{this.props.objAccount.name}</b>
                        </p>
                        <p>
                            From date:- <b>{utils.convertToSystemDateFormate(this.props.dateRange.startDate)}</b>
                        </p>
                        <p>
                            To date:- <b>{utils.convertToSystemDateFormate(this.props.dateRange.endDate)}</b>
                        </p>
                        <p>
                            Closing Balance:- <b>{scanRUtils.prettyPrice(this.props.totalBalance)}</b>
                        </p>
                    </div>
                    <table style={{ width: "100%" }} {...{ border: 1 }} cellSpacing="0" cellPadding="5">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Ref.No</th>
                                <th>Credit</th>
                                <th>Debit</th>
                                <th>Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>Opening Balance</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{scanRUtils.prettyPrice(currentStatementBalance)}</td>
                            </tr>

                            {this.props.lstStatement.map((objStatement: Journal) => {
                                return (
                                    <tr key={objStatement._id}>
                                        <td>{utils.convertToSystemDateTimeFormate(objStatement.date)}</td>
                                        <td>{this.renderDescription(objStatement)}</td>
                                        <td>{this.renderRef(objStatement)}</td>
                                        <td>{this.renderCredit(objStatement)}</td>
                                        <td>{this.renderDebit(objStatement)}</td>
                                        <td>{scanRUtils.prettyPrice((objStatement as any).currentStatementBalance)}</td>
                                    </tr>
                                )
                            })}

                            <tr>
                                <td></td>
                                <td>Closing Balance</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{scanRUtils.prettyPrice(this.props.totalBalance)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );
    }
}

export default AccountStatementTable;