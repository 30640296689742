import React from 'react'
import { Container, withTheme, Theme, Grid, Paper, Typography } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IconInventory, IconSales, IconPurchase, IconPayment, IconReceipt, IconIncome, IconExpanse, IconSupplier, IconCustomer, IconBalanceSheet, IconAccount, IconProfitAndLoss, IconItem, IconTransfer, IconCart, IconCategory, IconTax } from '../../Components/Icons/Icons';

interface ReportsProps extends RouteComponentProps {
    theme: Theme
}

interface ReportsState {
}
const reportList = [
    {
        key: "balanceSheet",
        name: "Balance Sheet",
        link: "/bs",
        icon: <IconBalanceSheet style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    {
        key: "profileAndLoss",
        name: "Profile and Loss",
        link: "pnl",
        icon: <IconProfitAndLoss style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    
    {
        key: "inventoryReport",
        name: "Inventory Reports",
        link: "/reports/stockSummary",
        icon: <IconInventory style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    {
        key: "itemStockBook",
        name: "Item Wise Stock",
        link: "/reports/stockBook",
        icon: <IconInventory style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    {
        key: "categoryWiseStock",
        name: "Category Wise Stock",
        link: "/reports/catWiseStock",
        icon: <IconInventory style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    {
        key: "salesReport",
        name: "Sales Reports",
        link: "/reports/sReport",
        icon: <IconSales style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    {
        key: "purchaseReport",
        name: "Purchase Reports",
        link: "/reports/pReport",
        icon: <IconPurchase style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    {
        key: "paymentReport",
        name: "Payment Reports",
        link: "/reports/paymentReport",
        icon: <IconPayment style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    {
        key: "receiptReport",
        name: "Receipt Reports",
        link: "/reports/receiptReport",
        icon: <IconReceipt style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    {
        key: "income",
        name: "Income",
        link: "/reports/incomeReport",
        icon: <IconIncome style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    {
        key: "expense",
        name: "Expense",
        link: "/reports/expanseReport",
        icon: <IconExpanse style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    {
        key: "supplierWisePurchase",
        name: "Supplier Wise Purchase",
        link: "/reports/supplierWisePurchase",
        icon: <IconSupplier style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    {
        key: "salesByCategory",
        name: "Sales By Category",
        link: "/reports/sByCategory",
        icon: <IconCategory style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    {
        key: "purchaseByCategory",
        name: "Purchase By Category",
        link: "/reports/pByCategory",
        icon: <IconCategory style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    {
        key: "categoryWisePL",
        name: "Category Wise P&L",
        link: "/reports/catWisePL",
        icon: <IconCategory style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    {
        key: "gstReport",
        name: "GSTR1 Report",
        link: "/reports/gstR1Report",
        icon: <IconTax style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    {
        key: "gstR3Report",
        name: "GSTR3 Report",
        link: "/reports/gstR3Report",
        icon: <IconTax style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
 
    {
        key: "billsPayable",
        name: "Bills Payable",
        link: "/reports/billsPayable",
        icon: <IconCart style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    {
        key: "billsReceivable",
        name: "Bill Receivable",
        link: "/reports/billsReceivable",
        icon: <IconIncome style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    {
        key: "customerWiseSales",
        name: "Customer Wise Sales",
        link: "/reports/customerWiseSales",
        icon: <IconCustomer style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    {
        key: "salesByItem",
        name: "Sales By Item",
        link: "/reports/salesByItem",
        icon: <IconItem style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    {
        key: "purchaseByItem",
        name: "Purchase By Item",
        link: "/reports/purchaseByItem",
        icon: <IconItem style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },
    {
        key: "dateWiseSalesReport",
        name: "Date Wise Sales",
        link: "/reports/dateWiseSales",
        icon: <IconSales style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },

    {
        key: "accountBook",
        name: "Account Book/Statement",
        link: "/reports/accountBook",
        icon: <IconAccount style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },

    {
        key: "dateWisePurchase",
        name: "Date Wise Purchase",
        link: "/reports/dateWisePurchase",
        icon: <IconPurchase style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },

    {
        key: "bankTransfer",
        name: "Bank Transfer",
        link: "/reports/bankTransfer",
        icon: <IconTransfer style={{ fontSize: 75 }} fontSize={"large"} color="primary" />
    },

]

class Reports extends React.Component<ReportsProps, ReportsState> {
    constructor(props: ReportsProps) {
        super(props);
        let state: ReportsState = {

        };
        this.state = state;
    }

    componentDidMount() { }

    render() {
        return (
            <Container >
                <Grid container spacing={5}>
                    {reportList.map((report: any) => {
                        return (
                            <Grid xs={12} md={4} item key={report.key}>
                                <Paper elevation={3}
                                    onClick={() => this.props.history.push(report.link)}
                                    style={{ cursor: 'pointer', padding: 10, paddingTop: 25, paddingBottom: 25, textAlign: 'center' }}>
                                    {report.icon}
                                    <Typography variant="h6">
                                        {report.name}
                                    </Typography>
                                </Paper>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        );
    }
}

export default withTheme(withRouter(Reports));