import React, { FormEvent } from 'react'
import moment from 'moment';
import CommonModal from '../../Components/CommonModal';
import { Grid, Button } from '@material-ui/core';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import { IAccount, Journal } from '../../Types/AllTypes';
import HyperForm from '../../Components/HyperInput/HyperForm';
import api from '../../Services/ApiServices';
import utils from '../../Services/Utils';
import AccountAutoComplete from '../Journal/AccountAutoComplete';
import { JournalInsertObject } from '../../Types/InsertObjects';
import HyperAmountField from '../../Components/HyperInput/HyperAmountField';
import enums from '../../enums';

interface AddUpdatePaymentProps {
    objJournal?: Journal
    isOpen: boolean
    isDisabledDebitField?: boolean
    isDisabledCreditField?: boolean
    isDisabledAmountField?: boolean
    hideDebitField?: boolean
    hideCreditField?: boolean
    hideAmountField?: boolean
    showDueAmount?: boolean

    onComplete?: () => void
    toggle: () => void
}

interface AddUpdatePaymentState {
    objJournal: Journal
}


const initialJournal: Journal = {
    _id: "",
    debitAccountId: 0,
    creditAccountId: 0,
    amount: 0,
    comment: "",
    date: "",
    createdAt: "",
    username: "",
    id: 0,
    debitAccount: {} as IAccount,
    creditAccount: {} as IAccount,
} as Journal;


class AddUpdatePayment extends React.Component<AddUpdatePaymentProps, AddUpdatePaymentState> {
    constructor(props: AddUpdatePaymentProps) {
        super(props);
        console.log(props)
        let state: AddUpdatePaymentState = {
            objJournal: initialJournal
        };

        if (this.props.objJournal) {
            state.objJournal = this.props.objJournal
            this.isEditing = (this.props.hideDebitField || this.props.hideCreditField) ? false : true;;
        }
        if (!state.objJournal.date) {
            state.objJournal.date = moment().format("YYYY-MM-DD")
        }
        else {
            state.objJournal.date = moment(state.objJournal.date).format("YYYY-MM-DD")
        }

        this.state = state;
        this.frm = new HyperForm();
    }
    frm: HyperForm
    isEditing: boolean = false;

    componentDidMount() {
        // document.addEventListener(enums.EVENT.Journal_ADD_UPDATE_MODEL, this.onModelToggle);
    }

    componentWillUnmount() {
        // document.removeEventListener(enums.EVENT.Journal_ADD_UPDATE_MODEL, this.onModelToggle);
    }

    onModelToggle = (event?: any) => {
        this.props.toggle()
    }

    setJournal(obj: Partial<Journal>) {
        this.setState({ objJournal: { ...this.state.objJournal, ...obj } });
    }

    onSubmit = async (e: FormEvent) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.frm.setIsFormSubmitted(true)
            if (this.frm.isValid()) {
                let result = null;
                let io = new JournalInsertObject();
                io.id = this.state.objJournal.id;
                io.debitAccountId = this.state.objJournal.debitAccountId;
                io.creditAccountId = this.state.objJournal.creditAccountId;
                io.amount = this.state.objJournal.amount;
                io.comment = this.state.objJournal.comment;
                io.date = this.state.objJournal.date;
                io.info = this.state.objJournal.info;
                io.category = enums.JOURNAL_CATEGORY.CONTRA
                utils.showLoader();
                if (this.isEditing) {
                    // result = await api.updateJournal(io);
                    result = await api.journalMultiAction([{ actionCode: enums.DB_ACTION.UPDATE, data: io }]);
                }
                else {
                    result = await api.journalMultiAction([{ actionCode: enums.DB_ACTION.INSERT, data: io }]);
                    // result = await api.addJournal(io);
                }
                this.onModelToggle();
                if (this.props.onComplete) {
                    this.props.onComplete();
                }
                console.log(result)
            }
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }

    render() {
        return (
            <div>
                <CommonModal
                    dialogProps={{
                        open: this.props.isOpen
                    }}
                    title={"Transfer"}
                    toggle={this.props.toggle}
                >
                    {/* {this.props.showDueAmount ?
                        <Grid item xs={12}>
                            <Alert severity="info">Total Due Amount - {scanRUtils.prettyPrice(this.props.objJournal?.amount || 0)}</Alert>
                        </Grid>
                        :
                        ""
                    } */}
                    <form onSubmit={this.onSubmit}>
                        <Grid container spacing={2}>
                            {!this.props.hideDebitField &&
                                <Grid item xs={12} md={6}>
                                    <AccountAutoComplete
                                        accountId={this.state.objJournal.creditAccountId}
                                        onChange={(objAccount: IAccount) => {
                                            if (objAccount)
                                                this.setJournal({ creditAccountId: objAccount.id })
                                        }}
                                        label="From"
                                        frm={this.frm}
                                        category={enums.JOURNAL_CATEGORY.CASH_BANK}

                                        required
                                        autoFocus

                                        addBtnLabel="Add New Bank Option"
                                        autoCompleteAddModalType={enums.AUTO_COMPLETE_ADD_TYPE.ADD_PAYMENT_OPTION}
                                    />
                                </Grid>
                            }
                            {!this.props.hideCreditField &&
                                <Grid item xs={12} md={6}>
                                    <AccountAutoComplete
                                        accountId={this.state.objJournal.debitAccountId}
                                        onChange={(objAccount: IAccount) => {
                                            if (objAccount)
                                                this.setJournal({ debitAccountId: objAccount.id })
                                        }}
                                        label="To"
                                        // category={enums.JOURNAL_CATEGORY.CASH_BANK}
                                        categories={[enums.JOURNAL_CATEGORY.CASH_BANK]}

                                        frm={this.frm}
                                        required

                                        addBtnLabel="Add New Bank Option"
                                        autoCompleteAddModalType={enums.AUTO_COMPLETE_ADD_TYPE.ADD_PAYMENT_OPTION}
                                    />
                                </Grid>
                            }
                            {!this.props.hideAmountField &&
                                <Grid item xs={12} md={6}>
                                    <HyperAmountField
                                        required
                                        value={this.state.objJournal.amount}
                                        onChange={(e: any) => { this.setJournal({ amount: parseFloat(e.target.value) }) }}
                                        label={"Amount"}
                                        type="number"
                                        frm={this.frm}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} md={6}>
                                <HyperTextField
                                    required
                                    value={this.state.objJournal.date}
                                    onChange={(e: any) => {
                                        this.setJournal({ date: (e.target.value) })
                                    }}
                                    label={"Date"}
                                    type="date"
                                    frm={this.frm}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <HyperTextField
                                    required
                                    value={this.state.objJournal.comment}
                                    onChange={(e: any) => {
                                        this.setJournal({ comment: (e.target.value) })
                                    }}
                                    label={"Comment"}
                                    type="text"
                                    multiline
                                    frm={this.frm}
                                />
                            </Grid>

                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Button type="submit" color="primary" variant="contained">
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CommonModal>
            </div>
        );
    }
}

export default AddUpdatePayment;