import React from 'react'
import BaseInput from './BaseInput'
import DatePicker from '../DatePicker';

export default class HyperDateTimeField extends BaseInput {
    getValue = () => {
        return this.props.selected
    }

    render() {

        return (
            <DatePicker
                selected={new Date(this.props.selected)}
                error={!this.isValid()}
                onChange={this.props.onChange}
                helperText={this.getHelperText()}
                onFocus={this.onFocus}
                minDate={this.props.minDate}
                maxDate={this.props.maxDate}
                label={this.props.label}
                disabled={this.props.disabled}
            />
        )
    }
}
