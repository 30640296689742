import React from 'react'
import { Container, Grid, Paper, Typography, Button } from '@material-ui/core'
import api from '../../../Services/ApiServices';
import ReportsDataTable from '../../../Components/ReportsDataTable';
import scanRUtils from '../../../Services/ScanRUtilService';
import ItemAutoComplete from '../../Item/ItemAutoComplete';
import { IItem, IWindowState } from '../../../Types/AllTypes';
import utils from '../../../Services/Utils';
import enums from '../../../enums';
import HyperDateRangePicker from '../../../Components/HyperDateRangePicker';
import moment from "moment";
import { RouteComponentProps } from 'react-router-dom';
import withWindowState from '../../../Components/withWindowState';
import { StockBookSearchOption } from '../../../Types/SearchOptions';
import SeeMore from '../../../Components/SeeMore';

interface ItemStockBookProps extends RouteComponentProps, IWindowState {
}

interface ItemStockBookState {
    lstItemStockBook: any
    totalPcs: number
    totalAmount: number
    objItem: IItem | undefined
    totalPurchaseQty: number
    totalPurchaseAmount: number
    totalSalesQty: number
    totalSalesAmount: number
    dateRange: any
    isLoading: boolean
    showSN: boolean
}


class ItemStockBook extends React.Component<ItemStockBookProps, ItemStockBookState> {
    constructor(props: ItemStockBookProps) {
        super(props);
        let state: ItemStockBookState = {
            lstItemStockBook: [],
            totalPcs: 0,
            totalAmount: 0,
            objItem: undefined,
            totalPurchaseQty: 0,
            totalPurchaseAmount: 0,
            totalSalesQty: 0,
            isLoading: false,
            showSN: false,
            totalSalesAmount: 0,
            dateRange: { startDate: moment().startOf("month").toDate(), endDate: moment().endOf("month").toDate(), label: "This Month" }
        };
        this.state = state;
    }

    async componentDidMount() {
        let queryParams = utils.decodeQueryObject(this.props.location.search);
        await this.setState({ objItem: queryParams, showSN: queryParams?.showSN || false })
        if (this.state.objItem)
            this.getItemStockBook()
    }
    getItemStockBook = async () => {
        try {
            await this.setState({ isLoading: true });
            let so = new StockBookSearchOption();
            so.itemId = this.state.objItem?.id;
            let data = await api.getStockBook(so);
            // console.log(data, "data")
            let totalSalesQty: number = 0;
            let totalSalesAmount: number = 0;
            let totalPurchaseQty: number = 0;
            let totalPurchaseAmount: number = 0;
            if (data.length === 0)
                return false
            data.transactions.forEach((item: any) => {
                if (item.category === enums.ACCOUNT_CATEGORY.SALES) {
                    totalSalesQty = totalSalesQty + item.info.items.qty;
                    totalSalesAmount = totalSalesAmount + item.info.items.amount;
                }
                else if (item.category === enums.ACCOUNT_CATEGORY.PURCHASE) {
                    totalPurchaseQty = totalPurchaseQty + item.info.items.qty;
                    totalPurchaseAmount = totalPurchaseAmount + item.info.items.amount;
                }
            })
            let transactions: any = data.transactions;
            if (this.state.showSN)
                transactions = this.getInStockItemList(data.transactions)
            this.setState({
                lstItemStockBook: transactions,
                totalSalesQty,
                totalSalesAmount,
                totalPurchaseQty,
                totalPurchaseAmount
            })
        }
        catch (ex) {
            console.log(ex)
            utils.showError(ex.message)
        }
        finally {
            await this.setState({ isLoading: false });
        }
    }

    getInStockItemList = (arr: any) => {
        let repeated: any = []
        let findArr: any = [];
        arr.forEach((item: any) => {
            //PTH_NIK
            if (item.info.items.lstSerialNo) {
                let serialNos = item.info.items.lstSerialNo;
                if (serialNos) {
                    // console.log(serialNos, "serialNos")
                    if (typeof serialNos == "string")
                        serialNos = serialNos.split(",");
                    serialNos.forEach((sItem: any) => {
                        let pushedObj: any = { ...item };
                        pushedObj.info = { ...item.info };
                        pushedObj.info.items = { ...item.info.items };
                        pushedObj.info.items.amount = (pushedObj.info.items.amount / serialNos.length);
                        pushedObj.info.items.tax = (pushedObj.info.items.tax / serialNos.length);
                        pushedObj.info.items.qty = pushedObj.info.items.qty / serialNos.length;
                        pushedObj.info.items.serialNo = sItem;
                        findArr.push(pushedObj);
                    })
                }
            }
        });

        let uniqueArr = findArr.reduce(
            (accumulator: any, current: any) => {
                if (!accumulator.some((x: any) => x.info.items?.serialNo === current.info.items?.serialNo)) {
                    accumulator.push(current)
                }
                else {
                    repeated.push(current)
                }
                return accumulator;
            }, []
        )
        let arrFinal = uniqueArr.filter((uItem: any) => {
            if (!repeated.find((rItem: any) => rItem.info.items.serialNo === uItem.info.items.serialNo))
                return uItem;
            return false
        })
        return arrFinal
    }
    getSN = (rowData: any) => {
        if (rowData.info) {
            let serialNos = rowData.info.items.serialNo;
            if (serialNos) {
                serialNos = serialNos.split(",").join(", ")
            }
            // console.log(serialNos, "serialNos")
            // return serialNos;
            return <div style={{ width: 100 }}><SeeMore text={serialNos} length={20} /></div>
        }
        return "";
    }

    render() {
        // console.log(this.state, "State")
        let lstExtendColumns = [];

        if (this.props.companySettings.showItemSerialNo) {
            lstExtendColumns.push({ title: "S/N", render: this.getSN, sortField: "info.items.serialNo" })
        }
        return (
            <Container>
                <Grid container spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item md={6} style={{ textAlign: 'right', margin: 'auto' }}>
                        <Button onClick={this.getItemStockBook} variant="contained" color="primary">Refresh</Button>{" "}
                        <HyperDateRangePicker
                            initialDateRange={this.state.dateRange}
                            onChange={async (range: any) => {
                                await this.setState({ dateRange: range });
                                this.getItemStockBook()
                            }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <ItemAutoComplete
                            placeholder="Select Item for Stock Book"
                            itemId={this.state.objItem?.id}
                            onChange={async (data: IItem) => { await this.setState({ objItem: data }); this.getItemStockBook() }}
                        />
                    </Grid>
                </Grid>

                <ReportsDataTable
                    key={Date.now()}
                    title={`Stock Book Of ${this.state.objItem?.name || ""}`}
                    columns={([
                        { title: "Category", field: "category" },
                        {
                            title: "Transaction Date",
                            render: (rowData: any) => {
                                return utils.convertToSystemDateTimeFormate(rowData.date)
                            },
                            sortField: "date"
                        },
                        {
                            title: "Transaction Qty",
                            render: (rowData: any) => {
                                if (rowData.category === enums.ACCOUNT_CATEGORY.SALES)
                                    return <Typography color="error">{"- " + rowData.info.items.qty}</Typography>
                                if (rowData.category === enums.ACCOUNT_CATEGORY.PURCHASE)
                                    return <Typography color="primary">{"+ " + rowData.info.items.qty}</Typography>
                            },
                            sortField: "info.items.qty"
                        },
                        { title: "Tax", field: "info.items.tax" },
                        { title: "Amount", field: "info.items.amount" },
                    ] as any).concat(lstExtendColumns)}
                    data={this.state.lstItemStockBook}
                    options={{
                        sorting: true,

                    }}
                    isLoading={this.state.isLoading}
                    onRowClick={() => { }}
                />
                <Paper elevation={3} style={{ marginTop: 20, paddingTop: 30, paddingBottom: 30, padding: 20 }}>
                    <Grid container>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6">Purchase Quantity</Typography>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6" color="primary">{this.state.totalPurchaseQty}</Typography>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6">Total Purchase Amount</Typography>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6" color="primary">{scanRUtils.prettyPrice(this.state.totalPurchaseAmount)}</Typography>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6">Sales Quantity</Typography>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6" color="primary">{this.state.totalSalesQty}</Typography>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6">Total Sales Amount</Typography>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6" color="primary">{scanRUtils.prettyPrice(this.state.totalSalesAmount)}</Typography>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6">Current Quantity</Typography>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6" color="primary">{this.state.totalPurchaseQty - this.state.totalSalesQty}</Typography>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6">Total Amount</Typography>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6" color="primary">{scanRUtils.prettyPrice(this.state.totalSalesAmount - this.state.totalPurchaseAmount)}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        );

    }
}

export default withWindowState(ItemStockBook, ["companySettings"]);