import utils from "./Utils";
import lsu from "./LocalStorageUtils";

export class WindowState {
    constructor() {
        if (!window._privateState) {
            window._privateState = {};
        }
        for (let objKey of this._registerableProps) {
            let key = objKey.key;
            this._refs[key] = [];

            window._privateState[key] = objKey.defaultValue;
            this[this.getSetStateKey(key)] = (val) => {
                window._privateState[key] = { ...window._privateState[key], ...val };
                for (let ref of this._refs[key]) {
                    if (!ref.unmounted) {
                        ref.forceUpdate();
                    }
                }
            }
            Object.defineProperty(this.state, key, {
                get: () => {
                    return window._privateState[key];
                },
                set: this[this.getSetStateKey(key)],
            });
        }
        // console.log(!!lsu.lsGet("isMenuOpen"),"Register WinState",lsu.lsGet("isMenuOpen"))
    }
    _registerableProps = [
        { key: "user", defaultValue: {} },
        { key: "companySettings", defaultValue: {} },
        {
            key: "global", defaultValue: {
                lstSerialNo: [],
                isMenuOpen: !!lsu.lsGet("isMenuOpen")
            }
        },
        { key: "category", defaultValue: {} },
    ]
    state = {}
    _refs = {};

    getSetStateKey(key) {
        return "set" + utils.convertFirstCharCap(key);
    }

    register(that, aryKeys) {
        for (let key of aryKeys) {
            if (this._refs[key]) {
                this._refs[key].push(that);
            }
        }
    }

    unregister(that) {
        for (let key in this._refs) {
            for (let ref of this._refs[key]) {
                if (ref === that) {
                    ref.unmounted = true;
                }
            }
        }
    }

    get(aryKeys) {
        if (typeof aryKeys == "string")
            aryKeys = [aryKeys];
        let obj = {};
        for (let key of aryKeys) {
            obj[key] = window._privateState[key];
            obj[this.getSetStateKey(key)] = this[this.getSetStateKey(key)];
        }
        return obj;
    }
}

// const winState = new WindowState();
// window.winState = winState;
// export default winState;