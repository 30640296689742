import React from 'react'
import CommonModal from '../../Components/CommonModal';
import { Grid } from '@material-ui/core';
import { Journal } from '../../Types/AllTypes';
import { JournalSearchOptions } from '../../Types/SearchOptions';
import api from '../../Services/ApiServices';
import utils from '../../Services/Utils';
import NotificationSkeleton from '../../Components/Skeletons/NotificationSkeleton';
import DataTable from '../../Components/DataTable';
import AccountText from '../Accounts/AccountText';
import scanRUtils from '../../Services/ScanRUtilService';

interface SnTransactionsModalProps {
    isOpen: boolean
    searchText: string

    toggleModal: () => void
}

interface SnTransactionsModalState {
    shouldShowSkeleton: boolean,
    lstJournal: Journal[],
}


class SnTransactionsModal extends React.Component<SnTransactionsModalProps, SnTransactionsModalState> {
    constructor(props: SnTransactionsModalProps) {
        super(props);
        let state: SnTransactionsModalState = {
            shouldShowSkeleton: true,
            lstJournal: [],
        };
        this.state = state;
    }
    componentDidMount() {
        this.loadJournals();
    }

    async loadJournals() {
        try {
            let so = new JournalSearchOptions();
            so.info = {
                "info.items.lstSerialNo": this.props.searchText
            }

            let lstJournal: any = await api.getJournals(so);
            lstJournal.forEach((item: any) => {
                item.debitAccountName = <AccountText objAccount={item.debitAccount} />
                item.creditAccountName = <AccountText objAccount={item.creditAccount} />
                item.date = utils.convertToSystemDateTimeFormate(new Date(item.date))
                let objItem = item.info.items.find((infoItem: any) => infoItem.lstSerialNo.includes(this.props.searchText))
                if (objItem)
                    item.itemAmount = scanRUtils.prettyPrice(objItem.amount || 0)
            });
            // console.log(lstJournal, "lstJournal")
            this.setState({ lstJournal, shouldShowSkeleton: false })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }

    }

    render() {
        return (
            <div>
                <CommonModal
                    dialogProps={{
                        open: this.props.isOpen
                    }}
                    maxWidth="lg"
                    isFullScreen={true}
                    title={`Account Book`}
                    toggle={this.props.toggleModal}
                >
                    <Grid container >
                        <Grid item xs={12}>
                            {this.state.shouldShowSkeleton ? (
                                <NotificationSkeleton />
                            ) : (
                                    <DataTable
                                        title={`Transaction of ` + this.props.searchText}
                                        columns={[
                                            { title: "ID", field: "id" },
                                            { title: "Transaction", field: "category" },
                                            { title: "Date", field: "date" },
                                            { title: "Debit Account", field: "debitAccountName" },
                                            { title: "Credit Account", field: "creditAccountName" },
                                            { title: "Amount", field: "itemAmount" },
                                        ]}
                                        data={this.state.lstJournal}
                                        options={{
                                            sorting: true,
                                        }}
                                    />
                                )}
                        </Grid>
                    </Grid>
                </CommonModal>
            </div>
        );
    }
}

export default SnTransactionsModal;