import React from 'react'
import { FormControlLabel, FormControl, FormHelperText, FormGroup, Checkbox } from '@material-ui/core'
import BaseInput from './BaseInput'
import PropTypes from 'prop-types';


export default class HyperCheckboxField extends BaseInput {
    render() {
        return (
            <FormControl
                variant="outlined"
                fullWidth={this.props.fullWidth}
                disabled={this.props.disabled}
                error={!this.isValid()}
            >
                <FormGroup
                    row
                    variant={this.props.variant || "outlined"}
                    onFocus={this.onFocus}
                    value={this.props.value}
                    type={this.props.type || "text"}
                    onChange={this.onChange}
                >
                    {this.props.options.map((itemCheckBox, indexCheckBox) => {
                        return (
                            <FormControlLabel
                                checked={this.props.value?.split(",").includes(itemCheckBox) || false}
                                value={itemCheckBox}
                                key={indexCheckBox}
                                control={<Checkbox color="primary" />}
                                label={itemCheckBox}
                                color="primary"
                            />
                        )
                    })}
                    <FormHelperText>{this.getHelperText()}</FormHelperText>
                </FormGroup>
            </FormControl>
        )
    }
}

HyperCheckboxField.propTypes = {
    fullWidth: PropTypes.any,
    disabled: PropTypes.any,
    variant: PropTypes.any,
    value: PropTypes.any,
    type: PropTypes.any,
    options: PropTypes.any,
}
