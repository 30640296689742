import io from 'socket.io-client';
import { v4 } from 'uuid';
import config from '../config';
import enums from '../enums';
import auth from './AuthService';
import utils from './Utils';


class SocketService {
    apiCallResponseListeners = {};
    init() {
        if (window.socket) { // Socket is already established
            return;
        }
        // console.log("sdf")

        window.socket = io(config.API_URL, {
            query: `deviceId=${auth.getDeviceID()}`,
            extraHeaders: {
                Authorization: auth.getToken()
            },

        });
        window.socket.on('connect', function () { console.log("connected") });
        window.socket.on('event', function (data) { console.log("event", data) });
        window.socket.on('disconnect', function () { console.log("disconnect") });
        window.socket.on('barcodeScanned', this.onBarcodeScanned.bind(this));
        window.socket.on('apiresponse', this.onApiResponse.bind(this));
    }

    onBarcodeScanned(data) {
        console.log("data", data, document.activeElement)
        utils.fireEvent(enums.EVENT.BARCODE_SCANNED, data);
        if (document.activeElement && document.activeElement.tagName === "input" && data.data) {
            document.activeElement.value = data.data;
        }
    }

    callAPI1(data) {
        let callId = v4();;
        data.callId = callId;
        this.apiCallResponseListeners[callId] = (result) => {
            console.log("res", result)
        }
        window.socket.emit('apicall', data);
    }

    callAPI(data) {
        return new Promise((resolve, reject) => {
            try {
                window.socket.emit('apicall', data, (result) => {
                    if (!result.status)
                        reject(new Error(result.message))
                    resolve(result)
                });
            }
            catch (ex) {
                reject(ex);
            }
        });
    }



    onApiResponse(data) {
        console.log(data)
        if (data.callId && this.apiCallResponseListeners[data.callId]) {
            this.apiCallResponseListeners[data.callId](data.result);
            delete this.apiCallResponseListeners[data.callId]
        }
    }

}

const socketService = new SocketService();
window.socketService = socketService;
export default socketService;
