import React from 'react'
import BaseInput from './BaseInput'
import { FormControl, FormHelperText, Select, InputLabel } from '@material-ui/core';

export default class HyperSelectField extends BaseInput {
    getValue = () => {
        return this.props.selectedValue;
    }

    render() {
        return (
            <FormControl
                variant="outlined"
                fullWidth={this.props.fullWidth}
                disabled={this.props.disabled}
                label={this.props.label}
                error={!this.isValid()}
                size="small"
            >
                <InputLabel htmlFor="hyper-select-field">{this.props.label}</InputLabel>
                <Select
                    labelWidth={this.props.labelWidth}
                    native
                    onChange={this.props.onChange}
                    value={this.props.selectedValue}
                    size="small"
                    id="hyper-select-field"
                >
                    <option value="" disabled> {this.props.label || "select option"}</option>
                    {this.props.options && this.props.options.length > 0 && this.props.options.map((option, index) => {

                        return (
                            <option
                                key={index}
                                value={option[this.props.optionIdField] || option}
                            >
                                {option[this.props.optionNameField] || option}
                            </option>
                        )
                    })}
                </Select>
                <FormHelperText>{this.props.helperText}</FormHelperText>
            </FormControl>
        )
    }
}
