import React from 'react';
import { CircularProgress } from '@material-ui/core';

export default class Loading extends React.Component {
    render() {
        return (
            <div id="divLoader"
                style={{ "position": "fixed", "top": "0px", "left": "0px", "height": "100%", "width": "100%", "background": "rgba(100,100,100,0.5)", "display": this.props.shown?"block":"none", "zIndex": "5000" }}>
                <CircularProgress thickness={5} size={60} style={{ margin: 'auto', left: 0, right: 0, top: 0, bottom: 0, position: 'absolute' }} />
            </div>
        );
    }
}