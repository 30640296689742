import React from 'react'
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import { IPurchaseItem, IItem, IDiscount, IWindowState } from '../../Types/AllTypes';
import HyperForm from '../../Components/HyperInput/HyperForm';
import HyperAmountField from '../../Components/HyperInput/HyperAmountField';
import { IconDeleteFill, IconBulkEntry, } from '../../Components/Icons/Icons';
import ItemAutoComplete from '../Item/ItemAutoComplete';
import scanRUtils from '../../Services/ScanRUtilService';
import BunchEntryModal from './BunchEntryModal';
import HyperModal from '../../Components/HyperInput/HyperModal';
import enums from '../../enums';
import withWindowState from '../../Components/withWindowState';
import utils from '../../Services/Utils';

interface PurchaseItemProps extends IWindowState {
    objPurchaseItem: IPurchaseItem
    frm: HyperForm
    focusedItemRef: any
    objDiscount: IDiscount

    removeItem?: () => void
    onComplete?: () => void
    onChange?: (objPurchaseItem: IPurchaseItem) => void
}


interface PurchaseItemState {
    // objPurchaseItem: IPurchaseItem
}

class PurchaseItem extends React.Component<PurchaseItemProps, PurchaseItemState> {
    constructor(props: PurchaseItemProps) {
        super(props);
        let state: PurchaseItemState = {
        };
        this.state = state;
        this.bunchEntryModal = new HyperModal(this);
    }
    bunchEntryModal: HyperModal

    setPurchaseItem = async (obj: Partial<IPurchaseItem>) => {
        if (this.props.onChange)
            this.props.onChange(obj as IPurchaseItem);
    }

    onBarcodeInput = async (barcode: string) => {
        if (this.props.objPurchaseItem?.objItem?.maintainSerialWiseInventory) {
            try {
                let lstSerialNo = this.props.objPurchaseItem.lstSerialNo || [];
                let objItem = await scanRUtils.getItemFromSerialNoAndValidate(barcode, lstSerialNo, enums.JOURNAL_CATEGORY.PURCHASE);
                if (objItem) {
                    lstSerialNo.push(barcode);
                    this.props.objPurchaseItem.qty = lstSerialNo.length;
                    if (this.props.objPurchaseItem.objItem.isTaxInclusive)
                        this.props.objPurchaseItem.amount = scanRUtils.toFixedFloat(this.props.objPurchaseItem.qty * this.props.objPurchaseItem.objItem.defaultPurchasePrice);

                    this.setPurchaseItem({ lstSerialNo: lstSerialNo });
                }
            }
            catch (ex) {
                utils.showError(ex.msg || ex.message)
            }
        }
    }

    onFinishBunchEntry = (lstSerialNo: string[]) => {
        this.props.setGlobal({ lstSerialNo: this.props.global.lstSerialNo.concat(lstSerialNo) })
        this.props.objPurchaseItem.lstSerialNo = lstSerialNo;
        this.props.objPurchaseItem.qty = lstSerialNo.length
        this.props.objPurchaseItem.amount = scanRUtils.toFixedFloat(this.props.objPurchaseItem.objItem.defaultPurchasePrice * lstSerialNo.length)
        this.forceUpdate();
    }

    render() {
        if (this.props.objPurchaseItem?.objItem?.isTaxInclusive) {
            if (this.props.objPurchaseItem.objItem) {
                this.props.objPurchaseItem.tax = scanRUtils.countReverseTax(this.props.objPurchaseItem.amount, this.props.objPurchaseItem.taxes, this.props.objPurchaseItem.qty);
                this.props.objPurchaseItem.priceAfterDiscount = scanRUtils.toFixedFloat((this.props.objPurchaseItem.amount - this.props.objPurchaseItem.tax) / this.props.objPurchaseItem.qty);
                this.props.objPurchaseItem.price = this.props.objPurchaseItem.priceAfterDiscount;
                this.props.objPurchaseItem.discountAmountPerItem = scanRUtils.countDiscountAmount(this.props.objPurchaseItem.price, this.props.objDiscount)
                this.props.objPurchaseItem.totalTaxableAmount = scanRUtils.toFixedFloat(this.props.objPurchaseItem.priceAfterDiscount * this.props.objPurchaseItem.qty)
            }
        }
        else {
            this.props.objPurchaseItem.amount = this.props.objPurchaseItem.price * this.props.objPurchaseItem.qty;
            if (this.props.objPurchaseItem.objItem) {
                this.props.objPurchaseItem.discountAmountPerItem = scanRUtils.countDiscountAmount(this.props.objPurchaseItem.price, this.props.objDiscount)
                this.props.objPurchaseItem.priceAfterDiscount = scanRUtils.toFixedFloat(this.props.objPurchaseItem.price - this.props.objPurchaseItem.discountAmountPerItem);

                this.props.objPurchaseItem.tax = scanRUtils.countTax(this.props.objPurchaseItem.priceAfterDiscount, this.props.objPurchaseItem.taxes, this.props.objPurchaseItem.qty);
                this.props.objPurchaseItem.amount = scanRUtils.toFixedFloat((this.props.objPurchaseItem.priceAfterDiscount * this.props.objPurchaseItem.qty) + this.props.objPurchaseItem.tax);
                this.props.objPurchaseItem.totalDiscountPerItem = scanRUtils.toFixedFloat(this.props.objPurchaseItem.discountAmountPerItem * this.props.objPurchaseItem.qty)
                this.props.objPurchaseItem.totalTaxableAmount = scanRUtils.toFixedFloat(this.props.objPurchaseItem.priceAfterDiscount * this.props.objPurchaseItem.qty)
            }
        }
        // console.log(this.props.objPurchaseItem, "Obj Item")

        return (
            <React.Fragment>
                <Grid container style={{ marginTop: 10 }} spacing={1}>
                    <Grid item xs={4}>
                        <Grid container>
                            <Grid item xs={3} style={{ textAlign: 'center' }}>
                                <Tooltip title="Delete Item" >
                                    <IconButton onClick={this.props.removeItem} >
                                        <IconDeleteFill color={"error"} />
                                    </IconButton>
                                </Tooltip>
                                {this.props.objPurchaseItem?.objItem?.maintainSerialWiseInventory &&
                                    <Tooltip title="Bulk Entry" >
                                        <IconButton onClick={this.bunchEntryModal.toggle}>
                                            <IconBulkEntry color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Grid>
                            <Grid item xs={9}>
                                <ItemAutoComplete
                                    itemId={this.props.objPurchaseItem.objItem?.id}
                                    label="Search Item"
                                    onChange={(objItem: IItem) => {
                                        if (objItem) {
                                            //console.log(objItem, objItem.defaultTaxes, objItem.defaultPurchasePrice)
                                            if (objItem.isTaxInclusive)
                                                this.setPurchaseItem({ objItem: objItem, taxes: objItem.defaultTaxes, amount: objItem.defaultPurchasePrice })
                                            else
                                                this.setPurchaseItem({ objItem: objItem, taxes: objItem.defaultTaxes, price: objItem.defaultPurchasePrice })
                                        }
                                    }}
                                    onFocus={() => { this.props.focusedItemRef.current = this; }}
                                    frm={this.props.frm}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'center' }}>
                        {this.props.objPurchaseItem?.objItem?.maintainSerialWiseInventory ?
                            <React.Fragment>
                                <HyperTextField
                                    required
                                    value={this.props.objPurchaseItem.lstSerialNo ? this.props.objPurchaseItem.lstSerialNo.join(",") : ""}
                                    label="Serial No"
                                    disabled={true}
                                    onFocus={() => { this.props.focusedItemRef.current = this; }}
                                    frm={this.props.frm}
                                />
                            </React.Fragment>
                            :
                            <HyperTextField
                                required
                                value={this.props.objPurchaseItem.qty}
                                label="Qty"
                                onChange={(e: any) => { this.setPurchaseItem({ qty: parseFloat(e.target.value), amount: scanRUtils.toFixedFloat(this.props.objPurchaseItem.objItem.defaultPurchasePrice * parseFloat(e.target.value || "1")) }) }}
                                onFocus={() => { this.props.focusedItemRef.current = this; }}
                                type="number"
                                frm={this.props.frm}
                            />
                        }
                    </Grid>
                    <Grid item xs={2}>
                        <HyperAmountField
                            required
                            value={scanRUtils.toFixedFloat(this.props.objPurchaseItem.price)}
                            onChange={(e: any) => { this.setPurchaseItem({ price: parseFloat(e.target.value) }) }}
                            type="number"
                            label="Price"
                            frm={this.props.frm}
                            disabled={this.props.objPurchaseItem?.objItem?.isTaxInclusive ? true : false}

                            infoText={`${scanRUtils.prettyPrice(this.props.objPurchaseItem.price)} - ${scanRUtils.prettyPrice(this.props.objPurchaseItem.discountAmountPerItem)} = ${scanRUtils.prettyPrice(this.props.objPurchaseItem.priceAfterDiscount)} (${this.props.objDiscount.discountPercentage}% Discount Applied).`}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <HyperAmountField
                            value={scanRUtils.toFixedFloat(this.props.objPurchaseItem.tax)}
                            type="number"
                            label="Tax"
                            frm={this.props.frm}
                            disabled={true}
                            infoText={`${scanRUtils.countTotalTaxPercentage(this.props.objPurchaseItem.taxes)} Tax Applied.`}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <HyperAmountField
                            value={scanRUtils.toFixedFloat(this.props.objPurchaseItem.amount)}
                            label="Total"
                            type="number"
                            // readOnly={this.props.objPurchaseItem?.objItem?.isTaxInclusive ? false : true}
                            frm={this.props.frm}
                            onChange={(e: any) => { this.setPurchaseItem({ amount: parseFloat(e.target.value) }) }}
                            disabled={this.props.objPurchaseItem?.objItem?.isTaxInclusive ? false : true}
                        />
                    </Grid>

                </Grid>
                {(this.bunchEntryModal.isOpen()) &&
                    <BunchEntryModal
                        isOpen={this.bunchEntryModal.isOpen()}
                        toggleModal={this.bunchEntryModal.toggle}
                        onFinishBunchEntry={this.onFinishBunchEntry}
                        cat={enums.ACCOUNT_CATEGORY.PURCHASE}
                        lstSerialNo={this.props.objPurchaseItem.lstSerialNo || []}
                    />
                }
            </React.Fragment>
        );
    }
}

export default withWindowState(PurchaseItem, ["global"]);