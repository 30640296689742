import React from 'react'
import { Typography, Box, Grid } from '@material-ui/core';
import { HyperTheme } from '../../Theme/Theme';
import { IAccount } from '../../Types/AllTypes';
import scanRUtils from '../../Services/ScanRUtilService';

interface SalesReturnCustomerInfoProps {
    customerInfo: IAccount
}

interface SalesReturnCustomerInfoState { }

class SalesReturnCustomerInfo extends React.Component<SalesReturnCustomerInfoProps, SalesReturnCustomerInfoState> {
    constructor(props: SalesReturnCustomerInfoProps) {
        super(props);
        let state: SalesReturnCustomerInfoState = {};
        this.state = state;
    }

    render() {

        return (
            <Box style={{ backgroundColor: HyperTheme.palette.grey[200] }} borderRadius={7} boxShadow={2} padding={2}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant={"subtitle1"} align="center" color="primary"><b>Customer Info</b></Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2">
                            GSTIN : <b>{this.props.customerInfo.info.GST || "N/A"} </b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2">
                            PAN : <b>{this.props.customerInfo.info.GST || "N/A"} </b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2">
                            Mobile : <b>{this.props.customerInfo.info.mobile || "N/A"} </b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2">
                            Email : <b>{this.props.customerInfo.info.email || "N/A"} </b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="subtitle2">
                            Address : <b>{scanRUtils.makeAddressFromObj(this.props.customerInfo.info)}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="subtitle2">
                            State : <b>{this.props.customerInfo.info.state || "N/A"}</b>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}
export default SalesReturnCustomerInfo;