import React from "react";
import { Route, BrowserRouter, Router, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import Main from "./Main/Main";
import "./Css/scrollbar.css";
import "./Css/style.css";
import { HyperTheme } from "./Theme/Theme";
import history from "./Main/history";
import PublicInvoice from "./Pages/Invoice/PublicInvoice";
import ErrorBoundary from "./Components/ErrorBoundary";
import SnackbarStack from "./Components/SnackbarStack";


export const navigatorRef = React.createRef<BrowserRouter>();

class App extends React.Component {
  state = { incrementVal: 1 }

  render() {
    return (
      <ErrorBoundary>
        <ThemeProvider theme={HyperTheme} key={this.state.incrementVal} >
          <Router history={history}>
            <Switch>
              <Route exact path={`/invoice/:id`} component={PublicInvoice} />
              <Route path="/" component={Main} />
            </Switch>
          </Router>
          <SnackbarStack />
        </ThemeProvider>
      </ErrorBoundary>
    );
  }
}

export default App;
