import React, { Component } from "react";
import TopBar from "./TopBar/TopBar";
import {
  Route,
  withRouter,
  RouteComponentProps,
  Redirect,
} from "react-router-dom";
import auth from "../Services/AuthService";
import utils from "../Services/Utils";
import Loading from "../Components/Loading";
import { LoginLoadable } from "../Components/LoadableComponents";
import ScrollToTopButton from "../Components/ScrollToTopButton";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Accounts from "../Pages/Accounts/Accounts";
import Menu from "../Pages/Menu/Menu";
import InvoiceDownload from "../Pages/Invoice/InvoiceDownload";
import Sales from "../Pages/Sales/Sales";
import Journals from "../Pages/Journal/Journals";
import Items from "../Pages/Item/Items";
import Taxes from "../Pages/Tax/Taxes";
import Customers from "../Pages/Customers/Customers";
import BalanceSheet from "../Pages/Reports/BalanceSheet";
import ProfitAndLoss from "../Pages/Reports/ProfitAndLoss";
import SideBar from "./SideBar";
import { withTheme, Theme } from "@material-ui/core";
import enums from "../enums";
// import { navigatorRef } from '../App';
// import Settings from '../Pages/Settings/Settings';
import Expanses from "../Pages/Expanses/Expanses";
import Incomes from "../Pages/Incomes/Incomes";
import Payments from "../Pages/Payments/Payments";
import Receipts from "../Pages/Receipts/Receipts";
import Suppliers from "../Pages/Suppliers/Suppliers";
import Purchase from "../Pages/Purchases/Purchases";
import { reInitApp } from "..";
import history from "./history";
import { winState } from "../Services/ScanRUtilService";
import withWindowState from "../Components/withWindowState";
import ShortCutsContainer from "../Components/ShortCutsContianer";
import { IWindowState } from "../Types/AllTypes";
import socketService from "../Services/SocketService";
import Reports from "../Pages/Reports/Reports";
import StockSummary from "../Pages/Reports/StockSummary/StockSummary";
import Contra from "../Pages/Contra/Contra";
import ItemStockBook from "../Pages/Reports/ItemStockBook/ItemStockBook";
import SalesReport from "../Pages/Reports/SalesReport/SalesReport";
import PurchaseReport from "../Pages/Reports/PurchaseReport/PurchaseReport";
import PaymentReport from "../Pages/Reports/PaymentReport/PaymentReport";
import ReceiptReport from "../Pages/Reports/ReceiptReport/ReceiptReport";
import IncomeReport from "../Pages/Reports/IncomeReport/IncomeReport";
import ExpanseReport from "../Pages/Reports/ExpanseReport/ExpanseReport";
import SupplierWisePurchase from "../Pages/Reports/SupplierWisePurchase/SupplierWisePurchase";
import CustomerWiseSales from "../Pages/Reports/CustomerWiseSales/CustomerWiseSales";
import SalesByItem from "../Pages/Reports/SalesByItem/SalesByItem";
import PurchaseByItem from "../Pages/Reports/PurchaseByItem/PurchaseByItem";
import DateWiseSales from "../Pages/Reports/DateWiseSales/DateWiseSales";
import AccountBook from "../Pages/Reports/AccountBook/AccountBook";
import DateWisePurchase from "../Pages/Reports/DateWisePurchase/DateWisePurchase";
import BillPayable from "../Pages/Reports/BillPayable/BillPayable";
import BillReceivable from "../Pages/Reports/BillReceivable/BillReceivable";
import BankTransfer from "../Pages/Reports/BankTransfer/BankTransfer";
import Category from "../Pages/Item/Category/Category";
import SalesByCategory from "../Pages/Reports/SalesByCategory/SalesByCategory";
import PurchaseByCategory from "../Pages/Reports/PurchaseByCategory/PurchaseByCategory";
import CategoryWisePL from "../Pages/Reports/CategoryWisePL/CategoryWisePL";
// import GSTReport from '../Pages/Reports/GSTReport/GSTR1Report';
import GSTR1Report from "../Pages/Reports/GSTReport/GSTR1Report";
import SalesReturn from "../Pages/SalesReturn/SalesReturn";
import PurchaseReturn from "../Pages/PurchasesReturn/PurchaseReturn";
import lsu from "../Services/LocalStorageUtils";
import CompanyProfile from "../Pages/Settings/CompanyProfile";
// import Marquee from '../Components/Marquee';
import BankAccounts from "../Pages/BankAccounts/BankAccounts";
import ForgotPassword from "../Pages/Login/ForgotPassword";
import GSTR3 from "../Pages/Reports/GSTR3/GSTR3";
import CategoryWiseStock from "../Pages/Reports/CategoryWiseStock/CategoryWiseStock";
import CompanySelection from "../Pages/CompanySelection/CompanySelection";
const win = window as any;

interface MainProps extends RouteComponentProps, IWindowState {
  theme: Theme;
}

interface MainState {
  refresh: boolean;
  bottomNavigationCount: any;
  essentialsLoaded: boolean;
}
class Main extends Component<MainProps, MainState> {
  constructor(props: MainProps) {
    super(props);
    this.state = {
      refresh: false,
      bottomNavigationCount: undefined,
      essentialsLoaded: false,
    };
    // this.isMenuOpen = new HyperModal(this, lsu.lsGet("isMenuOpen"));
    // this.isMenuOpen.onToggle = (isOpen: boolean) => {
    //     console.log(isOpen)
    //     lsu.lsSet("isMenuOpen", isOpen);
    // }
    // console.log(winState.state)
  }

  componentDidMount() {
    // console.log(this.props, "Main")
    if (!auth.isUserLogged() && window.location.pathname !== "/login")
      this.props.history.push("/login");
    // console.log(navigatorRef)
    document.addEventListener(enums.EVENT.LOGOUT, this.onLogout);

    // document.body.onclick = () => { utils.fireEvent(enums.EVENT.USER_INTERACTION) }
    win.__FirstPageLoadDateTime = new Date();
    if (auth.isUserLogged()) {
      this.loadEssential();
    }

    socketService.init();
  }

  componentWillUnmount() {
    // document.removeEventListener(enums.EVENT.USER_INTERACTION, this.onUserInteraction);
  }

  componentDidUpdate() {
    if (!this.state.essentialsLoaded && auth.isUserLogged()) {
      // this.loadEssential()
      // this.setState({ essentialsLoaded: true })
    }
  }

  loadEssential = () => {
    if (this.state.essentialsLoaded) return;
    if (auth.isValidSelectedCompanyId())
      this.setState({ essentialsLoaded: true });
    else this.props.history.push("/companySelection");
  };

  onLogout = () => {
    localStorage.clear();
    win.user = undefined;
    reInitApp();
    history.replace("/dashboard");
  };

  isShowTopBar = () => {
    return auth.isUserLogged();
  };

  isShowBottomBar = () => {
    return auth.isUserLogged();
  };
  toggleSideMenu = () => {
    let currentToggleState = winState.state.global.isMenuOpen;
    winState.setGlobal({ isMenuOpen: !currentToggleState });
    lsu.lsSet("isMenuOpen", !currentToggleState);
  };

  render() {
    if (!this.state.essentialsLoaded) {
      return (
        <React.Fragment>
          <Loading />
          <Route path={`/login`} component={LoginLoadable} />
          <Route path={`/forgotPassword`} component={ForgotPassword} />
          <Route
            exact={true}
            path={`/companySelection`}
            component={CompanySelection}
          />
        </React.Fragment>
      );
    }
    let isMenuOpen = winState.state.global.isMenuOpen;
    return (
      <React.Fragment>
        <div>
          <Loading />
          {this.isShowTopBar() && <TopBar onMenuChange={this.toggleSideMenu} />}
          <div style={{ width: "100%" }}>
            <div>
              <SideBar isOpenDrawer={isMenuOpen} />
            </div>
            <ShortCutsContainer />
            <main
              style={{
                paddingBottom: 15,
                paddingTop: 15,
                paddingLeft: utils.isDesktop() ? (isMenuOpen ? 240 : 52) : 0,
                // backgroundColor: theme.palette.secondary.light
              }}
            >
              {auth.isUserLogged() && (
                <React.Fragment>
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/dashboard" />}
                  />
                  <Route
                    exact={true}
                    path={`/companySelection`}
                    component={CompanySelection}
                  />
                  <Route
                    exact={true}
                    path={`/dashboard`}
                    component={Dashboard}
                  />
                  <Route exact={true} path={`/accounts`} component={Accounts} />
                  <Route
                    exact={true}
                    path={`/customers`}
                    component={Customers}
                  />
                  <Route
                    exact={true}
                    path={`/suppliers`}
                    component={Suppliers}
                  />
                  <Route exact={true} path={`/journal`} component={Journals} />
                  <Route exact={true} path={`/expanses`} component={Expanses} />
                  <Route exact={true} path={`/incomes`} component={Incomes} />
                  <Route exact={true} path={`/payments`} component={Payments} />
                  <Route exact={true} path={`/receipts`} component={Receipts} />
                  <Route exact={true} path={`/sales`} component={Sales} />
                  <Route
                    exact={true}
                    path={`/sReturn`}
                    component={SalesReturn}
                  />
                  <Route
                    exact={true}
                    path={`/purchases`}
                    component={Purchase}
                  />
                  <Route
                    exact={true}
                    path={`/pReturn`}
                    component={PurchaseReturn}
                  />
                  <Route exact={true} path={`/items`} component={Items} />
                  <Route exact={true} path={`/category`} component={Category} />
                  <Route exact={true} path={`/taxes`} component={Taxes} />
                  <Route exact={true} path={`/menu`} component={Menu} />
                  <Route
                    exact={true}
                    path={`/InvoiceDownload`}
                    component={InvoiceDownload}
                  />
                  <Route exact={true} path={`/bs`} component={BalanceSheet} />
                  <Route exact={true} path={`/pnl`} component={ProfitAndLoss} />
                  <Route
                    exact={true}
                    path={`/settings`}
                    component={CompanyProfile}
                  />
                  <Route exact={true} path={`/reports`} component={Reports} />
                  <Route exact={true} path={`/contra`} component={Contra} />
                  <Route
                    exact={true}
                    path={`/bankAccounts`}
                    component={BankAccounts}
                  />

                  <Route
                    exact={true}
                    path={`/reports/stockSummary`}
                    component={StockSummary}
                  />
                  <Route
                    exact={true}
                    path={`/reports/catWiseStock`}
                    component={CategoryWiseStock}
                  />
                  <Route
                    exact={true}
                    path={`/reports/gstR1Report`}
                    component={GSTR1Report}
                  />
                  <Route
                    exact={true}
                    path={`/reports/gstr3Report`}
                    component={GSTR3}
                  />
                  <Route
                    exact={true}
                    path={`/reports/stockBook`}
                    component={ItemStockBook}
                  />
                  <Route
                    exact={true}
                    path={`/reports/sReport`}
                    component={SalesReport}
                  />
                  <Route
                    exact={true}
                    path={`/reports/pReport`}
                    component={PurchaseReport}
                  />
                  <Route
                    exact={true}
                    path={`/reports/paymentReport`}
                    component={PaymentReport}
                  />
                  <Route
                    exact={true}
                    path={`/reports/receiptReport`}
                    component={ReceiptReport}
                  />
                  <Route
                    exact={true}
                    path={`/reports/incomeReport`}
                    component={IncomeReport}
                  />
                  <Route
                    exact={true}
                    path={`/reports/expanseReport`}
                    component={ExpanseReport}
                  />
                  <Route
                    exact={true}
                    path={`/reports/supplierWisePurchase`}
                    component={SupplierWisePurchase}
                  />
                  <Route
                    exact={true}
                    path={`/reports/customerWiseSales`}
                    component={CustomerWiseSales}
                  />
                  <Route
                    exact={true}
                    path={`/reports/salesByItem`}
                    component={SalesByItem}
                  />
                  <Route
                    exact={true}
                    path={`/reports/sByCategory`}
                    component={SalesByCategory}
                  />
                  <Route
                    exact={true}
                    path={`/reports/pByCategory`}
                    component={PurchaseByCategory}
                  />
                  <Route
                    exact={true}
                    path={`/reports/catWisePL`}
                    component={CategoryWisePL}
                  />
                  <Route
                    exact={true}
                    path={`/reports/purchaseByItem`}
                    component={PurchaseByItem}
                  />
                  <Route
                    exact={true}
                    path={`/reports/dateWiseSales`}
                    component={DateWiseSales}
                  />
                  <Route
                    exact={true}
                    path={`/reports/accountBook`}
                    component={AccountBook}
                  />
                  <Route
                    exact={true}
                    path={`/reports/dateWisePurchase`}
                    component={DateWisePurchase}
                  />
                  <Route
                    exact={true}
                    path={`/reports/billsPayable`}
                    component={BillPayable}
                  />
                  <Route
                    exact={true}
                    path={`/reports/billsReceivable`}
                    component={BillReceivable}
                  />
                  <Route
                    exact={true}
                    path={`/reports/bankTransfer`}
                    component={BankTransfer}
                  />
                </React.Fragment>
              )}
            </main>
            {/* <Marquee text={""} /> */}
          </div>
          <Route exact={true} path={`/login`} component={LoginLoadable} />
          <Route
            exact={true}
            path={`/forgotPassword`}
            component={ForgotPassword}
          />

          {/* {this.isShowBottomBar() &&
                        <BottomNav
                            bottomNavigationCount={this.state.bottomNavigationCount}
                        />
                    } */}
          <ScrollToTopButton />
        </div>
      </React.Fragment>
    );
  }
}

export default withWindowState(withRouter(withTheme(Main)), [
  "global",
  "user",
  "companySettings",
]);
