import React, { FormEvent } from 'react'
import CommonModal from '../../Components/CommonModal';
import { Grid, Button } from '@material-ui/core';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import { IItem, ICategory, IWindowState } from '../../Types/AllTypes';
import HyperForm from '../../Components/HyperInput/HyperForm';
import api from '../../Services/ApiServices';
import utils from '../../Services/Utils';
import { ItemInsertObject } from '../../Types/InsertObjects';
import HyperAmountField from '../../Components/HyperInput/HyperAmountField';
import TaxAutoComplete from '../Tax/TaxAutoComplete';
import ItemUnitAutoComplete from './ItemUnitAutoComplete';
import CategoryAutoComplete from './Category/CategoryAutoComplete';
import HyperSwitchField from '../../Components/HyperInput/HyperSwitchField';
import HyperSelectField from '../../Components/HyperInput/HyperSelectField';
import enums from '../../enums';
import withWindowState from '../../Components/withWindowState';

interface AddUpdateItemProps extends IWindowState {
    isOpen: boolean
    objItem?: IItem
    toggle: () => void
    onComplete?: (data?: IItem) => void
}

interface AddUpdateItemState {
    objItem: IItem
}

const initialItem: IItem = {
    name: "",
    categoryId: 0,
    subCategoryId: -1,
    defaultPurchasePrice: 0,
    defaultSalesPrice: 0,
    defaultDescription: "",
    hsnCode: "",
    sacCode: "",
    productCode: "",
    itemType: enums.ITEM_TYPE.PRODUCT,
    isTaxInclusive: false,
    isCESSApplied: false,
    defaultTaxes: [],
    unit: "",
    info: {}
} as IItem;

class AddUpdateItem extends React.Component<AddUpdateItemProps, AddUpdateItemState> {
    constructor(props: AddUpdateItemProps) {
        super(props);
        let state: AddUpdateItemState = {
            objItem: initialItem
        };

        if (this.props.objItem) {
            state.objItem = this.props.objItem
            this.isEditing = true;
        }
        else {
            state.objItem.defaultTaxes = this.props.companySettings.defaultTaxes;
            state.objItem.unit = this.props.companySettings.defaultItemUnit;
        }

        this.state = state;
        this.frm = new HyperForm();
    }

    frm: HyperForm;
    isEditing: boolean = false;

    setItem(obj: any) {
        this.setState({ objItem: { ...this.state.objItem, ...obj } });
    }

    onSubmit = async (e: FormEvent) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.frm.setIsFormSubmitted(true)
            if (this.frm.isValid()) {
                let result = null;
                let io = new ItemInsertObject();
                io.name = this.state.objItem.name;
                io.categoryId = this.state.objItem.categoryId;
                io.subCategoryId = this.state.objItem.subCategoryId;
                io.id = this.state.objItem.id;
                io.unit = this.state.objItem.unit;
                io.defaultPurchasePrice = this.state.objItem.defaultPurchasePrice;
                io.defaultSalesPrice = this.state.objItem.defaultSalesPrice;
                io.maintainSerialWiseInventory = this.state.objItem.maintainSerialWiseInventory;
                io.isTaxInclusive = this.state.objItem.isTaxInclusive;
                io.defaultDescription = this.state.objItem.defaultDescription;
                io.showTaxOnInvoice = this.state.objItem.showTaxOnInvoice;
                io.hsnCode = this.state.objItem.hsnCode;
                io.sacCode = this.state.objItem.sacCode;
                io.productCode = this.state.objItem.productCode;
                io.defaultTaxes = this.state.objItem.defaultTaxes;
                io.isCESSApplied = this.state.objItem.isCESSApplied;
                io.itemType = this.state.objItem.itemType;

                utils.showLoader();
                if (this.isEditing) {
                    result = await api.updateItem(io);
                    utils.snackbarSuccess("Item updated successfully.")
                }
                else {
                    result = await api.addItem(io);
                    utils.snackbarSuccess("New item added successfully.")
                }

                this.props.toggle();
                if (this.props.onComplete) {
                    this.props.onComplete(result);
                }
            }
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }

    render() {
        // console.log(this.state.objItem.defaultTaxes)
        return (
            <div>
                <CommonModal
                    dialogProps={{
                        open: this.props.isOpen
                    }}
                    toggle={this.props.toggle}
                    title={"Item"}
                >
                    <form onSubmit={this.onSubmit}>
                        <Grid container spacing={2}>

                            <Grid item xs={6}>
                                <HyperTextField
                                    required
                                    value={this.state.objItem.name}
                                    onChange={(e: any) => { this.setItem({ name: e.target.value }) }}
                                    label={"Item Name"}
                                    frm={this.frm}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <HyperSelectField
                                    labelWidth={40}
                                    fullWidth
                                    options={[enums.ITEM_TYPE.PRODUCT, enums.ITEM_TYPE.SERVICE]}
                                    selectedValue={this.state.objItem.itemType}
                                    onChange={(e: any) => { this.setItem({ itemType: e.target.value }) }}
                                    label={"Type"}
                                    frm={this.frm}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <HyperAmountField
                                    required
                                    value={this.state.objItem.defaultPurchasePrice}
                                    onChange={(e: any) => { this.setItem({ defaultPurchasePrice: e.target.value }) }}
                                    label={"Purchase Price"}
                                    frm={this.frm}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <HyperAmountField
                                    required
                                    value={this.state.objItem.defaultSalesPrice}
                                    onChange={(e: any) => { this.setItem({ defaultSalesPrice: e.target.value }) }}
                                    label={"Sales Price"}
                                    frm={this.frm}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CategoryAutoComplete
                                    frm={this.frm}
                                    required
                                    categoryId={this.state.objItem.categoryId}
                                    onChange={(data: ICategory) => { this.setItem({ categoryId: data?.id, subCategoryId: -1 }) }}
                                    label={"Select Category"}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CategoryAutoComplete
                                    frm={this.frm}
                                    categoryId={this.state.objItem.subCategoryId}
                                    parentId={this.state.objItem.categoryId}
                                    disabled={this.state.objItem.categoryId > 0 ? false : true}
                                    onChange={(data: ICategory) => { this.setItem({ subCategoryId: data?.id }) }}
                                    label={"Select Sub Category"}
                                />
                            </Grid>
                            {this.state.objItem.itemType === enums.ITEM_TYPE.PRODUCT ?
                                <Grid item xs={6}>
                                    <HyperTextField
                                        value={this.state.objItem.hsnCode}
                                        onChange={(e: any) => { this.setItem({ hsnCode: e.target.value }) }}
                                        label={"HSN Code"}
                                        frm={this.frm}
                                    />
                                </Grid>
                                :
                                <Grid item xs={6}>
                                    <HyperTextField
                                        value={this.state.objItem.sacCode}
                                        onChange={(e: any) => { this.setItem({ sacCode: e.target.value }) }}
                                        label={"SAC Code"}
                                        frm={this.frm}
                                    />
                                </Grid>
                            }
                            <Grid item xs={6}>
                                <HyperTextField
                                    value={this.state.objItem.productCode}
                                    onChange={(e: any) => { this.setItem({ productCode: e.target.value }) }}
                                    label={"Produce Code"}
                                    frm={this.frm}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <ItemUnitAutoComplete
                                    label="Item Unit"
                                    value={this.state.objItem.unit}
                                    onChange={(newValue: any) => { this.setItem({ unit: newValue?.key }) }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TaxAutoComplete
                                    // required
                                    onChange={(lstTaxes: any) => {
                                        this.setItem({ defaultTaxes: lstTaxes ? [lstTaxes] : [] })

                                    }}
                                    selectedItem={this.state.objItem.defaultTaxes ? this.state.objItem.defaultTaxes[0] : undefined}
                                    label={"Tax"}
                                    frm={this.frm}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <HyperTextField
                                    multiline
                                    rows={2}
                                    value={this.state.objItem.defaultDescription}
                                    onChange={(e: any) => { this.setItem({ defaultDescription: e.target.value }) }}
                                    label={"Default Description"}
                                    frm={this.frm}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                {/* <HyperSwitchField
                                    label={"In this Item CESS Applied?"}
                                    checked={this.state.objItem.isCESSApplied}
                                    onChange={(val: boolean) => this.setItem({ isCESSApplied: val })}
                                />
                                <br /> */}
                                <HyperSwitchField
                                    label={"Maintain Serial Wise Inventory?"}
                                    checked={this.state.objItem.maintainSerialWiseInventory}
                                    onChange={(val: boolean) => this.setItem({ maintainSerialWiseInventory: val })}
                                />
                                <br />
                                <HyperSwitchField
                                    label={"Tax Included in this  item?"}
                                    checked={this.state.objItem.isTaxInclusive}
                                    onChange={(val: boolean) => this.setItem({ isTaxInclusive: val })}
                                />
                                <br />
                                <HyperSwitchField
                                    label={"Show Tax On Invoice?"}
                                    checked={this.state.objItem.showTaxOnInvoice}
                                    onChange={(val: boolean) => this.setItem({ showTaxOnInvoice: val })}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Button type="submit" color="primary" variant="contained">
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CommonModal>
            </div>
        );
    }
}

export default withWindowState(AddUpdateItem, ["companySettings"])