import React from 'react'
import { Container, withTheme, Theme, Grid, Paper, Typography, Button } from '@material-ui/core'
import api from '../../../Services/ApiServices';
import { JournalSearchOptions } from '../../../Types/SearchOptions';
import { Journal, ICompanySettings } from '../../../Types/AllTypes';
import moment from "moment";
import HyperDateRangePicker from '../../../Components/HyperDateRangePicker';
import ReportsDataTable from '../../../Components/ReportsDataTable';
import scanRUtils from '../../../Services/ScanRUtilService';
import HyperCompanySelection from '../../../Components/HyperInput/HyperCompanySelection';
import auth from '../../../Services/AuthService';


interface PurchaseByCategoryProps {
    theme: Theme
}

interface PurchaseByCategoryState {
    lstCategoryPurchase: Journal[]
    totalPurchase: number
    isLoading: boolean
    dateRange: any
}


class PurchaseByCategory extends React.Component<PurchaseByCategoryProps, PurchaseByCategoryState> {
    constructor(props: PurchaseByCategoryProps) {
        super(props);
        let state: PurchaseByCategoryState = {
            lstCategoryPurchase: [],
            totalPurchase: 0,
            isLoading: false,
            dateRange: { startDate: moment().startOf("month").toDate(), endDate: moment().endOf("month").toDate(), label: "This Month" }

        };
        this.state = state;
        this.companySelection = new HyperCompanySelection(this);
    }
    companySelection: HyperCompanySelection;

    componentDidMount() {
        this.getReportDetail()
    }
    getReportDetail = async () => {
        try {
            await this.setState({ isLoading: true })
            let so = new JournalSearchOptions();

            so.fromDate = moment(this.state.dateRange.startDate).format("YYYY-MM-DD")
            so.toDate = moment(this.state.dateRange.endDate).format("YYYY-MM-DD")
            so.companyIds = this.companySelection.getSelectedCompany().map((item: ICompanySettings) => item.id).join(",")
            let lstCategoryPurchase = await api.getCategoryWisePurchase(so);
            // console.log(lstCategoryPurchase, "lstCategoryPurchase")
            let totalAmount = 0;
            lstCategoryPurchase.forEach((item: any) => {
                // console.log(item)
                totalAmount = totalAmount + (item.purchase?.totalAmount || 0);
                item.companyName = auth.getCompanyNameById(item.companyId);
                if (item.purchase && item.purchase.totalAmount)
                    item.purchase.totalAmount = scanRUtils.prettyPrice(item.purchase?.totalAmount);
            })
            this.setState({ lstCategoryPurchase, totalPurchase: totalAmount })
        }
        catch (ex) {
            console.log(ex)
        }
        finally {
            await this.setState({ isLoading: false })
        }
    }

    render() {
        // console.log(this.state.lstCategoryPurchase)
        return (
            <Container>
                <Grid container spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item md={6}>
                        {this.companySelection.renderCompanySelection()}
                    </Grid>
                    <Grid item md={6} style={{ textAlign: 'right', margin: 'auto' }}>
                        <Button onClick={this.getReportDetail} variant="contained" color="primary">Refresh</Button>{" "}
                        <HyperDateRangePicker
                            initialDateRange={this.state.dateRange}
                            onChange={async (range: any) => {
                                await this.setState({ dateRange: range });
                                this.getReportDetail()
                            }}
                        />
                    </Grid>
                </Grid>

                <ReportsDataTable
                    key={Date.now()}
                    title={`Purchase By Category`}
                    columns={[
                        { title: "Category Id", field: "id" },
                        { title: "Category Name", field: "name" },
                        { title: "Total Qty", field: "purchase.totalQty", emptyValue: () => 0 },
                        { title: "Total Purchase", field: "purchase.totalAmount", emptyValue: () => 0 },
                        { title: "Company Name",hidden:auth.isSingleCompany(), field: 'companyName' },
                    ]}
                    data={this.state.lstCategoryPurchase}
                    options={{
                        sorting: true,

                    }}
                    isLoading={this.state.isLoading}
                    onRowClick={() => { }}
                />
                <Paper elevation={3} style={{ marginTop: 20, paddingTop: 30, paddingBottom: 30, padding: 20 }}>
                    <Grid container>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6">Total Purchase By Category</Typography>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography variant="h6" color="primary">{scanRUtils.prettyPrice(this.state.totalPurchase)}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        );
    }
}

export default withTheme(PurchaseByCategory);