export class JournalInsertObject {
    id?: number
    debitAccountId?: number
    creditAccountId?: number
    amount: number = 0
    comment: string = ""
    date: string = ""
    transactionDate: string = ""
    isDifferentTransactionDate: boolean = false
    voucherImage: string = ""
    info?: any
    category: string = "";
}

export class ItemInsertObject {
    id?: number
    name: string = ""
    category: string = ""
    unit: string = "";
    defaultPurchasePrice: number = 0
    defaultSalesPrice: number = 0
    categoryId: number = 0
    subCategoryId: number = 0
    isTaxInclusive: boolean = false
    defaultTaxes: any = {}
    imagePath: string = ""
    info: any
    maintainSerialWiseInventory: boolean = false;
    isCESSApplied: boolean = false;
    defaultDescription: string = ""
    showTaxOnInvoice: boolean = true
    productCode: string = "";
    hsnCode: string = "";
    sacCode: string = "";
    itemType: string = "";
}
export class CategoryInsertObject {
    id?: number
    name: string = ""
    info: any
    parentId?: number
}

export class TaxInsertObject {
    id?: number
    name: string = "";
    percentage: number = 0;
    key: string = ""
}

export class AccountInsertObject {
    id?: number
    name: string = ""
    type: string = ""
    category: string = ""
    info: any = {}
}
