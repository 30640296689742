import React from 'react'
import { Container, withTheme, Theme, Grid, Button } from '@material-ui/core'
import HyperModal from '../../../Components/HyperInput/HyperModal';
import api from '../../../Services/ApiServices';
import { JournalSearchOptions } from '../../../Types/SearchOptions';
import { Journal } from '../../../Types/AllTypes';
import moment from "moment";
import HyperDateRangePicker from '../../../Components/HyperDateRangePicker';
import scanRUtils from '../../../Services/ScanRUtilService';
import CSVServices from '../../../Services/CSVServices';

interface SalesByItemProps {
    theme: Theme
}

interface SalesByItemState {
    objR3B: Journal[]
    isLoading: boolean
    dateRange: any
    salesPurchaseData: any
}

const eligibleITC = {
    header: "4 Eligible ITC",
    data: {
        itcAvailable: {
            details: "(A) ITC available (whether in full or part)",
            subData: {
                importOfGoods: { details: "(1) Import of goods", integratedTax: 0, centralTax: "-", stateUTTax: "-" },
                importOfService: { details: "(2) Import of services", integratedTax: 0, centralTax: "-", stateUTTax: "-" },
                inwardSuppliesLiableToRC: { details: "(3) Inward supplies liable to reverse charge (other than 1 & 2 above)", integratedTax: 0, centralTax: 0, stateUTTax: 0 },
                inwardSuppliesFromISD: { details: "(4) Inward supplies from ISD", integratedTax: 0, centralTax: 0, stateUTTax: 0 },
                allOtherITC: { details: "(5) All other ITC", integratedTax: 0, centralTax: 0, stateUTTax: 0 },
            },
            integratedTax: "", centralTax: "", stateUTTax: ""
        },
        itcReversed: {
            details: "(B) ITC Reversed", subData: {
                asPerRule42And43: { details: "(1) As per Rule 42 & 43 of SGST/CGST rules", integratedTax: 0, centralTax: 0, stateUTTax: 0 },
                others: { details: "(2) Others", integratedTax: 0, centralTax: 0, stateUTTax: 0 },
            }, integratedTax: "", centralTax: "", stateUTTax: ""
        },
        netItcAvailable: {
            details: "(C) Net ITC available (A) - (B)", subData: {}, integratedTax: 0, centralTax: 0, stateUTTax: 0
        },
        ineligibleItc: {
            details: " (D) Ineligible ITC", subData: {
                asPerSection17And5: { details: "(1) As per section 17(5) of CGST//SGST Act", integratedTax: 0, centralTax: 0, stateUTTax: 0 },
                others: { details: "(2) Others", integratedTax: 0, centralTax: 0, stateUTTax: 0 },
            }, integratedTax: "", centralTax: "", stateUTTax: ""
        },
    }
}
const nonGstNilRatedExemptedIS = {
    header: "5 Value of exempt, nill-rated, and non-GST inward supplies",
    data: {
        exemptedNilRatedIS: {
            natureOfSupplies: "From a supplier under composition scheme, Exempt and Nil rated supply",
            interStateSupplies: 0, intraStateSupplies: 0
        },
        nonGSTSupplies: {
            natureOfSupplies: "Non GST Supply",
            interStateSupplies: 0, intraStateSupplies: 0
        }
    }
}
const salesPurchaseData = {
    header: "3.1 Details of your sales and purchases which are liable for reverse charge",
    data: {
        outwardTaxableSuppliesWithGST: {
            natureOfSupplies: "(a) Outward taxable supplies (other than zero rated, nil rated and exempted)",
            totalTaxableAmount: 0, integratedTax: 0, centralTax: 0, stateUTTax: 0,
            isIntegratedTaxApplicable: true, isCentralTaxApplicable: true, isStateUTTaxApplicable: true,
        },
        outwardTaxableSuppliesZeroRated: {
            natureOfSupplies: "(b) Outward taxable supplies (zero rated)",
            totalTaxableAmount: 0, integratedTax: 0, centralTax: 0, stateUTTax: 0,
            isIntegratedTaxApplicable: true, isCentralTaxApplicable: false, isStateUTTaxApplicable: false,
        },
        otherOutwardSupplies: {
            natureOfSupplies: "(c) Other outward supplies (Nil rated, exempted)",
            totalTaxableAmount: 0, integratedTax: 0, centralTax: 0, stateUTTax: 0,
            isIntegratedTaxApplicable: false, isCentralTaxApplicable: false, isStateUTTaxApplicable: false,
        },
        inwardSuppliesWithReverseCharge: {
            natureOfSupplies: "(d) Inward supplies (liable to reverse charge)",
            totalTaxableAmount: 0, integratedTax: 0, centralTax: 0, stateUTTax: 0,
            isIntegratedTaxApplicable: true, isCentralTaxApplicable: true, isStateUTTaxApplicable: true,
        },
        nonGSTOutwardSupplies: {
            natureOfSupplies: "(e) Non GST outward supplies",
            totalTaxableAmount: 0, integratedTax: 0, centralTax: 0, stateUTTax: 0,
            isIntegratedTaxApplicable: false, isCentralTaxApplicable: false, isStateUTTaxApplicable: false,
        },
    }
}
const unregisteredInterState = {
    header: "3.2 Details of inter-state sales made to unregistered buyers, buyers registered under the composition scheme, and UIN holders",
    data: [
        {
            placeOfSupply: "",
            "suppliesMadeToUnregisteredPersons": { totalTaxableAmount: 0, amountOfIntegratedTax: 0 },
            "suppliesMadeToCompositionTaxablePersons": { totalTaxableAmount: 0, amountOfIntegratedTax: 0 },
            "suppliesMadeToUINHolders": { totalTaxableAmount: 0, amountOfIntegratedTax: 0 },
        }
    ]
}
const interestAndLateFee = {
    header: "5.1 Interest & late fee payable",
    data: {
        exemptedNilRatedIS: {
            description: "Interest",
            integratedTax: 0, centralTax: 0, stateUTTax: 0, CESS: 0
        }
    }
}
class SalesByItem extends React.Component<SalesByItemProps, SalesByItemState> {
    constructor(props: SalesByItemProps) {
        super(props);
        let state: SalesByItemState = {
            objR3B: [],
            isLoading: false,
            dateRange: { startDate: moment().startOf("month").toDate(), endDate: moment().endOf("month").toDate(), label: "This Month" },
            salesPurchaseData: salesPurchaseData

        };
        this.state = state;
        this.modal = new HyperModal(this);
    }
    modal: HyperModal;

    componentDidMount() {
        this.getReportDetail();
    }
    getReportDetail = async () => {
        try {
            await this.setState({ isLoading: true })
            let so = new JournalSearchOptions();
            so.fromDate = moment(this.state.dateRange.startDate).format("YYYY-MM-DD");
            so.toDate = moment(this.state.dateRange.endDate).format("YYYY-MM-DD");
            let objR3B = await api.getR3B(so);
            // console.log(objR3B, "objR3B")
            eligibleITC.data.itcAvailable.subData.importOfService = { ...eligibleITC.data.itcAvailable.subData.importOfService, ...objR3B.objEligibleITC.itcAvailable.importOfService }
            eligibleITC.data.itcAvailable.subData.importOfGoods = { ...eligibleITC.data.itcAvailable.subData.importOfGoods, ...objR3B.objEligibleITC.itcAvailable.importOfGoods }
            eligibleITC.data.itcAvailable.subData.inwardSuppliesLiableToRC = { ...eligibleITC.data.itcAvailable.subData.inwardSuppliesLiableToRC, ...objR3B.objEligibleITC.itcAvailable.inwardSuppliesLiableToRC }
            eligibleITC.data.itcAvailable.subData.inwardSuppliesFromISD = { ...eligibleITC.data.itcAvailable.subData.inwardSuppliesFromISD, ...objR3B.objEligibleITC.itcAvailable.inwardSuppliesFromISD }
            eligibleITC.data.itcAvailable.subData.allOtherITC = { ...eligibleITC.data.itcAvailable.subData.allOtherITC, ...objR3B.objEligibleITC.itcAvailable.allOtherITC }

            salesPurchaseData.data.outwardTaxableSuppliesWithGST = { ...salesPurchaseData.data.outwardTaxableSuppliesWithGST, ...objR3B.objSAndP.outwardTaxableSuppliesWithGST }
            salesPurchaseData.data.outwardTaxableSuppliesZeroRated = { ...salesPurchaseData.data.outwardTaxableSuppliesZeroRated, ...objR3B.objSAndP.outwardTaxableSuppliesZeroRated }
            salesPurchaseData.data.otherOutwardSupplies = { ...salesPurchaseData.data.otherOutwardSupplies, ...objR3B.objSAndP.otherOutwardSupplies }
            salesPurchaseData.data.inwardSuppliesWithReverseCharge = { ...salesPurchaseData.data.inwardSuppliesWithReverseCharge, ...objR3B.objSAndP.inwardSuppliesWithReverseCharge }
            salesPurchaseData.data.nonGSTOutwardSupplies = { ...salesPurchaseData.data.nonGSTOutwardSupplies, ...objR3B.objSAndP.nonGSTOutwardSupplies }

            nonGstNilRatedExemptedIS.data.exemptedNilRatedIS = { ...nonGstNilRatedExemptedIS.data.exemptedNilRatedIS, ...objR3B.nonGstNilRatedExemptedIS.exemptedNilRatedIS }
            nonGstNilRatedExemptedIS.data.nonGSTSupplies = { ...nonGstNilRatedExemptedIS.data.nonGSTSupplies, ...objR3B.nonGstNilRatedExemptedIS.nonGSTSupplies }
            this.setState({ objR3B })
        }
        catch (ex) {
            console.log(ex)
        }
        finally {
            await this.setState({ isLoading: false })
        }
    }
    downloadCSV = () => {
        // let data = [
        //     ["3.1 Details of outward supplies and inward supplies liable to reverse charge"],
        //     ["Nature of Supplies", "Total taxable", "Integrated tax", "Central tax", "State/UT tax", "CESS"],
        //     [`(a) Outward taxable supplies (other than zero rated - nil rated and exempted)`, scanRUtils.prettyPrice(objR3B.objSAndP.outwardTaxableSuppliesWithGST.totalTaxableAmount), scanRUtils.prettyPrice(objR3B.objSAndP.outwardTaxableSuppliesWithGST.integratedTax), scanRUtils.prettyPrice(objR3B.objSAndP.outwardTaxableSuppliesWithGST.centralTax), scanRUtils.prettyPrice(objR3B.objSAndP.outwardTaxableSuppliesWithGST.stateUTTax), scanRUtils.prettyPrice(objR3B.objSAndP.outwardTaxableSuppliesWithGST.cess)],
        //     [`(b) Outward taxable supplies (zero rated)`, scanRUtils.prettyPrice(objR3B.objSAndP.outwardTaxableSuppliesZeroRated.totalTaxableAmount), scanRUtils.prettyPrice(objR3B.objSAndP.outwardTaxableSuppliesZeroRated.integratedTax), scanRUtils.prettyPrice(objR3B.objSAndP.outwardTaxableSuppliesZeroRated.centralTax), scanRUtils.prettyPrice(objR3B.objSAndP.outwardTaxableSuppliesZeroRated.stateUTTax), scanRUtils.prettyPrice(objR3B.objSAndP.outwardTaxableSuppliesZeroRated.cess)],
        //     [`(c) Other outward supplies (Nil rated-exempted)`, scanRUtils.prettyPrice(objR3B.objSAndP.otherOutwardSupplies.totalTaxableAmount), scanRUtils.prettyPrice(objR3B.objSAndP.otherOutwardSupplies.integratedTax), scanRUtils.prettyPrice(objR3B.objSAndP.otherOutwardSupplies.centralTax), scanRUtils.prettyPrice(objR3B.objSAndP.otherOutwardSupplies.stateUTTax), scanRUtils.prettyPrice(objR3B.objSAndP.otherOutwardSupplies.cess)],
        //     [`(d) Inward supplies (liable to reverse charge)`, scanRUtils.prettyPrice(objR3B.objSAndP.inwardSuppliesWithReverseCharge.totalTaxableAmount), scanRUtils.prettyPrice(objR3B.objSAndP.inwardSuppliesWithReverseCharge.integratedTax), scanRUtils.prettyPrice(objR3B.objSAndP.inwardSuppliesWithReverseCharge.centralTax), scanRUtils.prettyPrice(objR3B.objSAndP.inwardSuppliesWithReverseCharge.stateUTTax), scanRUtils.prettyPrice(objR3B.objSAndP.inwardSuppliesWithReverseCharge.cess)],
        //     [`(e) Non GST outward supplies`, scanRUtils.prettyPrice(objR3B.objSAndP.nonGSTOutwardSupplies.totalTaxableAmount), scanRUtils.prettyPrice(objR3B.objSAndP.nonGSTOutwardSupplies.integratedTax), scanRUtils.prettyPrice(objR3B.objSAndP.nonGSTOutwardSupplies.centralTax), scanRUtils.prettyPrice(objR3B.objSAndP.nonGSTOutwardSupplies.stateUTTax), scanRUtils.prettyPrice(objR3B.objSAndP.nonGSTOutwardSupplies.cess)],
        //     [],
        //     [],
        //     ["3.2 Details of inter-state sales made to unregistered buyers-buyers registered under the composition scheme- and UIN holders"],
        // ]
        CSVServices.download_csv_file()
    }

    render() {
        return (
            <Container>
                <Grid container style={{ marginBottom: 20 }} spacing={2}>
                    <Grid item md={12} style={{ textAlign: 'right', margin: 'auto' }}>
                        <Button onClick={this.getReportDetail} variant="contained" color="primary">Refresh</Button>{" "}
                        <Button onClick={this.downloadCSV} variant="contained" color="primary">Download CSV</Button>{" "}
                        <HyperDateRangePicker
                            initialDateRange={this.state.dateRange}
                            onChange={async (range: any) => {
                                await this.setState({ dateRange: range });
                                this.getReportDetail()
                            }}
                        />
                    </Grid>
                    <table id="main-table">
                        <tbody>
                            <tr>
                                <td>
                                    <table style={{ paddingTop: 20, paddingBottom: 20, padding: 30, width: '100%', marginTop: 20 }} cellPadding="10" cellSpacing="10">
                                        <thead>
                                            <tr >
                                                <th colSpan={5} style={{ fontSize: 25 }}>{this.state.salesPurchaseData.header}</th>
                                            </tr>
                                            <tr>
                                                <th>Nature of Supplies</th>
                                                <th>Total Taxable</th>
                                                <th>Integrated Tax</th>
                                                <th>Central Tax</th>
                                                <th>State/UT Tax</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(this.state.salesPurchaseData.data).map((key: any, index: number) => {
                                                let obj = this.state.salesPurchaseData.data[key];
                                                return (
                                                    <tr key={index}>
                                                        <td>{obj.natureOfSupplies}</td>
                                                        <td align="right">{scanRUtils.prettyPrice(obj.totalTaxableAmount)}</td>
                                                        <td align="right">{obj.isIntegratedTaxApplicable ? scanRUtils.prettyPrice(obj.integratedTax) : "-"}</td>
                                                        <td align="right">{obj.isCentralTaxApplicable ? scanRUtils.prettyPrice(obj.centralTax) : "-"}</td>
                                                        <td align="right">{obj.isStateUTTaxApplicable ? scanRUtils.prettyPrice(obj.stateUTTax) : "-"}</td>
                                                    </tr>
                                                )
                                            })
                                            }
                                            <tr></tr>
                                            <tr></tr>
                                        </tbody>
                                    </table>



                                    <table style={{ paddingTop: 20, paddingBottom: 20, padding: 30, width: '100%', marginTop: 20 }} {...{ border: 1 }} cellPadding="5" cellSpacing="0">
                                        <thead>
                                            <tr >
                                                <th colSpan={7} style={{ fontSize: 25 }}>{unregisteredInterState.header}</th>
                                            </tr>
                                            <tr >
                                                <th rowSpan={2}>Place Of Supply</th>
                                                <th colSpan={2}>Supplies made to Unregistered Persons</th>
                                                <th colSpan={2}>Supplies made to Composition Taxable Persons</th>
                                                <th colSpan={2}>Supplies made to UIN holders</th>
                                            </tr>
                                            <tr>
                                                <th>Total Taxable Value</th>
                                                <th>Integrated Tax</th>
                                                <th>Total Taxable Value</th>
                                                <th>Integrated Tax</th>
                                                <th>Total Taxable Value</th>
                                                <th>Integrated Tax</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {unregisteredInterState.data.map((item: any, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        <th colSpan={7} align="center">
                                                            -------------------- We are not Calculating --------------------
                                                </th>
                                                    </tr>
                                                )
                                            })}
                                            <tr></tr>
                                            <tr></tr>
                                        </tbody>
                                    </table>

                                    <table style={{ paddingTop: 20, paddingBottom: 20, padding: 30, width: '100%', marginTop: 20 }} cellPadding="10" cellSpacing="10">
                                        <thead>
                                            <tr >
                                                <th colSpan={5} style={{ fontSize: 25 }}>{eligibleITC.header}</th>
                                            </tr>
                                            <tr>
                                                <th>Details</th>
                                                <th>Integrated Tax</th>
                                                <th>Central Tax</th>
                                                <th>State/UT Tax</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(eligibleITC.data).map((key: any, index: number) => {
                                                let item = (eligibleITC.data as any)[key];
                                                if (Object.keys(item.subData).length > 0) {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <td>{item.details}</td>
                                                                <td align="right">{item.integratedTax}</td>
                                                                <td align="right">{item.centralTax}</td>
                                                                <td align="right">{item.stateUTTax}</td>
                                                            </tr>
                                                            {Object.keys(item.subData).map((objSubItemKey: any, subIndex: number) => {
                                                                let objSubItem = (item.subData as any)[objSubItemKey];
                                                                return <tr key={subIndex} style={{ paddingLeft: 15 }}>
                                                                    <td>{objSubItem.details}</td>
                                                                    <td align="right">{objSubItem.integratedTax === "-" ? objSubItem.integratedTax : scanRUtils.prettyPrice(objSubItem.integratedTax)}</td>
                                                                    <td align="right">{objSubItem.centralTax === "-" ? objSubItem.centralTax : scanRUtils.prettyPrice(objSubItem.centralTax)}</td>
                                                                    <td align="right">{objSubItem.stateUTTax === "-" ? objSubItem.stateUTTax : scanRUtils.prettyPrice(objSubItem.stateUTTax)}</td>
                                                                </tr>
                                                            })}
                                                        </React.Fragment>
                                                    )
                                                }
                                                else
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item.details}</td>
                                                            <td align="right">{scanRUtils.prettyPrice(item.integratedTax)}</td>
                                                            <td align="right">{scanRUtils.prettyPrice(item.centralTax)}</td>
                                                            <td align="right">{scanRUtils.prettyPrice(item.stateUTTax)}</td>
                                                        </tr>
                                                    )
                                            })}
                                            <tr></tr>
                                            <tr></tr>
                                        </tbody>
                                    </table>

                                    <table style={{ paddingTop: 20, paddingBottom: 20, padding: 30, width: '100%', marginTop: 20 }} cellPadding="10" cellSpacing="10">
                                        <thead>
                                            <tr >
                                                <th colSpan={5} style={{ fontSize: 25 }}>{nonGstNilRatedExemptedIS.header}</th>
                                            </tr>
                                            <tr>
                                                <th>Nature of supplies</th>
                                                <th>Inter-state supplies</th>
                                                <th>Intra-state supplies</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(nonGstNilRatedExemptedIS.data).map((key: any, index: number) => {
                                                let item = (nonGstNilRatedExemptedIS.data as any)[key];
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.natureOfSupplies}</td>
                                                        <td align="center">{scanRUtils.prettyPrice(item.interStateSupplies)}</td>
                                                        <td align="center">{scanRUtils.prettyPrice(item.intraStateSupplies)}</td>
                                                    </tr>
                                                )
                                            })}
                                            <tr></tr>
                                            <tr></tr>
                                        </tbody>
                                    </table>

                                    <table style={{ paddingTop: 20, paddingBottom: 20, padding: 30, width: '100%', marginTop: 20 }} cellPadding="10" cellSpacing="10">
                                        <thead>
                                            <tr >
                                                <th colSpan={5} style={{ fontSize: 25 }}>{interestAndLateFee.header}</th>
                                            </tr>
                                            <tr>
                                                <th>Description</th>
                                                <th>Integrated Tax</th>
                                                <th>Central Tax</th>
                                                <th>State/UT Tax</th>
                                                <th>Cess</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(interestAndLateFee.data).map((key: any, index: number) => {
                                                let item = (interestAndLateFee.data as any)[key];
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.description}</td>
                                                        <td align="center">{scanRUtils.prettyPrice(item.integratedTax)}</td>
                                                        <td align="center">{scanRUtils.prettyPrice(item.centralTax)}</td>
                                                        <td align="center">{scanRUtils.prettyPrice(item.stateUTTax)}</td>
                                                        <td align="center">{scanRUtils.prettyPrice(item.CESS)}</td>
                                                    </tr>
                                                )
                                            })}
                                            <tr></tr>
                                            <tr></tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>
            </Container>
        );
    }
}

export default withTheme(SalesByItem);