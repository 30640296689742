import React, { FormEvent } from 'react'
import CommonModal from '../../Components/CommonModal';
import { Grid, Button } from '@material-ui/core';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import { ITax } from '../../Types/AllTypes';
import HyperForm from '../../Components/HyperInput/HyperForm';
import api from '../../Services/ApiServices';
import utils from '../../Services/Utils';
import { TaxInsertObject } from '../../Types/InsertObjects';

interface AddUpdateTaxProps {
    isOpen: boolean
    objTax?: ITax
    toggle: () => void
    onComplete?: (e?: any) => void
}

interface AddUpdateTaxState {
    objTax: ITax
}

const initialTax: ITax = {
    id: 0,
    name: "",
    key: "",
    percentage: 0,
} as ITax;

class AddUpdateTax extends React.Component<AddUpdateTaxProps, AddUpdateTaxState> {
    constructor(props: AddUpdateTaxProps) {
        super(props);
        let state: AddUpdateTaxState = {
            objTax: initialTax
        };

        if (this.props.objTax) {
            state.objTax = this.props.objTax
            this.isEditing = true;
        }

        this.state = state;
        this.frm = new HyperForm();

    }

    frm: HyperForm;
    isEditing: boolean = false;

    setTax(obj: any) {
        this.setState({ objTax: { ...this.state.objTax, ...obj } });
    }

    onSubmit = async (e: FormEvent) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.frm.setIsFormSubmitted(true)
            if (this.frm.isValid()) {
                let result = null;
                let io = new TaxInsertObject()
                io.name = this.state.objTax.name;
                io.key = this.state.objTax.key;
                io.percentage = this.state.objTax.percentage;
                io.id = this.state.objTax.id;
                utils.showLoader();
                if (this.isEditing) {
                    result = await api.updateTax(io);
                }
                else {
                    result = await api.addTax(io);
                }
                this.props.toggle();
                if (this.props.onComplete) {
                    this.props.onComplete(result);
                }
            }
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }

    render() {
        return (
            <div>
                <CommonModal
                    dialogProps={{
                        open: this.props.isOpen
                    }}
                    title={"Tax"}
                    toggle={this.props.toggle}
                >
                    <form onSubmit={this.onSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <HyperTextField
                                    required
                                    value={this.state.objTax.name}
                                    onChange={(e: any) => { this.setTax({ name: e.target.value }) }}
                                    label={"Tax Name"}
                                    frm={this.frm}
                                    autoFocus
                                />

                            </Grid>
                            <Grid item xs={12}>
                                <HyperTextField
                                    required
                                    value={this.state.objTax.percentage}
                                    onChange={(e: any) => { this.setTax({ percentage: e.target.value }) }}
                                    label={"Tax Percentage"}
                                    frm={this.frm}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <br></br>
                                <Button type="submit" color="primary" variant="contained">
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CommonModal>
            </div>
        );
    }
}

export default AddUpdateTax;