import React, { FormEvent } from 'react'
import enums from '../../enums';
import CommonModal from '../../Components/CommonModal';
import { Grid, Button } from '@material-ui/core';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import { IBankAccount } from '../../Types/AllTypes';
import HyperForm from '../../Components/HyperInput/HyperForm';
import api from '../../Services/ApiServices';
import utils from '../../Services/Utils';
import { AccountInsertObject } from '../../Types/InsertObjects';
// import scanRUtils from '../../Services/ScanRUtilService';

interface AddUpdateBankAccountsProps {
    objAccount?: IBankAccount
    accountCategory?: string
    modalTitle?: string
    isOpen: boolean

    onComplete?: (result: any) => void
    toggle: () => void
}

interface AddUpdateBankAccountsState {
    objAccount: IBankAccount
}

const initialAccount: IBankAccount = { name: "", type: "", category: "", info: { balance: 0 } } as IBankAccount;

class AddUpdateBankAccounts extends React.Component<AddUpdateBankAccountsProps, AddUpdateBankAccountsState> {
    constructor(props: AddUpdateBankAccountsProps) {
        super(props);
        let state: AddUpdateBankAccountsState = {
            objAccount: initialAccount
        };

        if (this.props.accountCategory) {
            state.objAccount.category = this.props.accountCategory;
        }

        if (this.props.objAccount) {
            state.objAccount = this.props.objAccount
            this.isEditing = true;
        }

        this.state = state;
        this.frm = new HyperForm();
    }
    frm: HyperForm
    isEditing: boolean = false;

    setAccount(obj: any) {
        this.setState({ objAccount: { ...this.state.objAccount, ...obj } });
    }

    setInfo(obj: any) {
        let objAccount = this.state.objAccount;
        objAccount.info = { ...this.state.objAccount.info, ...obj };
        this.setState({ objAccount });
    }

    onSubmit = async (e: FormEvent) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.frm.setIsFormSubmitted(true)
            if (this.frm.isValid()) {
                let result = null;
                if (this.state.objAccount.info?.accountNumber !== this.state.objAccount.info?.confirmAccountNumber)
                    return utils.showError("Both Account number must be same.")
                utils.showLoader();
                let io = new AccountInsertObject()
                io.id = this.state.objAccount.id;
                io.name = this.state.objAccount.name;
                io.info = this.state.objAccount.info;
                io.category = enums.ACCOUNT_CATEGORY.CASH_BANK
                io.type = (enums.CATEGORY_TO_TYPE_MAP as any)[enums.ACCOUNT_CATEGORY.CASH_BANK];

                if (this.isEditing) {
                    result = await api.updateAccount(io);
                }
                else {
                    result = await api.addAccount(io);
                }

                this.props.toggle();
                if (this.props.onComplete) {
                    this.props.onComplete(result);
                }
                // console.log(result)
            }
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }

    render() {
        return (
            <div>
                <CommonModal
                    dialogProps={{
                        open: this.props.isOpen
                    }}
                    title={this.props.modalTitle || "Account"}
                    toggle={this.props.toggle}
                >
                    <form onSubmit={this.onSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <HyperTextField
                                    required
                                    value={this.state.objAccount.name}
                                    onChange={(e: any) => { this.setAccount({ name: e.target.value }) }}
                                    label={"Name"}
                                    frm={this.frm}
                                />

                            </Grid>
                            <Grid item xs={12} md={6}>
                                <HyperTextField
                                    value={this.state.objAccount.info?.accountHolderName}
                                    onChange={(e: any) => { this.setInfo({ accountHolderName: e.target.value }) }}
                                    label={"Account Holder Name"}
                                    type="text"
                                    frm={this.frm}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <HyperTextField
                                    value={this.state.objAccount.info?.accountNumber}
                                    onChange={(e: any) => { this.setInfo({ accountNumber: e.target.value }) }}
                                    label={"Account Number"}
                                    type="number"
                                    frm={this.frm}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <HyperTextField
                                    value={this.state.objAccount.info?.confirmAccountNumber}
                                    onChange={(e: any) => { this.setInfo({ confirmAccountNumber: e.target.value }) }}
                                    label={"Confirm Account Number"}
                                    type="number"
                                    frm={this.frm}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <HyperTextField
                                    value={this.state.objAccount.info?.IFSCCode}
                                    onChange={(e: any) => { this.setInfo({ IFSCCode: e.target.value }) }}
                                    label={"IFSC Code"}
                                    type="text"
                                    frm={this.frm}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center', marginTop: 20 }}>

                                <Button type="submit" color="primary" variant="contained">
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CommonModal>
            </div>
        );
    }
}

export default AddUpdateBankAccounts;