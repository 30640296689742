import React from 'react'
import { Button, TableContainer, Table, TableBody, TableRow, TableCell, Paper, Grid } from '@material-ui/core';
import { IItem, ISalesItem, IDiscount, IWindowState } from '../../Types/AllTypes';
import HyperForm from '../../Components/HyperInput/HyperForm';

import SalesItem from './SalesItem';
import utils from '../../Services/Utils';
import scanRUtils from '../../Services/ScanRUtilService';
import enums from '../../enums';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import HyperSelectField from '../../Components/HyperInput/HyperSelectField';
import Alert from '@material-ui/lab/Alert';
import withWindowState from '../../Components/withWindowState';

interface SalesItemsProps extends IWindowState {
    frm: HyperForm
    lstSalesItem: ISalesItem[]
    objDiscount: IDiscount
    totalPaymentAmount: number
    taxInvoiceType: string

    onChange?: () => void
    setDiscount: (data: Partial<IDiscount>) => void
}

interface SalesItemsState {
    scanningBarcode: boolean
}

const objDefaultSalesItem = {
    objItem: {},
    qty: 1,
    price: 0,
    priceAfterDiscount: 0,
    discountAmountPerItem: 0,
    totalDiscountPerItem: 0,
    lstSerialNo: ([] as string[])
} as ISalesItem;

class SalesItems extends React.Component<SalesItemsProps, SalesItemsState> {
    constructor(props: SalesItemsProps) {
        super(props);
        this.state = {
            scanningBarcode: false,
        }
    }
    currentFocusedItem: any = {};
    barcodeInput: React.RefObject<HTMLInputElement> = React.createRef()

    componentDidMount() {
        document.addEventListener(enums.EVENT.BARCODE_SCANNED, this.onBarcodeScanned)
    }

    componentWillUnmount() {
        document.removeEventListener(enums.EVENT.BARCODE_SCANNED, this.onBarcodeScanned)
    }

    onBarcodeScanned = async (event: any) => {
        if (!this.state.scanningBarcode) {
            return;
        }
        let data = event.detail?.data;
        await this.setState({ scanningBarcode: false })
        if (!data) {
            utils.showWarning("Barcode reading failed. ")
            return;
        }
        // Identify item from barcode. Need to search journal record of purchase. 
        try {
            utils.showLoader();

            let result: IItem = await scanRUtils.getItemFromSerialNoAndValidate(data.data, this.props.global.lstSerialNo, enums.JOURNAL_CATEGORY.SALES)

            if (!result)
                throw Error("Scan Item Not Valid")
            let item = { ...objDefaultSalesItem };
            item.id = result.id;
            item.objItem = result;
            if (result.isTaxInclusive) {
                item.amount = result.defaultSalesPrice
            }
            else {
                item.price = result.defaultSalesPrice;
            }
            item.qty = 1;
            item.lstSerialNo = [data.data];
            item.taxes = result.defaultTaxes;

            if (this.props.lstSalesItem[0].price === 0) {
                this.props.lstSalesItem[0] = item;
            }
            else {
                let isFound: boolean = false;
                this.props.lstSalesItem.forEach((sItem: any) => {
                    if ((sItem.objItem?.id === item.id || sItem.id === item.id) && !isFound) {

                        sItem.lstSerialNo.push(data.data);
                        sItem.qty = sItem.lstSerialNo.length;
                        sItem.amount = scanRUtils.toFixedFloat(sItem.objItem.defaultSalesPrice * sItem.lstSerialNo.length);
                        isFound = true;
                    }
                })
                if (!isFound)
                    this.props.lstSalesItem.push(item);
            }
            this.forceUpdate();
        }
        catch (ex) {
            utils.showWarning(ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }

    addItem = () => {
        this.props.lstSalesItem.push(objDefaultSalesItem)
        this.forceUpdate();
    }

    removeItem = (index: number) => {
        if (this.props.lstSalesItem.length <= 1) {
            utils.showWarning("You must have at least one item. ")
            return;
        }

        this.props.lstSalesItem.splice(index, 1);
        this.forceUpdate();
    }

    scanBarcode = async () => {
        await this.setState({ scanningBarcode: true });
        if (this.barcodeInput.current) {
            this.barcodeInput.current.focus();
        }
    }

    onBarcodeInputChange = (event: any) => {
        this.onBarcodeScanned({ detail: { data: { data: event.target.value, type: "BAR" } } })

    }

    getDueAmount = () => {
        let itemTotals = scanRUtils.getTotalCounts(this.props.lstSalesItem, this.props.objDiscount)
        return itemTotals.amountWithTax - this.props.totalPaymentAmount;
    }

    onDiscountChange = (val: any, subTotal: number) => {
        let discountValue = val || "0";
        if (this.props.objDiscount.discountType === enums.DISCOUNT_OPTION.FIXED) {
            let discountPercentage = scanRUtils.getPercentageOfFixeDiscount(subTotal, parseFloat(discountValue))
            this.props.setDiscount({ discountAmount: parseFloat(discountValue), discountPercentage: discountPercentage })
        }
        else {
            this.props.setDiscount({ discountPercentage: parseFloat(discountValue) })
        }
    }

    render() {
        let objTotalCount = scanRUtils.getTotalCounts(this.props.lstSalesItem, this.props.objDiscount)
        // console.log(this.props.objDiscount, "objTotalCount")

        return (
            <div>
                {this.state.scanningBarcode &&
                    <div className="absolute-overlay">
                        <div className="absolute-overlay-body">
                            <div>Scan Barcode From Your Device</div>
                            <img src="/images/scanner-scanning.gif" style={{ width: "70%", maxHeight: "70%" }} alt="scanner-scanning.gif"/>
                            <br>
                            </br>
                            <input ref={this.barcodeInput} type="text" onChange={this.onBarcodeInputChange} style={{
                                border: "none", color: "white", outline: "none"
                            }} />
                            <br></br>
                            <Button variant="contained" color="primary" onClick={() => { this.setState({ scanningBarcode: false }) }} >Close</Button>
                        </div>
                    </div>
                }
                <div style={{ textAlign: 'right' }}>
                    <Button variant="contained" color="primary" onClick={this.scanBarcode}>Scan</Button>
                    {" "}
                    <Button variant="contained" color="primary" onClick={this.addItem}>Add Item</Button>
                </div>
                {this.props.lstSalesItem.map((objSalesItem: ISalesItem, index: number) => {
                    return <SalesItem
                        key={index}
                        objDiscount={this.props.objDiscount}
                        objSalesItem={objSalesItem}
                        removeItem={() => { this.removeItem(index) }}
                        frm={this.props.frm}
                        focusedItemRef={this.currentFocusedItem}
                        taxInvoiceType={this.props.taxInvoiceType}
                        onChange={(objItem: ISalesItem) => {
                            this.props.lstSalesItem[index] = { ...objSalesItem, ...objItem };
                            this.forceUpdate();
                        }}
                    />
                })}
                <br />
                <TableContainer component={Paper} elevation={3}>
                    <Table aria-label="customized table">
                        <TableBody>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right">Sub Total</TableCell>
                                <TableCell align="right"> {scanRUtils.prettyPrice(objTotalCount.subTotal)} </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell style={{ width: "35%", paddingBottom: 0, paddingTop: 5 }}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={3}>
                                            <HyperSelectField
                                                options={[enums.DISCOUNT_OPTION.PERCENTAGE, enums.DISCOUNT_OPTION.FIXED]}
                                                selectedValue={this.props.objDiscount.discountType}
                                                onChange={(e: any) => { this.props.setDiscount({ discountType: e.target.value }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <HyperTextField
                                                placeholder="Enter Your Discount Here"
                                                value={(this.props.objDiscount.discountType === enums.DISCOUNT_OPTION.FIXED
                                                    ? this.props.objDiscount.discountAmount
                                                    : this.props.objDiscount.discountPercentage) || ""
                                                }
                                                type="number"
                                                onChange={(e: any) => this.onDiscountChange(e.target.value, objTotalCount.subTotal)}
                                            />
                                        </Grid>
                                        <Grid item xs={3} style={{ textAlign: 'right', margin: 'auto' }}>
                                            <span>Discount</span>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell align="right" style={{ width: '20%' }}>
                                    {scanRUtils.prettyPrice(objTotalCount.discountAmount)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right">Total After Discount </TableCell>
                                <TableCell align="right">{scanRUtils.prettyPrice(objTotalCount.totalAfterDiscount)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right">Total Tax</TableCell>
                                <TableCell align="right">{scanRUtils.prettyPrice(objTotalCount.tax)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right">Grand Total</TableCell>
                                <TableCell align="right">{scanRUtils.prettyPrice(objTotalCount.amountWithTax)} </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {this.getDueAmount() ?
                    <Alert style={{ marginTop: 20 }} severity="info">Total Due Amount - {scanRUtils.prettyPrice(this.getDueAmount())}</Alert>
                    :
                    ""
                }
            </div>
        );
    }
}

export default withWindowState(SalesItems, ["global"]);