import React from 'react'
import { IAccount } from '../../Types/AllTypes';
import HyperForm from '../../Components/HyperInput/HyperForm';
import api from '../../Services/ApiServices';
import utils from '../../Services/Utils';
import HyperAutoCompleteField from '../../Components/HyperInput/HyperAutoCompleteField';
// import AddUpdateJournal from './AddUpdateJournal';
import HyperModal from '../../Components/HyperInput/HyperModal';
import AddUpdateCustomer from '../Customers/AddUpdateCustomer';
import enums from '../../enums';
import AddUpdateSupplier from '../Suppliers/AddUpdateSupplier';
import AddUpdateAccount from '../Accounts/AddUpdateAccount';

interface AccountAutoCompleteProps {
    accountId?: number
    onChange?: (e: IAccount) => void
    required?: boolean
    frm?: any
    label?: string
    autoFocus?: boolean
    placeholder?: string
    category?: string
    categories?: string[]
    defaultSelectFirstItem?: boolean
    addBtnLabel?: string
    disabled?: boolean
    autoCompleteAddModalType?: string
}

interface AccountAutoCompleteState {
    lstAccount: IAccount[]
    objAccount?: IAccount
}


class AccountAutoComplete extends React.Component<AccountAutoCompleteProps, AccountAutoCompleteState> {
    constructor(props: AccountAutoCompleteProps) {
        super(props);
        let state: AccountAutoCompleteState = {
            lstAccount: [],
            objAccount: undefined
        };


        this.state = state;
        this.frm = new HyperForm();
    }
    frm: HyperForm
    isEditing: boolean = false;
    itemModal: HyperModal = new HyperModal(this);

    componentDidMount() {
        // document.addEventListener(enums.EVENT.Journal_ADD_UPDATE_MODEL, this.onModelToggle);
        this.loadAccounts();
    }

    async loadAccounts() {
        try {
            let lstAccount = await api.getAccountsList_withWindowCache();

            lstAccount = lstAccount.map((item: any) => {
                if ((this.props.category === enums.ACCOUNT_CATEGORY.SUPPLIER || this.props.category === enums.ACCOUNT_CATEGORY.CUSTOMER) && item.info?.mobile) {
                    item.label = `${item.name} (${item.info.mobile})`;
                }
                else
                    item.label = item.name;

                return { ...item };
            })
            let objAccount = undefined;
            if (this.props.accountId) {
                objAccount = lstAccount.find((item: IAccount) => item.id === this.props.accountId);
            }
            else if (this.props.defaultSelectFirstItem) {
                objAccount = this.getFilteredAccountList(lstAccount)[0];
                if (objAccount && this.props.onChange)
                    this.props.onChange(objAccount);
            }
            // console.log(objAccount)
            this.setState({ lstAccount, objAccount })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }

    }

    getFilteredAccountList(lstAccount?: IAccount[]) {
        if (!lstAccount)
            lstAccount = this.state.lstAccount;
        if (!lstAccount) {
            return lstAccount;
        }
        // console.log(lstAccount,"lstAccount")
        // console.log(this.props.category,"lstAccount")
        // console.log(this.props.categories,"lstAccount")
        if (this.props.category) {
            // console.log(lstAccount)
            return lstAccount.filter((item) => item.category === this.props.category);
        }
        else if (this.props.categories) {
            // console.log(lstAccount.filter((item) => this.props.categories?.includes(item.category)))
            return lstAccount.filter((item) => this.props.categories?.includes(item.category));
        }

        return lstAccount;
    }

    render() {
        let autoCompleteProps: any = {};
        if (this.props.autoCompleteAddModalType) {
            autoCompleteProps.onAddClick = () => { this.itemModal.toggle(true) }
            autoCompleteProps.addBtnLabel = this.props.addBtnLabel;

        }
        return (
            <React.Fragment>
                <HyperAutoCompleteField
                    options={this.getFilteredAccountList()}
                    value={this.state.objAccount || this.props.accountId || ""}
                    onChange={(e: any) => {
                        this.setState({ objAccount: e })
                        if (this.props.onChange) {
                            this.props.onChange(e);
                        }
                    }}
                    label={this.props.label}
                    required={this.props.required}
                    frm={this.props.frm}
                    disabled={this.props.disabled}
                    autoFocus={this.props.autoFocus}
                    placeholder={this.props.placeholder}
                    {...autoCompleteProps}
                />

                {(this.itemModal.isOpen() && this.props.autoCompleteAddModalType) &&
                    this.renderAddUpdateModal()
                }
            </React.Fragment>
        );
    }
    renderAddUpdateModal = () => {
        if (this.props.autoCompleteAddModalType === enums.AUTO_COMPLETE_ADD_TYPE.ADD_CUSTOMER) {
            return <AddUpdateCustomer
                isOpen={this.itemModal.isOpen()}
                onComplete={async (v: any) => {
                    await this.loadAccounts();
                    let objAccount = this.state.lstAccount.find((item: IAccount) => item.id === v.id);
                    this.setState({ objAccount });
                    if (this.props.onChange) {
                        this.props.onChange(objAccount as IAccount);
                    }
                }}
                toggle={this.itemModal.toggle}
            />
        }
        else if (this.props.autoCompleteAddModalType === enums.AUTO_COMPLETE_ADD_TYPE.ADD_SUPPLIER) {
            return <AddUpdateSupplier
                isOpen={this.itemModal.isOpen()}
                onComplete={async (v: any) => {
                    await this.loadAccounts();
                    let objAccount = this.state.lstAccount.find((item: IAccount) => item.id === v.id);
                    this.setState({ objAccount });
                    if (this.props.onChange) {
                        this.props.onChange(objAccount as IAccount);
                    }
                }}
                toggle={this.itemModal.toggle}
            />
        }
        else if (this.props.autoCompleteAddModalType === enums.AUTO_COMPLETE_ADD_TYPE.ADD_EXPENSE) {
            return <AddUpdateAccount
                isOpen={this.itemModal.isOpen()}
                accountCategory={enums.ACCOUNT_CATEGORY.EXPENSE}
                modalTitle={"Add New Expense Type"}
                onComplete={async (v: any) => {
                    await this.loadAccounts();
                    let objAccount = this.state.lstAccount.find((item: IAccount) => item.id === v.id);
                    this.setState({ objAccount });
                    if (this.props.onChange) {
                        this.props.onChange(objAccount as IAccount);
                    }
                }}
                toggle={this.itemModal.toggle}
            />
        }
        else if (this.props.autoCompleteAddModalType === enums.AUTO_COMPLETE_ADD_TYPE.ADD_INCOME) {
            return <AddUpdateAccount
                isOpen={this.itemModal.isOpen()}
                accountCategory={enums.ACCOUNT_CATEGORY.INCOME}
                modalTitle={"Add New Income Type"}
                onComplete={async (v: any) => {
                    await this.loadAccounts();
                    let objAccount = this.state.lstAccount.find((item: IAccount) => item.id === v.id);
                    this.setState({ objAccount });
                    if (this.props.onChange) {
                        this.props.onChange(objAccount as IAccount);
                    }
                }}
                toggle={this.itemModal.toggle}
            />
        }
        else if (this.props.autoCompleteAddModalType === enums.AUTO_COMPLETE_ADD_TYPE.ADD_PAYMENT_OPTION) {
            return <AddUpdateAccount
                isOpen={this.itemModal.isOpen()}
                accountCategory={enums.ACCOUNT_CATEGORY.CASH_BANK}
                modalTitle={"Add New Payment Option"}
                onComplete={async (v: any) => {
                    await this.loadAccounts();
                    let objAccount = this.state.lstAccount.find((item: IAccount) => item.id === v.id);
                    this.setState({ objAccount });
                    if (this.props.onChange) {
                        this.props.onChange(objAccount as IAccount);
                    }
                }}
                toggle={this.itemModal.toggle}
            />
        }
    }
}

export default AccountAutoComplete;