import React from 'react'
import CommonModal from '../../Components/CommonModal';
import { Grid, Button } from '@material-ui/core';
import { Journal, IAccount } from '../../Types/AllTypes';
import { AccountBookSearchOption } from '../../Types/SearchOptions';
import api from '../../Services/ApiServices';
import utils from '../../Services/Utils';
import NotificationSkeleton from '../../Components/Skeletons/NotificationSkeleton';
import DataTable from '../../Components/DataTable';
import AddUpdateJournal from '../Journal/AddUpdateJournal';
import { HyperTheme } from '../../Theme/Theme';
import enums from '../../enums';
import Alert from '@material-ui/lab/Alert';
import scanRUtils, { winState } from '../../Services/ScanRUtilService';
import moment from "moment";
import HyperDateRangePicker from '../../Components/HyperDateRangePicker';
import AccountStatementTable from './AccountStatementTable';
import HyperSelectField from '../../Components/HyperInput/HyperSelectField';

interface AccountDetailProps {
    isOpen: boolean
    toggleModal: () => void

    objAccount: IAccount
}

interface AccountDetailState {
    shouldShowSkeleton: boolean,
    lstAccountJournal: Journal[],
    accountBalance: number
    currentData?: Journal
    isDialogOpen: boolean
    name: any
    dateRange: any
    sortBy: string
}


class AccountDetail extends React.Component<AccountDetailProps, AccountDetailState> {
    constructor(props: AccountDetailProps) {
        super(props);
        let state: AccountDetailState = {
            shouldShowSkeleton: true,
            lstAccountJournal: [],
            currentData: undefined,
            isDialogOpen: false,
            name: "",
            accountBalance: 0,
            dateRange: { startDate: moment().startOf("month").toDate(), endDate: moment().endOf("month").toDate(), label: "This Month" },
            sortBy: ""
        };
        this.state = state;
    }
    componentDidMount() {
        this.loadJournals();
        this.getAccountBalance();
    }

    async loadJournals() {
        try {
            let so = new AccountBookSearchOption();
            // console.log(this.props.objAccount, "accountId={rowData.creditAccountId.toString()} accountName={rowData.creditAccount.name}")
            so.accountId = this.props.objAccount.id.toString()
            so.fromDate = this.state.dateRange.startDate;
            so.toDate = this.state.dateRange.endDate;
            so.sort = this.state.sortBy;

            // console.log(so)
            let lstAccountJournal = await api.getAccountBookById(so);
            this.setState({ lstAccountJournal, shouldShowSkeleton: false })
        }
        catch (ex) {
            console.log(ex)
            utils.showError(ex.msg || ex.message)
        }

    }
    getAccountBalance = async () => {
        try {
            let accountBalance = await api.getAccountBalance(this.props.objAccount.id, this.state.dateRange.endDate);
            // console.log(accountBalance, "accountBalance")
            this.setState({ accountBalance: accountBalance.balance })
        }
        catch (ex) {
            console.log(ex)
            utils.showError(ex.msg || ex.message)
        }
    }

    onAddClick = () => {
        this.setState({ isDialogOpen: true, currentData: undefined });
    }

    onEditClick = (objJournal: Journal) => {
        this.setState({ isDialogOpen: true, currentData: objJournal })
    }

    onDeleteClick = async (objJournal: Journal) => {
        let confirmed = await utils.showConfirm("Do you really want to delete this?", "");
        if (confirmed) {
            try {
                await api.deleteJournal(objJournal.id);
                this.loadJournals();
            }
            catch (ex) {
                utils.showError(ex.msg || ex.message)
            }
        }
    }

    toggleAddUpdateJournalModal = () => {
        this.setState({ isDialogOpen: !this.state.isDialogOpen })
    }

    download = async () => {
        let renderedObj = await scanRUtils.renderToString(<AccountStatementTable
            objAccount={this.props.objAccount}
            lstStatement={this.state.lstAccountJournal}
            totalBalance={this.state.accountBalance}
            dateRange={this.state.dateRange}
        />)

        let frame = document.createElement("iframe")
        document.body.appendChild(frame);
        frame.style.display = "none";
        frame.srcdoc = renderedObj.html;

        frame.onload = () => {
            console.log("did");
            frame.contentWindow?.print();
            // frame.remove()
        }
    }

    render() {
        // console.log(this.state.lstAccountJournal, this.props, winState.state.companySettings.invoicePrefix)
        const companySettings = winState.state.companySettings;

        return (
            <div>
                <CommonModal
                    dialogProps={{
                        open: this.props.isOpen
                    }}
                    maxWidth="lg"
                    isFullScreen={true}
                    title={`Account Book`}
                    toggle={this.props.toggleModal}
                >


                    <Grid container >
                        <Grid item xs={12} md={6} style={{ marginBottom: 20 }}>
                            <Alert severity="info">Total Account Balance - {scanRUtils.prettyPrice(this.state.accountBalance)}</Alert>
                        </Grid>
                        <Grid item md={6} xs={12} style={{ marginBottom: 20, textAlign: 'right' }}>
                            {companySettings.hyperAdvanceMode &&
                                <HyperSelectField
                                    selectedValue={this.state.sortBy}
                                    labelWidth={100}
                                    onChange={async (e: any) => {
                                        await this.setState({ sortBy: e.target.value })
                                        this.loadJournals();
                                    }}
                                    options={scanRUtils.getJournalSortOption()}
                                    optionIdField="key"
                                    optionNameField="name"
                                    label="Sort By"

                                />
                            }{" "}
                            <Button onClick={this.loadJournals} variant="contained" color="primary">Refresh</Button>{" "}
                            <Button onClick={this.download} variant="contained" color="primary">Download</Button>{" "}
                            <HyperDateRangePicker
                                initialDateRange={this.state.dateRange}
                                onChange={async (range: any) => {
                                    await this.setState({ dateRange: range });
                                    this.loadJournals();
                                    // this.getReportDetail()
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {this.state.shouldShowSkeleton ? (
                                <NotificationSkeleton />
                            ) : (
                                <DataTable
                                    title={`Records Of ` + this.props.objAccount.name}
                                    columns={[
                                        { title: "ID", field: "id" },
                                        // { title: "Debit Account", field: "debitAccount.name" },
                                        // { title: "Credit Account", field: "creditAccount.name" },
                                        {
                                            title: "Name", render: (rowData: Journal) => {
                                                // console.log(winState.state.companySettings.invoicePrefix, rowData.info.INVNumber)
                                                if (rowData.debitAccountId.toString() === this.props.objAccount.id.toString())
                                                    return `${rowData.creditAccount.name} ${rowData.category === enums.JOURNAL_CATEGORY.SALES || rowData.category === enums.JOURNAL_CATEGORY.PURCHASE ? "(" + winState.state.companySettings.invoicePrefix + "-" + rowData.info.INVNumber + ")" : ""}`;
                                                return rowData.debitAccount.name;
                                            },
                                            sortField: "creditAccount.name"
                                        },
                                        {
                                            title: "Date", render: (rowData: Journal) => {
                                                return utils.convertToSystemDateFormate(new Date(rowData.date));
                                            },
                                            sortField: "date",
                                            renderReturnsString: true
                                        },
                                        {
                                            title: "Transaction Date", render: (rowData: Journal) => {
                                                return rowData.transactionDate ? utils.convertToSystemDateFormate(new Date(rowData.transactionDate)) : "-";
                                            },
                                            sortField: "transactionDate",
                                            renderReturnsString: true,
                                            hidden: !companySettings.hyperAdvanceMode
                                        },
                                        {
                                            title: "Amount", render: (rowData: Journal) => {
                                                if (rowData.debitAccountId.toString() === this.props.objAccount.id.toString())
                                                    return `Dr ${rowData.amount}`;
                                                return `Cr ${rowData.amount}`;
                                            },
                                            sortField: "amount",
                                            cellStyle: (style: any, rowData: Journal) => {
                                                if (this.props.objAccount.type === enums.ACCOUNT_CATEGORY.REAL) {
                                                    if (rowData.debitAccountId.toString() === this.props.objAccount.id.toString())
                                                        return {
                                                            backgroundColor: HyperTheme.palette.success.light,
                                                            color: HyperTheme.palette.common.white
                                                        }
                                                    return {
                                                        backgroundColor: HyperTheme.palette.error.light,
                                                        color: HyperTheme.palette.common.white
                                                    }
                                                }
                                                else {
                                                    if (rowData.debitAccountId.toString() === this.props.objAccount.id.toString())
                                                        return {
                                                            backgroundColor: HyperTheme.palette.error.light,
                                                            color: HyperTheme.palette.common.white
                                                        }
                                                    return {
                                                        backgroundColor: HyperTheme.palette.success.light,
                                                        color: HyperTheme.palette.common.white
                                                    }
                                                }

                                            },
                                        },
                                        { title: "Comment", field: "comment" },
                                    ]}
                                    data={this.state.lstAccountJournal}
                                    options={{
                                        sorting: true,
                                    }}
                                    onEdit={(rowData: Journal) => { this.onEditClick(rowData) }}
                                    onDelete={(rowData: Journal) => { this.onDeleteClick(rowData) }}
                                // onView={this.viewUser}
                                />
                            )}
                        </Grid>
                    </Grid>
                </CommonModal>
                {this.state.isDialogOpen &&
                    <AddUpdateJournal
                        onComplete={this.loadJournals.bind(this)}
                        isOpen={this.state.isDialogOpen}
                        toggle={this.toggleAddUpdateJournalModal}
                        objJournal={this.state.currentData}
                    />
                }
            </div>
        );
    }
}

export default AccountDetail;