import { v4 } from "uuid";
import lsu from "./LocalStorageUtils";
import enums from "../enums";
import { ICompanySettings, IUser } from "../Types/AllTypes";
const win = (window as any)
class AuthService {
    tokenKey = "token";
    SELECTED_COMPANY_ID = "companyId";
    isUserLogged() {
        return !!this.getUserData();
    }

    setToken(token: any) {
        lsu.lsSet(this.tokenKey, token)
    }

    setCompanyId(selectedCompanyId: any) {
        lsu.lsSet(this.SELECTED_COMPANY_ID, selectedCompanyId)
    }

    getToken() {
        return lsu.lsGet(this.tokenKey)
    }

    getCompanyId() {
        return lsu.lsGet(this.SELECTED_COMPANY_ID)
    }

    setUserData(userData: any) {
        lsu.lsSet(enums.USER_DATA, userData)
    }

    getUserData() {
        return (win.user as IUser);
    }

    getCompanySettings() {
        let settings = (win?.user?.company as ICompanySettings)
        return settings;
    }

    getDeviceID() {
        let deviceID = lsu.lsGet("deviceId");
        if (!deviceID) {
            deviceID = v4();
            lsu.lsSet("deviceId", deviceID);
        }
        return deviceID;
    }

    isValidSelectedCompanyId = () => {
        // if(auth.isSingleCompany())
        //     return true;

        let isValid = false;
        let selectedCompanyId = this.getCompanyId();
        if (!selectedCompanyId)
            return isValid;
        let lstCompany = this.getUserCompanies();
        
        lstCompany.forEach((objCompany: ICompanySettings) => {
            if (selectedCompanyId === objCompany.id)
                isValid = true;
        })
        return isValid;
    }

    getUserCompanies() {
        try {
            let { lstCompany } = this.getUserData()
            return lstCompany
        }
        catch (ex) {
            console.log(ex, "Error occurred whiling getting companies.");
            return [];
        }
    }
    getCompanyNameById(companyId: number) {
        let lstCompany = this.getUserCompanies()
        let objCompany = lstCompany.find((objCompany: ICompanySettings) => objCompany.id === companyId)
        if (!objCompany)
            return ""
        return objCompany.companyName
    }
    isSingleCompany() {
        let lstCompanies = this.getUserCompanies();
        if (lstCompanies.length === 0 || lstCompanies.length === 1)
            return true;
        return false;
    }
}

const auth = new AuthService();
win.auth = auth;
export default auth;