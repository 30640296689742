import React from 'react'
import { Container, Paper, Grid, withStyles, Typography } from '@material-ui/core'
import HyperModal from '../../Components/HyperInput/HyperModal';
import withWindowState from '../../Components/withWindowState';
import { IWindowState } from '../../Types/AllTypes';
import { IconOffer } from '../../Components/Icons/Icons';
import utils from '../../Services/Utils';
import scanRUtils from '../../Services/ScanRUtilService';

const styles = () => ({
    root: {
        flexGrow: 1,
    },
})

interface CompanySubscriptionProps extends IWindowState { }

interface CompanySubscriptionState {
    tabValue: number
}

class CompanySubscription extends React.Component<CompanySubscriptionProps, CompanySubscriptionState> {
    constructor(props: CompanySubscriptionProps) {
        super(props);
        let state: CompanySubscriptionState = {
            tabValue: 0
        };

        this.state = state;
        this.modal = new HyperModal(this);
    }
    modal: HyperModal;

    componentDidMount() {

    }

    onTabChange = (event: any, newValue: number) => {
        this.setState({ tabValue: newValue });
    }

    render() {
        const { companySettings } = this.props;
        let subscriptions = companySettings.subscriptions.map((item: any) => item).reverse();
        return (
            <Container style={{ marginTop: 25 }}>
                <Grid container spacing={5}>
                    {subscriptions && subscriptions.map((objSubscription: any, index: number) => {
                        return <Grid item xs={12} md={4} key={index}>
                            <Paper elevation={3} style={{ textAlign: 'center', padding: 10, backgroundColor: scanRUtils.isActiveSubscription(objSubscription.expiresAt) ? "#d0fbd0" : "#e2e1e1" }} >
                                <IconOffer style={{ fontSize: 50 }} fontSize={"large"} color="primary" />
                                <div style={{ textAlign: 'left' }}>
                                    {/* <Typography>
                                        <b>Name  </b> : {utils.convertToCamelCase(objSubscription.name || "", "_", true)}
                                    </Typography>
                                    <Typography>
                                        <b>Type </b> : {utils.convertToCamelCase(objSubscription.type || "", "_", true)}
                                    </Typography> */}
                                    <Typography>
                                        <b>Activate Date </b> : {utils.convertToSystemDateFormate(objSubscription.activateDate)}
                                    </Typography>
                                    <Typography>
                                        <b>Expiry Date </b> : {utils.convertToSystemDateFormate(objSubscription.expiresAt)}
                                    </Typography>
                                    <Typography>
                                        <b>Validity </b> : {objSubscription.noOfDays} Days
                                        </Typography>

                                </div>
                            </Paper>
                        </Grid>
                    })
                    }
                </Grid>

            </Container>
        );
    }
}

export default withWindowState(withStyles(styles)(CompanySubscription), ["companySettings"]);