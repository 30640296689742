import { Card, CardContent } from '@material-ui/core';
import React from 'react'
import { HyperTheme } from '../../Theme/Theme';
import { Pie } from 'react-chartjs-2';

interface ProfitExpenseChartProps {
    paid: number
    unpaid: number
    title: string
}
interface ProfitExpenseChartState { }

const theme = HyperTheme;
class ProfitExpenseChart extends React.Component<ProfitExpenseChartProps, ProfitExpenseChartState> {
    data = {
        labels: ['Paid', 'Unpaid'],
        datasets: [
            {
                label: 'Rainfall',
                backgroundColor: [
                    '#C9DE00',
                    '#B21F00',
                ],
                hoverBackgroundColor: [
                    '#4B5000',
                    '#501800',
                ],
                data: [this.props.paid, this.props.unpaid]
            }
        ]
    }
    render() {
        return (
            <Card elevation={3}>
                <CardContent>
                    <Pie
                        data={this.data}
                        options={{
                            title: {
                                display: true,
                                text: this.props.title,
                                fontSize: 25,
                                fontColor: theme.palette.primary.main,
                                padding: 10
                            },
                            legend: {
                                display: true,
                                position: 'right'
                            }
                        }}
                    />
                </CardContent>
            </Card>
        );
    }
}

export default ProfitExpenseChart;