import React from 'react'

interface InvoiceIFrameProps {
    htmlTemplate:any
 }
interface InvoiceIFrameState { }

class InvoiceIFrame extends React.Component<InvoiceIFrameProps, InvoiceIFrameState> {
    constructor(props: InvoiceIFrameProps) {
        super(props);
        let state: InvoiceIFrameState = {};
        this.state = state;
    }

    render() {
        return (
            <iframe id="invoiceIFrame" className="invoiceIframe" srcDoc={this.props.htmlTemplate} title="Invoice"
                scrolling="no"
                style={{
                    display: "block",
                    width: "23cm",
                    height: "29cm",
                    border: "none",
                    margin: "auto",
                }}>
            </iframe>
        );
    }
}

export default InvoiceIFrame
