import React from 'react'
import { Container, withTheme, Theme } from '@material-ui/core'
import HyperModal from '../../../Components/HyperInput/HyperModal';
import api from '../../../Services/ApiServices';

import { JournalSearchOptions } from '../../../Types/SearchOptions';
import enums from '../../../enums';
import { Journal } from '../../../Types/AllTypes';

import moment from "moment";

import ComingSoon from '../../../Components/ComingSoon';


interface BankTransferProps {
    theme: Theme
}

interface BankTransferState {
    lstIncome: Journal[]
    totalIncome: number
    isLoading: boolean
    dateRange: any
}


class BankTransfer extends React.Component<BankTransferProps, BankTransferState> {
    constructor(props: BankTransferProps) {
        super(props);
        let state: BankTransferState = {
            lstIncome: [],
            totalIncome: 0,
            isLoading: false,
            dateRange: { startDate: moment().startOf("month").toDate(), endDate: moment().endOf("month").toDate(), label: "This Month" }

        };
        this.state = state;
        this.modal = new HyperModal(this);
    }
    modal: HyperModal;

    componentDidMount() {
        //this.getReportDetail()
    }
    getReportDetail = async () => {
        try {
            await this.setState({ isLoading: true })
            let so = new JournalSearchOptions();
            so.category = enums.JOURNAL_CATEGORY.INCOME;
            so.fromDate = moment(this.state.dateRange.startDate).format("YYYY-MM-DD")
            so.toDate = moment(this.state.dateRange.endDate).format("YYYY-MM-DD")
            // console.log(so, "so")
            let lstIncome = await api.getJournals(so);

            let totalAmount = 0;
            lstIncome.forEach((objIncome: any) => {
                totalAmount = totalAmount + objIncome.amount;
            })
            this.setState({ lstIncome, totalIncome: totalAmount })
        }
        catch (ex) {
            console.log(ex)
        }
        finally {
            await this.setState({ isLoading: false })
        }
    }

    render() {
        console.log(this.state.lstIncome)
        return (
            <Container>
                <ComingSoon />
            </Container>
        );
    }
}

export default withTheme(BankTransfer);