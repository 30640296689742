import { Button } from '@material-ui/core';
import React, { Component } from 'react';
import HyperModal from './HyperInput/HyperModal';
import { DateRangePicker } from "materialui-daterange-picker";
import moment from "moment";

class HyperDateRangePicker extends Component {
    state = {
        range: this.props.initialDateRange
    }
    model = new HyperModal(this);

    getRangeDisplayName(range) {
        if (range.label)
            return `Range: ${range.label}`;
        return `Range: ${moment(range.startDate).format("YYYY-MM-DD")} - ${moment(range.endDate).format("YYYY-MM-DD")}`;
    }

    definedRanges = [
        { startDate: moment().startOf("day").toDate(), endDate: moment().endOf("day").toDate(), label: "Today" },
        { startDate: moment().add(-1, "day").startOf("day").toDate(), endDate: moment().add(-1, "day").endOf("day").toDate(), label: "Yesterday" },
        { startDate: moment().startOf("week").toDate(), endDate: moment().endOf("week").toDate(), label: "This Week" },
        { startDate: moment().startOf("month").toDate(), endDate: moment().endOf("month").toDate(), label: "This Month" },
        { startDate: moment().add(-1, "month").startOf("month").toDate(), endDate: moment().add(-1, "month").endOf("month").toDate(), label: "Last Month" },
        { startDate: moment().add(-2, "month").startOf("month").toDate(), endDate: moment().add(-2, "month").endOf("month").toDate(), label: "Last Second Month" },
        { startDate: moment().startOf("year").toDate(), endDate: moment().endOf("year").toDate(), label: "This Year" },
        { startDate: moment().add(-1, "year").startOf("year").toDate(), endDate: moment().add(-1, "year").endOf("year").toDate(), label: "Last Year" },
        { startDate: moment(0).startOf("year").toDate(), endDate: moment().endOf("year").toDate(), label: "All Time" },
    ]

    render() {
        return (
            <React.Fragment>
                <Button color="primary" variant="contained" onClick={() => { this.model.toggle(true) }}>
                    {this.state.range ? this.getRangeDisplayName(this.state.range) : "Select Range"}
                </Button>
                <DateRangePicker
                    open={this.model.isOpen()}
                    toggle={this.model.toggle}
                    onChange={(range) => {
                        this.model.toggle();
                        this.setState({ range })
                        this.props.onChange(range);
                    }}
                    initialDateRange={this.props.initialDateRange}
                    closeOnClickOutside={true}
                    wrapperClassName="date-picker-wrapper"
                    definedRanges={this.definedRanges}
                />
            </React.Fragment>
        );
    }
}

export default HyperDateRangePicker;