import React from 'react'
import { Typography, Container, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Card, CardHeader, withTheme, Theme, Button } from '@material-ui/core'
import utils from '../../Services/Utils';
import NotificationSkeleton from '../../Components/Skeletons/NotificationSkeleton';
import { BalanceSheetSearchOptions } from '../../Types/SearchOptions';
import api from '../../Services/ApiServices';
import HyperModal from '../../Components/HyperInput/HyperModal';
import scanRUtils from '../../Services/ScanRUtilService';
import HyperDateRangePicker from '../../Components/HyperDateRangePicker';
import moment from "moment";
import AccountText from '../Accounts/AccountText';
import enums from '../../enums';

interface BalanceSheetProps {
    theme: Theme
}

interface BalanceSheetState {
    shouldShowSkeleton: boolean,
    lstBalanceSheet: any,
    categorizedBS: any,
    dateRange: any
}

class BalanceSheet extends React.Component<BalanceSheetProps, BalanceSheetState> {
    constructor(props: BalanceSheetProps) {
        super(props);
        let state: BalanceSheetState = {
            shouldShowSkeleton: true,
            lstBalanceSheet: [],
            categorizedBS: {
                leftSide: [],
                rightSide: []
            },
            dateRange: {
                label: "All Time",
                startDate: moment(0).startOf("year").toDate(),
                endDate: moment().endOf("year").toDate()
            }
        };

        this.state = state;
        this.modal = new HyperModal(this);
        this.loadBalanceSheet = this.loadBalanceSheet.bind(this)
    }
    modal: HyperModal;

    componentDidMount() {
        this.loadBalanceSheet();
    }

    async loadBalanceSheet() {
        try {
            this.setState({ shouldShowSkeleton: true })
            let so = new BalanceSheetSearchOptions();
            so.fromDate = moment(this.state.dateRange.startDate).format("YYYY-MM-DD")
            so.toDate = moment(this.state.dateRange.endDate).format("YYYY-MM-DD")
            let lstBalanceSheet = await api.getBalanceSheet(so);
            let categorizedBS = this.divideBSIntoCategories(lstBalanceSheet)
            this.setState({ lstBalanceSheet, shouldShowSkeleton: false, categorizedBS })
        }
        catch (ex) {
            console.log(ex)
            utils.showError(ex.msg || ex.message)
        }
    }

    divideBSIntoCategories = (lstBalanceSheet: any) => {
        let sundryDebtors = lstBalanceSheet.debitAccounts.filter((item: any) => {
            item.counted = true;
            return (item.type === enums.ACCOUNT_TYPE.PERSONAL)
        });
        let sundryCreditors = lstBalanceSheet.creditAccounts.filter((item: any) => {
            item.counted = true;
            return item.type === enums.ACCOUNT_TYPE.PERSONAL
        });
        let cashBank = lstBalanceSheet.debitAccounts.filter((item: any) => {
            item.counted = true;
            return item.category === enums.ACCOUNT_CATEGORY.CASH_BANK;
        });
        let assets = lstBalanceSheet.debitAccounts.filter((item: any) => {
            item.counted = true;
            return (item.category !== enums.ACCOUNT_CATEGORY.CASH_BANK && item.type === enums.ACCOUNT_TYPE.REAL)
        });

        let otherDebit = lstBalanceSheet.debitAccounts.filter((item: any) => {
            return !item.counted;
        })

        let otherCredit = lstBalanceSheet.creditAccounts.filter((item: any) => {
            return !item.counted;
        })

        let rightSide = [
            { label: "Sundry Creditors", lstAccounts: sundryCreditors },
            { label: "Other Accounts", lstAccounts: otherCredit },
        ]

        let leftSide = [
            { label: "Cash & Bank", lstAccounts: cashBank },
            { label: "Sundry Debtors", lstAccounts: sundryDebtors },
            { label: "Assets", lstAccounts: assets },
            { label: "Other Accounts", lstAccounts: otherDebit },
        ]
        // console.log(lstBalanceSheet)

        return ({ rightSide, leftSide })
    }


    render() {
        let leftSide = this.state.categorizedBS?.leftSide;
        let rightSide = this.state.categorizedBS?.rightSide;
        let { theme } = this.props;
        return (
            <Container>
                <Grid container>
                    <Grid item md={12} style={{ textAlign: 'right' }}>
                        <Button onClick={this.loadBalanceSheet} variant="contained" color="primary">Refresh</Button>{" "}
                        <HyperDateRangePicker
                            initialDateRange={this.state.dateRange}
                            onChange={async (range: any) => {
                                await this.setState({ dateRange: range });
                                this.loadBalanceSheet();
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container >
                    {this.state.shouldShowSkeleton ? (
                        <NotificationSkeleton />
                    ) :
                        (
                            <Grid container style={{ marginTop: 10 }} spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <Card elevation={3}>
                                        <CardHeader
                                            style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.common.white }}
                                            title={
                                                <Grid container direction="row" >
                                                    <Grid item xs={6}>
                                                        <Typography variant={"h6"}>Assets</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                                                        <b>{scanRUtils.prettyPrice(this.state.lstBalanceSheet?.totalDebitAmount)}</b>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />

                                        {leftSide &&
                                            <List>
                                                {leftSide.map((item: any) => {
                                                    if (!item.lstAccounts.length)
                                                        return null;
                                                    return (
                                                        <React.Fragment>
                                                            <ListItem>
                                                                <ListItemText primary={item.label} />
                                                            </ListItem>
                                                            {
                                                                item.lstAccounts.map((objAccount: any, index: number) => {
                                                                    return (
                                                                        <ListItem style={{ borderBottom: "solid 1px #e3e3e3", marginRight: "10px" }} key={index} >
                                                                            <ListItemText primary={<AccountText objAccount={objAccount} />} />
                                                                            <ListItemSecondaryAction style={{ color: "green" }} >
                                                                                {scanRUtils.prettyPrice(objAccount.debitBalance)}
                                                                            </ListItemSecondaryAction>
                                                                        </ListItem>
                                                                    )
                                                                })
                                                            }
                                                        </React.Fragment>
                                                    )

                                                })}
                                                
                                            </List>
                                        }
                                    </Card>

                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Card elevation={3}>
                                        <CardHeader
                                            style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.common.white }}
                                            title={
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <Typography variant={"h6"}>Liabilities</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                                                        <b>{scanRUtils.prettyPrice(this.state.lstBalanceSheet?.totalCreditAmount)}</b>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                        {rightSide &&
                                            <List style={{ padding: 0 }}>
                                                {rightSide.map((item: any) => {
                                                    if (!item.lstAccounts.length)
                                                        return null;
                                                    return (
                                                        <React.Fragment>
                                                            <ListItem>
                                                                <ListItemText primary={item.label} />
                                                            </ListItem>
                                                            {
                                                                item.lstAccounts.map((objAccount: any, index: number) => {
                                                                    return (
                                                                        <ListItem style={{ borderBottom: "solid 1px #e3e3e3", marginRight: "10px" }} key={index} >
                                                                            <ListItemText primary={<AccountText objAccount={objAccount} />} />
                                                                            <ListItemSecondaryAction style={{ color: "green" }} >
                                                                                {scanRUtils.prettyPrice(objAccount.creditBalance)}
                                                                            </ListItemSecondaryAction>
                                                                        </ListItem>
                                                                    )
                                                                })
                                                            }
                                                        </React.Fragment>
                                                    )

                                                })}
                                                
                                            </List>
                                        }
                                    </Card>
                                </Grid>
                            </Grid>
                        )
                    }
                </Grid>
            </Container>
        );
    }
}

export default withTheme(BalanceSheet);