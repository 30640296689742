import * as $ from "jquery";
import enums from "../enums";
import config from "../config";
import auth from "./AuthService";
import utils from "./Utils";

class HttpService {
    constructor() {
        window.requests = window.requests || [];
    }
    createJqReq(url, headers, type, data = undefined) {
        if (auth.getCompanyId()) {
            if (url.includes("?")) {
                url += `&companyId=${auth.getCompanyId()}`;
            }
            else {
                url += `?companyId=${auth.getCompanyId()}`;
            }
        }


        // let parsedUrl = new URL(url);

        // if (auth.getCompanyId()) {
        //     if (parsedUrl.search) {
        //         parsedUrl.search += `&companyId=${auth.getCompanyId()}`
        //     }
        //     else {
        //         parsedUrl.search += `&companyId=${auth.getCompanyId()}`
        //     }
        // }

        // url = parsedUrl.href;

        let objReq = {
            url,
            type: type,
            headers,
            data,
        };
        // headers["CommonToken"] = enums.COMMON_TOKEN;
        // headers["Authorization"] = 'Bearer ' + auth.getToken();

        if (headers["Content-Type"] === "application/json" && type !== "GET") {
            objReq.data = JSON.stringify(data)
            objReq.processData = false;
        }
        return objReq;
    }

    handleError(ex) {
        console.log("Original Error", ex)
        if (ex.responseText) {
            let error = "";
            try {
                let jErr = JSON.parse(ex.responseText);
                error = jErr; //+-Manik 20200224
            }
            catch (ex2) {
                error = ex.responseText;
            }
            throw error;
        }
        else if (ex.statusText) { //++Manik 20200225
            throw new Error(`Http request is unable to complete, Status: ${ex.statusText}, State: ${ex.state()}`);
        }
        else if (typeof ex === 'string') {
            throw ex;
        }
        else if (ex.length > 0) {
            throw ex[0];
        }
        else {
            throw new Error("Unexpected Error.");
        }
    }

    handleSuccess(data) {
        return data
    }

    async get(url, headers = {}) {
        try {
            let objAjax = $.ajax(this.createJqReq(url, headers, "GET"));
            let data = await objAjax;
            return this.handleSuccess(data);
        }
        catch (ex) {
            this.handleError(ex);
        }
    }

    async post(url, data, headers = {}) {
        headers = {
            "Content-Type": "application/json",
            ...headers
        }
        try {
            let objAjax = $.ajax(this.createJqReq(url, headers, "POST", data));
            let responseData = await objAjax;
            if (responseData.status)
                return this.handleSuccess(responseData);
            else
                return this.handleError(responseData.error)
        }
        catch (ex) {
            this.handleError(ex);
        }
    }

    async put(url, data, headers = {}) {
        headers = { "Content-Type": "application/json", ...headers }
        try {
            let responseData = await $.ajax(this.createJqReq(url, headers, "PUT", data));
            return this.handleSuccess(responseData);
        }
        catch (ex) {
            this.handleError(ex);
        }
    }

    async delete(url, data, headers = {}) {
        headers = { "Content-Type": "application/json", ...headers }
        try {
            let responseData = await $.ajax(this.createJqReq(url, headers, "DELETE", data));
            return this.handleSuccess(responseData);
        }
        catch (ex) {
            this.handleError(ex);
        }
    }

    async googo(ClassJOperationIC, KeyValues, options) {
        if (!options)
            options = { requestId: null, loading: null, showLoader: false }
        try {
            let headers = { "Content-Type": "application/x-www-form-urlencoded" };// { "Content-Type": "application/json" };
            let data = {
                GJIJ: JSON.stringify({
                    __RWSUSC: auth.getToken(),
                    ClassJOperationIC,
                    KeyValues,
                    __RWAC: enums.RW_APP_CODES.RWCLIENTWEB,
                    // __TDID: "RW_W_4ede775d-9a54-fe07-610f-75fc8b87455b"
                })
            }

            let url = config.API_URL;
            if (process.env.NODE_ENV === "development") {
                url = config.API_URL + "?OC=" + ClassJOperationIC;
            }

            let objAjax = $.ajax(this.createJqReq(url, headers, "POST", data));
            if (options.requestId) {
                window.requests[options.requestId] = objAjax;
            }

            if (options.showLoader)
                utils.showLoader()
            let responseData = await objAjax;

            if (!responseData.d) {
                throw responseData // -Manik 20200224
            }
            return this.handleSuccess(responseData.d);
        }
        catch (ex) {
            this.handleError(ex);
        }
        finally {
            if (options.showLoader)
                utils.hideLoader()
        }
    }
}

const http = new HttpService();
export default http;