import React from 'react'
import {  withTheme, Theme, Grid, Paper } from '@material-ui/core'
import HyperModal from '../../../Components/HyperInput/HyperModal';
import scanRUtils from '../../../Services/ScanRUtilService';
import utils from '../../../Services/Utils';

interface B2CLProps {
    theme: Theme
    objB2CL: any
}

interface B2CLState {
}

class B2CL extends React.Component<B2CLProps, B2CLState> {
    constructor(props: B2CLProps) {
        super(props);
        let state: B2CLState = {
        };
        this.state = state;
        this.modal = new HyperModal(this);
    }

    modal: HyperModal;

    componentDidMount() {

    }
    render() {
        return (
            <Grid container style={{ marginBottom: 20, marginTop: 20 }} spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={3} style={{ paddingTop: 30, paddingBottom: 30, padding: 20 }}>
                        <div style={{ display: 'inline-block', paddingLeft: 25 }}>
                            <span>No. of invoice</span><br />
                            <b>{this.props.objB2CL.totalInvoice}</b>
                        </div>
                        <div style={{ display: 'inline-block', paddingLeft: 25 }}>
                            <span>Total Invoice Value</span><br />
                            <b>{scanRUtils.prettyPrice(this.props.objB2CL.totalInvoiceValue)}</b>
                        </div>
                        <div style={{ display: 'inline-block', paddingLeft: 25 }}>
                            <span>Total Taxable Value</span><br />
                            <b>{scanRUtils.prettyPrice(this.props.objB2CL.totalTaxableValue)}</b>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={3} style={{ paddingTop: 30, paddingBottom: 30, padding: 20 }}>
                        <table {...{ border: 1 }} style={{ width: '100%' }} cellPadding={5} cellSpacing={0}>
                            <thead>
                                <tr>
                                    <th>Receiver Name</th>
                                    <th>Invoice Number</th>
                                    <th>Invoice Date</th>
                                    <th>Invoice Value</th>
                                    <th>Rate</th>
                                    <th>Taxable Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.objB2CL.lstSales.map((objSales: any, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td>{objSales.debitAccount.name}</td>
                                            <td>{utils.getInvoiceNumberWithPrefix(objSales.info.INVNumber)}</td>
                                            <td>{utils.convertToSystemDateTimeFormate(objSales.date)}</td>
                                            <td>{scanRUtils.prettyPrice(objSales.amount)}</td>
                                            <td>{scanRUtils.countTotalTaxPercentage(objSales.salesItem.taxes)}</td>
                                            <td>{scanRUtils.prettyPrice(objSales.salesItem.totalTaxableAmount)}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Paper>
                </Grid>
            </Grid>

        );
    }
}

export default withTheme(B2CL);