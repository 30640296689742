import React from 'react'
import { ISalesInvoiceTemplate, ISalesInvoiceTemplateItem } from '../../../Types/AllTypes';

interface Sticker4X6TemplateProps {
    objInvoice?: ISalesInvoiceTemplate

    onComplete: (html: string) => void
}
interface Sticker4X6TemplateState {
}

class Sticker4X6Template extends React.Component<Sticker4X6TemplateProps, Sticker4X6TemplateState> {
    constructor(props: Sticker4X6TemplateProps) {
        super(props);
        let state: Sticker4X6TemplateState = {};
        this.state = state;
    }

    ref = React.createRef<HTMLDivElement>();

    componentDidMount() {
        // let mainHTML = document.getElementById("divTemplate");
        let mainHTML = this.ref.current
        this.props.onComplete(mainHTML?.innerHTML || "")
    }
    getTitleFontSize = (title: string) => {
        if (!title)
            return 15;
        let titleLength = title.length;
        // console.log(titleLength, "titleLength")
        if (titleLength <= 10)
            return 90 / 2.5
        else if (titleLength <= 12)
            return 80 / 2.5
        else if (titleLength <= 20)
            return 60 / 2.5
        else if (titleLength <= 22)
            return 45 / 2.5
        else if (titleLength <= 25)
            return 38 / 2.5
        else if (titleLength <= 30)
            return 31 / 2.5
        else if (titleLength <= 35)
            return 25 / 2.5
        return 20 / 2.5
    }
    getRowSpan = () => {
        return 0
    }

    render() {
        const { objInvoice } = this.props;
        // console.log(objInvoice)
        let shouldShowInvoiceColumns: boolean | undefined = objInvoice?.items.some((item: any) => item.showTaxOnInvoice);
        return (
            <div ref={this.ref} id="divTemplate" style={{ display: 'none' }}>
                <table style={{ width: "99%", margin: 'auto', maxHeight: "14cm", fontSize: 13 }}>
                    <tbody>
                        <tr style={{ height: "2.5cm" }}>
                            <td>
                                <table style={{ width: "100%" }} {...{ border: 1 }} cellSpacing="0" cellPadding="5">
                                    <thead>
                                        <tr>
                                            <td valign="middle">
                                                <div style={{ width: "20%", float: 'left' }}>
                                                    {!!(objInvoice?.companyLogoURL) &&
                                                        <img src={objInvoice?.companyLogoURL} alt="CompanyLogo"
                                                            style={{ width: "100%", height: 70, }} />
                                                    }
                                                </div>
                                                <div style={{ width: "79%", float: 'left' }}>
                                                    <div style={{ display: "table", height: 70, }} >
                                                        <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                                                            <div style={{ fontSize: this.getTitleFontSize(objInvoice?.companyName || ""), fontWeight: 'bold' }}>
                                                                {objInvoice?.companyName}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                {objInvoice?.companyAddress}
                                                <div>
                                                    {!!(objInvoice?.companyGST) && <span> <b>&#8226; GST</b> : - <span style={{ fontWeight: 'normal' }}>{objInvoice?.companyGST} </span> &nbsp; </span>}
                                                    {!!(objInvoice?.companyMobile) && <span> <b>&#8226; Mobile</b> : - <span style={{ fontWeight: 'normal' }}>{objInvoice?.companyMobile} </span> &nbsp; </span>}
                                                    {/* {!!(objInvoice?.companyEmail) && <span> <b>&#8226; Email</b> : - <span style={{ fontWeight: 'normal' }}>{objInvoice?.companyEmail} </span> </span>} */}
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </td>
                        </tr>
                        <tr style={{ height: "1.9cm" }}>
                            <td>
                                <table style={{ width: "100%" }} {...{ border: 1 }} cellSpacing="0" cellPadding="5" >
                                    <tbody>
                                        <tr>
                                            <td valign="top" rowSpan={2} style={{ width: "400px" }}>
                                                <b>Mr/Mrs. </b> : - {objInvoice?.customerName}
                                                {!!(objInvoice?.customerGST) && <div> <b>&#8226; GST</b> : {objInvoice?.customerGST} </div>}
                                                {!!(objInvoice?.customerAddress) && <div><b>&#8226;</b> {objInvoice?.customerAddress} </div>}
                                                {!!(objInvoice?.customerMobile) && <div><b>&#8226;</b> {objInvoice?.customerMobile} </div>}
                                            </td>
                                            <td style={{ width: "200px" }}>
                                                <b>
                                                    <span>Bill No. : </span>
                                                </b>
                                                <span>{objInvoice?.invoiceNumber}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "200px" }}>
                                                <b>
                                                    <span>Date : </span>
                                                </b>
                                                <span>{objInvoice?.dateOfInvoice}</span>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr style={{ height: "3.8cm" }}>
                            <td valign="top">
                                <table className="tblBill" style={{ width: "100%", minHeight: "100%", border: "1px solid black", height: "4cm" }} {...{ border: 1 }} cellSpacing="0" cellPadding="5" >
                                    <thead>
                                        <tr>
                                            <th>Particulars</th>
                                            <th style={{ width: "30px" }}>Qty</th>
                                            <th style={{ width: "55x" }}>Rates</th>
                                            {shouldShowInvoiceColumns &&
                                                <th style={{ width: "50px" }}>Tax</th>
                                            }
                                            <th style={{ width: "55px" }}>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {objInvoice?.items.map((item: ISalesInvoiceTemplateItem, index: number) => {
                                            return (
                                                <tr key={index} style={{ height: "10px" }}>
                                                    <td>
                                                        <b>{item.itemName}</b>
                                                        {item.itemDefaultDescription ? <span style={{ fontSize: 11 }}> <br />{item.itemDefaultDescription}</span> : ''}
                                                        {item.itemSN ? <span style={{ fontSize: 11 }}><br /><b>&#8226; S/N</b>  : {item.itemSN}</span> : ''}
                                                    </td>
                                                    <td align="center" valign="top" style={{ width: "30px" }}>{item.itemQty}</td>
                                                    <td align="right" valign="top" style={{ width: "55px" }}>{item.itemPrice}</td>
                                                    {shouldShowInvoiceColumns &&
                                                        <td align="right" valign="top" style={{ width: "50px" }} >{item.itemTax}</td>
                                                    }
                                                    <td align="right" valign="top" style={{ width: "55px" }}>{item.itemAmount}</td>
                                                </tr>)
                                        })}
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            {shouldShowInvoiceColumns &&
                                                <td align="right" valign="top" ></td>
                                            }
                                        </tr>
                                        <tr style={{ height: 10 }}>
                                            <td>Sub Total</td>
                                            <td align="center"><b>{objInvoice?.totalQty}</b></td>
                                            <td align="right"><b>{objInvoice?.subTotal}</b></td>
                                            {shouldShowInvoiceColumns &&
                                                <td align="right"><b>{objInvoice?.totalTax}</b></td>
                                            }
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr style={{ height: "3cm" }}>
                            <td>
                                <table style={{ width: "100%" }} {...{ border: 1 }} cellSpacing="0" cellPadding="5" >
                                    <tbody>
                                        <tr>
                                            <td valign="top" style={{ width: "350px" }} rowSpan={this.getRowSpan()} >
                                                <b>Terms & Conditions :- </b>
                                                {objInvoice?.companyTerms.map((term: string, index: number) => <div key={index}>{term}</div>)}

                                            </td>
                                            <td style={{ width: "130px" }}> <b>Sub Total</b></td>
                                            <td style={{ width: "150px", textAlign: 'right' }}><b>{objInvoice?.subTotal}</b></td>
                                        </tr>
                                        {!!(objInvoice?.discountPercentage) &&
                                            <tr>
                                                <td><b>Discount({objInvoice?.discountPercentage}%)</b></td>
                                                <td style={{ textAlign: 'right' }}><b>{objInvoice?.totalDiscount}</b></td>
                                            </tr>
                                        }
                                        {shouldShowInvoiceColumns &&
                                            <React.Fragment>
                                                {objInvoice?.showIGst
                                                    ?
                                                    <tr>
                                                        <td><b>IGST({objInvoice?.totalIGSTPercentage}%)</b></td>
                                                        <td style={{ textAlign: 'right' }}><b>{objInvoice?.totalTax}</b></td>
                                                    </tr>
                                                    :
                                                    <React.Fragment>
                                                        <tr>
                                                            <td><b>CGST({objInvoice?.totalCGSTPercentage}%)</b></td>
                                                            <td style={{ textAlign: 'right' }}><b>{objInvoice?.totalCGst}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>SGST({objInvoice?.totalSGSTPercentage}%)</b></td>
                                                            <td style={{ textAlign: 'right' }}><b>{objInvoice?.totalSGst}</b></td>
                                                        </tr>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>

                                        }

                                        <tr>
                                            <td><b>Grand Total</b></td>
                                            <td align="right"><b>{objInvoice?.totalAmountWithTax}</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr style={{ height: "2.5cm" }}>
                            <td >
                                <table style={{ width: "100%" }} {...{ border: 1 }} cellSpacing="0" cellPadding="5" >
                                    <tbody>
                                        <tr>
                                            <td valign="top" rowSpan={2} style={{ width: "400px" }} >
                                                <b>Amount In Word :- </b>
                                                <div>{objInvoice?.totalAmountInWord}</div>
                                                <b>Note :- </b>
                                                <div>{objInvoice?.note}</div>
                                            </td>
                                            <td valign="top" rowSpan={3} style={{ width: "200px" }}>
                                                <b>
                                                    Signature :-
                                                </b>
                                                {!!(objInvoice?.companySignatureURL) &&
                                                    <p style={{ textAlign: 'center', margin: 0 }}>
                                                        <img
                                                            src={objInvoice?.companySignatureURL}
                                                            alt="companySignatureURL"
                                                            style={{ width: 175, height: 50 }}
                                                        />
                                                    </p>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div >
        );
    }
}

export default Sticker4X6Template;
