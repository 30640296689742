import { Card, CardContent, } from '@material-ui/core';
import React from 'react'
import { HyperTheme } from '../../Theme/Theme';
import { Pie } from 'react-chartjs-2';

interface SalesPurchaseChartProps {
    paid: number
    unpaid: number
    title: string
}
interface SalesPurchaseChartState { }

const theme = HyperTheme;
class SalesPurchaseChart extends React.Component<SalesPurchaseChartProps, SalesPurchaseChartState> {
    data = {
        labels: ['Paid', 'Unpaid'],
        datasets: [
            {
                label: '',
                backgroundColor: [
                    '#00A6B4',
                    '#6800B4'
                ],
                hoverBackgroundColor: [
                    '#003350',
                    '#35014F'
                ],
                data: [this.props.paid, this.props.unpaid]
            }
        ]
    }
    render() {
        return (
            <Card elevation={3}>
                <CardContent>
                    <Pie
                        data={this.data}
                        options={{
                            title: {
                                display: true,
                                text: this.props.title,
                                fontSize: 25,
                                fontColor: theme.palette.primary.main,
                                padding: 10
                            },
                            legend: {
                                display: true,
                                position: 'right'
                            }
                        }}
                    />
                </CardContent>
            </Card>
        );
    }
}

export default SalesPurchaseChart;