import React, { Component } from "react";
import {
    WithStyles,
    withStyles,
    Theme,
    createStyles,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Container,
    Paper,
    Button,
} from "@material-ui/core";
import { IconCommunication, IconAccount, IconLead } from '../../Components/Icons/Icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import api from "../../Services/ApiServices";

const styles = (theme: Theme) =>
    createStyles({
        paperMargin: {

            [theme.breakpoints.up('sm')]: {
                marginTop: 50
            },
        }
    });

interface IMenuProps extends WithStyles {
    history: any
}


class Menu extends Component<IMenuProps> {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.paperMargin}>
                <Container maxWidth="md">
                    <ListItem style={{ padding: "20px" }}>
                        <Button onClick={() => {
                            localStorage.removeItem("primaryColor");
                            localStorage.removeItem("secondaryColor");
                            window.location.reload();
                        }} style={{ background: '#191919', color: "white" }}>

                            Default Color
                                </Button>
                        &nbsp;
                                <Button onClick={() => {
                            localStorage.primaryColor = "#bc1c14";
                            localStorage.secondaryColor = "#191919";
                            window.location.reload();
                        }} style={{ background: '#bc1c14', color: "white" }}>
                            Alternate Color
                                </Button>
                        &nbsp;
                        <Button
                            onClick={() => {
                                this.props.history.push("/InvoiceDownload")
                            }}
                            style={{ background: '#bc1c14', color: "white" }}
                        >
                            Download
                        </Button>
                    </ListItem>
                    <Paper elevation={3}>
                        <List style={{ padding: '0px' }}>

                            <ListItem style={{ padding: "20px" }}
                                onClick={() => { this.props.history.push('/accounts') }}
                                divider button>
                                <ListItemAvatar>
                                    <IconAccount />
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Accounts"
                                />
                            </ListItem>

                            <ListItem style={{ padding: "20px" }}
                                onClick={() => { this.props.history.push('/pnl') }}
                                divider button>
                                <ListItemAvatar>
                                    <IconLead />
                                </ListItemAvatar>
                                <ListItemText
                                    primary="P & L"
                                />
                            </ListItem>
                            <ListItem style={{ padding: "20px" }} onClick={() => { this.props.history.push('/bs') }} divider button>
                                <ListItemAvatar>
                                    <IconCommunication />
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Balance Sheet"
                                />
                            </ListItem>
                            <ListItem style={{ padding: "20px" }} onClick={() => { this.props.history.push('/journal') }} divider button>
                                <ListItemAvatar>
                                    <IconCommunication />
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Journal"
                                />
                            </ListItem>
                            <ListItem style={{ padding: "20px" }} onClick={() => { this.props.history.push('/customers') }} divider button>
                                <ListItemAvatar>
                                    <IconCommunication />
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Customer"
                                />
                            </ListItem>
                            <ListItem style={{ padding: "20px" }} onClick={() => { this.props.history.push('/items') }} divider button>
                                <ListItemAvatar>
                                    <IconCommunication />
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Items"
                                />
                            </ListItem>
                            <ListItem style={{ padding: "20px" }} onClick={() => { this.props.history.push('/taxes') }} divider button>
                                <ListItemAvatar>
                                    <IconCommunication />
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Taxes"
                                />
                            </ListItem>

                            <ListItem style={{ padding: "20px" }}
                                onClick={() => { this.props.history.push('/settings') }}
                                divider button>
                                <ListItemAvatar>
                                    <SettingsIcon />
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Setting"
                                />
                            </ListItem>
                            {/* <ListItem
                                onClick={this.onClickLogout}
                                style={{ padding: "20px" }}
                                divider button>
                                <ListItemAvatar>
                                    <ExitToAppIcon />
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Logout"
                                />
                            </ListItem> */}
                            <ListItem onClick={api.clearAllCache} style={{ padding: "20px" }} divider button>
                                <ListItemAvatar>
                                    <ExitToAppIcon />
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Clear Cache"
                                />
                            </ListItem>
                        </List>
                    </Paper>
                </Container>
            </div>
        );
    }
}
export default withStyles(styles)(Menu);
