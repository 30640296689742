import React from 'react'
import { Typography, Popover, IconButton, ListItem } from '@material-ui/core';
import { IconMoreVert,  IconDeleteFill, IconEdit, IconCheckCircle } from '../../Components/Icons/Icons';

interface BankAccountsPopoverProps {
    onEdit: () => void
    setAsPrimary: () => void
    onDelete?: () => void

    style: any
}

interface BankAccountsPopoverState {
    anchorEl: any
}

class BankAccountsPopover extends React.Component<BankAccountsPopoverProps, BankAccountsPopoverState> {
    constructor(props: BankAccountsPopoverProps) {
        super(props);
        let state: BankAccountsPopoverState = {
            anchorEl: null
        };
        this.state = state;
    }
    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };
    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;
        return (
            <React.Fragment>
                <IconButton style={this.props.style} aria-describedby={id}  onClick={this.handleClick}>
                    <IconMoreVert />
                </IconButton>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <ListItem style={{ cursor: 'pointer' }} onClick={() => { this.handleClose(); this.props.onDelete && this.props.onEdit() }}>
                        <IconEdit />
                        <Typography style={{ marginLeft: 10 }}>Edit</Typography>
                    </ListItem>
                    <ListItem style={{ cursor: 'pointer' }} onClick={() => { this.handleClose(); this.props.onDelete && this.props.setAsPrimary() }}>
                        <IconCheckCircle />
                        <Typography style={{ marginLeft: 10 }}>Set As Primary</Typography>
                    </ListItem>
                    <ListItem style={{ cursor: 'pointer' }} onClick={() => { this.handleClose(); this.props.onDelete && this.props.onDelete() }}>
                        <IconDeleteFill />
                        <Typography style={{ marginLeft: 10 }}>Delete</Typography>
                    </ListItem>
                </Popover>
            </React.Fragment>
        );
    }
}
export default BankAccountsPopover;