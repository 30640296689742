import React from 'react';
import { winState } from '../Services/ScanRUtilService';
import { IWindowState, IWindowStateKeys } from '../Types/AllTypes';
import { Subtract } from 'utility-types';

const withWindowState = <P extends IWindowState>(Component: React.ComponentType<P>, aryKeys: IWindowStateKeys[]) => {
    
    return class WithContext extends React.Component<Subtract<P, IWindowState>> {
        constructor(props: P) {
            super(props);
            winState.register(this, aryKeys);
        }

        componentWillUnmount() {
            winState.unregister(this)
        }

        render() {
            return (
                <Component {...this.props as P} {...winState.get(aryKeys)} ></Component >
            )
        }
    }
}

export default withWindowState;


