import React from 'react'
import { Button, Container, Grid } from '@material-ui/core'
import AddUpdateExpanses from './AddUpdateExpanses';
import utils from '../../Services/Utils';
import NotificationSkeleton from '../../Components/Skeletons/NotificationSkeleton';
import DataTable from '../../Components/DataTable';
import { Journal } from '../../Types/AllTypes';
import api from '../../Services/ApiServices';
import { JournalSearchOptions } from '../../Types/SearchOptions';
import AccountText from '../Accounts/AccountText';
import enums from '../../enums';
import { IconAdd } from '../../Components/Icons/Icons';
import HyperSelectField from '../../Components/HyperInput/HyperSelectField';
import scanRUtils, { winState } from '../../Services/ScanRUtilService';

interface ExpanseProps { }

interface ExpanseState {
    shouldShowSkeleton: boolean,
    lstJournal: Journal[],
    currentData?: Journal
    isDialogOpen: boolean
    name: any
    sortBy: string
}

class Expanses extends React.Component<ExpanseProps, ExpanseState> {
    constructor(props: ExpanseProps) {
        super(props);
        let state: ExpanseState = {
            shouldShowSkeleton: true,
            lstJournal: [],
            currentData: undefined,
            isDialogOpen: false,
            name: "",
            sortBy: ""
        };

        this.state = state;
    }

    componentDidMount() {
        this.loadExpanse();
    }

    async loadExpanse() {
        try {
            let so = new JournalSearchOptions();
            so.category = enums.JOURNAL_CATEGORY.EXPENSE;
            so.sort = this.state.sortBy;

            let lstJournal = await api.getJournals(so);
            this.setState({ lstJournal, shouldShowSkeleton: false })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }

    }

    onAddClick = () => {
        this.setState({ isDialogOpen: true, currentData: undefined });
    }

    onEditClick = (objJournal: Journal) => {
        this.setState({ isDialogOpen: true, currentData: objJournal })
    }

    onDeleteClick = async (objJournal: Journal) => {
        let confirmed = await utils.showConfirm("Do you really want to delete this?", "");
        if (confirmed) {
            try {
                utils.showLoader();
                await api.deleteJournal(objJournal.id);
                this.loadExpanse();
            }
            catch (ex) {
                utils.showError(ex.msg || ex.message)
            }
            finally {
                utils.hideLoader();
            }
        }
    }

    toggleAddUpdateJournalModal = () => {
        this.setState({ isDialogOpen: !this.state.isDialogOpen })
    }

    render() {
        const companySettings = winState.state.companySettings;

        return (
            <Container>
                <div style={{ textAlign: 'end', marginBottom: 20 }}>
                    {companySettings.hyperAdvanceMode &&
                        <HyperSelectField
                            selectedValue={this.state.sortBy}
                            labelWidth={100}
                            onChange={async (e: any) => {
                                await this.setState({ sortBy: e.target.value })
                                this.loadExpanse();
                            }}
                            options={scanRUtils.getJournalSortOption()}
                            optionIdField="key"
                            optionNameField="name"
                            label="Sort By"

                        />
                    }
                    <Button
                        style={{ marginLeft: 8 }}
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={this.onAddClick}
                        startIcon={<IconAdd />}
                    >
                        New
                    </Button>
                </div>
                <Grid item xs={12}>
                    {this.state.shouldShowSkeleton ? (
                        <NotificationSkeleton />
                    ) : (
                        <DataTable
                            title="Expanse"
                            columns={[
                                {
                                    title: "ID", render: (rowData: Journal) => {
                                        return `#${rowData.id}`;
                                    },
                                    sortField: "id"
                                },
                                {
                                    title: "Expanse Type", render: (rowData: Journal) => {
                                        return <AccountText objAccount={rowData.debitAccount} />
                                    },
                                    sortField: "debitAccount.name"
                                },

                                {
                                    title: "Paid Using", render: (rowData: Journal) => {
                                        return <AccountText objAccount={rowData.creditAccount} />
                                    },
                                    sortField: "creditAccount.name"
                                },

                                {
                                    title: "Date", render: (rowData: Journal) => {
                                        return utils.convertToSystemDateFormate(new Date(rowData.date));
                                    },
                                    sortField: "date",
                                    renderReturnsString: true
                                },
                                {
                                    title: "Transaction Date", render: (rowData: Journal) => {
                                        return rowData.transactionDate ? utils.convertToSystemDateFormate(new Date(rowData.transactionDate)) : "-";
                                    },
                                    sortField: "transactionDate",
                                    renderReturnsString: true,
                                    hidden: !companySettings.hyperAdvanceMode
                                },
                                { title: "Amount", field: "amount" },
                                { title: "Comment", field: "comment" },
                            ]}
                            data={this.state.lstJournal}
                            options={{
                                sorting: true,
                            }}
                            onEdit={(rowData: Journal) => { this.onEditClick(rowData) }}
                            onDelete={(rowData: Journal) => { this.onDeleteClick(rowData) }}
                        // onView={this.viewUser}
                        />
                    )}
                </Grid>
                {this.state.isDialogOpen &&
                    <AddUpdateExpanses
                        onComplete={this.loadExpanse.bind(this)}
                        isOpen={this.state.isDialogOpen}
                        toggle={this.toggleAddUpdateJournalModal}
                        objJournal={this.state.currentData}
                    />
                }
            </Container>
        );
    }
}

export default Expanses;