import React, { FormEvent } from 'react'
import enums from '../../enums';
import CommonModal from '../../Components/CommonModal';
import { Grid, Button } from '@material-ui/core';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import { IPurchase, IAccount, IPurchaseItem, Journal, IDiscount } from '../../Types/AllTypes';
import HyperForm from '../../Components/HyperInput/HyperForm';
import api from '../../Services/ApiServices';
import utils from '../../Services/Utils';
import AccountAutoComplete from '../Journal/AccountAutoComplete';
import PurchaseItems from './PurchaseItems';
import scanRUtils from '../../Services/ScanRUtilService';
import { JournalInsertObject } from '../../Types/InsertObjects';
import { JournalSearchOptions } from '../../Types/SearchOptions';
import { IconAdd } from '../../Components/Icons/Icons';
import PaymentCard from './PaymentCard';
import PaymentItem from './PaymentItem';
import HyperDateTimeField from '../../Components/HyperInput/HyperDateTimeField';
import AddUpdateAttachment from '../Sales/AddUpdateAttachment';
import HyperSelectField from '../../Components/HyperInput/HyperSelectField';
import SalesCustomerInfo from '../Sales/SalesCustomerInfo';

interface AddUpdatePurchaseProps {
    objPurchase?: IPurchase
    isOpen: boolean
    onComplete?: (e?: any) => void
    toggle: () => void
}

interface AddUpdatePurchaseState {
    objPurchase: IPurchase
    lstPayment: Journal[]
    currentObjPaymentIndex: number
    showAddPayment: boolean
    isAddingExpense: boolean
    totalPaymentAmount: number
    purchaseActionList: any
    objDiscount: IDiscount
}

const objDefaultPurchaseItem = {
    objItem: {},
    qty: 1,
    price: 0,
} as IPurchaseItem;


const PURCHASE_TAX_TYPE_NAME: any = enums.PURCHASE_TAX_TYPE_NAME;
const purchaseTaxType: any = Object.keys(PURCHASE_TAX_TYPE_NAME).map((item: any) => {
    return { id: item, value: item, name: PURCHASE_TAX_TYPE_NAME[item] }
})

class AddUpdatePurchase extends React.Component<AddUpdatePurchaseProps, AddUpdatePurchaseState> {
    constructor(props: AddUpdatePurchaseProps) {
        super(props);
        let state: AddUpdatePurchaseState = {
            objPurchase: {} as IPurchase,
            currentObjPaymentIndex: -1,
            lstPayment: [] as Journal[],
            showAddPayment: false,
            isAddingExpense: false,
            totalPaymentAmount: 0,
            purchaseActionList: [],
            objDiscount: { discountType: enums.DISCOUNT_OPTION.PERCENTAGE, discountAmount: 0, discountPercentage: 0 }
        };

        if (this.props.objPurchase) {
            state.objPurchase = this.props.objPurchase
            this.isEditing = true;
        }
        if (!state.objPurchase.date) {
            state.objPurchase.date = new Date().toString();
        }
        else {
            state.objPurchase.date = new Date(state.objPurchase.date).toString();;
        }


        if (state.objPurchase.info) {
            state.objPurchase.lstPurchaseItem = [];
            state.objPurchase.INVNumber = state.objPurchase.info.INVNumber;
            state.objPurchase.dueDate = state.objPurchase.info?.dueDate || new Date().toString();
            state.objPurchase.invoiceTaxType = state.objPurchase.info.invoiceTaxType || enums.PURCHASE_TAX_TYPE.NO_TAX;
            state.objPurchase.supplierInfo = state.objPurchase.info.supplierInfo;

            state.objDiscount.discountAmount = state.objPurchase.info.discountAmount;
            state.objDiscount.discountPercentage = state.objPurchase.info.discountPercentage;
            state.objDiscount.discountType = state.objPurchase.info.discountType;

        }
        else {
            state.objPurchase.lstPurchaseItem = [objDefaultPurchaseItem];
            state.objPurchase.dueDate = new Date().toString();
            state.objPurchase.invoiceTaxType = enums.PURCHASE_TAX_TYPE.NO_TAX
        }

        this.state = state;
        this.frm = new HyperForm();
    }
    frm: HyperForm
    isEditing: boolean = false;

    async componentDidMount() {
        let state = this.state;
        let lstPurchaseItem = null;
        if (state.objPurchase.info) {
            lstPurchaseItem = state.objPurchase.info.items;
            for (let objPurchaseItem of lstPurchaseItem) {
                objPurchaseItem.objItem = await scanRUtils.getItem(objPurchaseItem.id) as any;
            }
            state.objPurchase.lstPurchaseItem = lstPurchaseItem;
            this.forceUpdate();
        }

        if (this.isEditing) {
            this.getPayment()
        }
        // document.addEventListener(enums.EVENT.Journal_ADD_UPDATE_MODEL, this.onModelToggle);
    }
    getPayment = async () => {
        try {
            let so = new JournalSearchOptions();
            so.info = {
                "info.purchaseId": this.state.objPurchase.id
            };
            let payments = await api.getJournals(so)
            if (payments.length > 0) {
                let totalPaymentAmount = 0;
                payments.forEach((item: any) => {
                    // item.date = item.date;
                    totalPaymentAmount = totalPaymentAmount + parseFloat(item.amount);
                });;

                this.setState({ lstPayment: payments, totalPaymentAmount });
            }
        }
        catch (ex) {
            console.log("error while getting payment0", ex)
            utils.showError(ex.message)
        }
    }

    componentWillUnmount() {
        // document.removeEventListener(enums.EVENT.Journal_ADD_UPDATE_MODEL, this.onModelToggle);
    }

    setPurchase(obj: Partial<IPurchase>) {
        this.setState({ objPurchase: { ...this.state.objPurchase, ...obj } });
    }

    setDiscount = (obj: Partial<IDiscount>) => {
        this.setState({ objDiscount: { ...this.state.objDiscount, ...obj } });
    }

    onSubmit = async (e: FormEvent) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.frm.setIsFormSubmitted(true)
            if (this.frm.isValid()) {
                if (!this.isEditing) {
                    let isINVValid = await this.validateINVNumber(this.state.objPurchase.INVNumber)
                    if (!isINVValid)
                        return;
                }

                let objTotalCount = scanRUtils.getTotalCounts(this.state.objPurchase.lstPurchaseItem, this.state.objDiscount)
                this.state.objPurchase.amount = objTotalCount.amountWithTax;
                this.state.objPurchase.comment = "Purchase";

                let io = new JournalInsertObject();
                io.id = this.state.objPurchase.id;
                io.creditAccountId = this.state.objPurchase.creditAccountId;

                let defaultPurchaseAccount: any = await scanRUtils.getDefaultAccountByCategory(enums.JOURNAL_CATEGORY.PURCHASE);
                // console.log(defaultPurchaseAccount, "defaultPurchaseAccount")
                if (!this.state.objPurchase.debitAccountId && defaultPurchaseAccount)
                    io.debitAccountId = defaultPurchaseAccount.id

                io.amount = this.state.objPurchase.amount;
                io.comment = this.state.objPurchase.comment;
                io.date = this.state.objPurchase.date;
                io.info = this.getInfo(this.state.objPurchase.lstPurchaseItem);
                io.category = enums.JOURNAL_CATEGORY.PURCHASE;
                utils.showLoader()

                // if (this.isEditing) {
                //     result = await api.updateJournal(io);
                // }
                // else {
                //     result = await api.addJournal(io);
                // }

                let lstPayment = await this.updatePaymentList(io);

                let result = null;
                result = await api.addUpdatePurchase(io, lstPayment)


                this.props.toggle();
                if (this.props.onComplete) {
                    this.props.onComplete(result);
                }
            }
        }
        catch (ex) {
            console.log(ex)
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }
    validateINVNumber = async (invNumber: string) => {
        try {
            if (this.isEditing)
                return true;
            if (!invNumber)
                return;
            if (!this.state.objPurchase.creditAccountId)
                return;
            await api.isPurchaseINVValid(invNumber, enums.JOURNAL_CATEGORY.PURCHASE, this.state.objPurchase.creditAccountId);
            // console.log(data)
            return true
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
            return false
        }
        finally {
            utils.hideLoader();
        }
    }

    updatePaymentList = async (purchaseIo: JournalInsertObject) => {
        let actionList = [];
        for (let objPayment of this.state.lstPayment) {

            let io = new JournalInsertObject();
            io.id = objPayment.id;
            io.creditAccountId = objPayment.creditAccountId;
            io.debitAccountId = purchaseIo.creditAccountId; // Supplier's account id

            io.amount = objPayment.amount;
            io.comment = objPayment.comment || "  ";
            io.date = objPayment.date;
            io.info = objPayment.info;

            if (!io.id) {
                io.category = objPayment.creditAccount.category === enums.ACCOUNT_CATEGORY.INCOME ? enums.JOURNAL_CATEGORY.INCOME : enums.JOURNAL_CATEGORY.PAYMENT;
                actionList.push({ actionCode: enums.DB_ACTION.INSERT, data: io });
            }
            else if (io.id && (objPayment as any).isUpdated) {
                io.category = objPayment.category;
                actionList.push({ actionCode: enums.DB_ACTION.UPDATE, data: io });
            }
        }

        return actionList.concat(this.state.purchaseActionList)
    }

    getInfo(lstPurchaseItem: IPurchaseItem[]) {
        let obj: any = {};
        let objTotalCount = scanRUtils.getTotalCounts(this.state.objPurchase.lstPurchaseItem, this.state.objDiscount);
        obj = { ...objTotalCount };

        let lstAttachment = scanRUtils.getAttachmentList(enums.JOURNAL_CATEGORY.PURCHASE);
        let stateInfo: any = this.state.objPurchase.info || {};
        // console.log(obj, "obj")
        if (lstAttachment && lstAttachment.length > 0) {
            lstAttachment.forEach((objAttachment: any) => {
                obj[objAttachment.key] = stateInfo[objAttachment.key];
            })
        }

        obj.discountPercentage = this.state.objDiscount.discountPercentage;
        obj.discountType = this.state.objDiscount.discountType;
        obj.amount = objTotalCount.amountWithTax;

        obj.INVNumber = this.state.objPurchase.INVNumber;
        obj.supplierInfo = this.state.objPurchase.supplierInfo;
        obj.invoiceTaxType = this.state.objPurchase.invoiceTaxType;
        obj.dueDate = new Date(this.state.objPurchase.dueDate).toISOString();
        obj.paymentStatus = this.state.objPurchase.paymentStatus;
        if (this.state.objPurchase.invoiceTaxType === enums.PURCHASE_TAX_TYPE.NO_TAX)
            obj.isIntraState = scanRUtils.isIntraStatePurchase(this.state.objPurchase.supplierInfo.info.GST || this.state.objPurchase.supplierInfo.info.state)
        else
            obj.isIntraState = scanRUtils.isIntraStatePurchase(this.state.objPurchase.invoiceTaxType)
        obj.items = lstPurchaseItem.map(item => {
            let item1: any = item;
            item1.id = item.objItem.id;
            item1.name = item.objItem.name;
            item1.lstSerialNo = item.lstSerialNo;
            // delete item1.objItem;
            return item1;
        })
        return obj;
    }

    toggleShowPayment = () => {
        this.setState({ showAddPayment: !this.state.showAddPayment })
    }

    onPaymentSave = (objPayment: Journal, isEditing: boolean) => {
        if (isEditing) {
            (this.state.lstPayment as any)[this.state.currentObjPaymentIndex] = { ...objPayment, isUpdated: true };
        }
        else {
            this.state.lstPayment.push(objPayment)
        }
        const totalPaymentAmount = this.state.lstPayment.reduce((sum, { amount }) => sum + amount, 0);
        this.setState({ showAddPayment: false, totalPaymentAmount, currentObjPaymentIndex: -1 })
    }

    isThisPaymentAmountValid = (currentAmount: number, isEditing: boolean) => {
        let currentTotalPaymentAmount = this.state.totalPaymentAmount + currentAmount;
        let objTotalCount = scanRUtils.getTotalCounts(this.state.objPurchase.lstPurchaseItem, this.state.objDiscount)
        if (isEditing)
            currentTotalPaymentAmount = currentTotalPaymentAmount - this.state.lstPayment[this.state.currentObjPaymentIndex].amount;
        if (currentTotalPaymentAmount <= objTotalCount.amountWithTax) {
            return true;
        }
        return false;
    }
    onEditPayment = (currentObjPaymentIndex: number) => {
        this.setState({ currentObjPaymentIndex, showAddPayment: true })
    }
    onDeletePayment = (objPaymentIndex: number) => {
        let objPayment = this.state.lstPayment[objPaymentIndex];
        this.state.purchaseActionList.push({ actionCode: enums.DB_ACTION.DELETE, data: { id: objPayment.id } });
        this.state.lstPayment.splice(objPaymentIndex, 1);
        this.setState({ totalPaymentAmount: this.state.totalPaymentAmount - scanRUtils.toFixedFloat(objPayment.amount) })
    }

    getDueAmount = () => {
        let itemTotals = scanRUtils.getTotalCounts(this.state.objPurchase.lstPurchaseItem, this.state.objDiscount)
        return itemTotals.amountWithTax - this.state.totalPaymentAmount;
    }
    validateInvoiceTaxType = (invoiceTaxType: string) => {
        // console.log(invoiceTaxType, this.state.objPurchase)
        let supplierInfo = this.state.objPurchase.supplierInfo;
        if (!supplierInfo)
            return false;
        let isIntraState = scanRUtils.isIntraState(supplierInfo.info?.state)
        if (isIntraState) {
            // this.setState({ showInModalError: true })
        }
    }
    render() {
        console.log(this.props.objPurchase)
        return (
            <div>
                <CommonModal
                    dialogProps={{
                        open: this.props.isOpen
                    }}
                    maxWidth="lg"
                    title={"Purchase"}
                    toggle={this.props.toggle}

                    isFullScreen={true}
                    onSave={this.onSubmit}
                    disableOnSave={this.state.showAddPayment ? true : false}
                // disableEscapeKey={true}
                // primaryTitle={true}
                >
                    <form onSubmit={this.onSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <AccountAutoComplete
                                            accountId={this.state.objPurchase.creditAccountId}
                                            onChange={(objAccount: IAccount) => {
                                                if (objAccount)
                                                    this.setPurchase({ creditAccountId: objAccount.id, supplierInfo: objAccount })
                                            }}
                                            label="Supplier"
                                            frm={this.frm}
                                            required
                                            autoFocus
                                            category={enums.ACCOUNT_CATEGORY.SUPPLIER}
                                            addBtnLabel="Add New Supplier"
                                            autoCompleteAddModalType={enums.AUTO_COMPLETE_ADD_TYPE.ADD_SUPPLIER}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <HyperDateTimeField
                                            required
                                            selected={this.state.objPurchase.date}
                                            onChange={(date: any) => {
                                                this.setPurchase({ date: date })
                                            }}
                                            label={"Date"}
                                            type="date"
                                            frm={this.frm}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <HyperDateTimeField
                                            required
                                            selected={this.state.objPurchase.dueDate}
                                            onChange={(date: any) => {
                                                this.setPurchase({ dueDate: date })
                                            }}
                                            label={"Due Date"}
                                            type="date"
                                            frm={this.frm}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <HyperSelectField
                                            labelWidth={120}
                                            required
                                            selectedValue={this.state.objPurchase.invoiceTaxType}
                                            onChange={(e: any) => { this.setPurchase({ invoiceTaxType: e.target.value }); this.validateInvoiceTaxType(e.target.value) }}
                                            options={purchaseTaxType}
                                            optionIdField={"value"}
                                            optionNameField={"name"}
                                            label="Invoice Tax Type"
                                            fullWidth
                                            frm={this.frm}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <HyperTextField
                                            required
                                            value={this.state.objPurchase.INVNumber || ""}
                                            onChange={(e: any) => {
                                                this.setPurchase({ INVNumber: (e.target.value) })
                                            }}
                                            onBlur={(e: any) => this.validateINVNumber(e.target.value)}
                                            label={"Invoice Number"}
                                            type="text"
                                            frm={this.frm}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {(this.state.objPurchase.supplierInfo) &&
                                <Grid item xs={12} md={6}>
                                    <SalesCustomerInfo customerInfo={this.state.objPurchase.supplierInfo} title={"Supplier Info"} />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                {/* Purchase Item && Purchase Info */}
                                <PurchaseItems
                                    lstPurchaseItem={this.state.objPurchase.lstPurchaseItem}
                                    frm={this.frm}
                                    objDiscount={this.state.objDiscount}
                                    setDiscount={this.setDiscount}
                                    totalPaymentAmount={this.state.totalPaymentAmount}
                                    taxInvoiceType={this.state.objPurchase.invoiceTaxType}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <HyperTextField
                                    value={this.state.objPurchase.comment || ""}
                                    onChange={(e: any) => {
                                        this.setPurchase({ comment: (e.target.value) })
                                    }}
                                    label={"Comment"}
                                    type="text"
                                    frm={this.frm}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center', paddingTop: 20 }}>
                                {(!this.state.showAddPayment) &&
                                    <React.Fragment>
                                        <Button variant="contained" color="default" onClick={async () => { await this.setState({ isAddingExpense: false }); this.toggleShowPayment() }}>
                                            <IconAdd />
                                            Payment
                                        </Button> {" "}
                                        <Button variant="contained" color="default" onClick={async () => { await this.setState({ isAddingExpense: true }); this.toggleShowPayment() }}>
                                            <IconAdd />
                                            Income
                                        </Button>
                                    </React.Fragment>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {this.state.lstPayment.map((objPayment: any, index: number) => {
                                        return (
                                            <Grid item xs={6} key={index}>
                                                <PaymentCard
                                                    objPayment={objPayment}
                                                    onEdit={async () => {
                                                        await this.setState({ isAddingExpense: objPayment.category === enums.JOURNAL_CATEGORY.INCOME ? true : false });
                                                        this.onEditPayment(index);
                                                    }}
                                                    onDelete={() => { this.onDeletePayment(index) }}
                                                />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>

                            {this.state.showAddPayment &&
                                <Grid item xs={12}>
                                    <PaymentItem
                                        key={this.state.currentObjPaymentIndex}
                                        onRemoveItem={this.toggleShowPayment}
                                        onSave={this.onPaymentSave}
                                        isAddingExpense={this.state.isAddingExpense}
                                        totalDueAmount={this.getDueAmount()}
                                        objPayment={this.state.lstPayment[this.state.currentObjPaymentIndex]}
                                        checkPaymentAmount={this.isThisPaymentAmountValid}
                                    />
                                </Grid>
                            }

                            <Grid item xs={12}>
                                <AddUpdateAttachment
                                    objInfo={this.state.objPurchase.info || {}}
                                    journalCategory={enums.JOURNAL_CATEGORY.PURCHASE}
                                    onAttachmentDone={(key: string, url: string) => {
                                        let info: any = this.state.objPurchase.info || {};
                                        info[key] = url;
                                        this.setPurchase({ info });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </CommonModal>
            </div>
        );
    }
}

export default AddUpdatePurchase;