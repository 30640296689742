import React from 'react';

class ExceptionShowComponent extends React.Component {
    prepareErrorMessage = (err) => {
        if (typeof err === 'string') {
            return err;
        }
        if (typeof err === 'object') {
            if (typeof err.error === 'string')
                return err.error;

            if (typeof (err.error && err.error[0]) === 'string')
                return err.error[0];

            if (typeof err.message === 'string')
                return err.message;

            if (typeof err.msg === 'string')
                return err.msg;
        }

        return 'Unexpected Error';
    }


    render() {
        if (this.props.ex) {
            return <div style={{ padding: 50, paddingLeft: 150, paddingRight: 150 }}>
                <div>
                    <h1 style={{ color: 'red' }} align="center">System Error</h1>
                </div>
                <div>
                    <p style={{ color: 'red' }}>{this.prepareErrorMessage(this.props.ex)}</p>
                </div>
                <div style={{ maxHeight: 250, width: "100%", overflow: 'scroll' }}>
                    <pre>
                        {this.props.ex.stack}
                    </pre>
                </div>
                {this.props.errorInfo &&
                    <div style={{ maxHeight: 250, width: "100%", overflow: 'scroll' }}>
                        <pre>
                            Info: {this.props.errorInfo.componentStack}
                        </pre>
                    </div>
                }
            </div>;
        }

        return null;
    }
}

export default ExceptionShowComponent;