import React, { FormEvent } from 'react'
import enums from '../../enums';
import CommonModal from '../../Components/CommonModal';
import { Grid, Button } from '@material-ui/core';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import { IAccount } from '../../Types/AllTypes';
import HyperSelectField from '../../Components/HyperInput/HyperSelectField';
import HyperForm from '../../Components/HyperInput/HyperForm';
import api from '../../Services/ApiServices';
import utils from '../../Services/Utils';
import { AccountInsertObject } from '../../Types/InsertObjects';

interface AddUpdateAccountProps {
    objAccount?: IAccount
    accountCategory?: string
    modalTitle?: string
    isOpen: boolean

    onComplete?: (result: any) => void
    toggle: () => void

}

interface AddUpdateAccountState {
    objAccount: IAccount
}

const initialAccount: IAccount = { name: "", type: "", category: "" } as IAccount;

class AddUpdateAccount extends React.Component<AddUpdateAccountProps, AddUpdateAccountState> {
    constructor(props: AddUpdateAccountProps) {
        super(props);
        let state: AddUpdateAccountState = {

            objAccount: initialAccount
        };

        if (this.props.accountCategory) {
            state.objAccount.category = this.props.accountCategory;
        }

        if (this.props.objAccount) {
            state.objAccount = this.props.objAccount
            this.isEditing = true;
        }

        this.state = state;
        this.frm = new HyperForm();
    }
    frm: HyperForm
    isEditing: boolean = false;

    setAccount(obj: any) {
        this.setState({ objAccount: { ...this.state.objAccount, ...obj } });
    }

    onSubmit = async (e: FormEvent) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.frm.setIsFormSubmitted(true)
            console.log(this.frm.isValid(), "Is Valid")
            if (this.frm.isValid()) {
                let result = null;
                utils.showLoader();
                if (this.isEditing) {
                    let UO = new AccountInsertObject()
                    UO.id = this.state.objAccount.id;
                    UO.name = this.state.objAccount.name;
                    UO.category = this.state.objAccount.category;
                    UO.type = (enums.CATEGORY_TO_TYPE_MAP as any)[this.state.objAccount.category];

                    result = await api.updateAccount(UO);
                }
                else {
                    let IO = new AccountInsertObject()
                    IO.name = this.state.objAccount.name;
                    IO.category = this.state.objAccount.category;
                    IO.type = (enums.CATEGORY_TO_TYPE_MAP as any)[this.state.objAccount.category];;

                    result = await api.addAccount(IO);
                }
                this.props.toggle();
                if (this.props.onComplete) {
                    this.props.onComplete(result);
                }
                console.log(result)
            }
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }

    render() {
        return (
            <div>
                <CommonModal
                    dialogProps={{
                        open: this.props.isOpen
                    }}
                    title={this.props.modalTitle || "Account"}
                    toggle={this.props.toggle}
                >
                    <form onSubmit={this.onSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <HyperTextField
                                    required
                                    value={this.state.objAccount.name}
                                    onChange={(e: any) => { this.setAccount({ name: e.target.value }) }}
                                    label={"Account Name"}
                                    frm={this.frm}
                                />

                            </Grid>
                            <Grid item xs={12} md={6}>
                                <HyperSelectField
                                    required
                                    selectedValue={this.state.objAccount.category}
                                    labelWidth={130}
                                    onChange={(e: any) => { this.setAccount({ category: e.target.value }) }}
                                    options={utils.getDisplayableAccountCategories()}
                                    label="Account Category"
                                    fullWidth
                                    disabled={this.props.accountCategory ? true : false}
                                    frm={this.frm}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Button type="submit" color="primary" variant="contained">
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CommonModal>
            </div>
        );
    }
}

export default AddUpdateAccount;