import React from 'react'
import { TextField, Button } from '@material-ui/core'
import BaseInput from './BaseInput'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { IconAdd } from '../Icons/Icons';


export default class HyperAutoCompleteField extends BaseInput {
    getValue() {
        return this.props.selected;
    }
    render() {
        //  console.log(this.state, this.isValid(), this.getHelperText())
        let renderInput = (params) => {
            // if (this.props.onAddClick) {
            //     console.log(params.InputProps)
            //     params.InputProps.startAdornment = <IconButton>
            //         <IconAdd />
            //     </IconButton>
            // }
            if (params.inputProps) {
                params.inputProps.autoComplete = 'new-password';
            }

            return (
                <TextField
                    autoFocus={this.props.autoFocus}
                    placeholder={this.props.placeholder}
                    error={!this.isValid()}
                    helperText={this.getHelperText()}
                    type={this.props.type || "text"}
                    {...params}
                    variant={this.props.variant || "outlined"}
                    label={this.props.label}

                />
            )
        }
        if (this.props.renderInput)
            renderInput = this.props.renderInput;

        let options = Array.from(this.props.options);
        if (this.props.onAddClick) {
            options.unshift({ addBtn: true, label: this.props.addBtnLabel || "Add" });
        }

        return (
            <Autocomplete
                options={options}
                size="small"
                renderOption={
                    this.props.renderOption ? this.props.renderOption  :
                    (option) => {
                    if (option.addBtn) {
                        return (
                            <Button variant="text" startIcon={<IconAdd />} color="primary" >{option.label}</Button>
                        )
                    }
                    return ((typeof option === "string" ? option : option.label) || "")
                }}

                getOptionLabel={(option) => {
                    return ((typeof option === "string" ? option : option.label) || "")
                }}

                // style={{ width: 300 }}
                value={this.props.value || ""}

                getOptionSelected={(a, b) => {
                    
                    if (this.props.value === "") {
                        return undefined;
                    }
                    
                    if (a.addBtn) {
                        return options.find(item => item.addBtn);
                    }

                    if (!this.props.value)
                        return false;
                    if (this.props.value.find)
                        return this.props.value.find(item => item.label === a.label)
                    else
                        return this.props.value.label === a.label;
                }}

                onChange={(e, value) => {
                    if (value && value.addBtn) {
                        this.props.onAddClick(value);
                        return;
                    }
                    this.onChange(value)
                }}
                // onInputChange={(e, value) => {
                //     console.log(value)
                // }}
                filterOptions={(a, b) => {
                    if (!a || !a.filter)
                        return [];
                    return a.filter(item => {
                        if (item.addBtn)
                            return true;
                        return RegExp(b.inputValue, "i").test(b.getOptionLabel(item));
                    })
                }}
                fullWidth
                multiple={this.props.multiple}
                onFocus={this.onFocus}
                renderInput={renderInput}
                disabled={this.props.disabled}
                disableCloseOnSelect={this.props.disableCloseOnSelect}
            // autoHighlight={true}
            />
        )
    }
}
