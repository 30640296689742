import React from 'react'
import { Grid, Button, TextField, IconButton, Typography, Chip } from '@material-ui/core';
import HyperForm from '../../Components/HyperInput/HyperForm';
import utils from '../../Services/Utils';
import CommonModal from '../../Components/CommonModal';
import { IconAddCircle } from '../../Components/Icons/Icons';
import api from '../../Services/ApiServices';
import withWindowState from '../../Components/withWindowState';
import { IWindowState } from '../../Types/AllTypes';

interface BunchEntryModalProps extends IWindowState {
    isOpen: boolean
    lstSerialNo: string[]
    cat: string
    isNewDisabled?: boolean

    toggleModal: () => void
    onFinishBunchEntry: (lstSerialNo: string[]) => void
}

interface BunchEntryModalState {
    serialNo: string
    lstSerialNo: string[]
}

class BunchEntryModal extends React.Component<BunchEntryModalProps, BunchEntryModalState> {
    constructor(props: BunchEntryModalProps) {
        super(props);
        let state: BunchEntryModalState = {
            serialNo: "",
            lstSerialNo: []
        };
        if (this.props.lstSerialNo.length > 0)
            state.lstSerialNo = this.props.lstSerialNo;
        this.state = state;
        this.frm = new HyperForm();
    }
    frm: HyperForm;
    isEditing: boolean = false;

    onSaveSN = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        if (!this.state.serialNo)
            return;
        if (this.state.lstSerialNo.includes(this.state.serialNo))
            return utils.showError("Serial Number is already exist!")
        if (this.props.global.lstSerialNo.includes(this.state.serialNo))
            return utils.showError("Same Serial Number exist in another Item!")
        try {
            await api.getItemFromSerialNo(this.state.serialNo, this.props.cat)
            // console.log(data)
            this.state.lstSerialNo.push(this.state.serialNo);
            await this.setState({ serialNo: "" });
        }
        catch (ex) {
            utils.showWarning(ex.msg || ex.message)
        }
    }

    onFinishBunchEntry = () => {
        this.props.onFinishBunchEntry(this.state.lstSerialNo)
        this.props.toggleModal();
    }

    onDeleteSN = (deleteIndex: number) => {
        // console.log(deleteIndex)
        this.state.lstSerialNo.splice(deleteIndex, 1)
        this.forceUpdate();
    }

    render() {
        const isNewDisabled = this.props.isNewDisabled ? true : false;
        return (
            <div>
                <CommonModal
                    dialogProps={{
                        open: this.props.isOpen
                    }}
                    maxWidth="sm"
                    title={"Bunch Entry"}
                    toggle={this.props.toggleModal}
                    disableEscapeKey={true}
                >
                    <form onSubmit={this.onSaveSN}>
                        <Grid container>
                            <Grid item xs={10}>
                                <TextField
                                    fullWidth
                                    label="Serial No."
                                    value={this.state.serialNo}
                                    onChange={(e: any) => { this.setState({ serialNo: e.target.value }) }}
                                    disabled={isNewDisabled}
                                />
                            </Grid>
                            <Grid xs={2} item style={{ margin: 'auto' }}>
                                <IconButton type="submit" color={"primary"} disabled={isNewDisabled}>
                                    <IconAddCircle />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </form>
                    <br />

                    <Typography variant="subtitle1" >
                        Added Serial No.({this.state.lstSerialNo.length})
                    </Typography>

                    {this.state.lstSerialNo.map((sn: string, index: number) => {
                        return <Chip style={{ margin: 3 }} key={index} label={sn} onDelete={() => this.onDeleteSN(index)} color="primary" />
                    })}
                    <Grid container>
                        <Grid item xs={12} style={{ textAlign: 'center', marginTop: 20 }} >
                            <Button variant="contained" color="primary" onClick={this.onFinishBunchEntry}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </CommonModal>
            </div>
        );
    }
}

export default withWindowState(BunchEntryModal, ["global"]);