import xlsx from 'xlsx';
const r3bSheetName = "GSTR-3B";

class CSVService {
    download_csv_file() {
        let wb = xlsx.utils.table_to_book(document.getElementById('main-table'))
        xlsx.writeFile(wb, r3bSheetName + '.xlsx');
        return false;

    }
}

const CSVServices = new CSVService();
window.CSVServices = CSVServices;
export default CSVServices;
