import React from 'react'
import { Button, Container, Grid } from '@material-ui/core'
import utils from '../../Services/Utils';
import NotificationSkeleton from '../../Components/Skeletons/NotificationSkeleton';
import DataTable from '../../Components/DataTable';
import api from '../../Services/ApiServices';
import HyperModal from '../../Components/HyperInput/HyperModal';
import { IItem, ISupplier, IAccount } from '../../Types/AllTypes';
import AddUpdateSupplier from './AddUpdateSupplier';
import { AccountSearchOption } from '../../Types/SearchOptions';
import enums from '../../enums';
import AccountText from '../Accounts/AccountText';
import { IconAdd } from '../../Components/Icons/Icons';

interface SuppliersProps {

}
interface SuppliersState {
    shouldShowSkeleton: boolean,
    lstSuppliers: ISupplier[],
    currentData?: ISupplier
}

class Suppliers extends React.Component<SuppliersProps, SuppliersState> {
    constructor(props: SuppliersProps) {
        super(props);
        let state: SuppliersState = {
            shouldShowSkeleton: true,
            lstSuppliers: [],
            currentData: undefined
        };

        this.state = state;
        this.modal = new HyperModal(this);
    }
    modal: HyperModal;

    componentDidMount() {
        this.loadSuppliers();
    }

    async loadSuppliers() {
        try {
            let SO = new AccountSearchOption()
            SO.category = enums.ACCOUNT_CATEGORY.SUPPLIER;
            let lstSuppliers = await api.getAccountsList(SO)
            lstSuppliers = lstSuppliers.map((Supplier: any) => {
                return { ...Supplier, ...Supplier.info }
            });
            this.setState({ lstSuppliers, shouldShowSkeleton: false })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }

    }

    onDeleteClick = async (objItem: IItem) => {
        let confirmed = await utils.showConfirm("Do you really want to delete this?", "");
        if (confirmed) {
            try {
                utils.showLoader();
                let data = await api.deleteAccount(objItem.id);
                if (data.isUsed)
                    return utils.showWarning("You can't delete this Supplier because it's already used in Purchase.")
                this.loadSuppliers();
            }
            catch (ex) {
                utils.showError(ex.msg || ex.message)
            }
            finally {
                utils.hideLoader();
            }
        }
    }

    onAddClick = () => {
        this.modal.toggle(true)
    }

    onEditClick = (objItem: IItem) => {
        this.modal.toggle(true, { currentData: objItem });
    }

    render() {
        return (
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={this.onAddClick}
                            startIcon={<IconAdd />}
                        >
                            New
                    </Button>
                    </Grid>

                    <Grid item xs={12}>
                        {this.state.shouldShowSkeleton ?
                            (
                                <NotificationSkeleton />
                            ) : (
                                <DataTable
                                    title="Suppliers"
                                    columns={[
                                        {
                                            title: "ID", render: (rowData: IAccount) => {
                                                return `#${rowData.id}`;
                                            },
                                            sortField:"id"
                                        },
                                        {
                                            title: "Name", render: (rowData: IAccount) => {
                                                return <AccountText objAccount={rowData} />
                                            },
                                            sortField:"nnname"
                                        },
                                        { title: "Mobile", field: "mobile" },
                                        { title: "Email", field: "email" },
                                        { title: "GST", field: "info.GST" },

                                    ]}
                                    data={this.state.lstSuppliers}
                                    options={{
                                        sorting: true,
                                    }}
                                    onEdit={(rowData: IItem) => { this.onEditClick(rowData) }}
                                    onDelete={(rowData: IItem) => { this.onDeleteClick(rowData) }}
                                // onView={this.viewUser}
                                />
                            )}
                    </Grid>
                </Grid>

                {(this.modal.isOpen()) &&
                    <AddUpdateSupplier
                        isOpen={this.modal.isOpen()}
                        objSupplier={this.state.currentData}
                        onComplete={this.loadSuppliers.bind(this)}
                        toggle={this.modal.toggle}
                    />
                }
            </Container>
        );
    }
}

export default Suppliers;