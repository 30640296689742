import React from 'react'
import { Container, withTheme, Theme, Grid, Paper, Typography, Button } from '@material-ui/core'
import HyperModal from '../../../Components/HyperInput/HyperModal';
import api from '../../../Services/ApiServices';
import ReportsDataTable from '../../../Components/ReportsDataTable';
import scanRUtils from '../../../Services/ScanRUtilService';
import { JournalSearchOptions } from '../../../Types/SearchOptions';
import enums from '../../../enums';
import { Journal, ICompanySettings } from '../../../Types/AllTypes';
import utils from '../../../Services/Utils';
import HyperDateRangePicker from '../../../Components/HyperDateRangePicker';
import moment from "moment";
import HyperCompanySelection from '../../../Components/HyperInput/HyperCompanySelection';
import auth from '../../../Services/AuthService';


interface PaymentReportProps {
    theme: Theme
}

interface PaymentReportState {
    lstPayment: Journal[]
    totalPayment: number
    isLoading: boolean
    dateRange: any
}

class PaymentReport extends React.Component<PaymentReportProps, PaymentReportState> {
    constructor(props: PaymentReportProps) {
        super(props);
        let state: PaymentReportState = {
            lstPayment: [],
            totalPayment: 0,
            isLoading: false,
            dateRange: { startDate: moment().startOf("month").toDate(), endDate: moment().endOf("month").toDate(), label: "This Month" }

        };
        this.state = state;
        this.modal = new HyperModal(this);
        this.companySelection = new HyperCompanySelection(this);
    }
    modal: HyperModal;
    companySelection: HyperCompanySelection;

    componentDidMount() {
        this.getReportDetail()
    }
    getReportDetail = async () => {
        try {
            await this.setState({ isLoading: true })
            let so = new JournalSearchOptions();
            so.category = enums.JOURNAL_CATEGORY.PAYMENT;
            so.fromDate = moment(this.state.dateRange.startDate).format("YYYY-MM-DD")
            so.toDate = moment(this.state.dateRange.endDate).format("YYYY-MM-DD")
            so.companyIds = this.companySelection.getSelectedCompany().map((item: ICompanySettings) => item.id).join(",")
            // console.log(so, "so")
            let lstPayment = await api.getJournals(so);

            let totalAmount = 0;
            lstPayment.forEach((objPayment: any) => {
                totalAmount = totalAmount + objPayment.amount;
                objPayment.companyName = auth.getCompanyNameById(objPayment.companyId)

            })
            this.setState({ lstPayment, totalPayment: totalAmount })
        }
        catch (ex) {
            console.log(ex)
        }
        finally {
            await this.setState({ isLoading: false })
        }
    }

    render() {
        console.log(this.state.lstPayment)
        return (
            <Container>
                <Grid container style={{ marginBottom: 20 }}>
                    <Grid item md={6}>
                        {this.companySelection.renderCompanySelection()}
                    </Grid>
                    <Grid item md={6} style={{ textAlign: 'right', margin: 'auto' }}>
                        <Button onClick={this.getReportDetail} variant="contained" color="primary">Refresh</Button>{" "}
                        <HyperDateRangePicker
                            initialDateRange={this.state.dateRange}
                            onChange={async (range: any) => {
                                await this.setState({ dateRange: range });
                                this.getReportDetail()
                            }}
                        />
                    </Grid>
                </Grid>
                <ReportsDataTable
                    key={Date.now()}
                    title="Payment Report"
                    columns={[
                        { title: "ID", render: (rowData: Journal) => { return `#${rowData.id}`; }, sortField: "id" },
                        { title: "Payment To", field: "debitAccount.name" },
                        { title: "Paid Using", field: "creditAccount.name" },
                        {
                            title: "Payment Date", render: (rowData: Journal) => {
                                return utils.convertToSystemDateTimeFormate(new Date(rowData.date));
                            },
                            sortField: "date",
                            renderReturnsString: true
                        },
                        { title: "Amount", render: (rowData: Journal) => { return `${scanRUtils.prettyPrice(rowData.amount)}` } },
                        { title: "Comment", field: "comment" },
                        { title: "Company Name",hidden:auth.isSingleCompany(), field: 'companyName' },
                    ]}
                    data={this.state.lstPayment}
                    options={{
                        sorting: true,
                    }}
                    isLoading={this.state.isLoading}
                    onRowClick={() => { }}
                />
                <Paper elevation={3} style={{ marginTop: 20, paddingTop: 30, paddingBottom: 30, padding: 20 }}>
                    <Grid container>
                        <Grid item md={6} xs={6}>
                            <Typography variant="h6">Total Payment</Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Typography variant="h6" align="right" color="primary">{scanRUtils.prettyPrice(this.state.totalPayment)}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        );
    }
}

export default withTheme(PaymentReport);