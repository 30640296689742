import React from 'react'
import { Container, Grid, withTheme, Theme, Paper, Typography, IconButton, Button } from '@material-ui/core'
import AddUpdateBankAccounts from './AddUpdateBankAccounts';
import utils from '../../Services/Utils';
import NotificationSkeleton from '../../Components/Skeletons/NotificationSkeleton';
import { IBankAccount } from '../../Types/AllTypes';
import api from '../../Services/ApiServices';
import { AccountSearchOption } from '../../Types/SearchOptions';
import enums from '../../enums';
import { IconBankAccount, IconAdd } from '../../Components/Icons/Icons';
import HyperModal from '../../Components/HyperInput/HyperModal';
import { HyperTheme } from '../../Theme/Theme';
import BankAccountsPopover from './BankAccountsPopover';
interface BankAccountsProps {
    theme: Theme
}

interface BankAccountsState {
    shouldShowSkeleton: boolean,
    lstAccount: IBankAccount[],
    currentData?: IBankAccount
    name: any
}
const theme = HyperTheme;
class BankAccounts extends React.Component<BankAccountsProps, BankAccountsState> {
    constructor(props: BankAccountsProps) {
        super(props);
        let state: BankAccountsState = {
            shouldShowSkeleton: true,
            lstAccount: [],
            currentData: undefined,
            name: ""
        };

        this.state = state;
        this.addUpdateBankAccountsModal = new HyperModal(this);
    }
    addUpdateBankAccountsModal: HyperModal;
    componentDidMount() {
        this.loadBankAccounts();
    }

    async loadBankAccounts() {
        try {
            let SO = new AccountSearchOption();
            SO.category = enums.ACCOUNT_CATEGORY.CASH_BANK;
            let lstAccount = await api.getAccountsList(SO);
            this.setState({ lstAccount, shouldShowSkeleton: false })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }

    }

    setAsPrimary = async (objAccount: any) => {
        try {
            utils.showLoader();
            await api.setAsPrimaryBankAccount(objAccount.id);
            this.loadBankAccounts();
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }

    onEdit = (objAccount: any) => {
        this.addUpdateBankAccountsModal.toggle(true, { currentData: objAccount })
    }
    onBankDelete = async (objAccount: any) => {
        let confirmed = await utils.showConfirm("Do you really want to delete this?", "");
        if (confirmed) {

            try {
                utils.showLoader();
                let data = await api.deleteAccount(objAccount.id);
                if (data.isUsed)
                    return utils.showWarning("You can't delete this Account because it's already used.")
                this.loadBankAccounts();
            }
            catch (ex) {
                utils.showError(ex.msg || ex.message)
            }
            finally {
                utils.hideLoader();
            }
        }
    }

    render() {

        return (
            <Container>
                <div style={{ textAlign: 'end', marginBottom: 20 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => this.addUpdateBankAccountsModal.toggle(true, { currentData: undefined })}
                        startIcon={<IconAdd />}
                    >
                        New
                    </Button>
                </div>
                <Grid container>
                    <Grid item xs={12}>
                        {this.state.shouldShowSkeleton ? (
                            <NotificationSkeleton />
                        ) : (
                            <Grid container spacing={2}>
                                {this.state.lstAccount.map((objAccount: any, index: number) => {
                                    return <Grid item xs={12} md={4} key={index}>
                                        <Paper
                                            elevation={3}
                                            style={{
                                                padding: 10,
                                                paddingTop: 20,
                                                paddingBottom: 20,
                                                backgroundColor: objAccount?.info?.isPrimary ? theme.palette.primary.light : theme.palette.grey[200],
                                                color: objAccount?.info?.isPrimary ? theme.palette.common.white : theme.palette.common.black,
                                                position: 'relative'
                                            }}
                                        >
                                            <Grid container >
                                                <Grid item md={2} xs={2} style={{ textAlign: 'start', margin: 'auto' }}>
                                                    <IconButton style={{ color: objAccount?.info?.isPrimary ? theme.palette.common.white : theme.palette.common.black }}>
                                                        <IconBankAccount />
                                                    </IconButton>

                                                    <BankAccountsPopover
                                                        style={{ position: "absolute", top: -5, right: -5 }}
                                                        onDelete={() => this.onBankDelete(objAccount)}
                                                        onEdit={() => this.onEdit(objAccount)}
                                                        setAsPrimary={() => this.setAsPrimary(objAccount)}
                                                    />
                                                </Grid>
                                                <Grid item md={10} xs={10} >
                                                    <Typography noWrap style={{ width: '100%', fontSize: 20, fontWeight: 600 }}>
                                                        {objAccount.name}
                                                    </Typography>
                                                    {/* <Typography>{scanRUtils.prettyPrice(objAccount.info?.balance)}</Typography> */}
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                })
                                }
                            </Grid>
                        )
                        }
                    </Grid>
                </Grid>

                {!!(this.addUpdateBankAccountsModal.isOpen()) &&
                    <AddUpdateBankAccounts
                        onComplete={this.loadBankAccounts.bind(this)}
                        isOpen={this.addUpdateBankAccountsModal.isOpen()}
                        toggle={this.addUpdateBankAccountsModal.toggle}
                        objAccount={this.state.currentData}
                    />
                }

            </Container>
        );
    }
}

export default withTheme(BankAccounts);