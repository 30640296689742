import React from 'react'

import api from '../../Services/ApiServices';
import utils from '../../Services/Utils';
import HyperAutoCompleteField from '../../Components/HyperInput/HyperAutoCompleteField';
import { ITax } from '../../Types/AllTypes';

interface TaxAutoCompleteProps {
    selectedItem?: ITax
    onChange?: (e: ITax) => void
    required?: boolean
    frm?: any
    label?: string
    autoFocus?: boolean
    placeholder?: string
}

interface TaxAutoCompleteState {
    lstItem: ITax[]
    selectedItem?: ITax
}


class TaxAutoComplete extends React.Component<TaxAutoCompleteProps, TaxAutoCompleteState> {
    constructor(props: TaxAutoCompleteProps) {
        super(props);
        let state: TaxAutoCompleteState = {
            lstItem: [],
            selectedItem: {} as ITax
        };
        this.state = state;
    }

    isEditing: boolean = false;

    componentDidMount() {
        this.loadItem();
    }

    async loadItem() {
        try {
            let lstItem = await api.getTaxList_withWindowCache();
            lstItem.forEach((item: any) => {
                item.label = `${item.name}`;
            });
            // lstItem.unshift({})

            let selectedItem = [];
            if (this.props.selectedItem) {

                selectedItem = lstItem.find((item: ITax) => this.props.selectedItem?.id === item.id);
            }
            this.setState({ lstItem, selectedItem })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }

    }

    render() {
        return (
            <HyperAutoCompleteField
                options={this.state.lstItem}
                value={this.state.selectedItem}
                onChange={(e: any) => {
                    this.setState({ selectedItem: e })
                    console.log(e, "e")
                    if (this.props.onChange) {
                        this.props.onChange(e);
                    }
                }}
                // multiple
                label={this.props.label}
                required={this.props.required}
                frm={this.props.frm}
                autoFocus={this.props.autoFocus}
                placeholder={this.props.placeholder}
            />
        );
    }
}

export default TaxAutoComplete;