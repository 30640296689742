import React from 'react'
import { Container, Paper, Tabs, Tab, Grid, withStyles } from '@material-ui/core'
import HyperModal from '../../Components/HyperInput/HyperModal';
import Settings from './Settings';
import CompanySubscription from './CompanySubscription';
import CompanySMS from './CompanySMS';

const styles = () => ({
    root: {
        flexGrow: 1,
    },
})

interface CompanyProfileProps { }

interface CompanyProfileState {
    tabValue: number
}

class CompanyProfile extends React.Component<CompanyProfileProps, CompanyProfileState> {
    constructor(props: CompanyProfileProps) {
        super(props);
        let state: CompanyProfileState = {
            tabValue: 0
        };

        this.state = state;
        this.modal = new HyperModal(this);
    }
    modal: HyperModal;

    componentDidMount() {

    }

    onTabChange = (event: any, newValue: number) => {
        this.setState({ tabValue: newValue });
    }

    render() {
        return (
            <Container>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: "center", marginTop: 35 }}>
                        <Paper square elevation={3}>
                            <Tabs
                                value={this.state.tabValue}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={this.onTabChange}
                                variant="fullWidth"

                            >
                                <Tab label="Company Setting" />
                                {/* <Tab label="Invoice Setting" /> */}
                                <Tab label="Your Subscription" />
                                <Tab label="SMS" />
                            </Tabs>
                        </Paper>
                    </Grid>
                </Grid>
                {(this.state.tabValue === 0) &&
                    <Settings />
                }

                {/* {(this.state.tabValue === 1) &&
                    <InvoiceSetting />
                } */}

                {(this.state.tabValue === 1) &&
                    <CompanySubscription />
                }
                
                {(this.state.tabValue === 2) &&
                    <CompanySMS />
                }
            </Container>
        );
    }
}

export default withStyles(styles)(CompanyProfile);