import { Paper, List, ListItem, ListItemText, Typography, ListItemSecondaryAction, Tooltip, Button } from '@material-ui/core';
import React from 'react'
import { IWindowState, IPurchase } from '../../Types/AllTypes';
import { HyperTheme } from '../../Theme/Theme';
import withWindowState from '../../Components/withWindowState';
import utils from '../../Services/Utils';
import scanRUtils from '../../Services/ScanRUtilService';
import { Link } from 'react-router-dom';

interface DuePurchaseProps extends IWindowState {
    lstDuePurchase: IPurchase[]

}
interface DuePurchaseState {
    searchText: string
}

class DuePurchase extends React.Component<DuePurchaseProps, DuePurchaseState> {
    constructor(props: DuePurchaseProps) {
        super(props);
        let state: DuePurchaseState = {
            searchText: "",

        }
        this.state = state;
    }

    render() {
        return (
            <Paper elevation={3} style={{ marginTop: 25 }}>
                <List>
                    <ListItem divider style={{ backgroundColor: HyperTheme.palette.secondary.light, color: HyperTheme.palette.common.white }} >
                        <ListItemText
                            primary={<Typography>Due Supplier Purchase</Typography>}
                        />
                        <ListItemSecondaryAction>
                            <Tooltip title={`Show All`} placement="top">
                                <Link to={`/reports/billsPayable`}>
                                    <Button variant="text" style={{ color: HyperTheme.palette.common.white }}>Show All</Button>
                                </Link>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </ListItem>
                    {this.props.lstDuePurchase.map((objSales: IPurchase, index: number) => {
                        return (
                            <ListItem key={index} divider style={{ paddingTop: 0, paddingBottom: 0 }}>
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle2">
                                            {`${objSales.creditAccount.name} | ${utils.getInvoiceNumberWithPrefix(objSales.info.INVNumber)}`}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography variant="caption">
                                            Due Date: {utils.convertToSystemDateFormate(objSales.info.dueDate)} | Due Amount: <b>{scanRUtils.prettyPrice(objSales.info.totalDueAmount)}</b>
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        )
                    })}
                </List>
            </Paper>
        );
    }
}

export default withWindowState(DuePurchase, ["companySettings"]);