import React from 'react'
import { Container, withTheme, Theme, Grid, Button, Paper, Typography } from '@material-ui/core'
// import HyperModal from '../../../Components/HyperInput/HyperModal';
import api from '../../../Services/ApiServices';
import { DueCustomerSupplierInvoiceSearchOption } from '../../../Types/SearchOptions';
import enums from '../../../enums';
import { Journal, ICompanySettings } from '../../../Types/AllTypes';
import moment from "moment";
import utils from '../../../Services/Utils';
import scanRUtils from '../../../Services/ScanRUtilService';
import HyperDateRangePicker from '../../../Components/HyperDateRangePicker';
import ReportsDataTable from '../../../Components/ReportsDataTable';
import HyperCompanySelection from '../../../Components/HyperInput/HyperCompanySelection';
import auth from '../../../Services/AuthService';


interface BillPayableProps {
    theme: Theme
}

interface BillPayableState {
    lstTransaction: Journal[]
    totalUnpaid: number
    totalPartiallyUnpaid: number
    totalUnpaidInvoice: number
    totalPartiallyUnpaidInvoice: number
    isLoading: boolean
    dateRange: any
}


class BillPayable extends React.Component<BillPayableProps, BillPayableState> {
    constructor(props: BillPayableProps) {
        super(props);
        let state: BillPayableState = {
            lstTransaction: [],
            totalUnpaid: 0,
            totalPartiallyUnpaid: 0,
            totalUnpaidInvoice: 0,
            totalPartiallyUnpaidInvoice: 0,
            isLoading: false,
            dateRange: { startDate: moment(-1).toDate(), endDate: moment().toDate(), label: `${moment(-1).format("YYYY-MM-DD")} - ${moment().format("YYYY-MM-DD")}` }

        };
        this.state = state;
        this.companySelection = new HyperCompanySelection(this);
    }
    companySelection: HyperCompanySelection;

    componentDidMount() {
        this.getReportDetail()
    }
    getReportDetail = async () => {
        try {

            await this.setState({ isLoading: true })
            let so = new DueCustomerSupplierInvoiceSearchOption();

            so.lstCategory = [enums.JOURNAL_CATEGORY.PURCHASE]
            so.fromDate = moment(this.state.dateRange.startDate).format("YYYY-MM-DD")
            so.toDate = moment(this.state.dateRange.endDate).format("YYYY-MM-DD")
            so.companyIds = this.companySelection.getSelectedCompany().map((item: ICompanySettings) => item.id).join(",")
            let lstTransaction = await api.getDueSupplierAndCustomerRecords(so);

            let totalUnpaid = 0, totalPartiallyUnpaid = 0, totalUnpaidInvoice = 0, totalPartiallyUnpaidInvoice = 0;
            lstTransaction.forEach((objItem: any) => {
                if (objItem.info.paymentStatus === enums.PAYMENT_STATUS.PARTIALLY_PAID) {
                    totalPartiallyUnpaid += scanRUtils.toFixedFloat(objItem.info.totalDueAmount);
                    totalPartiallyUnpaidInvoice++;
                }
                else if (objItem.info.paymentStatus === enums.PAYMENT_STATUS.UNPAID) {
                    totalUnpaid += scanRUtils.toFixedFloat(objItem.info.totalDueAmount);
                    totalUnpaidInvoice++;
                }

                objItem.info.INVNumber = `NV-${objItem.info.INVNumber}`;
                objItem.date = utils.convertToSystemDateFormate(objItem.date);
                objItem.info.dueDate = utils.convertToSystemDateFormate(objItem.info.dueDate);
                objItem.amount = scanRUtils.prettyPrice(objItem.amount);
                objItem.info.totalDueAmount = scanRUtils.prettyPrice(objItem.info.totalDueAmount);
                objItem.paymentStatus = objItem.info.paymentStatus === enums.PAYMENT_STATUS.PARTIALLY_PAID ? 'Partially Paid' : 'Unpaid'
                objItem.companyName = auth.getCompanyNameById(objItem.companyId);
            })
            await this.setState({ lstTransaction, totalUnpaid, totalPartiallyUnpaid, totalPartiallyUnpaidInvoice, totalUnpaidInvoice });
        }
        catch (ex) {
            console.log(ex)
        }
        finally {
            await this.setState({ isLoading: false })
        }
    }

    render() {
        // console.log(this.state.lstTransaction)
        return (
            <Container>
                <Grid container spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item md={6}>
                        {this.companySelection.renderCompanySelection()}
                    </Grid>
                    <Grid item md={6} style={{ textAlign: 'right', margin: 'auto' }}>
                        <Button onClick={this.getReportDetail} variant="contained" color="primary">Refresh</Button>{" "}
                        <HyperDateRangePicker
                            initialDateRange={this.state.dateRange}
                            onChange={async (range: any) => {
                                await this.setState({ dateRange: range });
                                this.getReportDetail()
                            }}
                        />
                    </Grid>
                </Grid>

                <Paper elevation={3} style={{ marginTop: 20, paddingTop: 30, paddingBottom: 30, padding: 20 }}>
                    <table cellPadding="5" cellSpacing="5">
                        <thead>
                            <tr>
                                <th>
                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Total Unpaid Invoice </Typography>
                                </th>
                                <th>
                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Total Partially Paid Invoice </Typography>
                                </th>
                                <th>
                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Total Unpaid</Typography>
                                </th>
                                <th>
                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Total Partially Unpaid</Typography>
                                </th>
                                <th>
                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Total</Typography>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td align="center">
                                    <Typography variant="subtitle1" color="primary">{this.state.totalUnpaidInvoice}</Typography>
                                </td>
                                <td align="center">
                                    <Typography variant="subtitle1" color="primary">{this.state.totalPartiallyUnpaidInvoice}</Typography>
                                </td>
                                <td align="center">
                                    <Typography variant="subtitle1" color="primary">{scanRUtils.prettyPrice(this.state.totalUnpaid)}</Typography>
                                </td>
                                <td align="center">
                                    <Typography variant="subtitle1" color="primary">{scanRUtils.prettyPrice(this.state.totalPartiallyUnpaid)}</Typography>
                                </td>
                                <td align="center">
                                    <Typography variant="subtitle1" color="primary">{scanRUtils.prettyPrice(this.state.totalPartiallyUnpaid + this.state.totalUnpaid)}</Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Paper>

                <ReportsDataTable
                    key={Date.now()}
                    title={`Bills Payable`}
                    columns={[
                        { title: "INV", field: "info.INVNumber", emptyValue: () => "-" },
                        { title: "Supplier Name", field: "creditAccount.name", emptyValue: () => "-" },
                        { title: "Date", field: "date", emptyValue: () => '-' },
                        { title: "Due Date", field: "info.dueDate", emptyValue: () => '-' },
                        { title: "Amount", field: "amount", emptyValue: () => 0 },
                        { title: "Due Amount", field: "info.totalDueAmount", emptyValue: () => 0 },
                        { title: "Status", field: "paymentStatus", emptyValue: () => '-' },
                        { title: "Company Name",hidden:auth.isSingleCompany(), field: 'companyName' },
                    ]}
                    data={this.state.lstTransaction}
                    options={{ sorting: true }}
                    isLoading={this.state.isLoading}
                    onRowClick={() => { }}
                    style={{ marginTop: 20 }}
                />


            </Container>
        );
    }
}

export default withTheme(BillPayable);