import React from 'react'
import { Menu, MenuItem, withStyles, WithStyles, Typography, Theme, Zoom, ListItemSecondaryAction } from '@material-ui/core'
import { RouteComponentProps, withRouter } from 'react-router-dom'


const styles = (theme: Theme) => ({
    link: {
        textDecoration: 'none',
        color: theme.palette.common.white,
    },
    button: {
        borderRadius: 10,
    },
    menuPaper: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    },
})
interface SideMenuPopoverProps extends WithStyles, RouteComponentProps {
    lstSubMenu: any
    anchorEl: any
    handleClose: () => void
}

class SideMenuPopover extends React.Component<SideMenuPopoverProps> {
    onItemClick = (objSubMenu: any) => {
        this.props.history.push(objSubMenu.route);
    }
    render() {
        const { classes, anchorEl } = this.props
        // console.log(this.props, "cc")
        return (
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                classes={{ paper: classes.menuPaper }}
                open={!!anchorEl}
                onClose={this.props.handleClose}

            >
                <Zoom in={!!anchorEl}>
                    <div onMouseLeave={this.props.handleClose}>
                        {this.props.lstSubMenu.map((objSubMenu: any, index: number) => {
                            let isSelected = this.props.location.pathname.includes(objSubMenu.route);
                            return (<MenuItem key={index} onClick={() => this.onItemClick(objSubMenu)}
                                style={isSelected ? { background: "linear-gradient(90deg, transparent, rgba(256,256,256,0.5))", borderRadius: 5, } : {}}
                            >
                                <objSubMenu.icon />
                                <Typography style={{ paddingLeft: 15 }}>
                                    {objSubMenu.label}
                                </Typography>
                                {objSubMenu.shortcutKey &&
                                    <ListItemSecondaryAction>
                                        <Typography variant="caption">
                                            {objSubMenu.shortcutKey.toUpperCase()}
                                        </Typography>
                                    </ListItemSecondaryAction>
                                }
                            </MenuItem>)
                        })}
                    </div>
                </Zoom>
            </Menu>
        )
    }
}



export default withStyles(styles)(withRouter(SideMenuPopover))
