import http from "./HttpService";
import lsu from "./LocalStorageUtils";
import config from "../config";
import auth from "./AuthService";
import { StockBookSearchOption, StockSummarySearchOption, ItemSearchOption, JournalSearchOptions, AccountBookSearchOption, TaxSearchOption, AccountSearchOption, BalanceSheetSearchOptions, ProfitAndLossSearchOptions, DashboardSearchOptions, CategorySearchOption, SupplierWisePurchaseSearchOptions, ReportSearchOptions,  DueCustomerSupplierInvoiceSearchOption, StockSummaryByCategorySearchOption } from "../Types/SearchOptions";
import { ItemInsertObject, JournalInsertObject, TaxInsertObject, AccountInsertObject, CategoryInsertObject } from "../Types/InsertObjects";

if (!localStorage.pageLoadTime) {
    localStorage.pageLoadTime = 500;
}

const win = (window as any);

class ApiServices {
    withCache(apiFunction: any, key: string, expireDateOrExpireAfterInMinutes: any = null) {
        return async (...args: any[]) => {
            let userSpecificKey = `${auth.getCompanyId()}_${key}`;
            let data = lsu.lsGet(userSpecificKey);
            if (data) {
                return data;
            }
            data = await apiFunction(...args);
            lsu.lsSet(userSpecificKey, data, expireDateOrExpireAfterInMinutes);
            win[userSpecificKey] = data;
            return data;
        }
    }

    withWindowCache(apiFunction: any, key: string, expireDateOrExpireAfterInMinutes: any = null) {
        return async (...args: any[]) => {
            let userSpecificKey = `${auth.getCompanyId()}_${key}`;
            let data = win[userSpecificKey];
            if (data) {
                return data;
            }
            data = await apiFunction(...args);
            win[userSpecificKey] = data;
            return data;
        }
    }

    abortRequest(requestId: string) {
        const win = (window as any);
        if (win.requests[requestId] && win.requests[requestId].abort) {
            win.requests[requestId].abort();
            delete win.requests[requestId];
        }
    }

    apiCacheKey = {
        HapCustomFilterViewList: "HapCustomFilterViewList",
        HapTypeList: "GetHapTypeList",
        GlobalSystemUserXClientGroupList: 'GlobalSystemUserXClientGroupList',
        GetSubBrokerOrRMRegisteredCallNTradeNumberList: 'GetSubBrokerOrRMRegisteredCallNTradeNumberList'
    }

    windowCacheKey = {
        AccountList: "AccountList",
        ItemList: "ItemList",
        CategoryList: "CategoryList",
        TaxList: "TaxList",
    }

    clearCache(key: string) {
        let userSpecificKey = `${auth.getCompanyId()}_${(this.apiCacheKey as any)[key]}`;
        lsu.lsSet(userSpecificKey, null)
    }

    clearWindowCache(key: string) {
        let userSpecificKey = `${auth.getCompanyId()}_${(this.windowCacheKey as any)[key]}`;
        win[userSpecificKey] = undefined;
    }

    clearAllCache = () => {
        let apiCacheKeyList: { [key: string]: string } = this.apiCacheKey;
        Object.keys(apiCacheKeyList).forEach(key => {
            let userSpecificKey = `${auth.getCompanyId()}_${apiCacheKeyList[key]}`;
            lsu.lsSet(userSpecificKey, null);
        });

        Object.keys(this.windowCacheKey).forEach(key => {
            let userSpecificKey = `${auth.getCompanyId()}_${key}`;
            win[userSpecificKey] = undefined;
        });
    }



    async login(username: string, password: string): Promise<any> {
        let data = await http.post(`${config.API_URL}/api/public/login`, {
            username, password
        });
        return data.data;
    }


    async getUserData() {
        let url = `${config.API_URL}/api/user`;
        let data = await http.get(url, { Authorization: auth.getToken() });
        // console.log(data, "data")
        return data;
    }

    async updateUserData(user: any) {
        let url = `${config.API_URL}/api/user`;
        let data = await http.put(url, user, { Authorization: auth.getToken() });
        return data;
    }

    async updateCompanySettings(io: any) {
        delete io.__v
        let url = `${config.API_URL}/api/user/company`;
        let data = await http.put(url, io, { Authorization: auth.getToken() });
        return data;
    }


    async getAccountsList(so: AccountSearchOption) {

        let url = `${config.API_URL}/api/account?`;
        if (so) {
            so.sort = so.sort || "id";
            so.sortOrder = "DESC";
            url += so.getPaginationAndSortingQueryString();
            url += so.category ? `&category=${so.category}` : "";
        }

        let data = await http.get(url, { Authorization: auth.getToken() });
        return data.data;
    }

    getAccountsList_withWindowCache = this.withWindowCache(this.getAccountsList, this.windowCacheKey.AccountList);

    async getAccountBookById(so: AccountBookSearchOption) {

        let url = `${config.API_URL}/api/report/book/${so.accountId}?`;
        if (so) {
            so.sort =so.sort ||"date";
            so.sortOrder = "DESC";
            url += so.getPaginationAndSortingQueryString();
            url += so.fromDate ? `&fromDate=${so.fromDate}` : "";
            url += so.toDate ? `&toDate=${so.toDate}` : "";
        }
        // console.log(url)
        let data = await http.get(url, { Authorization: auth.getToken() });
        return data.data;
    }

    async changePassword(oldPassword: string, newPassword: string) {
        let data = await http.put(`${config.API_URL}/api/user/changePassword`, { oldPassword, newPassword }, { Authorization: auth.getToken() });
        this.clearWindowCache(this.windowCacheKey.AccountList);
        return data.data;
    }
    async addAccount(IO: AccountInsertObject) {
        let data = await http.post(`${config.API_URL}/api/account`, IO, { Authorization: auth.getToken() });
        this.clearWindowCache(this.windowCacheKey.AccountList);
        return data.data;
    }

    async updateAccount(UO: AccountInsertObject) {
        let data = await http.put(config.API_URL + '/api/account', UO, { Authorization: auth.getToken() });
        this.clearWindowCache(this.windowCacheKey.AccountList);
        return data.data;
    }

    async deleteAccount(id: number) {
        let data = await http.delete(config.API_URL + '/api/account', { id }, { Authorization: auth.getToken() });
        this.clearWindowCache(this.windowCacheKey.AccountList);
        return data.data;
    }

    async getItemList(so: ItemSearchOption) {
        let url = `${config.API_URL}/api/item?`;
        if (so)
            url += so.getPaginationAndSortingQueryString();

        let data = await http.get(url,
            { Authorization: auth.getToken() });
        return data.data;
    }

    getItemList_withWindowCache = this.withWindowCache(this.getItemList, this.windowCacheKey.ItemList);

    async getCategoryList(so: CategorySearchOption) {
        let url = `${config.API_URL}/api/category?`;
        if (so)
            url += so.getPaginationAndSortingQueryString();

        let data = await http.get(url,
            { Authorization: auth.getToken() });
        return data.data;
    }

    getCategoryList_withWindowCache = this.withWindowCache(this.getCategoryList, this.windowCacheKey.CategoryList);

    async addCategory(io: CategoryInsertObject) {
        let data = await http.post(`${config.API_URL}/api/category`, io, { Authorization: auth.getToken() });
        this.clearWindowCache(this.windowCacheKey.CategoryList);
        return data.data;
    }

    async updateCategory(io: CategoryInsertObject) {
        let data = await http.put(config.API_URL + '/api/category', io, { Authorization: auth.getToken() });
        this.clearWindowCache(this.windowCacheKey.CategoryList);
        return data.data;
    }

    async addItem(io: ItemInsertObject) {
        let data = await http.post(`${config.API_URL}/api/item`, io, { Authorization: auth.getToken() });
        this.clearWindowCache(this.windowCacheKey.ItemList);
        return data.data;
    }

    async updateItem(io: ItemInsertObject) {
        let data = await http.put(config.API_URL + '/api/item', io, { Authorization: auth.getToken() });
        this.clearWindowCache(this.windowCacheKey.ItemList);
        return data.data;
    }

    async deleteItem(id: number) {
        let data = await http.delete(config.API_URL + '/api/item', { id }, { Authorization: auth.getToken() });
        this.clearWindowCache(this.windowCacheKey.ItemList);
        return data.data;
    }

    async getTaxList(so: TaxSearchOption) {
        let url = `${config.API_URL}/api/tax?`;
        if (so)
            url += so.getPaginationAndSortingQueryString();

        let data = await http.get(url,
            { Authorization: auth.getToken() });
        return data.data;
    }

    getTaxList_withWindowCache = this.withWindowCache(this.getTaxList, this.windowCacheKey.TaxList);

    async addTax(io: TaxInsertObject) {
        let data = await http.post(`${config.API_URL}/api/tax`, io, { Authorization: auth.getToken() });
        this.clearWindowCache(this.windowCacheKey.TaxList);
        return data.data;
    }

    async updateTax(io: TaxInsertObject) {
        let data = await http.put(config.API_URL + '/api/tax', io, { Authorization: auth.getToken() });
        this.clearWindowCache(this.windowCacheKey.TaxList);
        return data.data;
    }

    async deleteTax(id: number) {
        let data = await http.delete(config.API_URL + '/api/tax', { id }, { Authorization: auth.getToken() });
        this.clearWindowCache(this.windowCacheKey.ItemList);
        return data.data;
    }

    async addJournal(io: JournalInsertObject) {
        let data = await http.post(config.API_URL + '/api/journal', io, { Authorization: auth.getToken() });
        return data.data;
    }

    async updateJournal(io: JournalInsertObject) {
        let data = await http.put(config.API_URL + '/api/journal', io, { Authorization: auth.getToken() });
        return data.data;
    }

    async getJournals(so: JournalSearchOptions) {
        let url = `${config.API_URL}/api/journal?`;
        if (so) {
            so.sort = so.sort || "date";
            so.sortOrder = "DESC";
            url += so.getPaginationAndSortingQueryString();
            url += so.category ? `&category=${so.category}` : "";
            url += so.info ? `&info=${JSON.stringify(so.info)}` : "";
            url += so.debitAccountId ? `&debitAccountId=${JSON.stringify(so.debitAccountId)}` : "";
            url += so.creditAccountId ? `&creditAccountId=${JSON.stringify(so.creditAccountId)}` : "";
            url += so.fromDate ? `&fromDate=${so.fromDate}` : "";
            url += so.toDate ? `&toDate=${so.toDate}` : "";
            url += so.companyIds ? `&companyIds=${so.companyIds}` : "";
        }
        let data = await http.get(url, { Authorization: auth.getToken() });
        return data.data;

    }

    async deleteJournal(id: number) {
        let data = await http.delete(config.API_URL + '/api/journal', { id }, { Authorization: auth.getToken() });
        return data.data;
    }
    async deleteJournal2(so: JournalSearchOptions) {

        let data = await http.delete(config.API_URL + '/api/journal', so, { Authorization: auth.getToken() });
        return data.data;
    }

    async getBalanceSheet(so: BalanceSheetSearchOptions) {
        let url = `${config.API_URL}/api/report/bs?fromDate=${so.fromDate}&toDate=${so.toDate}`;
        let data = await http.get(url, { Authorization: auth.getToken() });
        return data.data;
    }

    async getProfitLoss(so: ProfitAndLossSearchOptions) {
        let url = `${config.API_URL}/api/report/pnl?fromDate=${so.fromDate}&toDate=${so.toDate}`;
        let data = await http.get(url, { Authorization: auth.getToken() });
        return data.data;
    }

    async getDashboard1(so: DashboardSearchOptions) {
        let url = `${config.API_URL}/api/report/dashboard1?fromDate=${so.fromDate}&toDate=${so.toDate}`;
        let data = await http.get(url, { Authorization: auth.getToken() });
        return data.data;
    }

    async getProfitLossGraphData() {
        let url = `${config.API_URL}/api/report/getProfitLossGraphData`;
        let data = await http.get(url, { Authorization: auth.getToken() });
        return data.data;
    }

    async uploadFile(fileDocumentType: string, fileName: string, extension: string = "png", base64: string) {
        return http.post(config.API_URL + "/api/user/uploadfile", { fileDocumentType, fileName, extension, base64 }, {
            Authorization: auth.getToken()
        });
    }

    async uploadFile2(fileDocumentType: string, fileName: string, extension: string = "png", base64: string) {
        return http.post(config.API_URL + "/api/user/uploadfile2", { fileDocumentType, fileName, extension, base64 }, {
            Authorization: auth.getToken()
        });
    }

    async getItemFromSerialNo(serialNo: string, category: string) {
        let data = await http.get(`${config.API_URL}/api/journal/getItemFromSerialNo?serialNo=${serialNo}&category=${category}`, {
            Authorization: auth.getToken()
        });
        return data.data;
    }
    async getStockSummary(so: StockSummarySearchOption) {
        let url = `${config.API_URL}/api/report/stocksummary?`;
        if (so) {
            so.sort = "date";
            so.sortOrder = "DESC";
            url += so.getPaginationAndSortingQueryString();
            url += so.companyIds ? `&companyIds=${so.companyIds}` : '';
        }

        let data = await http.get(url, {
            Authorization: auth.getToken()
        });
        return data.data;
    }
    async getStockSummaryByCategory(so: StockSummaryByCategorySearchOption) {
        let url = `${config.API_URL}/api/report/getCategoryWiseStock?`;
        if (so) {
            so.sort = "date";
            so.sortOrder = "DESC";
            url += so.getPaginationAndSortingQueryString();
        }

        let data = await http.get(url, {
            Authorization: auth.getToken()
        });
        return data.data;
    }

    async getStockBook(so: StockBookSearchOption) {
        let url = `${config.API_URL}/api/report/stockBook?`;
        if (so) {
            so.sort = "date";
            so.sortOrder = "DESC";
            url += so.getPaginationAndSortingQueryString();
            url += so.itemId ? `&itemId=${so.itemId}` : '';
        }

        let data = await http.get(url, {
            Authorization: auth.getToken()
        });
        return data.data;
    }
    async getSupplierWisePurchase(so: SupplierWisePurchaseSearchOptions) {
        let url = `${config.API_URL}/api/report/supplierWisePurchase?`;
        if (so) {
            so.sort = "date";
            so.sortOrder = "DESC";
            url += so.getPaginationAndSortingQueryString();
            url += so.fromDate ? `&fromDate=${so.fromDate}` : "";
            url += so.toDate ? `&toDate=${so.toDate}` : "";
            url += so.companyIds ? `&companyIds=${so.companyIds}` : '';
        }

        // console.log(so)

        let data = await http.get(url, {
            Authorization: auth.getToken()
        });
        return data.data;
    }
    async getCategoryWiseSales(so: ReportSearchOptions) {
        let url = `${config.API_URL}/api/report/salesByCategory?`;
        
        if (so) {
            so.sort = "date";
            so.sortOrder = "DESC";
            url += so.getPaginationAndSortingQueryString();
            url += so.fromDate ? `&fromDate=${so.fromDate}` : "";
            url += so.toDate ? `&toDate=${so.toDate}` : "";
            url += so.companyIds ? `&companyIds=${so.companyIds}` : '';
        }

        let data = await http.get(url, {
            Authorization: auth.getToken()
        });
        return data.data;
    }
    async categoryWisePL(so: ReportSearchOptions) {
        let url = `${config.API_URL}/api/report/categoryWisePL?`;
        
        if (so) {
            so.sort = "date";
            so.sortOrder = "DESC";
            url += so.getPaginationAndSortingQueryString();
            url += so.fromDate ? `&fromDate=${so.fromDate}` : "";
            url += so.toDate ? `&toDate=${so.toDate}` : "";
            url += so.companyIds ? `&companyIds=${so.companyIds}` : '';
        }
        let data = await http.get(url, {
            Authorization: auth.getToken()
        });
        return data.data;
    }

    async getDueSupplierAndCustomerRecords(so: DueCustomerSupplierInvoiceSearchOption) {
        let url = `${config.API_URL}/api/report/getDueSupplierAndCustomerRecords?`;
        if (so) {
            so.sortOrder = "DESC";
            url += so.getPaginationAndSortingQueryString();
            url += so.lstCategory ? `&lstCategory=${JSON.stringify(so.lstCategory)}` : '';
            url += so.companyIds? `&companyIds=${so.companyIds}` : '';
        }
        let data = await http.get(url, {
            Authorization: auth.getToken()
        });
        return data.data;
    }

    async getCategoryWisePurchase(so: ReportSearchOptions) {

        let url = `${config.API_URL}/api/report/purchaseByCategory?`;
        
        if (so) {
            so.sort = "date";
            so.sortOrder = "DESC";
            url += so.getPaginationAndSortingQueryString();
            url += so.fromDate ? `&fromDate=${so.fromDate}` : "";
            url += so.toDate ? `&toDate=${so.toDate}` : "";
            url += so.companyIds ? `&companyIds=${so.companyIds}` : '';
        }
        let data = await http.get(url, {
            Authorization: auth.getToken()
        });
        return data.data;
    }
    async getAccountBalance(accountId: number, toDate?: string) {
        let url = `${config.API_URL}/api/report/accountbalance?accountId=${accountId}`;
        if (toDate)
            url += `&toDate=${toDate}`;
        let data = await http.get(url, {
            Authorization: auth.getToken()
        });
        return data.data;
    }

    async journalMultiAction(actionList: any) {
        return http.post(config.API_URL + "/api/journal/multiaction", actionList, {
            Authorization: auth.getToken()
        });
    }
    async addUpdateSales(sales: JournalInsertObject, lstPayment: any) {
        return http.post(config.API_URL + "/api/journal/addUpdateSales", { sales, lstPayment }, {
            Authorization: auth.getToken()
        });
    }
    async addUpdatePurchase(purchase: JournalInsertObject, lstPayment: any) {
        return http.post(config.API_URL + "/api/journal/addUpdatePurchase", { purchase, lstPayment }, {
            Authorization: auth.getToken()
        });
    }
    async addUpdateSalesReturn(salesReturn: JournalInsertObject, lstPayment: any) {
        return http.post(config.API_URL + "/api/journal/addUpdateSalesReturn", { salesReturn, lstPayment }, {
            Authorization: auth.getToken()
        });
    }
    async addUpdatePurchaseReturn(purchaseReturn: JournalInsertObject, lstPayment: any) {
        return http.post(config.API_URL + "/api/journal/addUpdatePurchaseReturn", { purchaseReturn, lstPayment }, {
            Authorization: auth.getToken()
        });
    }
    async sendPromotionMsg(msgList: any) {
        return http.post(config.API_URL + "/api/user/sendPromotion", { msgList }, {
            Authorization: auth.getToken()
        });
    }
    async sendPromotionSMSWithSBP(lstNumber: any = [], msg: string) {
        return http.post(config.API_URL + "/api/user/sendPromotionSMSWithSBP", { lstNumber, msg }, {
            Authorization: auth.getToken()
        });
    }

    async getInvoiceData(salesMongoId: string) {
        let data = await http.get(`${config.API_URL}/api/public/getInvoiceData?salesMongoId=${salesMongoId}`, {
            Authorization: auth.getToken()
        });
        return data.data;
    }
    async getSaleByINV(INVNumber: string, category: string) {
        let data = await http.get(`${config.API_URL}/api/journal/getSaleByINV?INVNumber=${INVNumber}&category=${category}`, {
            Authorization: auth.getToken()
        });
        return data.data;
    }
    async getPurchaseByINVAndId(INVNumber: string, supplierId: number, category: string) {
        let data = await http.get(`${config.API_URL}/api/journal/getPurchaseByINVAndId?INVNumber=${INVNumber}&supplierId=${supplierId}&category=${category}`, {
            Authorization: auth.getToken()
        });
        return data.data;
    }
    async isPurchaseINVValid(INVNumber: string, category: string, creditAccountId: number) {
        let data = await http.get(`${config.API_URL}/api/journal/isPurchaseINVValid?INVNumber=${INVNumber}&category=${category}&creditAccountId=${creditAccountId}`, {
            Authorization: auth.getToken()
        });
        return data.data;
    }

    async sendSalesSMS(salesId: number) {
        let data = await http.post(`${config.API_URL}/api/journal/sendSalesSMS`, { salesId }, {
            Authorization: auth.getToken()
        });
        return data.data;
    }

    async sendPaymentRemainderSMS(INVNumber: string, dueDate: string, dueAmount: any, customerName: string, mobile: string) {
        let data = await http.post(`${config.API_URL}/api/journal/sendPaymentRemainderSMS`, { dueAmount, customerName, dueDate, INVNumber, mobile }, {
            Authorization: auth.getToken()
        });
        return data.data;
    }
    async setAsPrimaryBankAccount(bankAccountId: number) {
        let data = await http.put(`${config.API_URL}/api/account/setAsPrimaryBankAccount`, { bankAccountId }, {
            Authorization: auth.getToken()
        });
        return data.data;
    }

    async getB2BSales() {
        let data = await http.get(`${config.API_URL}/api/gst/b2bSales`, {
            Authorization: auth.getToken()
        });
        return data.data;
    }
    async checkUsername(username: string) {
        let data = await http.get(`${config.API_URL}/api/public/checkUsername?username=${username}`, {
            Authorization: auth.getToken()
        });
        return data.data;
    }

    async savePassword(username: string, newPassword: string) {
        let data = await http.post(`${config.API_URL}/api/public/savePassword`, { username, newPassword }, {
            Authorization: auth.getToken()
        });
        return data.data;
    }

    async getGSTInfo(gstNo: string) {
        let data = await http.get(`${config.API_URL}/api/public/getGSTInfo?gstNo=${gstNo}`, {
            Authorization: auth.getToken()
        });
        return data.data;
    }

    async getGSTR1(so: JournalSearchOptions) {
        let url = `${config.API_URL}/api/gst/gstR1?`;
        if (so) {
            so.sort = "date";
            so.sortOrder = "DESC";
            url += so.getPaginationAndSortingQueryString();
            url += so.category ? `&category=${so.category}` : "";
            url += so.info ? `&info=${JSON.stringify(so.info)}` : "";
            url += so.debitAccountId ? `&debitAccountId=${JSON.stringify(so.debitAccountId)}` : "";
            url += so.creditAccountId ? `&creditAccountId=${JSON.stringify(so.creditAccountId)}` : "";
            url += so.fromDate ? `&fromDate=${so.fromDate}` : "";
            url += so.toDate ? `&toDate=${so.toDate}` : "";
        }
        if (so.categories && so.categories.length > 0) {
            url += so.categories ? `&categories=${JSON.stringify(so.categories)}` : "";
        }

        let data = await http.get(url, {
            Authorization: auth.getToken()
        });
        return data.data;
    }
    async getR3B(so: JournalSearchOptions) {
        let url = `${config.API_URL}/api/gst/r3b?`;
        if (so) {
            so.sort = "date";
            so.sortOrder = "DESC";
            url += so.getPaginationAndSortingQueryString();
            url += so.fromDate ? `&fromDate=${so.fromDate}` : "";
            url += so.toDate ? `&toDate=${so.toDate}` : "";
        }

        let data = await http.get(url, {
            Authorization: auth.getToken()
        });
        return data.data;
    }

    async getLstSalesInvoiceNumber() {
        const objCompany = auth.getCompanySettings()
        if (!objCompany)
            return null
        let data = await http.get(`${config.API_URL}/api/company/nextSalesInvoiceNumber?companyId=${objCompany.id}`, {
            Authorization: auth.getToken()
        });
        return data.data;
    }

}
const api = new ApiServices();
(window as any)["api"] = api;
export default api;