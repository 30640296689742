import React, { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable, { MTableToolbar } from 'material-table';
import SaveIcon from '@material-ui/icons/Save';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { IconInvoice } from './Icons/Icons';
import { withTheme } from '@material-ui/core';
import HyperSelectField from './HyperInput/HyperSelectField';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    SaveIcon: forwardRef((props, ref) => <SaveIcon {...props} ref={ref} />),
    ViewIcon: forwardRef((props, ref) => <VisibilityIcon {...props} ref={ref} />),
    InvoiceIcon: forwardRef((props, ref) => <IconInvoice {...props} ref={ref} />),

};

class DataTable extends React.Component {
    // constructor(props) {
    //     super(props);

    // }

    getPValue(obj, dottedNotation) {
        try {
            return dottedNotation.split('.').reduce((o, i) => o[i], obj)
        }
        catch (ex) {
            console.log(ex, obj);
            return "";
        }
    }

    render() {

        this.props.columns.forEach(element => {
            if (element.sortField) {
                element.customSort = (a, b) => {
                    let aval = this.getPValue(a, element.sortField);
                    let bval = this.getPValue(b, element.sortField);
                    if (aval < bval)
                        return -1;
                    if (aval > bval)
                        return 1;
                }
                element.customFilterAndSearch = (term, rowData) => {
                    let val = "";
                    if (element.renderReturnsString) {
                        val = element.render(rowData);
                        // console.log(val, "val")
                        if (typeof val !== "string")
                            val = "";
                    }
                    else
                        val = this.getPValue(rowData, element.sortField);
                    // console.log(val, "val")
                    return val ? val.toString().toLowerCase().includes(term.toString().toLowerCase()) : false;
                }
            }
        });

        const { theme } = this.props;
        let actions = []
        let components = {}
        if (this.props.onEdit)
            actions.push({
                icon: tableIcons.Edit,
                tooltip: 'Edit',
                onClick: (event, rowData) => this.props.onEdit(rowData)
            })
        if (this.props.onDelete)
            actions.push({
                icon: tableIcons.Delete,
                tooltip: 'Delete',
                onClick: (event, rowData) => this.props.onDelete(rowData)
            })
        if (this.props.onView)
            actions.push({
                icon: tableIcons.ViewIcon,
                tooltip: 'View Details',
                onClick: (event, rowData) => this.props.onView(rowData)
            })
        if (this.props.onAdd)
            actions.push(rowData => {
                if (rowData.parentId)
                    return null
                return {
                    icon: tableIcons.Add,
                    tooltip: 'Add Sub Category',
                    onClick: (event, rowData) => this.props.onAdd(rowData)
                }
            })
        if (this.props.onInvoice)
            actions.push({
                icon: tableIcons.InvoiceIcon,
                tooltip: 'View Invoice',
                onClick: (event, rowData) => this.props.onInvoice(rowData)
            })
        if (this.props.customFilterOption) {
            components.Toolbar = props => (
                <div>
                    <MTableToolbar {...props} />
                    <div style={{ padding: '0px 10px' }}>
                        <HyperSelectField
                            options={this.props.customFilterOption}
                            optionIdField={this.props.customFilterFieldKey}
                            optionNameField={this.props.customFilterFieldName}
                            selectedValue={this.props.customFilterValue}
                            label={this.props.customFilterLabel}
                            onChange={(event) => { this.props.onSelectedFilterValue(event.target.value) }}
                        />
                    </div>
                </div>
            )
        }

        // console.log(this.props, "this.pros")
        return (
            <MaterialTable
                icons={tableIcons}
                {...this.props}
                actions={actions}
                options={{
                    pageSize: 10,
                    rowStyle: {
                    },
                    pageSizeOptions: [5, 10, 20, 30, 40, 50],
                    headerStyle: {
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.common.white
                    },
                    // actionsCellStyle: {
                    //     color: theme.palette.common.white
                    // },
                    // filterRowStyle: {
                    //     color: theme.palette.common.white
                    // },
                    // filterCellStyle: {
                    //     color: theme.palette.common.white
                    // },

                    // searchFieldStyle: {
                    //     color: theme.palette.common.white
                    // },

                    actionsColumnIndex: 0,
                    ...this.props.options
                }}
                components={components}
            // columns={{
            //     // customFilterAndSearch: (data) => {
            //     //     console.log(data, "data")
            //     // }
            // }}
            />
        )
    }
}

export default withTheme(DataTable)