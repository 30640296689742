import React from 'react'
import { Typography, Container, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, withTheme, CardHeader, Card, Theme, Button } from '@material-ui/core'
import utils from '../../Services/Utils';
import NotificationSkeleton from '../../Components/Skeletons/NotificationSkeleton';
import { ProfitAndLossSearchOptions } from '../../Types/SearchOptions';
import api from '../../Services/ApiServices';
import HyperModal from '../../Components/HyperInput/HyperModal';
import scanRUtils from '../../Services/ScanRUtilService';
import HyperDateRangePicker from '../../Components/HyperDateRangePicker';
import moment from "moment";
import AccountText from '../Accounts/AccountText';

interface ProfitAndLossProps {
    theme: Theme
}

interface ProfitAndLossState {
    shouldShowSkeleton: boolean,
    lstProfitAndLoss: any,
    dateRange: any
}

class ProfitAndLoss extends React.Component<ProfitAndLossProps, ProfitAndLossState> {
    constructor(props: ProfitAndLossProps) {
        super(props);
        let state: ProfitAndLossState = {
            shouldShowSkeleton: true,
            lstProfitAndLoss: [],
            dateRange: { startDate: moment().startOf("month").toDate(), endDate: moment().endOf("month").toDate(), label: "This Month" }
        };

        this.state = state;
        this.modal = new HyperModal(this);
        this.loadProfitAndLoss = this.loadProfitAndLoss.bind(this)
    }
    modal: HyperModal;

    componentDidMount() {
        this.loadProfitAndLoss();
    }

    async loadProfitAndLoss() {
        try {
            this.setState({ shouldShowSkeleton: true })
            let so = new ProfitAndLossSearchOptions();
            so.fromDate = moment(this.state.dateRange.startDate).format("YYYY-MM-DD")
            so.toDate = moment(this.state.dateRange.endDate).format("YYYY-MM-DD")
            let lstProfitAndLoss = await api.getProfitLoss(so);
            this.setState({ lstProfitAndLoss, shouldShowSkeleton: false })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }

    }


    render() {
        let { theme } = this.props;
        let debitAccounts = this.state.lstProfitAndLoss?.debitAccounts;
        let creditAccounts = this.state.lstProfitAndLoss?.creditAccounts;
        console.log(debitAccounts, creditAccounts, this.state)
        return (
            <Container>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Button onClick={this.loadProfitAndLoss} variant="contained" color="primary">Refresh</Button>{" "}
                        <HyperDateRangePicker
                            initialDateRange={this.state.dateRange}
                            onChange={async (range: any) => {
                                await this.setState({ dateRange: range });
                                this.loadProfitAndLoss();
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container >
                    {this.state.shouldShowSkeleton ? (
                        <NotificationSkeleton />
                    ) :
                        (
                            <Grid container style={{ marginTop: 10 }} spacing={1}>
                                <Grid item xs={12}>
                                    <Card elevation={3}>
                                        <CardHeader
                                            style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.common.white }}
                                            title={
                                                <Grid container direction="row" >
                                                    <Grid item xs={6}>
                                                        <Typography variant={"h6"}>Account Name</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                                                        <Typography variant={"h6"}>Amount</Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />

                                        <List>
                                            {creditAccounts &&

                                                creditAccounts.map((objAccount: any, index: number) => {
                                                    return (
                                                        <ListItem style={{ borderBottom: "solid 1px #e3e3e3" }} key={index}>
                                                            <ListItemText primary={<AccountText objAccount={objAccount} />} />
                                                            <ListItemSecondaryAction style={{  color: "green" }} >{scanRUtils.prettyPrice(objAccount.creditBalance)}</ListItemSecondaryAction>
                                                        </ListItem>
                                                    )
                                                })}
                                            <ListItem style={{ borderBottom: "solid 1px #e3e3e3", marginRight: "10px" }} >
                                                <ListItemText primary={<Typography variant="h6">Total Credit</Typography>} />
                                                <ListItemSecondaryAction  >
                                                    <Typography variant="h6">
                                                        {scanRUtils.prettyPrice(this.state.lstProfitAndLoss.totalCreditAmount)}
                                                    </Typography>
                                                </ListItemSecondaryAction>
                                            </ListItem>

                                            {debitAccounts && debitAccounts.map((objAccount: any, index: number) => {
                                                return (
                                                    <ListItem style={{ borderBottom: "solid 1px #e3e3e3", marginRight: "10px" }} key={index} >
                                                        <ListItemText primary={<AccountText objAccount={objAccount} />} />
                                                        <ListItemSecondaryAction style={{color: "#c73620" }} >{scanRUtils.prettyPrice(objAccount.debitBalance)}</ListItemSecondaryAction>
                                                    </ListItem>
                                                )
                                            })}
                                            <ListItem style={{ borderBottom: "solid 1px #e3e3e3", marginRight: "10px" }} >
                                                <ListItemText primary={<Typography variant="h6">Total Debit</Typography>} />
                                                <ListItemSecondaryAction >
                                                    <Typography variant="h6">
                                                        - {scanRUtils.prettyPrice(this.state.lstProfitAndLoss.totalDebitAmount)}
                                                    </Typography>
                                                </ListItemSecondaryAction>
                                            </ListItem>

                                            {this.state.lstProfitAndLoss.profit ?
                                                <ListItem style={{ borderBottom: "solid 1px #e3e3e3", backgroundColor: theme.palette.primary.main, color: theme.palette.common.white, marginRight: "10px" }} >
                                                    <ListItemText primary={<Typography variant="h6" >
                                                        <b>{"Net Profit"}</b>
                                                    </Typography>} />
                                                    <ListItemSecondaryAction  >
                                                        <Typography variant="h6" style={{ color: 'white' }}>
                                                            <b> {scanRUtils.prettyPrice(this.state.lstProfitAndLoss.profit)}</b>
                                                        </Typography>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                :
                                                <ListItem style={{ borderBottom: "solid 1px #e3e3e3", backgroundColor: theme.palette.error.main, color: theme.palette.common.white, marginRight: "10px" }} >
                                                    <ListItemText primary={<Typography variant="h6">
                                                        <b>{"Net Loss"}</b>
                                                    </Typography>} />
                                                    <ListItemSecondaryAction >
                                                        <Typography variant="h6" style={{ color: 'white' }}>
                                                            <b> {scanRUtils.prettyPrice(this.state.lstProfitAndLoss.loss)}</b>
                                                        </Typography>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            }
                                        </List>
                                    </Card>

                                </Grid>
                            </Grid>
                        )
                    }
                </Grid>
            </Container>
        );
    }
}

export default withTheme(ProfitAndLoss);