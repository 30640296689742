import React from 'react'
import { Button, TableContainer, Table, TableBody, TableRow, TableCell, Paper } from '@material-ui/core';
import { IItem, ISalesItem, IDiscount } from '../../Types/AllTypes';
import HyperForm from '../../Components/HyperInput/HyperForm';
import utils from '../../Services/Utils';
import scanRUtils from '../../Services/ScanRUtilService';
import enums from '../../enums';
import api from '../../Services/ApiServices';
import PurchaseReturnItem from './PurchaseReturnItem';

interface PurchaseReturnItemsProps {
    frm: HyperForm
    lstPurchaseItem: ISalesItem[]
    objDiscount: IDiscount
    taxInvoiceType?: string

    onChange?: () => void
}

interface PurchaseReturnItemsState {
    scanningBarcode: boolean
}

const objDefaultSalesItem = {
    objItem: {},
    qty: 1,
    price: 0,
    priceAfterDiscount: 0,
    discountAmountPerItem: 0,
    totalDiscountPerItem: 0,
    lstSerialNo: ([] as string[])
} as ISalesItem;

class PurchaseReturnItems extends React.Component<PurchaseReturnItemsProps, PurchaseReturnItemsState> {
    constructor(props: PurchaseReturnItemsProps) {
        super(props);
        this.state = {
            scanningBarcode: false,
        }
    }
    currentFocusedItem: any = {};
    barcodeInput: React.RefObject<HTMLInputElement> = React.createRef()

    componentDidMount() {
        document.addEventListener(enums.EVENT.BARCODE_SCANNED, this.onBarcodeScanned)
    }

    componentWillUnmount() {
        document.removeEventListener(enums.EVENT.BARCODE_SCANNED, this.onBarcodeScanned)
    }

    onBarcodeScanned = async (event: any) => {
        if (!this.state.scanningBarcode) {
            return;
        }
        let data = event.detail?.data;
        await this.setState({ scanningBarcode: false })
        if (!data) {
            utils.showWarning("Barcode reading failed. ")
            return;
        }
        // Identify item from barcode. Need to search journal record of purchase. 
        try {
            utils.showLoader();
            let result: IItem = await api.getItemFromSerialNo(data.data, enums.JOURNAL_CATEGORY.SALES);
            // console.log(result, objDefaultSalesItem)
            let item = { ...objDefaultSalesItem };
            item.id = result.id;
            item.objItem = result;
            item.price = result.defaultSalesPrice;
            item.qty = 1;
            // PTH_NIK
            item.serialNo = data.data;
            item.taxes = result.defaultTaxes;

            if (this.props.lstPurchaseItem[0].price === 0) {
                this.props.lstPurchaseItem[0] = item;
            }
            else {
                let isFound: boolean = false;
                this.props.lstPurchaseItem.forEach((sItem: any) => {
                    if ((sItem.objItem?.id === item.id || sItem.id === item.id) && !isFound) {
                        // PTH_NIK
                        sItem.lstSerialNo.push(item.serialNo);
                        sItem.qty = sItem.lstSerialNo.length;
                        isFound = true;
                    }
                })
                if (!isFound)
                    this.props.lstPurchaseItem.push(item);
            }
            this.forceUpdate();
        }
        catch (ex) {
            utils.showWarning(ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }

    addItem = () => {
        this.props.lstPurchaseItem.push(objDefaultSalesItem)
        this.forceUpdate();
    }

    removeItem = (index: number) => {
        if (this.props.lstPurchaseItem.length <= 1) {
            utils.showWarning("You must have at least one item. ")
            return;
        }

        this.props.lstPurchaseItem.splice(index, 1);
        this.forceUpdate();
    }

    scanBarcode = async () => {
        await this.setState({ scanningBarcode: true });
        if (this.barcodeInput.current) {
            this.barcodeInput.current.focus();
        }
    }

    onBarcodeInputChange = (event: any) => {
        this.onBarcodeScanned({ detail: { data: { data: event.target.value, type: "BAR" } } })

    }

    render() {
        let objTotalCount = scanRUtils.getTotalCounts(this.props.lstPurchaseItem)

        return (
            <div>
                {this.state.scanningBarcode &&
                    <div className="absolute-overlay">
                        <div className="absolute-overlay-body">
                            <div>Scan Barcode From Your Device</div>
                            <img src="/images/scanner-scanning.gif" style={{ width: "70%", maxHeight: "70%" }} alt="scanner-scanning-gif"/>
                            <br>
                            </br>
                            <input ref={this.barcodeInput} type="text" onChange={this.onBarcodeInputChange} style={{
                                border: "none", color: "white", outline: "none"
                            }} />
                            <br></br>
                            <Button variant="contained" color="primary" onClick={() => { this.setState({ scanningBarcode: false }) }} >Close</Button>
                        </div>
                    </div>
                }
                {this.props.lstPurchaseItem.map((objPurchaseItem: ISalesItem, index: number) => {
                    return <PurchaseReturnItem
                        key={index}
                        objDiscount={this.props.objDiscount}
                        objPurchaseReturnItem={objPurchaseItem}
                        taxInvoiceType={this.props.taxInvoiceType}
                        removeItem={() => { this.removeItem(index) }}
                        frm={this.props.frm}
                        focusedItemRef={this.currentFocusedItem}
                        onChange={(objItem: ISalesItem) => {
                            this.props.lstPurchaseItem[index] = { ...objPurchaseItem, ...objItem };
                            this.forceUpdate();
                        }}
                    />
                })}
                <br />
                <TableContainer component={Paper} elevation={3}>
                    <Table aria-label="customized table">
                        <TableBody>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right">Sub Total</TableCell>
                                <TableCell align="right"> {scanRUtils.prettyPrice(objTotalCount.subTotal)} </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right">Total After Discount </TableCell>
                                <TableCell align="right">{scanRUtils.prettyPrice(objTotalCount.totalAfterDiscount)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right">Total Tax</TableCell>
                                <TableCell align="right">{scanRUtils.prettyPrice(objTotalCount.tax)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right">Grand Total</TableCell>
                                <TableCell align="right">{scanRUtils.prettyPrice(objTotalCount.amountWithTax)} </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}

export default PurchaseReturnItems;