import { ISalesInvoiceTemplate, ISalesInvoiceTemplateItem } from "../../../Types/AllTypes";

let css = `
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

a {
  color: #5D6975;
  text-decoration: underline;
}

body {
  position: relative;
  width: 21cm;  
  height: 29.7cm; 
  margin: 0 auto; 
  color: #001028;
  background: #FFFFFF; 
  font-family: Arial, sans-serif; 
  font-size: 12px; 
  font-family: Arial;
}

header {
  padding: 10px 0;
  margin-bottom: 30px;
}

#logo {
  text-align: center;
  margin-bottom: 10px;
}

#logo img {
  width: 90px;
}

h1 {
  border-top: 1px solid  #5D6975;
  border-bottom: 1px solid  #5D6975;
  color: #5D6975;
  font-size: 2.4em;
  line-height: 1.4em;
  font-weight: normal;
  text-align: center;
  margin: 0 0 20px 0;
  background: url(dimension.png);
}

#project {
  float: left;
}

#project span {
  color: #5D6975;
  text-align: right;
  width: 52px;
  margin-right: 10px;
  display: inline-block;
  font-size: 0.8em;
}

#company {
  float: right;
  text-align: right;
}

#project div,
#company div {
  white-space: nowrap;        
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
}

table tr:nth-child(2n-1) td {
  background: #F5F5F5;
}

table th,
table td {
  text-align: center;
}

table th {
  padding: 5px 20px;
  color: #5D6975;
  border-bottom: 1px solid #C1CED9;
  white-space: nowrap;        
  font-weight: normal;
}

table .service,
table .desc {
  text-align: left;
}

table td {
  padding: 20px;
  text-align: right;
}

table td.service,
table td.desc {
  vertical-align: top;
}

table td.unit,
table td.qty,
table td.total {
  font-size: 1.2em;
}

table td.grand {
  border-top: 1px solid #5D6975;;
}

#notices .notice {
  color: #5D6975;
  font-size: 1.2em;
}

footer {
  color: #5D6975;
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #C1CED9;
  padding: 8px 0;
  text-align: center;
}
`


function getItem(objItem: ISalesInvoiceTemplateItem, index: number) {

  return `
          <tr>
            <td class="no">${index + 1}</td>
            <td class="desc"><h3>${objItem.itemName}</h3></td>
            <td class="unit">${objItem.itemPrice}</td>
            <td class="qty">${objItem.itemQty}</td>
            <td class="tax">${objItem.itemTax}</td>
            <td class="total">${objItem.itemAmount}</td>
          </tr>
        `
}

export default function template2(obj: ISalesInvoiceTemplate) {
  let html = `
  
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8">
    <title>Example 1</title>
    <style>
    ${css}
      </style>
  </head>
  <body style="padding-left:10px;padding-right:10px;">
    <header class="clearfix">
      <div id="logo">
      ${obj.companyLogoURL ? `<img src=${obj.companyLogoURL}>` : ""}
      </div>
      <h1>${obj.invoiceNumber}</h1>
      <div id="company" class="clearfix">
        <h2 class="name">${obj.companyName}</h2>
        <div>${obj.companyAddress || ""}</div>
        <div><a href="mailto:${obj.companyEmail || ""}">${obj.companyEmail || ""}</a></div>
      </div>
      <div id="project">
        <div><span>CLIENT</span>${obj.customerName}</div>
        ${obj.customerAddress ? `
        <div>
          <span>ADDRESS</span>${obj.customerAddress}
        </div> `: ``}
        
        ${obj.customerMobile ? `<div><span>MOBILE</span>${obj.customerMobile}</div>` : ``}
        ${obj.customerEmail ?
      `<div><span>EMAIL</span><a href=${obj.customerEmail}>${obj.customerEmail}</a></div>`
      :
      ''
    }
        <div><span>GSTIN</span>${obj.customerGST ? obj.customerGST : 'N/A'}</div>
        <div><span>DATE</span>${obj.dateOfInvoice}</div>
      </div>
    </header>
    <main>
      <table>
        <thead>
          <tr>
            <th style="text-align:right;">#</th>
            <th class="desc">ITEM</th>
            <th style="text-align:right;">PRICE</th>
            <th style="text-align:right;">QTY</th>
            <th  style="text-align:right;">TAX</th>
            <th  style="text-align:right;">TOTAL</th>
          </tr>
        </thead>
        <tbody>
        ${obj.items.map((objItem: any, index: any) => {
      return getItem(objItem, index);
    }).join("")}
        </tbody>
        <tfoot style="border-top:1px solid grey">
        <tr>
          <td colspan="3"></td>
          <td colspan="2">SUBTOTAL</td>
          <td>${obj.totalAmount}</td>
        </tr>
        ${obj.showIGst ?
      `<tr>
                  <td colspan="3"></td>
                  <td colspan="2">IGST</td>
                  <td>${obj.totalIGst}</td>
                </tr>`:
      `
                  <tr>
                  <td colspan="3"></td>
                  <td colspan="2">CGST</td>
                  <td>${obj.totalCGst}</td>
                </tr>
    
                <tr>
                  <td colspan="3"></td>
                  <td colspan="2">SGST</td>
                  <td>${obj.totalSGst}</td>
                </tr>
                  `}
        <tr>
          <td colspan="3"></td>
          <td colspan="2">GRAND TOTAL</td>
          <td>${obj.totalAmountWithTax}</td>
        </tr>
      </tfoot>
      </table>
     <!-- <div id="notices">
        <div>NOTICE:</div>
        <div class="notice">A finance charge of 1.5% will be made on unpaid balances after 30 days.</div>
      </div> -->
    </main>
    <footer>
      Invoice was created on a computer and is valid without the signature and seal.
    </footer>
  </body>
</html>`

  return html;
}