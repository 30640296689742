import React from 'react'
import { FormControlLabel, FormControl, FormHelperText, Switch } from '@material-ui/core'
import BaseInput from './BaseInput'

export default class HyperSwitchField extends BaseInput {
    render() {
        return (
            <FormControl
                variant="outlined"
                fullWidth={this.props.fullWidth}
                disabled={this.props.disabled}
                error={!this.isValid()}
            >
                <FormControlLabel
                    control={<Switch
                        color="primary"
                        checked={this.props.checked || false}
                        onChange={(bol, val) => {
                            this.props.onChange(val)
                        }}
                        name="sendSmsToCustomerOnSales"
                    />}
                    label={this.props.label}
                />
                <FormHelperText>{this.getHelperText()}</FormHelperText>
            </FormControl>
        )
    }
}
