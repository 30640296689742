import React, { FormEvent } from 'react'
import enums from '../../enums';
import CommonModal from '../../Components/CommonModal';
import { Grid, Button } from '@material-ui/core';
import HyperTextField from '../../Components/HyperInput/HyperTextField';
import { IAccount, ISalesItem, IWindowState, IDiscount, ISalesReturn, ISales } from '../../Types/AllTypes';
import HyperForm from '../../Components/HyperInput/HyperForm';
import api from '../../Services/ApiServices';
import utils from '../../Services/Utils';
import AccountAutoComplete from '../Journal/AccountAutoComplete';
import scanRUtils, { winState } from '../../Services/ScanRUtilService';
import { JournalInsertObject } from '../../Types/InsertObjects';
import withWindowState from '../../Components/withWindowState';
import HyperDateTimeField from '../../Components/HyperInput/HyperDateTimeField';
import HyperSelectField from '../../Components/HyperInput/HyperSelectField';
import Alert from '@material-ui/lab/Alert';
import SalesReturnCustomerInfo from './SalesReturnCustomerInfo';
import SalesReturnItems from './SalesReturnItems';

interface AddUpdateSalesReturnProps extends IWindowState {
    objSalesReturn?: ISalesReturn
    invNumber?: string
    isOpen: boolean


    onComplete?: (e?: any) => void
    toggle: () => void
}

interface AddUpdateSalesReturnState {
    objSalesReturn: ISalesReturn
    objSales: ISales
    invNumber: string
    objDiscount: IDiscount
    showInModalError: boolean,
    showSales: boolean,
    inModalErrorMsg: string,
}

const objDefaultSalesItem = {
    objItem: {},
    qty: 1,
    price: 0,
} as ISalesItem;

const SALES_RETURN_TAX_TYPE_NAME: any = enums.SALES_RETURN_TAX_TYPE_NAME;
const salesReturnTaxType: any = Object.keys(SALES_RETURN_TAX_TYPE_NAME).map((item: any) => {
    return { id: item, value: item, name: SALES_RETURN_TAX_TYPE_NAME[item] }
})

class AddUpdateSalesReturn extends React.Component<AddUpdateSalesReturnProps, AddUpdateSalesReturnState> {
    constructor(props: AddUpdateSalesReturnProps) {
        super(props);
        let state: AddUpdateSalesReturnState = {
            objSalesReturn: {} as ISalesReturn,
            objSales: {} as ISales,
            showInModalError: false,
            showSales: false,
            invNumber: "",
            inModalErrorMsg: "",
            objDiscount: { discountType: enums.DISCOUNT_OPTION.PERCENTAGE, discountAmount: 0, discountPercentage: 0 }
        };
        // console.log(this.props.objSalesReturn)
        if (this.props.objSalesReturn) {
            state.objSalesReturn = this.props.objSalesReturn
            state.showSales = true
            this.isEditing = true;
        }
        else {
            state.objSalesReturn.CNNumber = (winState.state.companySettings.currentCNNumber + 1).toString();
            state.objSalesReturn.date = new Date().toString();
            state.objSalesReturn.lstSalesItem = [objDefaultSalesItem];
            state.objSalesReturn.invoiceTaxType = enums.SALES_RETURN_TAX_TYPE.NO_TAX
        }
        if (state.objSalesReturn.info) {
            state.objSalesReturn.lstSalesItem = [];
            state.objSalesReturn.invoiceDate = state.objSalesReturn.info.invoiceDate
            state.objSalesReturn.invoiceTaxType = state.objSalesReturn.info.invoiceTaxType
            state.objSalesReturn.CNNumber = state.objSalesReturn.info.CNNumber
            state.objSalesReturn.customerInfo = state.objSalesReturn.info.customerInfo
            state.invNumber = state.objSalesReturn.info.INVNumber
        }

        this.state = state;
        this.frm = new HyperForm();
    }
    frm: HyperForm
    isEditing: boolean = false;


    async componentDidMount() {
        let state = this.state;
        let lstSalesItem = null;
        if (state.objSalesReturn.info) {
            lstSalesItem = state.objSalesReturn.info.items;
            for (let objSalesItem of lstSalesItem) {
                objSalesItem.objItem = await scanRUtils.getItem(objSalesItem.id) as any;
            }
            state.objSalesReturn.lstSalesItem = lstSalesItem;
            this.forceUpdate();
        }
    }

    setSales(obj: Partial<ISalesReturn>) {
        this.setState({ objSalesReturn: { ...this.state.objSalesReturn, ...obj } });
    }

    getSalesByINV = async (invNumber: string) => {
        try {
            utils.showLoader()

            let lstSales = await api.getSaleByINV(invNumber, enums.JOURNAL_CATEGORY.SALES);

            let objSalesReturn: any = {};
            let objSales;
            if (!lstSales || !lstSales[0])
                return this.setState({ showInModalError: true, showSales: false, inModalErrorMsg: "Sales Not Found in given Invoice Number" });

            objSales = lstSales[0];
            if (this.isEditing) {
                await this.setState({ objSales, showSales: true, showInModalError: false })
                return;
            }
            let lstSalesItem = null;
            if (objSales.info) {
                lstSalesItem = objSales.info.items;
                for (let objSalesItem of lstSalesItem) {
                    objSalesItem.objItem = await scanRUtils.getItem(objSalesItem.id) as any;
                }
                objSalesReturn.lstSalesItem = lstSalesItem;
            }

            objSalesReturn.INVNumber = objSales.info.INVNumber;
            objSalesReturn.debitAccountId = objSales.creditAccountId;
            objSalesReturn.debitAccount = objSales.creditAccount;
            objSalesReturn.creditAccount = objSales.debitAccount;
            objSalesReturn.creditAccountId = objSales.debitAccountId;
            objSalesReturn.customerInfo = objSales.debitAccount;
            
            if (objSales.info.invoiceTaxType)
                objSalesReturn.invoiceTaxType = objSales.info.invoiceTaxType;
            else
                objSalesReturn.invoiceTaxType = enums.SALES_RETURN_TAX_TYPE.NO_TAX;
            objSalesReturn.invoiceDate = objSales.date;
            objSalesReturn.date = new Date();
            // console.log(objSalesReturn, "objSales", objSales)
            await this.setSales(objSalesReturn)
            await this.setState({ objSales, showSales: true, showInModalError: false })
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader();
        }
    }

    onSubmit = async (e: FormEvent) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.frm.setIsFormSubmitted(true);
            if (this.frm.isValid()) {
                let objTotalCount = scanRUtils.getTotalCounts(this.state.objSalesReturn.lstSalesItem, this.state.objDiscount)
                this.state.objSalesReturn.amount = objTotalCount.amountWithTax;

                let io = new JournalInsertObject();
                io.id = this.state.objSalesReturn.id;
                io.debitAccountId = this.state.objSalesReturn.debitAccountId;
                io.creditAccountId = this.state.objSalesReturn.creditAccountId;
                io.amount = this.state.objSalesReturn.amount;
                io.comment = this.state.objSalesReturn.comment || "Sales Return";
                io.date = this.state.objSalesReturn.date;
                io.info = this.getInfo(this.state.objSalesReturn.lstSalesItem);
                io.category = enums.JOURNAL_CATEGORY.SALES_RETURN;
                utils.showLoader();
                // console.log(io)

                let result = null;
                result = await api.addUpdateSalesReturn(io, [])

                if (!io.id)
                    this.updateInvoiceNumber();

                this.props.toggle();
                if (this.props.onComplete) {
                    this.props.onComplete(result);
                }
            }
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
        finally {
            utils.hideLoader()
        }
    }

    updateInvoiceNumber = async () => {
        try {
            await api.updateCompanySettings({ currentCNNumber: this.state.objSalesReturn.CNNumber });
            winState.setCompanySettings({ currentCNNumber: parseInt(this.state.objSalesReturn.CNNumber) });
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message);
        }
    }

    getInfo(lstSalesItem: ISalesItem[]) {
        let obj: any = {};
        let objTotalCount = scanRUtils.getTotalCounts(this.state.objSalesReturn.lstSalesItem, this.state.objDiscount);
        obj = { ...objTotalCount };
        obj.amount = objTotalCount.amountWithTax;

        obj.INVNumber = this.state.objSalesReturn.INVNumber || this.state.invNumber;
        obj.CNNumber = this.state.objSalesReturn.CNNumber;
        obj.customerInfo = this.state.objSalesReturn.customerInfo;
        obj.invoiceTaxType = this.state.objSalesReturn.invoiceTaxType;
        obj.invoiceDate = this.state.objSalesReturn.invoiceDate;
        obj.paymentStatus = this.state.objSalesReturn.paymentStatus;

        obj.items = lstSalesItem.map(item => {
            let item1: any = item;
            item1.id = item.objItem.id;
            item1.name = item.objItem.name;
            item1.defaultDescription = item.objItem.defaultDescription;
            item1.showTaxOnInvoice = item.objItem.showTaxOnInvoice;

            item1.hsnCode = item.objItem.hsnCode;
            item1.productCode = item.objItem.productCode;
            item1.sacCode = item.objItem.sacCode;
            delete item1.objItem;
            return item1;
        })
        // console.log(obj, "Obj")
        return obj;
    }
    validateInvoiceTaxType = (invoiceTaxType: string) => {
        // console.log(invoiceTaxType, this.state.objSales)
        let customerInfo = this.state.objSalesReturn.customerInfo;
        if (!customerInfo)
            return false;
        let isIntraState = scanRUtils.isIntraState(customerInfo.info?.state)
        if (isIntraState) {
            this.setState({ showInModalError: true })
        }
    }

    render() {
        return (
            <div>
                <CommonModal
                    dialogProps={{ open: this.props.isOpen }}
                    maxWidth="lg"
                    title={"Sales Return"}
                    toggle={this.props.toggle}
                >
                    <Grid container spacing={2}>
                        {!!(this.state.showInModalError) &&
                            <Grid item xs={12}>
                                <Alert style={{ marginBottom: 20 }} severity="error">{this.state.inModalErrorMsg}</Alert>
                            </Grid>
                        }

                        <Grid item xs={3}></Grid>
                        <Grid item xs={6} style={{ marginTop: 10, marginBottom: 10 }}>
                            <HyperTextField
                                value={this.state.invNumber}
                                onChange={(e: any) => this.setState({ invNumber: e.target.value })}
                                onBlur={(e: any) => { this.getSalesByINV(e.target.value) }}
                                label={"Invoice Number"}
                                type="text"
                                disabled={this.isEditing ? true : false}
                            />
                        </Grid>
                        <Grid item xs={3}></Grid>
                        <form onSubmit={this.onSubmit}>
                            <Grid container spacing={2}>
                                {this.state.showSales &&
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <AccountAutoComplete
                                                        accountId={this.state.objSalesReturn.creditAccountId}
                                                        onChange={(objAccount: IAccount) => {
                                                            if (objAccount)
                                                                this.setSales({ creditAccountId: objAccount.id, customerInfo: objAccount });
                                                        }}
                                                        label="Customer"
                                                        frm={this.frm}
                                                        required
                                                        autoFocus
                                                        category={enums.ACCOUNT_CATEGORY.CUSTOMER}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <HyperDateTimeField
                                                        required
                                                        selected={this.state.objSalesReturn.date}
                                                        onChange={(date: any) => { this.setSales({ date }) }}
                                                        label={"Date"}
                                                        type="date"
                                                        frm={this.frm}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <HyperDateTimeField
                                                        selected={this.state.objSalesReturn.invoiceDate}
                                                        label={"Invoice Date"}
                                                        type="date"
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <HyperSelectField
                                                        labelWidth={120}
                                                        required
                                                        selectedValue={this.state.objSalesReturn.invoiceTaxType}
                                                        onChange={(e: any) => { this.setSales({ invoiceTaxType: e.target.value }); this.validateInvoiceTaxType(e.target.value) }}
                                                        options={salesReturnTaxType}
                                                        optionIdField={"value"}
                                                        optionNameField={"name"}
                                                        label="Invoice Tax Type"
                                                        fullWidth
                                                        frm={this.frm}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <HyperTextField
                                                        value={this.state.objSalesReturn.CNNumber}
                                                        onChange={(e: any) => this.setSales({ CNNumber: e.target.value })}
                                                        label={"Credit Note Number"}
                                                        type="text"
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {(this.state.objSalesReturn.customerInfo) &&
                                            <Grid item xs={12} md={6}>
                                                <SalesReturnCustomerInfo customerInfo={this.state.objSalesReturn.customerInfo} />
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <SalesReturnItems
                                                lstSalesItem={this.state.objSalesReturn.lstSalesItem}
                                                frm={this.frm}
                                                objDiscount={this.state.objDiscount}
                                                taxInvoiceType={this.state.objSalesReturn.invoiceTaxType}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <HyperTextField
                                                value={this.state.objSalesReturn.comment}
                                                onChange={(e: any) => { this.setSales({ comment: (e.target.value) }) }}
                                                multiline
                                                rows={2}
                                                label={"Comment"}
                                                type="text"
                                                frm={this.frm}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                                            <Button variant="contained" color="primary" type="submit">Save</Button>
                                        </Grid>
                                    </React.Fragment>
                                }
                            </Grid>
                        </form>
                    </Grid>
                </CommonModal>
            </div>
        );
    }
}


export default withWindowState(AddUpdateSalesReturn, ["companySettings", "user"])