import React from 'react'
import { ISalesInvoiceTemplate, ISalesInvoiceTemplateItem } from '../../../Types/AllTypes';

interface HalfPageTemplateProps {
    objInvoice?: ISalesInvoiceTemplate

    onComplete: (html: string) => void
}
interface HalfPageTemplateState {
}

class HalfPageTemplate extends React.Component<HalfPageTemplateProps, HalfPageTemplateState> {
    constructor(props: HalfPageTemplateProps) {
        super(props);
        let state: HalfPageTemplateState = {};
        this.state = state;

    }
    ref = React.createRef<HTMLDivElement>();

    componentDidMount() {
        // let mainHTML = document.getElementById("divTemplate");
        let mainHTML = this.ref.current
        this.props.onComplete(mainHTML?.innerHTML || "")
    }
    getTitleFontSize = (title: string) => {
        if (!title)
            return 15;
        let titleLength = title.length;
        if (titleLength <= 10)
            return 90
        else if (titleLength <= 12)
            return 80
        else if (titleLength <= 20)
            return 60
        else if (titleLength <= 22)
            return 45
        else if (titleLength <= 25)
            return 38
        else if (titleLength <= 30)
            return 31
        else if (titleLength <= 35)
            return 25
        return 20
    }
    getRowSpan = () => {
        return 0
    }
    render() {
        const { objInvoice } = this.props;
        // console.log(objInvoice)
        return (
            <div ref={this.ref} id="divTemplate" style={{ display: 'none' }}>
                <table style={{ width: "100%", maxHeight: "29cm" }}>
                    <tbody>
                        <tr>
                            <td>
                                <table style={{ width: "100%" }} {...{ border: 1 }} cellSpacing="0" cellPadding="5">
                                    <thead>
                                        <tr>
                                            <td valign="middle">
                                                <div style={{ width: "20%", float: 'left' }}>
                                                    {!!(objInvoice?.companyLogoURL) &&
                                                        <img src={objInvoice?.companyLogoURL} alt="CompanyLogo"
                                                            style={{ width: "100%", height: 100, }} />
                                                    }
                                                </div>
                                                <div style={{ width: "79%", float: 'left' }}>
                                                    <div style={{ display: "table", height: 100, }} >
                                                        <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                                                            <div style={{ fontSize: this.getTitleFontSize(objInvoice?.companyName || "") }}>
                                                                {objInvoice?.companyName}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                {objInvoice?.companyAddress}
                                                <div>
                                                    {!!(objInvoice?.companyGST) && <span> <b>GST</b> : - <span style={{ fontWeight: 'normal' }}>{objInvoice?.companyGST} </span> &nbsp; </span>}
                                                    {!!(objInvoice?.companyMobile) && <span> <b>Mobile</b> : - <span style={{ fontWeight: 'normal' }}>{objInvoice?.companyMobile} </span> &nbsp; </span>}
                                                    {!!(objInvoice?.companyEmail) && <span> <b>Email</b> : - <span style={{ fontWeight: 'normal' }}>{objInvoice?.companyEmail} </span> </span>}
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table style={{ width: "100%" }} {...{ border: 1 }} cellSpacing="0" cellPadding="5" >
                                    <tbody>
                                        <tr>
                                            <td valign="top" rowSpan={3} style={{ width: "400px" }}>
                                                <b>Mr/Mrs. </b> : - {objInvoice?.customerName}
                                                {!!(objInvoice?.customerGST) && <div> <b>GST</b> : - {objInvoice?.customerGST} </div>}
                                                {!!(objInvoice?.customerAddress) && <div>{objInvoice?.customerAddress} </div>}
                                                {!!(objInvoice?.customerMobile) && <div>{objInvoice?.customerMobile} </div>}
                                                {!!(objInvoice?.customerEmail) && <div>{objInvoice?.customerEmail} </div>}
                                            </td>
                                            <td style={{ width: "200px" }}>
                                                <b>
                                                    <span>Bill No. : </span>
                                                </b>
                                                <span>{objInvoice?.invoiceNumber}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "200px" }}>
                                                <b>
                                                    <span>Date : </span>
                                                </b>
                                                <span>{objInvoice?.dateOfInvoice}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "200px" }}>
                                                <b>
                                                    <span>Due Date : </span>
                                                </b>
                                                <span>{objInvoice?.dueDateOfInvoice}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table style={{ width: "100%" }} {...{ border: 1 }} cellSpacing="0" cellPadding="5" >
                                    <thead>
                                        <tr>
                                            <th>Particulars</th>
                                            <th style={{ width: "40px" }}> Qty.</th>
                                            <th style={{ width: "79px" }}> Rates</th>
                                            <th style={{ width: "78px" }}> Tax</th>
                                            <th style={{ width: "120px" }}> Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {objInvoice?.items.map((item: ISalesInvoiceTemplateItem, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.itemName}</td>
                                                    <td style={{ textAlign: "center" }}>{item.itemQty}</td>
                                                    <td style={{ textAlign: "right" }}>{item.itemPrice}</td>
                                                    <td style={{ textAlign: "right" }}>{item.itemTax}</td>
                                                    <td style={{ textAlign: "right" }}>{item.itemAmount}</td>
                                                </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table style={{ width: "100%" }} {...{ border: 1 }} cellSpacing="0" cellPadding="5" >
                                    <tbody>
                                        <tr>
                                            <td valign="top" style={{ width: "350px" }} rowSpan={objInvoice?.discountPercentage ? 4 : 3} >
                                                <b>Note :- </b>
                                                <div>{objInvoice?.note}</div>
                                            </td>
                                            <td style={{ width: "100px" }}> <b>Sub Total</b></td>
                                            <td style={{ width: "150px", textAlign: 'right' }}><b>{objInvoice?.subTotal}</b></td>
                                        </tr>
                                        {!!(objInvoice?.discountPercentage) &&
                                            <tr>
                                                <td><b>Discount({objInvoice?.discountPercentage}%)</b></td>
                                                <td style={{ textAlign: 'right' }}><b>{objInvoice?.totalDiscount}</b></td>
                                            </tr>
                                        }
                                        <tr>
                                            <td><b>Tax ({objInvoice?.totalIGSTPercentage}%)</b></td>
                                            <td style={{ textAlign: 'right' }}><b>{objInvoice?.totalTax}</b></td>
                                        </tr>
                                        <tr>
                                            <td><b>Grand Total</b></td>
                                            <td style={{ textAlign: 'right' }}><b>{objInvoice?.totalAmountWithTax}</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table style={{ width: "100%" }} {...{ border: 1 }} cellSpacing="0" cellPadding="5" >
                                    <tbody>
                                        <tr>
                                            <td valign="top" rowSpan={2} style={{ width: "400px" }} >
                                                <b>Terms & Conditions :- </b>
                                                {objInvoice?.companyTerms.map((term: string, index: number) => <div key={index}>{term}</div>)}
                                            </td>
                                            <td valign="top" rowSpan={3} style={{ width: "200px" }}>
                                                <b>
                                                    Signature :-
                                                </b>
                                                {!!(objInvoice?.companySignatureURL) &&
                                                    <p style={{ textAlign: 'center', margin: 0 }}>
                                                        <img src={objInvoice?.companySignatureURL} alt="companySignatureURL" style={{ width: 175, height: 50 }} />
                                                    </p>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div >
        );
    }
}

export default HalfPageTemplate;
