import React from 'react'
import { ICategory, IWindowState } from '../../../Types/AllTypes';
import HyperAutoCompleteField from '../../../Components/HyperInput/HyperAutoCompleteField';
import HyperModal from '../../../Components/HyperInput/HyperModal';
import AddUpdateCategory from './AddUpdateCategory';
import withWindowState from '../../../Components/withWindowState';
import scanRUtils from '../../../Services/ScanRUtilService';
import AddUpdateSubCategory from './AddUpdateSubCategory';

interface CategoryAutoCompleteProps extends IWindowState {
    parentId?: number
    categoryId?: number
    required?: boolean
    frm?: any
    label?: string
    autoFocus?: boolean
    placeholder?: string
    disabled?: boolean
    onFocus?: () => void
    onChange?: (e: ICategory) => void
    onAddClick?: (e?: any) => void
}

interface CategoryAutoCompleteState {
    lstCategory: ICategory[]
    objCategory?: ICategory
}


class CategoryAutoComplete extends React.Component<CategoryAutoCompleteProps, CategoryAutoCompleteState> {
    constructor(props: CategoryAutoCompleteProps) {
        super(props);
        let state: CategoryAutoCompleteState = {
            lstCategory: [],
            objCategory: undefined
        };
        this.state = state;
    }

    isEditing: boolean = false;
    categoryModal: HyperModal = new HyperModal(this);
    subCategoryModal: HyperModal = new HyperModal(this);

    componentDidMount() {
        this.loadItem();
    }

    componentWillUnmount() {
    }

    componentDidUpdate(props: CategoryAutoCompleteProps) {
        if (props.categoryId !== this.props.categoryId) {
            let objCategory = this.props.category.lstCategories.find((item: ICategory) => item.id === this.props.categoryId);
            this.setState({ objCategory });
        }
    }

    onAutoCompleteUpdate = async (e: any) => {
        await this.loadItem();
        if (e.detail && e.detail.sendResponse) {
            e.detail.sendResponse("done");
        }
    }

    async loadItem() {
        if (!this.props.category.lstCategories) {
            await scanRUtils.loadCategory();
        }
        if (this.props.categoryId) {
            let objCategory = this.props.category.lstCategories.find((cat: any) => cat.id === this.props.categoryId)
            this.setState({ objCategory })
        }
    }
    mustLoadCategory = async () => {
        await scanRUtils.loadCategory();
    }


    render() {
        let lstCategory: any = []
        if (this.props.category.lstCategories && this.props.category.lstCategories.length !== 0) {
            if (this.props.parentId)
                lstCategory = this.props.category.lstCategories.filter((item: any) => item.parentId === this.props.parentId);
            else
                lstCategory = this.props.category.lstCategories.filter((item: any) => !item.parentId);
        }
        return (
            <React.Fragment>
                <HyperAutoCompleteField
                    options={lstCategory}
                    value={this.state.objCategory || this.props.categoryId || ""}
                    onChange={(e: any) => {
                        this.setState({ objCategory: e })
                        if (this.props.onChange) {
                            this.props.onChange(e);
                        }
                    }}
                    onFocus={this.props.onFocus}
                    label={this.props.label}
                    required={this.props.required}
                    frm={this.props.frm}
                    autoFocus={this.props.autoFocus}
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled}
                    onAddClick={() => {
                        if (this.props.parentId)
                            this.subCategoryModal.toggle(true);
                        else
                            this.categoryModal.toggle(true);
                    }}
                />
                {(this.categoryModal.isOpen()) &&
                    <AddUpdateCategory
                        isOpen={this.categoryModal.isOpen()}
                        onComplete={async (v: any) => {
                            await this.mustLoadCategory();
                            let objCategory = this.props.category.lstCategories.find((item: ICategory) => item.id === v.id);
                            await this.setState({ objCategory: objCategory });
                            if (this.props.onChange) {
                                this.props.onChange(objCategory as ICategory);
                            }
                        }}
                        toggle={this.categoryModal.toggle}
                    />
                }
                {(this.subCategoryModal.isOpen()) &&
                    <AddUpdateSubCategory
                        isOpen={this.subCategoryModal.isOpen()}
                        parentId={this.props.parentId || 0}
                        onComplete={async (v: any) => {
                            await this.mustLoadCategory();
                            let objCategory = this.props.category.lstCategories.find((item: ICategory) => item.id === v.id);
                            await this.setState({ objCategory });
                            if (this.props.onChange) {
                                this.props.onChange(objCategory as ICategory);
                            }
                        }}
                        toggle={this.subCategoryModal.toggle}
                    />
                }
            </React.Fragment>
        );
    }
}

export default withWindowState(CategoryAutoComplete, ["category"]);