import React from 'react'
import HyperAutoCompleteField from '../../Components/HyperInput/HyperAutoCompleteField';
import lstState from '../../Data/state';

interface StateAutoCompleteProps {
    required?: boolean
    frm?: any
    value: string
    label?: string
    autoFocus?: boolean
    placeholder?: string

    onChange?: (e: any) => void
    onAddClick?: (e?: any) => void
}

interface StateAutoCompleteState {

}


class StateAutoComplete extends React.Component<StateAutoCompleteProps, StateAutoCompleteState> {
    constructor(props: StateAutoCompleteProps) {
        super(props);
        let state: StateAutoCompleteState = {

        };
        this.state = state;
    }

    render() {

        return (
            <React.Fragment>
                <HyperAutoCompleteField
                    options={lstState}
                    value={this.props.value || ""}
                    onChange={(value: string) => {
                        if (this.props.onChange) {
                            this.props.onChange(value);
                        }
                    }}
                    label={this.props.label}
                    required={this.props.required}
                    frm={this.props.frm}
                    autoFocus={this.props.autoFocus}
                    placeholder={this.props.placeholder}
                />
            </React.Fragment>
        );
    }
}

export default StateAutoComplete;