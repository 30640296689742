import React from 'react'
import { withTheme, Theme, Grid, Paper } from '@material-ui/core'
import HyperModal from '../../../Components/HyperInput/HyperModal';
import scanRUtils from '../../../Services/ScanRUtilService';

interface HSNProps {
    theme: Theme
    objHSN: any
}

interface HSNState {
}

class HSN extends React.Component<HSNProps, HSNState> {
    constructor(props: HSNProps) {
        super(props);
        let state: HSNState = {
        };
        this.state = state;
        this.modal = new HyperModal(this);
    }

    modal: HyperModal;

    componentDidMount() {

    }
    render() {
        return (
            <Grid container style={{ marginBottom: 20, marginTop: 20 }} spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={3} style={{ paddingTop: 30, paddingBottom: 30, padding: 20 }}>
                        <div style={{ display: 'inline-block' }}>
                            <span>Total value</span><br />
                            <b>{scanRUtils.prettyPrice(this.props.objHSN.totalInvoiceValue)}</b>
                        </div>
                        <div style={{ display: 'inline-block', paddingLeft: 25 }}>
                            <span>Total taxable value</span><br />
                            <b>{scanRUtils.prettyPrice(this.props.objHSN.totalTaxableValue)}</b>
                        </div>
                        <div style={{ display: 'inline-block', paddingLeft: 25 }}>
                            <span>Total integrated tax</span><br />
                            <b>{scanRUtils.prettyPrice(this.props.objHSN.totalIntegratedTax)}</b>
                        </div>
                        <div style={{ display: 'inline-block', paddingLeft: 25 }}>
                            <span>Total central tax</span><br />
                            <b>{scanRUtils.prettyPrice(this.props.objHSN.totalCentralTax)}</b>
                        </div>
                        <div style={{ display: 'inline-block', paddingLeft: 25 }}>
                            <span>Total state/UT tax</span><br />
                            <b>{scanRUtils.prettyPrice(this.props.objHSN.totalStateTax)}</b>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={3} style={{ paddingTop: 30, paddingBottom: 30, padding: 20 }}>
                        <table {...{ border: 1 }} style={{ width: '100%' }} cellPadding={5} cellSpacing={0}>
                            <thead>
                                <tr>
                                    <th>HSN Code</th>
                                    <th>Description</th>
                                    <th>UQC</th>
                                    <th>Total Qty</th>
                                    <th>Total Value</th>
                                    <th>Taxable Value</th>
                                    <th>Integrated Tax Amount</th>
                                    <th>Central Tax Amount</th>
                                    <th>State Tax Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(this.props.objHSN.lstHSNCode) && Object.keys(this.props.objHSN.lstHSNCode).map((key: any, index: number) => {
                                    let objHSNItem = this.props.objHSN.lstHSNCode[key];
                                    return (
                                        <tr key={index}>
                                            <td>{objHSNItem.HSNCode}</td>
                                            <td>{objHSNItem.description}</td>
                                            <td>{objHSNItem.UQC}</td>
                                            <td>{objHSNItem.totalQty}</td>
                                            <td>{scanRUtils.prettyPrice(objHSNItem.totalValue)}</td>
                                            <td>{scanRUtils.prettyPrice(objHSNItem.taxableValue)}</td>
                                            <td>{scanRUtils.prettyPrice(objHSNItem.integratedTaxAmount)}</td>
                                            <td>{scanRUtils.prettyPrice(objHSNItem.centralTaxAmount)}</td>
                                            <td>{scanRUtils.prettyPrice(objHSNItem.stateTaxAmount)}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default withTheme(HSN);