import React from 'react'
import { Container, Paper, Grid, withStyles, Typography, Card, CardHeader, List, ListItem, ListItemText, ListItemSecondaryAction, Switch, Button } from '@material-ui/core'
import HyperModal from '../../Components/HyperInput/HyperModal';
import withWindowState from '../../Components/withWindowState';
import { IWindowState, ICompanySettings } from '../../Types/AllTypes';
import { IconEmail, IconNotification } from '../../Components/Icons/Icons';
// import HyperSwitchField from '../../Components/HyperInput/HyperSwitchField';
import { HyperTheme } from '../../Theme/Theme';
import utils from '../../Services/Utils';
import api from '../../Services/ApiServices';

const styles = () => ({
    root: {
        flexGrow: 1,
    },
})

interface CompanySMSProps extends IWindowState { }

interface CompanySMSState {
    settings: ICompanySettings
}

class CompanySMS extends React.Component<CompanySMSProps, CompanySMSState> {
    constructor(props: CompanySMSProps) {
        super(props);
        let state: CompanySMSState = {
            settings: this.props.companySettings as ICompanySettings
        };

        this.state = state;
        this.modal = new HyperModal(this);
    }
    modal: HyperModal;
    componentDidMount() { }

    setSettings(obj: Partial<ICompanySettings>) {
        this.setState({ settings: { ...this.state.settings, ...obj } });
    }

    getSettings(key: string) {
        return (this.state.settings as any)[key];
    }

    onSave = async () => {
        try {
            let result = await api.updateCompanySettings(this.state.settings);
            utils.showSuccess(result.message)
            this.props.setCompanySettings(this.state.settings)
        }
        catch (ex) {
            utils.showError(ex.msg || ex.message)
        }
    }

    render() {
        const { companySettings } = this.props;
        return (
            <Container style={{ marginTop: 25 }}>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} style={{ textAlign: 'center', padding: 10 }} >
                            <IconEmail style={{ fontSize: 50 }} fontSize={"large"} color="primary" />
                            <Typography>
                                {companySettings.totalSentSMS} / 3500
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
                        <Card elevation={3}>
                            <CardHeader
                                avatar={<IconNotification />}
                                title={<Typography variant="h6"><b>Sales SMS Setting</b></Typography>}
                                style={{ backgroundColor: HyperTheme.palette.grey[200] }}
                            />
                            <List>
                                <ListItem divider>
                                    <ListItemText>Do you want send SMS to Your Customer on new Sales?</ListItemText>
                                    <ListItemSecondaryAction>
                                        <Switch color="primary" checked={this.state.settings.sendSmsToCustomerOnSales || false}
                                            onChange={(bol: any, val: any) => { this.setSettings({ sendSmsToCustomerOnSales: val }) }}
                                            name="sendSmsToCustomerOnSales"
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem divider>
                                    <ListItemText>Do you want send SMS to Owner on new Sales?</ListItemText>
                                    <ListItemSecondaryAction>
                                        <Switch color="primary" checked={this.state.settings.sendSmsToOwnerOnSales || false}
                                            onChange={(bol: any, val: any) => { this.setSettings({ sendSmsToOwnerOnSales: val }) }}
                                            name="sendSmsToOwnerOnSales"
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
                        <Card elevation={3}>
                            <CardHeader
                                avatar={<IconNotification />}
                                title={<Typography variant="h6"><b>Sales Email Setting</b></Typography>}
                                style={{ backgroundColor: HyperTheme.palette.grey[200] }}
                            />
                            <List>
                                <ListItem divider>
                                    <ListItemText>Do you want send sales Email to customer on sales?</ListItemText>
                                    <ListItemSecondaryAction>
                                        <Switch color="primary" checked={this.state.settings.sendEmailToCustomerOnSales || false}
                                            onChange={(bol: any, val: any) => { this.setSettings({ sendEmailToCustomerOnSales: val }) }}
                                            name="sendEmailToCustomerOnSales"
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
                        <Card elevation={3}>
                            <CardHeader
                                avatar={<IconNotification />}
                                title={<Typography variant="h6"><b>Payment Remainder Setting</b></Typography>}
                                style={{ backgroundColor: HyperTheme.palette.grey[200] }}
                            />
                            <List>
                                <ListItem divider>
                                    <ListItemText>Send payment remainder to customer mobile number when invoice date come?</ListItemText>
                                    <ListItemSecondaryAction>
                                        <Switch color="primary" checked={this.state.settings.sendSMSToCustomerOnDueDate || false}
                                            onChange={(bol: any, val: any) => { this.setSettings({ sendSMSToCustomerOnDueDate: val }) }}
                                            name="sendSMSToCustomerOnDueDate"
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem divider>
                                    <ListItemText>Send SMS to self on Sales Due Date?</ListItemText>
                                    <ListItemSecondaryAction>
                                        <Switch color="primary" checked={this.state.settings.sendSMSToSelfOnDueDate || false}
                                            onChange={(bol: any, val: any) => { this.setSettings({ sendSMSToSelfOnDueDate: val }) }}
                                            name="sendSMSToSelfOnDueDate"
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem divider>
                                    <ListItemText>Do you want to send sms to self on purchase due date?</ListItemText>
                                    <ListItemSecondaryAction>
                                        <Switch color="primary" checked={this.state.settings.sendSMSToSelfOnPurchaseDueDate || false}
                                            onChange={(bol: any, val: any) => { this.setSettings({ sendSMSToSelfOnPurchaseDueDate: val }) }}
                                            name="sendSMSToSelfOnPurchaseDueDate"
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem divider>
                                    <ListItemText>Do you want to turn on Hyper Advance Mode?</ListItemText>
                                    <ListItemSecondaryAction>
                                        <Switch color="primary" checked={this.state.settings.hyperAdvanceMode || false}
                                            onChange={(bol: any, val: any) => { this.setSettings({ hyperAdvanceMode: val }) }}
                                            name="hyperAdvanceMode"
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </Card>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 25, textAlign: 'center' }}>
                        <Button variant="contained" color="primary" onClick={this.onSave} >Save</Button>
                    </Grid>
                </Grid>

            </Container>
        );
    }
}

export default withWindowState(withStyles(styles)(CompanySMS), ["companySettings"]);