import React, { Component } from 'react'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { RouteComponentProps } from 'react-router-dom';
import utils from '../../Services/Utils';
import api from '../../Services/ApiServices';


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://thehypertech.in/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const style: any = (theme: Theme) => ({
    paper: {
        marginTop: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 15,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        fontWeight: 'bold'
    },
})

interface IForgotPasswordProps extends WithStyles, RouteComponentProps {

}

interface IForgotPasswordState { }

class ForgotPassword extends Component<IForgotPasswordProps, IForgotPasswordState> {

    state = {
        Username: '',
        Password: '',
        confirmPassword: '',
        isUsernameValid: false,
        userInfo: null
    }

    checkUsername = async () => {
        try {
            utils.showLoader()
            let result = await api.checkUsername(this.state.Username);
            await this.setState({ userInfo: result, isUsernameValid: true })
        }
        catch (ex) {
            // console.log(ex)
            utils.showError(ex.message);
        }
        finally {
            utils.hideLoader()
        }
    }

    save = async () => {
        try {
            if (!this.state.Password)
                return utils.showError("Password must required!")
            if (this.state.Password !== this.state.confirmPassword)
                return utils.showError("Both Password must Same!")
            utils.showLoader();
            let result = await api.savePassword(this.state.Username, this.state.Password);
            await this.setState({ userInfo: result, isUsernameValid: true })
            this.props.history.push("/login")
        }
        catch (ex) {
            // console.log(ex)
            utils.showError(ex.message);
        }
        finally {
            utils.hideLoader()
        }
    }



    render() {
        const { classes } = this.props;
        return (
            <div>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Forgot Password
                        </Typography>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            label="Username"
                            disabled={this.state.isUsernameValid}
                            autoComplete="email"
                            onChange={(e) => { this.setState({ Username: e.target.value }) }}
                        />
                        {this.state.isUsernameValid &&
                            <React.Fragment>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    focused
                                    label="Password"
                                    type="password"
                                    autoComplete="current-password"
                                    onChange={(e) => { this.setState({ Password: e.target.value }) }}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Confirm Password"
                                    type="password"
                                    onChange={(e) => { this.setState({ confirmPassword: e.target.value }) }}
                                />

                            </React.Fragment>

                        }
                        {this.state.isUsernameValid ?
                            <Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={this.save}>
                                Save
                            </Button>
                            :
                            <Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={this.checkUsername}>
                                Next
                            </Button>
                        }
                    </div>
                    <Box mt={8}>
                        <Copyright />
                    </Box>
                    <Box mt={5} style={{ textAlign: 'center' }}>
                        <img
                            onClick={utils.downloadScanner}
                            src="/images/android-store.png"
                            alt="android-store"
                            style={{ cursor: 'pointer', height: 70 }}
                        />
                    </Box>
                    <Box mt={3} style={{ textAlign: 'center' }}>
                        <Typography variant="h6">
                            Contact Us
                        </Typography>
                        <Typography variant="caption" align="left">
                            <b>Email</b> : <a href="mailto:support@thehypertech.in">support@thehypertech.in</a> <br />
                            <b>Mobile</b> : +91 886668020 / +91 8306693039
                        </Typography>
                    </Box>
                </Container>
            </div>
        )
    }
}


export default (withStyles(style)(ForgotPassword));
