import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import {  ListItem, ListItemAvatar, ListItemText, Box } from '@material-ui/core';

class NotificationSkeleton extends React.Component {
    render() {
        return (
            <div style={{ padding: "5px",width:"100%" }}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => {
                    return (
                        <Box width={'100%'} key={item} >
                            <ListItem divider>
                                <ListItemAvatar>
                                    <Skeleton variant="circle" height={45} width={45} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<Skeleton height={20} width="80%" style={{ marginBottom: 6 }} />}
                                    secondary={<Skeleton height={10} width="80%" />} />
                            </ListItem>
                        </Box>
                    )
                })}
            </div>
        );
    }
}
export default NotificationSkeleton;