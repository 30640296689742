import React, { Component } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { HyperTheme } from '../Theme/Theme';

class ComingSoon extends Component {
    render() {

        return (
            <Paper
                elevation={3}
                style={{
                    background: `linear-gradient(${HyperTheme.palette.primary.dark},${HyperTheme.palette.primary.light})`,
                    color: HyperTheme.palette.common.white,
                    paddingTop: 100,
                    paddingBottom: 100,
                }}
            >
                <Typography variant="h2" align="center">
                    Coming Soon...
                </Typography>
            </Paper>
        )
    }
}

export default ComingSoon