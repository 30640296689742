import React from 'react'
import { Typography, Popover, IconButton, ListItem } from '@material-ui/core';
import { IconMoreVert,  IconBulkEntry, IconDeleteFill } from '../../Components/Icons/Icons';

interface SalesItemActionPopoverProps {
    showBatchEntry?: boolean
    onBatchBarcode: () => void
    onAddDiscount: () => void
    onDelete?: () => void
}

interface SalesItemActionPopoverState {
    anchorEl: any
}

class SalesItemActionPopover extends React.Component<SalesItemActionPopoverProps, SalesItemActionPopoverState> {
    constructor(props: SalesItemActionPopoverProps) {
        super(props);
        let state: SalesItemActionPopoverState = {
            anchorEl: null
        };
        this.state = state;
    }
    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };
    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;
        return (
            <React.Fragment>
                <IconButton aria-describedby={id} color="primary" onClick={this.handleClick}>
                    <IconMoreVert />
                </IconButton>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <ListItem style={{ cursor: 'pointer' }} onClick={() => { this.handleClose(); this.props.onDelete && this.props.onDelete() }}>
                        <IconDeleteFill />
                        <Typography style={{ marginLeft: 10 }}>Delete</Typography>
                    </ListItem>
                    {/* <ListItem style={{ cursor: 'pointer' }} onClick={() => { this.handleClose(); this.props.onAddDiscount() }}>
                        <IconOffer />
                        <Typography style={{ marginLeft: 10 }}>Add Discount</Typography>
                    </ListItem> */}

                    {this.props.showBatchEntry &&
                        <ListItem style={{ cursor: 'pointer' }} onClick={() => { this.handleClose(); this.props.onBatchBarcode() }}>
                            <IconBulkEntry />
                            <Typography style={{ marginLeft: 10 }}>Batch Barcode</Typography>
                        </ListItem>
                    }
                </Popover>
            </React.Fragment>
        );
    }
}
export default SalesItemActionPopover;