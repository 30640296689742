import React, { Component } from 'react';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextField } from '@material-ui/core';


export const DatePickerInput = (props) => {

    return (
        <TextField
            type="text"
            variant="outlined"
            {...props}
            fullWidth
        />
    )
}

class DatePicker extends Component {
    render() {
        return (
            <ReactDatePicker
                selected={this.props.selected}
                onChange={this.props.onChange}
                dateFormat="MMM d, yyyy h:mm aa"
                showTimeSelect
                timeIntervals={15}
                minDate={this.props.minDate}
                label={this.props.label}
                disabled={this.props.disabled}
                shouldCloseOnSelect
                customInput={<TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    label={this.props.label}
                    helperText={this.props.helperText}
                    fullWidth
                />}
            />
        );
    }
}

export default DatePicker;