import React from 'react';
import { Theme, withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Chip } from '@material-ui/core';
import scanRUtils from '../../Services/ScanRUtilService';

const useStyles = createStyles((theme: Theme) => ({}))
interface ManageUnpaidSalesProps extends WithStyles {
    lstUnpaidSales: any[]
    onChange: () => void
}

interface ManageUnpaidSalesState {}
class ManageUnpaidSales extends React.Component<ManageUnpaidSalesProps, ManageUnpaidSalesState> {
    constructor(props: ManageUnpaidSalesProps) {
        super(props);
        let state: ManageUnpaidSalesState = {
        };
        this.state = state;
    }
    render() {

        return (
            <Grid container>
                <Grid item xs={12}>
                    {this.props.lstUnpaidSales.map((objSales: any, index: number) => {
                        let chipProps: any = {}
                        chipProps.label = `${objSales.info.INVNumber} Due Amount-${scanRUtils.prettyPrice(objSales.info.totalDueAmount)}`;
                        chipProps.color = "default";
                        chipProps.clickable = true;
                        if (objSales.isSelected) {
                            chipProps.color = "primary";
                            chipProps.onDelete = (() => {
                                objSales.isSelected = false;
                                this.props.onChange()
                            });
                        }
                        return <Chip key={index} onClick={() => { objSales.isSelected = true; this.props.onChange(); }} style={{ margin: 3 }} {...chipProps} />
                    })}
                </Grid>
            </Grid>
        );
    }
}
export default withStyles(useStyles)(ManageUnpaidSales);