import { Card, CardContent } from '@material-ui/core';
import React from 'react'
import { HyperTheme } from '../../Theme/Theme';
import { Line } from 'react-chartjs-2';
import { IDashboard } from '../../Types/AllTypes';

interface CashFlowProps {
    dashboard: IDashboard
}
interface CashFlowState { }

const theme = HyperTheme;

class CashFlow extends React.Component<CashFlowProps, CashFlowState> {
    data = {
        labels: this.props.dashboard.cashFlow.months,
        datasets: [
            {
                label: 'Cash Inflow',
                fill: false,
                lineTension: 0.5,
                backgroundColor: 'rgba(75,192,192,1)',
                borderColor: '#328aef',
                borderWidth: 2,
                data: this.props.dashboard.cashFlow.inflows
            },
            {
                label: 'Cash Outflow',
                fill: false,
                lineTension: 0.5,
                backgroundColor: 'rgba(75,192,192,1)',
                borderColor: '#ef3232',
                borderWidth: 2,
                data: this.props.dashboard.cashFlow.outflows
            },
            // {
            //     label: 'Balance',
            //     fill: false,
            //     lineTension: 0.5,
            //     backgroundColor: 'rgba(75,192,192,1)',
            //     borderColor: '#6da252',
            //     borderWidth: 2,
            //     data: [60, 35, 60, 75, 80]
            // },
        ]
    }

    render() {
        
        return (
            <Card elevation={3} >
                <CardContent>
                    <Line
                        data={this.data}
                        options={{
                            title: {
                                display: true,
                                text: 'Cash Flow',
                                fontSize: 25,
                                fontColor: theme.palette.primary.main,
                                padding: 10
                            },
                            legend: {
                                display: true,
                                position: 'top',
                                align: 'end'
                            }

                        }}
                    />
                </CardContent>
            </Card>
        );
    }
}

export default CashFlow;
