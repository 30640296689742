import React from 'react'
import { Container, withTheme, Theme, Grid, Paper, Typography, Button } from '@material-ui/core'
// import HyperModal from '../../../Components/HyperInput/HyperModal';
import api from '../../../Services/ApiServices';
import ReportsDataTable from '../../../Components/ReportsDataTable';
import scanRUtils from '../../../Services/ScanRUtilService';
import { JournalSearchOptions } from '../../../Types/SearchOptions';
import enums from '../../../enums';
import { Journal, ICompanySettings } from '../../../Types/AllTypes';
import utils from '../../../Services/Utils';
import HyperDateRangePicker from '../../../Components/HyperDateRangePicker';
import moment from "moment";
import HyperCompanySelection from '../../../Components/HyperInput/HyperCompanySelection';
import auth from '../../../Services/AuthService';


interface ExpanseReportProps {
    theme: Theme
}

interface ExpanseReportState {
    lstExpanse: Journal[]
    totalExpanse: number
    isLoading: boolean
    dateRange: any
}


class ExpanseReport extends React.Component<ExpanseReportProps, ExpanseReportState> {
    constructor(props: ExpanseReportProps) {
        super(props);
        let state: ExpanseReportState = {
            lstExpanse: [],
            totalExpanse: 0,
            isLoading: false,
            dateRange: { startDate: moment().startOf("month").toDate(), endDate: moment().endOf("month").toDate(), label: "This Month" }

        };
        this.state = state;
        this.companySelection = new HyperCompanySelection(this);
    }

    companySelection: HyperCompanySelection;
    componentDidMount() {
        this.getReportDetail()
    }
    getReportDetail = async () => {
        try {
            await this.setState({ isLoading: true })
            let so = new JournalSearchOptions();
            so.category = enums.JOURNAL_CATEGORY.EXPENSE;
            so.fromDate = moment(this.state.dateRange.startDate).format("YYYY-MM-DD")
            so.toDate = moment(this.state.dateRange.endDate).format("YYYY-MM-DD")
            so.companyIds = this.companySelection.getSelectedCompany().map((item: ICompanySettings) => item.id).join(",")
            // console.log(so, "so")
            let lstExpanse = await api.getJournals(so);

            let totalAmount = 0;
            lstExpanse.forEach((objExpanse: any) => {
                totalAmount = totalAmount + objExpanse.amount;
            })
            this.setState({ lstExpanse, totalExpanse: totalAmount })
        }
        catch (ex) {
            console.log(ex)
        }
        finally {
            await this.setState({ isLoading: false })
        }
    }

    render() {
        console.log(this.state.lstExpanse)
        return (
            <Container>
                <Grid container style={{ marginBottom: 20 }}>
                    <Grid item md={6}>
                        {this.companySelection.renderCompanySelection()}
                    </Grid>
                    <Grid item md={6} style={{ textAlign: 'right', margin: 'auto' }}>
                        <Button onClick={this.getReportDetail} variant="contained" color="primary">Refresh</Button>{" "}
                        <HyperDateRangePicker
                            initialDateRange={this.state.dateRange}
                            onChange={async (range: any) => {
                                await this.setState({ dateRange: range });
                                this.getReportDetail()
                            }}
                        />
                    </Grid>
                </Grid>
                <ReportsDataTable
                    key={Date.now()}
                    title="Expanse Report"
                    columns={[
                        {
                            title: "ID", render: (rowData: Journal) => {
                                return `#${rowData.id}`;
                            },
                            sortField: "id"
                        },
                        { title: "Expanse Type", field: "creditAccount.name" },
                        { title: "Paid Using", field: "debitAccount.name" },
                        {
                            title: "Expanse Date", render: (rowData: Journal) => {
                                return utils.convertToSystemDateTimeFormate(new Date(rowData.date));
                            },
                            sortField: "date",
                            renderReturnsString: true
                        },
                        {
                            title: "Amount", render: (rowData: Journal) => {
                                return `${scanRUtils.prettyPrice(rowData.amount)}`
                            }
                        },
                        { title: "Comment", field: "comment" },
                        { title: "Company Name", hidden: auth.isSingleCompany(), field: 'companyName' },
                    ]}
                    data={this.state.lstExpanse}
                    options={{ sorting: true }}
                    isLoading={this.state.isLoading}
                // onRowClick={() => { }}
                />
                <Paper elevation={3} style={{ marginTop: 20, paddingTop: 30, paddingBottom: 30, padding: 20 }}>
                    <Grid container>
                        <Grid item md={6} xs={6}>
                            <Typography variant="h6">Total Expanse</Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Typography variant="h6" align="right" color="primary">{scanRUtils.prettyPrice(this.state.totalExpanse)}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        );
    }
}

export default withTheme(ExpanseReport);